import React, { useState } from 'react'
import Icon from '../../../assets/images/icon.png'
import Dummy from '../../../assets/images/Dummy.png'
import Dummy2 from '../../../assets/images/Dummy2.png'
import Dummy3 from '../../../assets/images/Dummy3.png'
import Dummy4 from '../../../assets/images/Dummy4.png'
import chatGPT from '../../../assets/images/frontend/chatGPT.png'
import "./SponsorOptions.css";


const SponsorOptions = () => {
  const [textContent, setTextContent] = useState(false)
  const [textTitle, setTextTitle] = useState(false)
  return (
    <div className="ai-sponsorship-options-main">
      <img className="ai-sponsorship-options-icon" src={Icon} alt="" />
      <h1>Welcome to AIToolSpot Sponsorship!</h1>
      <p className="ai-sponsor-p">Are you ready to showcase your AI tools to a world of tech enthusiasts and developers? Be among the pioneers to elevate your AI solutions by sponsoring AIToolSpot. As a recently launched platform, we understand that our traffic is currently in its early stages, but we are brimming with ambition and confidence that AIToolSpot will soon become a hub for the AI community.</p>
      <br />
      <h4 className='ai-enquiry-title'>Why Sponsor AIToolSpot?</h4>
      <br />
      <ul className='sponsorship-contain'>
        <li><b>Early Adoption Advantage:</b> By partnering with us now, your company can gain a significant advantage as an early adopter of our platform. As AIToolSpot gains momentum and attracts more visitors, your sponsored AI tool will be at the forefront of the AI revolution.</li>
        <li><b>Targeted Exposure:</b> Reach a highly targeted audience of AI enthusiasts, tech professionals, and developers who are actively seeking innovative AI tools. Your sponsorship ensures that your AI solutions are presented directly to your ideal customers.</li>
        <li><b>Build Brand Authority:</b> Associate your brand with cutting-edge AI technology and position your company as a thought leader in the AI domain. Sponsoring AIToolSpot will enhance your brand's reputation and credibility within the AI community.</li>
        <li><b>Prime Placement:</b> Your sponsored AI tool will be prominently displayed on our website, capturing the attention of potential users and encouraging them to explore the unique features of your AI tool.</li>
        <li><b>Newsletter Promotion:</b> Maximize your reach by sponsoring our newsletter. We offer exclusive promotional spots, enabling you to connect with a rapidly growing subscriber base passionate about AI.</li>
      </ul>
      <br />
      <h4 className='ai-enquiry-title'>What to Expect?</h4>
      <p className="ai-sponsor-p">While it's true that we are still in the early stages and our traffic is gradually building up, our expectations are high. We have a well-planned marketing strategy in place to attract a substantial audience to AIToolSpot. As a sponsor, you will be a fundamental part of this journey.</p>
      <br />
      <h4 className='ai-enquiry-title'>Your Company's Impact</h4>
      <p className="ai-sponsor-p">By supporting AIToolSpot in its nascent phase, you demonstrate your commitment to fostering the growth of the AI community. Your sponsorship directly contributes to the development of a resourceful platform that will empower individuals and businesses with groundbreaking AI solutions.</p>
      <br />
      <h4 className='ai-enquiry-title'>Sponsorship Options</h4>
      <div className="ai-accordion-box">
        <div className="ai-accordion-box-in">
          <div className={`ai-accordion-box-in-title ${textContent ? 'active-accordion' : ''}`} onClick={() => setTextContent(!textContent)}>
            Website sponsorship
          </div>
          {textContent &&
            <div className="ai-accordion-box-in-sub" >
              <p className="ai-sponsor-p">When you choose to sponsor your AI tool on AIToolSpot, your tool will be prominently showcased at the top of the website, giving it maximum exposure to our audience. Sponsored tools are easily distinguishable by the exclusive "Featured" badge, indicating their special status on the platform.</p>
              <p><img src={chatGPT} /></p>
              <br />
              <h4 className='ai-enquiry-title'>Priority Based on Sponsorship Date</h4>
              <p className="ai-sponsor-p">The most recent sponsor secures the first spot. If a new sponsorship comes in, the previous sponsored tool moves down. Let's illustrate how this works with an example:</p>
              <ul className='sponsorship-contain'>
                <li><b>Scenario:</b> Tool X sponsors from August 1st to August 7th, holding the first position.</li>
                <li><b>New Sponsorship:</b> On August 3rd, Tool Y secures a sponsorship slot.</li>
                <li><b>Position Change:</b> As a result, on August 3rd, Tool Y takes the top position, and Tool X moves one spot further.</li>
              </ul>
              <p className="ai-sponsor-p">In addition to the homepage, sponsored tools also enjoy priority placement on the specific category pages.</p>
            </div>
          }
        </div>
        <div className="ai-accordion-box-in">
          <div className={`ai-accordion-box-in-title ${textTitle ? 'active-accordion' : ''}`} onClick={() => setTextTitle(!textTitle)}>
            Newsletter sponsorship
          </div>
          {textTitle &&
            <div className="ai-accordion-box-in-sub" >
              <p className="ai-sponsor-p">If you opt for newsletter sponsorship, your AI tool will be highlighted in the "AI Tools of the Week" section in our newsletter. This section attracts the attention of our engaged subscriber base, who are always eager to discover the latest and most innovative AI solutions.</p>
            </div>
          }
        </div>
      </div>
      <br />
      <h4 className='ai-enquiry-title'>How to Secure Sponsorship</h4>
      <ol className='sponsorship-contain-2'>
        <li><b>Select Your Sponsorship Option:</b> Choose the ideal sponsorship package for your marketing goals and budget. Fill in your company's details and provide information about your AI tool using the provided form.</li>
        <li><b>Choose Promotion Dates:</b> Utilize the calendar feature to select the specific dates you want your AI tool to be promoted on AIToolSpot. Ensure optimal exposure to your target audience.</li>
        <li><b>Payment and Confirmation:</b> Complete the payment process to confirm and secure your sponsorship slot. Once the payment is successful, you will receive a confirmation email with all the details of your sponsorship.</li>
      </ol>
      <br />
      <h4 className='ai-enquiry-title'>Join the AIToolSpot Sponsorship Today!</h4>
      <p className="ai-sponsor-p">The future of AI is bright, and we invite you to be a part of it from the very beginning. Take this opportunity to propel your AI tools to new heights by sponsoring AIToolSpot. Connect with us at <a className='ai-sponsor-com'>sponsor@aitoolspot.com</a> to explore sponsorship opportunities and make a lasting impact in the world of AI.</p>
      <br />
      <p className="ai-sponsor-p"><i>AIToolSpot reserves the right to make data and traffic updates available to sponsors as soon as they become available. We are committed to transparency and will keep our sponsors informed of the platform's progress throughout our journey together.</i></p>



    </div>
  )
}

export default SponsorOptions