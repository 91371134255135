import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg';
import { useLocation, useNavigate } from 'react-router-dom'
import adminService, { bucketUrl } from '../../services/admin.service';
import Multiselect from 'multiselect-react-dropdown';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import toolbar from '../../config/toolbar';
import localization from '../../config/localization';
import draftToHtml from 'draftjs-to-html';
import { Alert, Snackbar } from '@mui/material';
import loaderimg from '../../assets/images/loder2.gif'
import { useDropzone } from 'react-dropzone';
import AddIcon from '@mui/icons-material/Add';
import slugify from 'slugify';

const EditTool = () => {

  const [viewToolData, setViewToolData] = useState({
    toolName: "",
    websiteUrl: "",
    subCategoryId: [],
    pricingId: [],
    featuresId: [],
    startingPrice: "",
    shortDesc: "",
    slug: "",
    metaTitle: "",
    metaDesc: ""
  })
  const [error, setError] = useState({
    toolName: '',
    websiteUrl: '',
    category: '',
    price: '',
    slug: ''
  })
  const [faq, setFaq] = useState([{
    que: "",
    ans: ""
  }])
  const [faqErrors, setFaqErrors] = useState([]);
  const [showFaq, setShowFaq] = useState(false)
  const [selectedFeature, setSelectedFeature] = useState([])
  const [isAffilated, setIsAffilated] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [files, setFiles] = useState([])
  const [message, setMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [loader, setLoader] = useState(false)
  const [content, setContent] = useState(EditorState.createEmpty());
  const [contenthtml, setContenthtml] = useState();
  const [categoryList, setcategortList] = useState([])
  const [priceList, setPriceList] = useState([])
  const [featureList, setFeatureList] = useState([])
  const [hideimagePrev, setHideimagePrev] = useState();
  const [viewHtml, setViewHtml] = useState('')
  const [image, setImage] = useState([])
  const location = useLocation().search
  const getId = new URLSearchParams(location).get("id")
  const navigate = useNavigate()
  useEffect(() => {
    priceListApi()
    featureListApi()
    toolViewApi()
    categoryListAPI()
  }, [])
  useEffect(() => {
    if (faq.length === 0) {
      setShowFaq(false)
    }
  }, [faq])
 
  async function toolViewApi() {
    const response = await adminService.toolViewService(getId)
    if (response.status === 200) {
      setViewToolData(response.data.data)
      setFiles(response.data.data.imageUrl)
      setViewHtml(response.data.data.longDesc)
      setIsAffilated(response.data.data.isAffiliated)
      setIsVerified(response.data.data.isVerified)
      const contentBlock = htmlToDraft(response.data.data.longDesc);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setContent(editorState)
      setShowFaq(response.data.data.isFAQ)
      setFaq(response.data.data.FAQ)
    }
  }
  async function editToolApi() {
    const formData = new FormData()
    formData.append('toolName', viewToolData.toolName)
    formData.append('websiteUrl', viewToolData.websiteUrl)
    viewToolData.subCategoryId.map((item) => (
      formData.append('subCategoryId', item._id)
    ))
    selectedFeature.map((item) => (
      formData.append('featuresId', item._id)
    ))
    viewToolData.pricingId.map((item) => (
      formData.append('pricingId', item._id)
    ))
    formData.append('shortDesc', viewToolData.shortDesc)
    formData.append('startingPrice', viewToolData.startingPrice)
    formData.append('longDesc', contenthtml !== undefined ? contenthtml : viewHtml)
    formData.append('isAffiliated', isAffilated)
    formData.append('isVerified', isVerified)
    formData.append('imageUrl', image.length === 0 ? files : image)
    formData.append('isFAQ', showFaq)
    formData.append('slug', viewToolData.slug)
    formData.append('metaTitle', viewToolData.metaTitle)
    formData.append('metaDesc', viewToolData.metaDesc)
    const excludedKey = '_id';
    faq.forEach((object, index) => {
      const filteredObject = Object.entries(object).reduce((acc, [key, value]) => {
        if (key !== excludedKey) {
          acc[key] = value;
        }
        return acc;
      }, {});
      Object.entries(filteredObject).forEach(([key, value]) => {
        formData.append(`FAQ[${index}][${key}]`, value);
      });
    })
    try {
      const response = await adminService.toolEditService(getId, formData)
      if (response.status === 200) {
        setMessage(response.data.message)
        setOpenSnackbar(true)
        setLoader(false)
        setTimeout(() => {
          navigate('/admin/tools')
        }, 2000)
      }
    } catch (error) {
      setLoader(false)
    }
  }
  const regex = new RegExp(/^https?:\/\/.+/i)
  const validate = () => {
    let toolError = {}
    let isValid = false
    if (viewToolData.toolName.trim() === "") {
      isValid = true
      toolError.toolName = 'Please enter the tool name.'
    }
    if (viewToolData.subCategoryId.length === 0) {
      isValid = true
      toolError.category = 'Please select category'
    }
    if ((viewToolData.pricingId).length === 0) {
      isValid = true
      toolError.price = 'Please select price'
    }
    if (viewToolData.websiteUrl.trim() === "") {
      isValid = true
      toolError.websiteUrl = 'Please enter the Website url.'
    } else if (!regex.test(viewToolData.websiteUrl)) {
      isValid = true
      toolError.websiteUrl = 'Please Enter valid url'
    } else {
      toolError.websiteUrl = ''
    }

    setError(toolError)
    return isValid
  }
  const handleEditOnchange = (e) => {
    const { name, value } = e.target
    setViewToolData({ ...viewToolData, [name]: value })
    switch (name) {
      case "toolName":
        value.trim() === "" ? setError({ ...error, toolName: "Please enter the tool name.", slug: "Please enter slug." }) : setError({ ...error, toolName: "", slug: "" })
        break
      case "websiteUrl":
        value.trim() === "" ? setError({ ...error, websiteUrl: "Please enter the Website url." }) : !regex.test(value) ? setError({ ...error, websiteUrl: "Please enter valid url." }) : setError({ ...error, websiteUrl: "" })
        break
      default:
        break
    }
  }
  //handle change for slug
  const handleSlugChange = (e) => {
    const {name, value } = e.target
    const newValue = slugify(value, { lower: true, strict: true });
    setViewToolData({ ...viewToolData, slug: newValue })
    switch (name) {
      case "slug":
        value.trim() === "" ? setError({ ...error, slug: "Please enter slug." }) : setError({ ...error, slug: "" })
        break
      default:
        break
    }
  }
  const handleEditorStateonChange = (editorState) => {
    setContenthtml(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    setContent(editorState)
  }
  const handleEditTool = (e) => {
    e.preventDefault()
    let isValid = true;

    if (showFaq) {
      // Validate question and answer fields in FAQ section
      for (let i = 0; i < faq.length; i++) {
        const { que, ans } = faq[i];
        if (que.trim() === '') {
          setFaqErrors(prevErrors => {
            const updatedErrors = [...prevErrors];
            updatedErrors[i] = {
              ...updatedErrors[i],
              que: 'Please enter the question.'
            };
            return updatedErrors;
          });
          isValid = false;
        }
        if (ans.trim() === '') {
          setFaqErrors(prevErrors => {
            const updatedErrors = [...prevErrors];
            updatedErrors[i] = {
              ...updatedErrors[i],
              ans: 'Please enter the answer.'
            };
            return updatedErrors;
          });
          isValid = false;
        }
      }
    }
    if (!validate(viewToolData) && isValid) {
      setLoader(true)
      editToolApi()
    }
  }
  async function categoryListAPI() {
    const response = await adminService.subCategoryListingService()
    if (response.status === 200) {
      setcategortList(response.data.data)
    }
  }
  async function priceListApi() {
    const response = await adminService.pricingListingService()
    if (response.status === 200) {
      setPriceList(response.data.data)
    }
  }
  async function featureListApi() {
    const response = await adminService.featureListingService()
    if (response.status === 200) {
      setFeatureList(response.data.data)
    }
  }
  const handleSelectCategory = (selectedList) => {
    setViewToolData({ ...viewToolData, subCategoryId: selectedList })
    selectedList.length === 0 ? setError({ ...error, category: "Please select category." }) : setError({ ...error, category: "" })
  }
  const handleSelectfeature = (selectedList) => {
    setSelectedFeature(selectedList)
  }
  const handleSelectprice = (selectedList) => {
    setViewToolData({ ...viewToolData, pricingId: selectedList })
    selectedList.length === 0 ? setError({ ...error, price: "Please select price." }) : setError({ ...error, price: "" })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }
  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    multiple: false,
    onDrop: e => {
      setHideimagePrev(!hideimagePrev)
      setFiles(URL.createObjectURL(e[0]))
      setImage(e[0])
    }
  });
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const handleAffilate = (e) => {
    setIsAffilated(e.target.checked)
  }
  //allow only number
  const handleKeyPress = (event) => {
    const charCode = event.which || event.keyCode;

    // Allow decimal point (ASCII code 46) and digits (ASCII codes 48 to 57)
    if (charCode === 46 || (charCode >= 48 && charCode <= 57)) {
      // Ensure only one decimal point is entered
      if (charCode === 46 && viewToolData.startingPrice.indexOf('.') !== -1) {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };
  //to add more faq
  const handleAddFaq = () => {
    setFaq([...faq, { que: '', ans: '' }])
  }
  //to remove faq
  const handleRemoveFaq = (e, i) => {
    const removeFaq = faq.filter((item, index) => index !== i)
    setFaq(removeFaq)
  }
  //handle change for faq
  const handleOnchangeFaq = (e, i) => {
    const { name, value } = e.target
    setFaq(prevFaq => {
      const updatedFaq = [...prevFaq];
      updatedFaq[i] = {
        ...updatedFaq[i],
        [name]: value
      };
      return updatedFaq;
    });

    setFaqErrors(prevErrors => {
      const updatedErrors = [...prevErrors];
      switch (name) {
        case 'que':
          updatedErrors[i] = {
            ...updatedErrors[i],
            [name]: value.trim() === '' ? 'Please enter the question.' : ''
          };
          break;
        case 'ans':
          updatedErrors[i] = {
            ...updatedErrors[i],
            [name]: value.trim() === '' ? 'Please enter the answer.' : ''
          };
          break;
        default:
          break;
      }
      return updatedErrors;
    });
  }
  return (
    <>
      <div className="page-wrapper doctris-theme toggled">
        <main className="page-content bg-light">
          <div className="container-fluid">
            <div className="layout-specing">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="mb-0">Edit Tool</h4>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card  rounded-md  border">
                    <div className="py-2 px-3">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="cstm-label">Tool Name<span className='cstm-astix'>*</span></label>
                            <input type="text" value={viewToolData.toolName} onChange={handleEditOnchange} className="cstm-input" placeholder="Enter tool name"
                              name="toolName" required="" />
                            <span className='error-message'>{error.toolName}</span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="cstm-label">Website URL<span className='cstm-astix'>*</span></label>
                            <input type="url" value={viewToolData.websiteUrl} className="cstm-input" onChange={handleEditOnchange} placeholder="Enter website url"
                              name="websiteUrl" required="" />
                            <span className='error-message'>{error.websiteUrl}</span>
                            <div className='cust-isaffilate'>
                              <input type='checkbox' checked={isAffilated} onChange={handleAffilate} /><label>Affilated Link</label>
                            </div>
                          </div>
                        </div>
                        {/* slug */}
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Slug<span className='cstm-astix'>*</span></label>
                            <input type="text" value={viewToolData.slug} className="cstm-input" placeholder="Enter your tool name"
                              name="slug" onChange={handleSlugChange} required="" />
                              <span className='error-message'>{error.slug}</span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="cstm-label">Select Categories (max 3)<span className='cstm-astix'>*</span></label>
                            <Multiselect
                              hidePlaceholder
                              placeholder={'Select categories'}
                              onSearch={function noRefCheck() { }}
                              selectionLimit={3}
                              className=''
                              name='subCategoryId'
                              displayValue='subCategoryName'
                              onSelect={handleSelectCategory}
                              onRemove={handleSelectCategory}
                              selectedValues={viewToolData.subCategoryId}
                              options={categoryList.map((item, i) => ({
                                subCategoryName: item.subCategoryName,
                                _id: item._id
                              }))}
                            />
                            <span className='error-message'>{error.category}</span>
                          </div>

                        </div>
                        <div className="col-lg-6 cstm-select-features">
                          <div className="mb-4">
                            <label className="cstm-label">Select Features (Optional)</label>
                            <Multiselect
                              hidePlaceholder
                              placeholder={'Select features'}
                              name='name'
                              displayValue='name'
                              onSelect={handleSelectfeature}
                              onRemove={handleSelectfeature}
                              selectedValues={viewToolData.featuresId}
                              options={featureList.map((item, i) => ({
                                name: item.name,
                                _id: item._id
                              }))}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 cstm-select-features">
                          <div className="mb-4">
                            <label className="cstm-label">Pricing-Select freemium if your tool has both paid and free versions<span className='cstm-astix'>*</span></label>
                            <Multiselect
                              hidePlaceholder
                              placeholder={'Select price'}
                              name='name'
                              displayValue='name'
                              onSelect={handleSelectprice}
                              onRemove={handleSelectprice}
                              selectedValues={viewToolData.pricingId}
                              options={priceList.map((item, i) => (
                                {
                                  name: item.name,
                                  _id: item._id
                                }
                              ))}
                            />
                            <span className='error-message'>{error.price}</span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="cstm-label">Starting Price (Optional)</label>
                            <input type="text" value={viewToolData.startingPrice} onChange={handleEditOnchange} className="cstm-input" placeholder="Enter starting price"
                              name="startingPrice" required="" onKeyPress={handleKeyPress} />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="cstm-label">Meta Title</label>
                            <input type="text" onChange={handleEditOnchange} value={viewToolData.metaTitle} className="cstm-input" placeholder="Enter meta title"
                              name="metaTitle" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="cstm-label">Meta Description</label>
                            <input type="text" onChange={handleEditOnchange} value={viewToolData.metaDesc} className="cstm-input" placeholder="Enter met description"
                              name="metaDesc" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Tools Short Description(Optional)</label>
                            <input type="text" value={viewToolData.shortDesc} onChange={handleEditOnchange} className="cstm-input" placeholder="Enter short description"
                              name="shortDesc" required="" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Tool Description (Optional)</label>
                            <Editor
                              editorState={content}
                              onEditorStateChange={handleEditorStateonChange}
                              placeholder='Enter long description'
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              toolbar={toolbar}
                              localization={localization}
                            />
                          </div>
                        </div>
                        <div className='cust-isverfied'>
                          <input type='checkbox' checked={showFaq} onClick={() => setShowFaq(!showFaq)} /> <label>Enable FAQ</label>
                        </div>
                        {showFaq &&
                          <>
                            <div className='add-faq-button'>
                              <button onClick={handleAddFaq}>Add FAQ</button>
                            </div>
                            {faq.map((item, i) => (
                              <>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label className="cstm-label">Question<span className='cstm-astix'>*</span></label>
                                    <input type="text" className="cstm-input" onChange={(e) => handleOnchangeFaq(e, i)} placeholder="Enter your question"
                                      value={item.que} name="que" required="" />
                                    <span className='error-message'>{faqErrors[i]?.que}</span>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label className="cstm-label">Answer<span className='cstm-astix'>*</span></label>
                                    <input type="text" className="cstm-input" placeholder="Enter your answer" onChange={(e) => handleOnchangeFaq(e, i)}
                                      value={item.ans} name="ans" required="" />
                                    <span className='error-message'>{faqErrors[i]?.ans}</span>
                                  </div>
                                </div>
                                <div className='remove-faq-button'>
                                  <button onClick={(e) => handleRemoveFaq(e, i)}>Remove FAQ</button>
                                </div>
                              </>
                            ))}
                          </>
                        }
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Feature image<span className='cstm-astix'>*</span></label>
                            <div {...getRootProps({ className: 'dropzone blog-dropzone' })}>
                              <input {...getInputProps()} />
                              <p> <span> <AddIcon /></span>  Drop your image here, or <span> browse</span></p>
                            </div>
                            {files && !hideimagePrev ? (
                              <>
                                <div className='col-1'>
                                  <div className='ai-uploaded-container'>
                                    <img
                                      className='ai-uploaded-items'
                                      src={bucketUrl + files}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='col-1'>
                                  <div className='ai-uploaded-container'>
                                    <img className='ai-uploaded-items' src={files ? files : ''} />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <span className='error-message'>{error.imageUrl || fileRejectionItems}</span>
                        </div>
                        <div className='cust-isverfied'>
                          <input type='checkbox' checked={isVerified} onChange={() => setIsVerified(!isVerified)} /> <label>isVerified</label>
                        </div>
                        <div className="pb-3 mt-2 button-div">

                          <div className="text-center cstn-loader-sec">
                            <button disabled={loader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleEditTool}>Submit</button>
                            {loader &&
                              <div className='loader-container-cstm'>
                                <img className='btn-pop-loader' src={loaderimg} />
                              </div>
                            }
                          </div>
                          <button className="cstm-btn2 cstm-btn-text-bordered" onClick={() => navigate("/admin/tools")}>Cancel</button>
                        </div>
                      </div>
                      <Snackbar
                        open={openSnackbar}
                        onClose={handleClose}
                        autoHideDuration={5000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      >
                        <Alert onClose={handleClose} variant='filled' severity='success'>{message}</Alert>
                      </Snackbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default EditTool;