import { Tooltip } from '@mui/material';
import React from 'react'

const Dashboard = () => {
  return (
    <>
      <div className="page-wrapper doctris-theme toggled">
        <main className="page-content bg-light">
          <div className="container-fluid">
            <div className="layout-specing">

              <h4 className="mb-4">Dashboard</h4>
              <div className="row">
                <Tooltip title='Tools' arrow placement='top'>
                  <div className="col-xl-3 col-lg-6 col-md-3">
                    <div className="card features feature-primary border rounded-md p-3">
                      <div className="d-flex align-items-center">
                        <div className="cstm-icon4">
                          <img src="./assets/images/total-revenue.png" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h4 className="mb-0">3000</h4>
                          <p className="text-muted mb-0 cstm-tx14">Total Tools</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <Tooltip title='Users' arrow placement='top'>
                  <div className="col-xl-3 col-lg-6 col-md-3">
                    <div className="card features feature-primary border rounded-md p-3">
                      <div className="d-flex align-items-center">
                        <div className="cstm-icon4">
                          <img src="./assets/images/total-doctors.png" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h4 className="mb-0">450</h4>
                          <p className="text-muted mb-0 cstm-tx14">Total Users</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <Tooltip title='Categories' arrow placement='top'>
                  <div className="col-xl-3 col-lg-6 col-md-3">
                    <div className="card features feature-primary border rounded-md p-3">
                      <div className="d-flex align-items-center">
                        <div className="cstm-icon4">
                          <img src="./assets/images/pharmacy-professionals.png" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h4 className="mb-0">980</h4>
                          <p className="text-muted mb-0 cstm-tx14">Total Categories</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <Tooltip title='SubCategories' arrow placement='top'>
                  <div className="col-xl-3 col-lg-6 col-md-3">
                    <div className="card features feature-primary border rounded-md p-3">
                      <div className="d-flex align-items-center">
                        <div className="cstm-icon4">
                          <img src="./assets/images/lab-professionals.png" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h4 className="mb-0">1024</h4>
                          <p className="text-muted mb-0 cstm-tx14">Total SubCategories</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <Tooltip title='Feedbacks' arrow placement='bottom'>
                  <div className="col-xl-3 col-lg-6 col-md-3">
                    <div className="card features feature-primary border rounded-md p-3">
                      <div className="d-flex align-items-center">
                        <div className="cstm-icon4">
                          <img src="./assets/images/lab-professionals.png" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h4 className="mb-0">1024</h4>
                          <p className="text-muted mb-0 cstm-tx14">Total Feedbacks</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <Tooltip title='Pending' arrow placement='bottom'>
                  <div className="col-xl-3 col-lg-6 col-md-3">
                    <div className="card features feature-primary border rounded-md p-3">
                      <div className="d-flex align-items-center">
                        <div className="cstm-icon4">
                          <img src="./assets/images/lab-professionals.png" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h4 className="mb-0">1024</h4>
                          <p className="text-muted mb-0 cstm-tx14">Total Verification Pending</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <Tooltip title='Verified' arrow placement='bottom'>
                  <div className="col-xl-3 col-lg-6 col-md-3">
                    <div className="card features feature-primary border rounded-md p-3">
                      <div className="d-flex align-items-center">
                        <div className="cstm-icon4">
                          <img src="./assets/images/lab-professionals.png" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h4 className="mb-0">1024</h4>
                          <p className="text-muted mb-0 cstm-tx14">Total verified Tool</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </div>
              {/* <div className="row">
                <div className="col-xl-8 col-lg-8 mt-4">
                  <div className="card border rounded-md p-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h5 className="align-items-center mb-0">Patients Visit By Gender</h5>
                      <div className="mb-0 position-relative">
                        <select className="form-select form-control" id="yearchart">
                          <option selected>2021</option>
                          <option value="2019">2020</option>
                          <option value="2018">2019</option>
                        </select>
                      </div>
                    </div>
                    <div className="cstm-revenue-analytics">
                      <img src="./assets/images/ra-graf.svg" />
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 mt-4">
                  <div className="card  border rounded-md height-full">
                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom mb-2">
                      <h5 className="align-items-center mb-0">Revenue</h5>
                      <div className="mb-0 position-relative">
                        <button className="cstm-btn3">View Report</button>
                      </div>
                    </div>
                    <div className="d-flex p-3 align-items-center mt-3 mb-5">
                      <div className="col-xl-5 col-lg-5">
                        <span className="cstm-rvn-pc">$300k</span>
                        <p className="text-muted mb-0 cstm-tx14">Lab Professionals</p>
                      </div>
                      <div className="col-xl-7 col-lg-7"><img src="./assets/images/revenue-map.svg" className="width-full" />
                      </div>
                    </div>

                    <div className="d-flex p-3 align-items-centerm-2 justify-content-between">

                      <div className="d-flex align-items-center">
                        <div className="cstm-icon4">
                          <img src="./assets/images/total-expense.png" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h4 className="mb-0">$250</h4>
                          <p className="text-muted mb-0 cstm-tx14">Total expense</p>
                        </div>
                      </div>

                      <div className="d-flex align-items-center">
                        <div className="cstm-icon4">
                          <img src="./assets/images/remaining-balance.png" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h4 className="mb-0">$1000</h4>
                          <p className="text-muted mb-0 cstm-tx14">Remaining balance</p>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div> */}

              {/* <div className="row">
                <div className="col-xl-6 col-lg-6 mt-4">
                  <div className="card  border rounded-md rounded">
                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                      <h5 className="mb-0"> <img src="./assets/images/stethoscope.png" className="mr-2" /> Pending Verification
                        <span className="cstm-dr">(50)</span></h5>
                      <button className="cstm-btn4">View All</button>
                    </div>
                    <ul className="list-unstyled mb-0 p-3">
                      <li className="mb-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-inline-flex">
                            <img src="./assets/images/dummy.png" className="cstm-rco-photo" />
                            <div className="ms-3">
                              <h5 className="text-dark mb-0 d-block">Dr. Jenny Cooper</h5>
                              <small className="text-muted">Heart Sergon</small>
                            </div>
                          </div>
                          <div className="cstm-ro-icon">
                            <a href="#" className="cstm-eye"><i className="fi fi-rr-eye"></i></a>
                            <a href="#" className="cstm-chekmank"><i className="fi fi-rr-check"></i></a>
                            <a href="#" className="cstm-cross mrn-rt"><i className="fi fi-rr-cross"></i></a>
                          </div>
                        </div>
                      </li>
                      <li className="mb-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-inline-flex">
                            <img src="./assets/images/dummy.png" className="cstm-rco-photo" />
                            <div className="ms-3">
                              <h5 className="text-dark mb-0 d-block">Dr. Jenny Cooper</h5>
                              <small className="text-muted">Heart Sergon</small>
                            </div>
                          </div>
                          <div className="cstm-ro-icon">
                            <a href="#" className="cstm-eye"><i className="fi fi-rr-eye"></i></a>
                            <a href="#" className="cstm-chekmank"><i className="fi fi-rr-check"></i></a>
                            <a href="#" className="cstm-cross mrn-rt"><i className="fi fi-rr-cross"></i></a>
                          </div>
                        </div>
                      </li>
                      <li className="mb-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-inline-flex">
                            <img src="./assets/images/dummy.png" className="cstm-rco-photo" />
                            <div className="ms-3">
                              <h5 className="text-dark mb-0 d-block">Dr. Jenny Cooper</h5>
                              <small className="text-muted">Heart Sergon</small>
                            </div>
                          </div>
                          <div className="cstm-ro-icon">
                            <a href="#" className="cstm-eye"><i className="fi fi-rr-eye"></i></a>
                            <a href="#" className="cstm-chekmank"><i className="fi fi-rr-check"></i></a>
                            <a href="#" className="cstm-cross mrn-rt"><i className="fi fi-rr-cross"></i></a>
                          </div>
                        </div>
                      </li>
                      <li className="mb-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-inline-flex">
                            <img src="./assets/images/dummy.png" className="cstm-rco-photo" />
                            <div className="ms-3">
                              <h5 className="text-dark mb-0 d-block">Dr. Jenny Cooper</h5>
                              <small className="text-muted">Heart Sergon</small>
                            </div>
                          </div>
                          <div className="cstm-ro-icon">
                            <a href="#" className="cstm-eye"><i className="fi fi-rr-eye"></i></a>
                            <a href="#" className="cstm-chekmank"><i className="fi fi-rr-check"></i></a>
                            <a href="#" className="cstm-cross mrn-rt"><i className="fi fi-rr-cross"></i></a>
                          </div>
                        </div>
                      </li>
                      <li className="mb-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-inline-flex">
                            <img src="./assets/images/dummy.png" className="cstm-rco-photo" />
                            <div className="ms-3">
                              <h5 className="text-dark mb-0 d-block">Dr. Jenny Cooper</h5>
                              <small className="text-muted">Heart Sergon</small>
                            </div>
                          </div>
                          <div className="cstm-ro-icon">
                            <a href="#" className="cstm-eye"><i className="fi fi-rr-eye"></i></a>
                            <a href="#" className="cstm-chekmank"><i className="fi fi-rr-check"></i></a>
                            <a href="#" className="cstm-cross mrn-rt"><i className="fi fi-rr-cross"></i></a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 mt-4">
                  <div className="card  border rounded-md rounded">
                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                      <h5 className="mb-0"> <img src="./assets/images/wheelchair.png" className="mr-2" /> Requested Charity
                        Organization</h5>
                      <button className="cstm-btn4">View All</button>
                    </div>

                    <ul className="list-unstyled mb-0 p-3 cstm-requested-charity-crganization">

                      <li className="d-flex align-items-center justify-content-between mb-3">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/dummy.png"
                            className="avatar avatar-small rounded-circle border shadow" alt="" />
                          <div className="flex-1 ms-3">
                            <span className="d-block h5 mb-0">Dr. Calvin Carlo</span>
                            <small className="text-muted">Orthopedic</small>

                            <ul className="list-unstyled mb-0">
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item text-muted">(45)</li>
                            </ul>

                          </div>
                        </div>
                        <p className="cstm-ls-cnt">150 Patients</p>
                      </li>

                      <li className="d-flex align-items-center justify-content-between mb-3">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/dummy.png"
                            className="avatar avatar-small rounded-circle border shadow" alt="" />
                          <div className="flex-1 ms-3">
                            <span className="d-block h5 mb-0">Dr. Calvin Carlo</span>
                            <small className="text-muted">Orthopedic</small>

                            <ul className="list-unstyled mb-0">
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item text-muted">(45)</li>
                            </ul>

                          </div>
                        </div>
                        <p className="cstm-ls-cnt">150 Patients</p>
                      </li>

                      <li className="d-flex align-items-center justify-content-between  mb-3">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/dummy.png"
                            className="avatar avatar-small rounded-circle border shadow" alt="" />
                          <div className="flex-1 ms-3">
                            <span className="d-block h5 mb-0">Dr. Calvin Carlo</span>
                            <small className="text-muted">Orthopedic</small>

                            <ul className="list-unstyled mb-0">
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item text-muted">(45)</li>
                            </ul>

                          </div>
                        </div>
                        <p className="cstm-ls-cnt">150 Patients</p>
                      </li>


                      <li className="d-flex align-items-center justify-content-between  mb-3">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/dummy.png"
                            className="avatar avatar-small rounded-circle border shadow" alt="" />
                          <div className="flex-1 ms-3">
                            <span className="d-block h5 mb-0">Dr. Calvin Carlo</span>
                            <small className="text-muted">Orthopedic</small>

                            <ul className="list-unstyled mb-0">
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item text-muted">(45)</li>
                            </ul>

                          </div>
                        </div>
                        <p className="cstm-ls-cnt">150 Patients</p>
                      </li>


                      <li className="d-flex align-items-center justify-content-between  mb-3">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/dummy.png"
                            className="avatar avatar-small rounded-circle border shadow" alt="" />
                          <div className="flex-1 ms-3">
                            <span className="d-block h5 mb-0">Dr. Calvin Carlo</span>
                            <small className="text-muted">Orthopedic</small>

                            <ul className="list-unstyled mb-0">
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item text-muted">(45)</li>
                            </ul>

                          </div>
                        </div>
                        <p className="cstm-ls-cnt">150 Patients</p>
                      </li>

                      <li className="d-flex align-items-center justify-content-between  mb-3">
                        <div className="d-flex align-items-center">
                          <img src="./assets/images/dummy.png"
                            className="avatar avatar-small rounded-circle border shadow" alt="" />
                          <div className="flex-1 ms-3">
                            <span className="d-block h5 mb-0">Dr. Calvin Carlo</span>
                            <small className="text-muted">Orthopedic</small>

                            <ul className="list-unstyled mb-0">
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                              <li className="list-inline-item text-muted">(45)</li>
                            </ul>

                          </div>
                        </div>
                        <p className="cstm-ls-cnt">150 Patients</p>
                      </li>

                    </ul>

                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default Dashboard;