import React from 'react'

const Priceicon = () => {
  return (
  <>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    className="ai-side-icons"
  >
    <path
      fill="#011367"
      fillRule="evenodd"
      d="m5.21 5.72-.332 4.982a.548.548 0 0 0 .16.424l8.79 8.79a.548.548 0 0 0 .774 0l5.314-5.314a.548.548 0 0 0 0-.775l-8.79-8.79a.548.548 0 0 0-.424-.159L5.72 5.21a.548.548 0 0 0-.51.51Zm-1.64-.109-.332 4.982c-.042.63.19 1.249.637 1.696l8.79 8.79a2.192 2.192 0 0 0 3.1 0l5.314-5.314a2.192 2.192 0 0 0 0-3.1l-8.79-8.79a2.192 2.192 0 0 0-1.696-.637l-4.982.332A2.192 2.192 0 0 0 3.57 5.61Z"
      clipRule="evenodd"
    />
    <circle cx={8} cy={8} r={1} fill="#011367" />
  </svg>
  </>
  )
}

export default Priceicon
