import React from 'react'
import { Link } from 'react-router-dom';
import userImg from '../../assets/images/user.png'
import { Avatar } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Header = () => {

    var userName = localStorage.getItem("name")
    const handleLogout = () => {
        localStorage.clear()
        window.location.href = '/admin'
    }
    return (
        <>
            <div className="page-wrapper doctris-theme toggled">
                <main className="page-content">
                    <div className="top-header">
                        <div className="header-bar d-flex justify-content-end border-bottom">

                            <ul className="list-unstyled mb-0">
                                {/* <li className="list-inline-item mb-0 ms-2">
                                    <Link href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                                        aria-controls="offcanvasRight">
                                        <div className="btn btn-icon btn-pills btn-soft-primary"><i className="fi fi-rr-bell"></i></div>
                                    </Link>
                                </li> */}
                                <li className="list-inline-item mb-0 ms-2">
                                    <div className="dropdown dropdown-primary">
                                        <button type="button" className="btn btn-pills dropdown-toggle p-0" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            <Avatar />
                                            {/* <img src={userImg}
                                                className="cstm-user-main-photo" alt="" /> */}
                                            {/* <i className="fi fi-rr-caret-down cstm-icon-mn"></i> */}
                                        </button>
                                        <div className="dropdown-menu dd-menu dropdown-menu-end csrm-user-dv">
                                            <Link className="active-us">{userName}</Link>
                                            <a href='/admin/reset-password'>Reset Password</a>
                                            <Link onClick={handleLogout} className="cstm-logout">Logout</Link>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default Header;