import React, { useEffect, useState } from 'react'
import Pagination from '../../pagination/Pagination'
import viewIcon from '../../../assets/images/view.svg'
import verify from "../../../assets/images/verify.svg";
import reject from "../../../assets/images/reject.svg";
import { Link } from 'react-router-dom'
import adminService from '../../../services/admin.service';
import moment from 'moment';
import Loader from '../../loader/Loader';
import loaderimg from '../../../assets/images/loder2.gif'
import { Alert, Snackbar } from '@mui/material';

const Review = () => {

    const [reviewListing, setReviewListing] = useState([])
    const [initialData, setInitialData] = useState([])
    const [getReviewData, setGetReviewData] = useState({})
    const [searchValue, setSearchValue] = useState('');
    const [loader, setLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 20
    const excludeColumns = ["_id"];
    var DataArray = []
    useEffect(() => {
        setLoader(true)
        reviewListingApi()
    }, [])
    function createData(_id, toolName, email, Rating, Review, date, status) {
        return {
            _id,
            toolName,
            email,
            Rating,
            Review,
            date,
            status
        };
    }
    async function reviewListingApi() {
        try {
            const response = await adminService.reviewListingService()
            if (response.status === 200) {
                var arr = response.data.data;
                if (response.data.data.length > 0) {
                    var newArr = []
                    for (var i = 0; i < arr.length; i++) {
                        var tool = arr[i].toolId !== null && arr[i].status !== "Pending" ? arr[i].toolId.toolName : "-"
                        var date = moment(arr[i].createdAt).format('DD MMM, YYYY')
                        var review = arr[i].review !== undefined ? arr[i].review : "-"
                        newArr[i] = createData(
                            arr[i]._id,
                            tool,
                            arr[i].userId.email,
                            arr[i].rating,
                            review,
                            date,
                            arr[i].status
                        )
                    }
                    newArr.map((data1) => {
                        DataArray = [...DataArray, data1]
                    })
                    DataArray = DataArray.sort().reverse()
                    setReviewListing(DataArray)
                    setInitialData(DataArray)
                    setLoader(false)
                }
            }
        } catch (error) {

        }
    }
    async function getReviewApi(id) {
        try {
            const response = await adminService.getReviewById(id)
            if (response.status === 200) {
                setGetReviewData(response.data.data)
            }
        } catch (error) {

        }
    }
    const onClickGetReview = (e, id) => {
        getReviewApi(id)
    }
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const onChangeFilter = (searchValue) => {
        setSearchValue(searchValue)
        if (searchValue !== '') {
            const filteredData = initialData.filter((item) => {
                return Object.keys(item).some(key =>
                    excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
                );
            })
            setReviewListing(filteredData)
        } else {
            setReviewListing(initialData)
        }
    }
    const finalList = reviewListing.filter((item) => item.status !== 'Pending')
    const indexOfLastRecord = currentPage * recordPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
    const currentRecords = finalList.slice(indexOfFirstRecord, indexOfLastRecord);

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(finalList.length / recordPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };
    return (
        <div className="page-wrapper doctris-theme toggled">
            <main className="page-content">
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="mb-0">Review Management</h4>
                            {/* <div className="cstm-bre uppercase">dashboard YEAR LONG COURSE <Link to="/topic">TOPICS</Link></div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card rounded-md border min-height-sc">
                                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                        <div className="col-md-12">
                                            <i className="fi fi-br-search cstm-search-ro"></i>
                                            <input name="name" id="name" onChange={(e) => onChangeFilter(e.target.value)}
                                                type="text" className="cstm-input-seacrh" placeholder="Search User" />

                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-12">
                                        <div className="table-responsive bg-white rounded">
                                            <table className="table mb-0 table-center">
                                                <thead>
                                                    <tr>
                                                        <th className="border-bottom w-4">No.</th>
                                                        <th className="border-bottom w-12">Tool Name</th>
                                                        <th className="border-bottom w-12">Email</th>
                                                        <th className="border-bottom w-12">Rating</th>
                                                        <th className="border-bottom w-10">Date</th>
                                                        <th className="border-bottom w-10">Status</th>
                                                        <th className="border-bottom w-11">Action</th>
                                                    </tr>
                                                </thead>
                                                {loader ?
                                                    <div className='loader-container'>
                                                        <Loader />
                                                    </div> :
                                                    (reviewListing.length === 0 ? <span className="cstm-norecord">No Record Found</span> :
                                                        currentRecords.map((item, i) => (
                                                            <tbody>
                                                                <tr>
                                                                    <td className="fw-bold">{i + 1}</td>
                                                                    <td>{item.toolName}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.Rating}</td>
                                                                    <td>{item.date}</td>
                                                                    <td>{item.status}</td>
                                                                    <td>
                                                                        <Link onClick={(e) => onClickGetReview(e, item._id)} className="cstm-btn6" data-bs-toggle="modal" data-bs-target="#viewReview">
                                                                            <i><img src={viewIcon} /></i>
                                                                        </Link>
                                                                        <div className="modal fade" id="viewReview" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                                                            <div className="modal-dialog modal-dialog-centered">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header border-0 p-4">
                                                                                        <h4 className="modal-title" id="exampleModalLabel1">Review</h4>
                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                                                                                    </div>
                                                                                    <div className='ai-admin-html-content' dangerouslySetInnerHTML={{ __html: getReviewData.review }}>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )))
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Pagignation */}
                        <Pagination
                            recordPerPage={recordPerPage}
                            totalRecord={finalList.length}
                            paginate={paginate}
                            currentPage={currentPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                        />
                    </div>
                </div>

            </main>
        </div>
    )
}

export default Review