import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Pagination = (props) => {
    const { recordPerPage, totalRecord, paginate, previousPage, nextPage, currentPage } = props
    const pageNumber = [];

    for (let i = 1; i <= Math.ceil(totalRecord / recordPerPage); i++) {
        pageNumber.push(i);
    }
    return (
        <>
            <div className="row text-center">
                <div className="col-12 mt-2">
                    <div className="d-md-flex align-items-center text-center justify-content-between">
                        <span className="text-muted me-3">Showing {currentPage}-{pageNumber.length} out of {totalRecord}</span>
                        <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                            <li onClick={previousPage} className="page-item"><Link className="page-link"
                                aria-label="Previous">Prev</Link></li>
                            {pageNumber.map((number) => (
                                <li key={number} onClick={() => paginate(number)} className="page-item">
                                    <Link className='page-link'>{number}</Link>
                                </li>
                            ))}
                            <li onClick={nextPage} className="page-item"><Link className="page-link"
                                aria-label="Next">Next</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pagination;