import React from "react";

const Feedbackicon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        className="ai-side-icons ai-feedback-icon"
      >
        <path
          fill="#011367"
          d="M6.297 22h-.274c-.344-.134-.477-.4-.473-.789.012-.91.004-1.82.004-2.73v-.234c-.595 0-1.17.004-1.742 0-1.049-.008-1.808-.81-1.808-1.933C2 12.19 1.996 8.066 2.008 3.941c0-.288.059-.601.176-.864C2.517 2.326 3.115 2 3.886 2h16.345c.587.02 1.088.25 1.417.776.164.263.239.59.352.89V16.58c-.059.197-.094.405-.176.59-.345.738-.924 1.076-1.687 1.076-3.343 0-6.685 0-10.031-.004a.443.443 0 0 0-.356.159 545.516 545.516 0 0 1-2.955 3.147c-.15.171-.33.305-.498.451Zm.431-2.192c.02.013.04.03.063.042.043-.063.078-.13.129-.184.72-.772 1.452-1.536 2.164-2.316.231-.255.482-.355.81-.355 3.417.008 6.838.004 10.255.004.458 0 .685-.242.685-.726V3.983c0-.489-.227-.73-.681-.73H3.859c-.458 0-.685.237-.685.726v12.29c0 .492.223.73.681.73h2.149c.513 0 .724.225.724.768v2.041Z"
        />
        <path
          fill="#011367"
          d="m8.625 13.733.316-1.813c.037-.21.054-.423.116-.624.065-.218-.013-.35-.169-.493-.563-.518-1.114-1.052-1.664-1.583-.185-.176-.276-.39-.194-.649.079-.255.26-.39.518-.435.128-.021.255-.042.387-.062.702-.099 1.405-.193 2.108-.3.082-.012.177-.095.218-.173.37-.707.723-1.426 1.089-2.137.201-.386.608-.513.92-.275.107.082.197.21.26.333.353.686.698 1.372 1.043 2.067.062.123.136.177.275.197.793.103 1.582.218 2.376.333.275.041.497.156.587.44.095.283-.02.505-.222.698-.57.543-1.138 1.085-1.71 1.624-.098.094-.135.18-.11.32.144.773.271 1.545.41 2.318.05.267-.011.49-.225.658-.218.168-.456.16-.695.037-.723-.37-1.446-.74-2.165-1.118-.124-.066-.218-.062-.342.004-.706.37-1.417.731-2.132 1.093-.317.16-.576.152-.769-.066-.115-.123-.173-.296-.226-.394Zm5.133-1.16a4.88 4.88 0 0 1-.02-.114c-.083-.469-.16-.941-.251-1.41-.062-.316 0-.575.255-.789.168-.14.324-.292.476-.452.201-.21.386-.435.567-.637l-.168-.029c-.46-.065-.917-.14-1.377-.197a.705.705 0 0 1-.58-.427c-.213-.436-.435-.867-.653-1.299-.02-.045-.053-.082-.09-.14-.181.362-.354.695-.522 1.028-.423.846-.341.776-1.307.908-.362.05-.723.107-1.122.164.325.313.6.62.92.872.453.345.564.756.416 1.294-.107.386-.144.793-.218 1.233.514-.263.986-.497 1.455-.748.259-.136.501-.144.756-.004.28.152.571.296.855.44.189.094.382.193.608.308Z"
        />
      </svg>
    </>
  );
};

export default Feedbackicon;
