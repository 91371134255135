import React, { useEffect, useState, useRef } from "react";
// CSS
import "./HomeBanner.css";
// images
import Aibadge from "../../../assets/images/frontend/ai-badge.svg";
import Users from "../../../assets/images/frontend/users.svg";
import Mark from "../../../assets/images/frontend/mark.svg";
import linkedIn from "../../../assets/images/frontend/linkedin.svg";
import Twitter from "../../../assets/images/frontend/twitter.svg";
import youTube from "../../../assets/images/frontend/youtube.svg";
import Setting from "../../../assets/images/frontend/setting.svg";
import Document from "../../../assets/images/frontend/document.svg";
import DocumentHover from "../../../assets/images/frontend/document-hover.svg";
import searchDark from "../../../assets/images/frontend/search-dark.svg";
import arrowDown from "../../../assets/images/frontend/arrow-down.svg";
import categoryIcon from "../../../assets/images/frontend/category-icon.svg";
import featuredIcon from "../../../assets/images/frontend/featured.svg";
import pricingIcon from "../../../assets/images/frontend/pricing.svg";
import Closered from "../../../assets/images/frontend/closered.svg";
import Closewhite from "../../../assets/images/frontend/closewhite.svg";
import verifiedIcon from "../../../assets/images/frontend/verified.svg";
import New from "../../../assets/images/frontend/new.svg";
//services
import frontendService from "../../../services/frontend.service";
import { Link, useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Cookies from "js-cookie";
import { Alert, Snackbar } from "@mui/material";
import Seo from "../../seo/Seo";

const Homebanner = ({
  subCtegoryData,
  categoryFilter,
  featureData,
  priceData,
  selectedPrice,
  openDropdownStatus,
  openDropdown,
  handleStatus,
  selectedStatus,
  onClickPrice,
  onRemovePriceFilter,
  onClickfilterFeature,
  onRemoveSelectedData,
  featureArray,
  selectedList,
  handleClearAll,
  selectedCategory,
  searchToolList,
  suggestionlist,
  subCategoryList,
  handleClickgetquery,
  onTextChanged,
  closeMenu,
  text,
  filteredSuggestions,
  displaySuggestions,
  displayToolSuggestions,
  displaytoolSuggestionsList,
  catOpen,
  setCateOpen,
  closeDropDown,
  closeCategoryDropDown,
  discription,
  metaTitle,
  toolList
}) => {
  var categoryQyery = window.location.pathname
    .substring(10)
    .split("-")
    .join(" ").split("+").join('/')
  //data
  const [homeBannerData, setHomeBannerData] = useState({});
  const [toggleFeature, setToggleFeature] = useState(false);
  const [togglePrice, setTogglePrice] = useState(false);
  const navigate = useNavigate();
  const featureRef = useRef(null);
  const priceRef = useRef(null);
  const [seoData, setSeoData] = useState({ title: '', description: '' });

  useEffect(() => {
    homeBannerApi();
  }, []);

  const closeOpenMenus = (e) => {
    if (
      priceRef.current &&
      togglePrice &&
      !priceRef.current.contains(e.target)
    ) {
      setTogglePrice(false);
    }
    if (
      featureRef.current &&
      toggleFeature &&
      !featureRef.current.contains(e.target)
    ) {
      setToggleFeature(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);
  //homeBannerApi
  async function homeBannerApi() {
    try {
      const response = await frontendService.homeBannerService();
      if (response.status === 200) {
        setHomeBannerData(response.data.data);
      }
    } catch (error) { }
  }

  const onClickCard = (e, id) => {
    Cookies.set("toolId", id);
  };

  const handleAddTool = () => {
    navigate("/submit-tool");
  };

  useEffect(() => {
    if (toolList !== undefined) {
      // Initialize title and description to empty strings
      let newTitle = '';
      let newDesc = '';

      toolList.forEach((item) => {
        if (item !== undefined && Array.isArray(item.subCategoryData)) {
          item.subCategoryData.forEach((subCategory) => {
            if (
              subCategory !== undefined &&
              subCategory.metaTitle !== undefined &&
              subCategory.metaDesc !== undefined
            ) {
              if (categoryQyery.replace(/\s+/g, '-') === subCategory.slug) {
                // Update title and description
                newTitle = subCategory.metaTitle;
                newDesc = subCategory.metaDesc;
              }
            }
          });
        }
      });

      // Set the title and description in the state
      setSeoData({ title: newTitle, description: newDesc });
    }
  }, [toolList, categoryQyery]);

  // first letter capital every word
  const arr = categoryQyery.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");

  return (
    <>
      {seoData.title !== undefined && seoData.title !== '' && (
        <Seo title={seoData.title} description={seoData.description} />
      )}
      <div className="row justify-content-center">
        <div className="col-lg-10">
          {/* Social icons */}
          {
            discription !== "" && discription !== undefined ?
              <>

                <div className="social-section ai-small-cate-count">
                  <p className="ai-p-base-bold">
                    Total category tools count
                    <span> {toolList.length}+</span>
                  </p>

                </div>
                <h1 className="ai-h1 banner-home">Discover The Best AI <span> {str2} </span>Tools</h1>
                <p className="ai-p-20 ai-filtered-p" dangerouslySetInnerHTML={{ __html: discription }} />
              </>
              :
              <>
                <div className="social-section">
                  <p className="ai-p-base-bold">
                    <img src={Users} alt="" /> {homeBannerData.userCount}+
                  </p>
                  <p className="ai-p-base-bold">
                    <img src={Mark} alt="" /> {homeBannerData.favouriteCount}+
                  </p>

                  <div className="social-icons">
                    <Link to='https://www.linkedin.com/company/aitoolspot/' target="_blank">
                      <img src={linkedIn} alt="linked-in-img" />
                    </Link>
                    <Link to='https://twitter.com/aitoolspot' target="_blank">
                      <img src={Twitter} alt="twiter-img" />
                    </Link>
                    <Link to='https://www.youtube.com/@aitoolspot' target="_blank">
                      <img src={youTube} alt="yt-img" />
                    </Link>
                  </div>
                </div>

                {/* Heading section */}
                <h1 className="ai-h1">Discover the Best AI Tools at AIToolSpot</h1>
                <p className="ai-p-20">
                  The #1 AI Tools Platform, Empowering Your AI Success.
                </p>

                {/* Buttons */}
                <div className="ai-hero-button">
                  <button type="button" className="ai-gredient-button" onClick={() => navigate('/recent')}>
                    <img src={Setting} alt="" />
                    <span className="ai-cta-name"> Tools Added Today</span>

                    <div className="ai-btn-noti-count">
                      <span>{homeBannerData.todayAddedToolCount}</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className="ai-button-secondary"
                    onClick={handleAddTool}
                  >
                    <img src={Document} alt="" className="doc-normal" />
                    <img src={DocumentHover} alt="" className="doc-hover" />
                    Submit A Tool
                  </button>
                </div>
              </>
          }


          <form>
            <div className="ai-form-inputes">
              <label className="ai-label-form">
                <img src={searchDark} alt="searchDark" />
              </label>
              <input
                type="text"
                className="ai-inputs"
                id="aiInput"
                aria-describedby="search"
                placeholder={`Search ${searchToolList.length} AI tools and ${subCategoryList.length} categories`}
                value={text}
                onChange={onTextChanged}
                onKeyDown={handleClickgetquery}
              />
              {suggestionlist && (
                <div className="ai-search-result" ref={closeMenu}>
                  <div className="ai-search-sec">
                    <span className="ai-search-listing-heading ai-p-14">
                      Category
                    </span>
                    <div className="ai-search-listing">
                      {displaySuggestions &&
                        filteredSuggestions.map((name, i) => (
                          <Link
                            key={i}
                            to={
                              "/ai-tools/" +
                              name.slug
                            }
                            onClick={(e) =>
                              categoryFilter(e, name._id, name.slug)}
                          >

                            {name.subCategoryName}
                          </Link>
                        ))}
                    </div>
                  </div>
                  {displaytoolSuggestionsList && (
                    <div className="ai-search-sec">
                      <>
                        <span className="ai-search-listing-heading ai-p-14">
                          Tools
                        </span>
                        <div className="ai-search-listing">
                          {displayToolSuggestions.map((name, i) => (
                            <>
                              <Link
                                key={i}
                                to={
                                  "/tool/" + name.slug
                                }
                                rel="noreferrer"
                                target={"_blank"}
                                onClick={(e) => onClickCard(e, name._id)}
                              >
                                {name.toolName}
                              </Link>
                            </>
                          ))}
                        </div>
                      </>
                    </div>
                  )}
                </div>
              )}
            </div>
          </form>

          {/* Custom dropdown section */}

          <div className="ai-sort-section">
            <div className="ai-sort-selection" ref={closeCategoryDropDown} onClick={() => setCateOpen(!catOpen)}>
              <label className="ai-sort-label"> Sort by</label>
              <div
                className="ai-sort-container"
              >
                <img src={categoryIcon} alt="category-Icon" />
                <span>{categoryQyery ? categoryQyery.charAt(0).toUpperCase() + categoryQyery.slice(1) : "Category"}</span>
              </div>
              <img
                src={arrowDown}
                alt=""
                className={`ai-sort-down ${catOpen ? "ai-sort-open" : ""}`}
              />
              {catOpen && (
                <div className="ai-banner-categorymenu">
                  <ul>
                    <li onClick={() => navigate('/')}>All</li>
                    {subCtegoryData.map((item, i) => (
                      <li
                        key={i}
                        onClick={(e) =>
                          categoryFilter(e, item._id, item.slug)
                        }
                      >
                        {item.subCategoryName}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* 2 */}
            <div
              className="ai-sort-selection"
              onClick={() => setToggleFeature(true)}
            >
              <label className="ai-sort-label ai-multi-dropdown"> Sort by</label>
              <div className="ai-sort-container ai-feature">
                <img src={featuredIcon} alt="" className="ai-feature-img" />
                <Multiselect
                  showCheckbox
                  hideSelectedList
                  placeholder="Feature"
                  name="featureName"
                  displayValue="featureName"
                  onSelect={onClickfilterFeature}
                  onRemove={onClickfilterFeature}
                  selectedValues={featureArray}
                  options={featureData.map((item, i) => ({
                    featureName: item.name,
                    feature_id: item._id,
                  }))}
                />
              </div>
              <img
                src={arrowDown}
                alt=""
                className={`ai-sort-down ${toggleFeature ? "ai-sort-open" : ""
                  }`}
                ref={featureRef}
              />
            </div>

            {/* 3 */}
            <div className="ai-sort-selection"
              onClick={() => setTogglePrice(true)} ref={priceRef}>
              <label className="ai-sort-label"> Sort by</label>
              <div className="ai-sort-container ai-feature">
                <img src={pricingIcon} alt="" className="ai-feature-img" />
                <Multiselect
                  onSearch={function noRefCheck() { }}
                  showCheckbox
                  hideSelectedList
                  placeholder="Pricing"
                  name="pricingName"
                  displayValue="pricingName"
                  onSelect={onClickPrice}
                  onRemove={onRemovePriceFilter}
                  selectedValues={selectedPrice}
                  options={priceData.map((item, i) => ({
                    pricingName: item.name,
                    pricing_id: item._id,
                  }))}
                />
              </div>
              <img src={arrowDown} alt=""
                className={`ai-sort-down ${togglePrice ? "ai-sort-open" : ""
                  }`} />
            </div>
            {/* 4 */}
            <div className="ai-sort-selection" ref={closeDropDown} onClick={openDropdownStatus}>
              {/* <div className="ai-sort-selection" > */}

              <label className="ai-sort-label"> Sort by</label>
              <div className="ai-sort-container">
                <img
                  src={
                    selectedStatus === "verified"
                      ? verifiedIcon
                      : selectedStatus === "new"
                        ? New
                        : selectedStatus === "popular"
                          ? Aibadge
                          : verifiedIcon
                  }
                  alt=""
                />
                <span>
                  {selectedStatus
                    ? selectedStatus.charAt(0).toUpperCase() +
                    selectedStatus.slice(1).toLowerCase()
                    : "Verified"}
                </span>
              </div>
              <img
                src={arrowDown}
                alt=""
                className={`ai-sort-down ${openDropdown ? "ai-sort-open" : ""}`}
              />
              {openDropdown && (
                <div className="ai-banner-categorymenu" >
                  <ul>
                    <li
                      onClick={(e) => handleStatus("verified")}
                      className={
                        selectedStatus === "verified" ? "selected" : ""
                      }
                    >
                      <img src={verifiedIcon} alt="" /> <span>Verified</span>
                    </li>
                    <li
                      onClick={(e) => handleStatus("new")}
                      className={selectedStatus === "new" ? "selected" : ""}
                    >
                      <img src={New} alt="" /> <span>New</span>
                    </li>
                    <li
                      onClick={(e) => handleStatus("popular")}
                      className={selectedStatus === "popular" ? "selected" : ""}
                    >

                      <img src={Aibadge} alt="" /> <span>Popular</span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          {selectedList && (
            <div className="ai-selection-label">
              <div className="ai-selection-label-top">
                <h6 className="ai-p-14">Applied filters</h6>
                <span className="ai-clear-all-link" onClick={handleClearAll}>
                  Clear all
                  <img src={Closered} />
                </span>
              </div>
              <div className="ai-selection-label-bottom">
                {selectedPrice.map((item, i) => (
                  <span
                    key={i}
                    className="ai-slc-label-main"
                    onClick={(e) => onRemoveSelectedData(e, item.pricing_id)}
                  >
                    {item.pricingName}
                    <img src={Closewhite} />
                  </span>
                ))}
                {featureArray.map((item, i) => (
                  <span
                    key={i}
                    className="ai-slc-label-main"
                    onClick={(e) => onRemoveSelectedData(e, item.feature_id)}
                  >
                    {item.featureName}
                    <img src={Closewhite} />
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Homebanner;
