import React, { useEffect, useState } from 'react'
import searchDark from "../../../assets/images/frontend/search-dark.svg";
import './SponsorList.css'
import frontendService from '../../../services/frontend.service';
import moment from 'moment';
import loaderImg from '../../../assets/images/loder2.gif'
import { useLocation } from 'react-router-dom';

const SponsorList = () => {
  //listing data
  const [sponsoredList, setSponsoredList] = useState([])
  const [initialData, setInitialData] = useState([])
  //filteration
  const [searchValue, setSearchValue] = useState('')
  const [searchMultiple, setsearchMultiple] = useState([])
  const [statusFilterInput, setStatusFilterInput] = useState('')
  const [categoryFilterInput, setCategoryFilterInput] = useState('')
  const excludeColumns = ["id", "pricing", "activeStatus", "dates"];
  //loader
  const [loader, setLoader] = useState(false)
  const location = useLocation().search
  const email = new URLSearchParams(location).get("email")
  useEffect(() => {
    setLoader(true)
    sponseredListService()
  }, [])
  async function sponseredListService() {
    try {
      let queryString = ''
      queryString += `?email=${email}`
      const response = await frontendService.sponsershipGetService(queryString)
      if (response.status === 200) {
        setInitialData(response.data.data.sponsoredTools)
        setSponsoredList(response.data.data.sponsoredTools)
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
    }
  }
  //onchange filter 
  const onChangeFilter = (searchValue) => {
    setSearchValue(searchValue)
    if (searchValue !== "" && statusFilterInput === "" && categoryFilterInput === "") {
      const filteredData = initialData.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      });
      setSponsoredList(filteredData)
      setsearchMultiple(filteredData)
    } else if (statusFilterInput !== "" && categoryFilterInput === "" && searchValue !== "") {

      var filteredKeywords = initialData.filter((item) => { return item.status === statusFilterInput });
      var finalFiltered = filteredKeywords.filter((item) => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      })
      setSponsoredList(finalFiltered);
    } else if (statusFilterInput !== "" && categoryFilterInput === "" && searchValue === "") {
      var filteredKeywords = initialData.filter((item) => { return item.status === statusFilterInput });
      setSponsoredList(filteredKeywords)
    } else if (statusFilterInput === "" && categoryFilterInput !== "" && searchValue === "") {
      var filteredKeywords = initialData.filter((item) => { return item.sponsorshipType === categoryFilterInput });
      setSponsoredList(filteredKeywords)
    } else if (statusFilterInput !== "" && searchValue !== "" && categoryFilterInput === "") {

      var filteredKeywords = searchMultiple.filter((item) => { return item.status === statusFilterInput });
      var finalFiltered = filteredKeywords.filter((item) => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      })
      setSponsoredList(finalFiltered);
    } else if (statusFilterInput !== "" && searchValue === "" && categoryFilterInput === "") {
      var filteredKeywords = searchMultiple.filter((item) => { return item.status === statusFilterInput });
      var finalFiltered = filteredKeywords.filter((item) => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      })
      setSponsoredList(finalFiltered);
    } else if (categoryFilterInput !== "" && searchValue !== "" && statusFilterInput === "") {

      var filteredKeywords = searchMultiple.filter((item) => { return item.sponsorshipType === categoryFilterInput });
      var finalFiltered = filteredKeywords.filter((item) => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      })
      setSponsoredList(finalFiltered);
    } else if (categoryFilterInput !== "" && searchValue === "" && statusFilterInput === "") {

      var filteredKeywords = searchMultiple.filter((item) => { return item.sponsorshipType === categoryFilterInput });
      var finalFiltered = filteredKeywords.filter((item) => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      })
      setSponsoredList(finalFiltered);
    } else if (categoryFilterInput !== "" && searchValue === "" && statusFilterInput !== "") {
      var filteredKeywords = searchMultiple.filter((item) => { return item.sponsorshipType === categoryFilterInput && item.status === statusFilterInput });
      var finalFiltered = filteredKeywords.filter((item) => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      })
      setSponsoredList(finalFiltered);
    } else if (categoryFilterInput !== "" && searchValue !== "" && statusFilterInput !== "") {
      var filteredKeywords = searchMultiple.filter((item) => { return item.sponsorshipType === categoryFilterInput && item.status === statusFilterInput });
      var finalFiltered = filteredKeywords.filter((item) => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      })
      setSponsoredList(finalFiltered);
    } else {
      setSponsoredList(initialData)
    }
  }
  //status filter
  const onChangeStatusFilter = (e) => {
    setStatusFilterInput(e.target.value)
    if (e.target.value !== "" && searchValue === "" && categoryFilterInput === "") {
      var filteredKeywords = initialData.filter((item) => { return item.status === e.target.value });
      setSponsoredList(filteredKeywords)
      setsearchMultiple(filteredKeywords)
    } else if (e.target.value !== "" && searchValue !== "" && categoryFilterInput === "") {
      var finalFiltered = searchMultiple.filter((item) => { return item.status === e.target.value })
      setSponsoredList(finalFiltered);
    } else if (e.target.value === "" && searchValue !== "" && categoryFilterInput === "") {
      var finalFiltered = searchMultiple.filter((item) => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      })
      setSponsoredList(finalFiltered);
    } else if (e.target.value !== "" && categoryFilterInput !== "" && searchValue === "") {
      var finalFiltered = initialData.filter((item) => { return item.status === e.target.value && item.sponsorshipType === categoryFilterInput })
      setSponsoredList(finalFiltered);
    } else if (e.target.value !== "" && categoryFilterInput !== "" && searchValue !== "") {
      var finalFiltered = searchMultiple.filter((item) => { return item.status === e.target.value && item.sponsorshipType === categoryFilterInput })
      const filteredData = finalFiltered.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      });
      setSponsoredList(filteredData);
    } else if (e.target.value === "" && categoryFilterInput !== "" && searchValue === "") {
      var finalFiltered = initialData.filter((item) => { return item.sponsorshipType === categoryFilterInput })
      setSponsoredList(finalFiltered);
    } else if (e.target.value === "" && categoryFilterInput !== "" && searchValue !== "") {
      var finalFiltered = searchMultiple.filter((item) => { return item.sponsorshipType === categoryFilterInput })
      const filteredData = finalFiltered.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      });
      setSponsoredList(filteredData)
    } else if (e.target.value === "" && searchValue !== "" && categoryFilterInput === "") {
      const filteredData = searchMultiple.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      });
      setSponsoredList(filteredData)
    } else {
      setSponsoredList(initialData)
    }
  }
  //type filter
  const onChangeCategoryFilter = (e) => {
    setCategoryFilterInput(e.target.value)
    if (e.target.value !== "" && searchValue === "" && statusFilterInput === "") {
      var filteredKeywords = initialData.filter((item) => { return item.sponsorshipType === e.target.value });
      setSponsoredList(filteredKeywords)
      setsearchMultiple(filteredKeywords)
    } else if (e.target.value !== "" && searchValue !== "" && statusFilterInput === "") {
      var finalFiltered = searchMultiple.filter((item) => { return item.sponsorshipType === e.target.value })
      setSponsoredList(finalFiltered);
    } else if (e.target.value !== "" && statusFilterInput !== "" && searchValue === "") {
      var finalFiltered = searchMultiple.filter((item) => { return item.sponsorshipType === e.target.value })
      setSponsoredList(finalFiltered);
    } else if (e.target.value !== "" && statusFilterInput !== "" && searchValue !== "") {
      var finalFiltered = searchMultiple.filter((item) => { return item.sponsorshipType === e.target.value && item.status === statusFilterInput })
      const filteredData = finalFiltered.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      });
      setSponsoredList(filteredData)
    } else if (e.target.value === "" && searchValue === "" && statusFilterInput !== "") {
      var finalFiltered = searchMultiple.filter((item) => { return item.status === statusFilterInput })
      setSponsoredList(finalFiltered);
    } else if (e.target.value !== "" && searchValue === "" && statusFilterInput !== "") {
      var finalFiltered = searchMultiple.filter((item) => { return item.sponsorshipType === e.target.value && item.status === statusFilterInput })
      setSponsoredList(finalFiltered);
    } else if (e.target.value === "" && statusFilterInput !== "" && searchValue !== "") {
      var finalFiltered = searchMultiple.filter((item) => { return item.status === statusFilterInput })
      const filteredData = finalFiltered.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      });
      setSponsoredList(filteredData)
    } else if (e.target.value === "" && statusFilterInput === "" && searchValue !== "") {
      const filteredData = searchMultiple.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      });
      setSponsoredList(filteredData)
    } else {
      setSponsoredList(initialData);
    }
  }

  return (
    <>
      <div className="toollistHeader">
        <h4 className="ai-h4">Sponsored tools</h4>
      </div>
      <div className='ai-sponsorlist-table'>
        <div className="ai-tool-table-sec">
          {/* Search section */}
          <div className="ai-tool-search-container">
            <form>
              <div className="ai-form-inputes">
                <label className="ai-label-form">
                  <img src={searchDark} alt="searchDark" />
                </label>
                <input
                  type="text"
                  className="ai-inputs"
                  aria-describedby="search"
                  placeholder="Search tool"
                  onChange={(e => onChangeFilter(e.target.value))}
                />
              </div>
            </form>

            <div className="ai-sort-selection">
              <label className="ai-sort-label">Status</label>
              <select onChange={onChangeStatusFilter}>
                <option style={{ fontFamily: 'Gilroy' }} value="">Select Status</option>
                <option style={{ fontFamily: 'Gilroy' }} value="Pending">Pending</option>
                <option style={{ fontFamily: 'Gilroy' }} value="In-process">In-process</option>
                <option style={{ fontFamily: 'Gilroy' }} value="Expired">Expired</option>
                <option style={{ fontFamily: 'Gilroy' }} value="Approved">Approved</option>
              </select>
            </div>
            <div className="ai-sort-selection">
              <label className="ai-sort-label">Sponsorship for</label>
              <select onChange={onChangeCategoryFilter}>
                <option style={{ fontFamily: 'Gilroy' }} value="">Select</option>
                <option style={{ fontFamily: 'Gilroy' }} value="Website">Website</option>
                <option style={{ fontFamily: 'Gilroy' }} value="Newsletter">Newsletter</option>
              </select>
            </div>
          </div>
          {/* Table section */}
          <div className="ai-table-container">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Tool Name</th>
                    <th scope="col">Website</th>
                    <th scope="col">Sponsorship for</th>
                    <th scope="col">Sponsorship days</th>
                    <th scope="col">Pricing</th>
                    <th scope="col">Sponsorship between</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>

                {loader ?
                  <div className='ai-front-loader-list'>
                    <img src={loaderImg} />
                  </div>
                  : (sponsoredList.length === 0 ?
                    <span className="ai-norecord-toollist">No Record Found</span> :
                    sponsoredList.map((item, i) => (
                      <tbody>
                        <tr>
                          <td scope="row">{i + 1}</td>
                          <td>{item.toolName}</td>
                          <td>{item.website}</td>
                          <td>{item.sponsorshipType}</td>
                          <td>{item.sponsorshipDays}</td>
                          <td>${item.totalprice}</td>
                          <td>{item.dates.map((item) => (
                            <span className='ai-date-break'>{moment(item.startDate).format('DD MMM, YYYY')} - {moment(item.endDate).format('DD MMM, YYYY')}</span>
                          ))}
                          </td>
                          <td><span className={item.status === 'Pending' ? 'red' : item.status === 'In-process' ? 'green' : 'blue'}>{item.status}</span></td>
                        </tr>
                      </tbody>
                    )))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SponsorList