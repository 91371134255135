import React from 'react'
import { Helmet } from 'react-helmet';

function Seo({ title, description, meta }) {
  const metaTitle = title
  const metaDescription = description

  return (
    <>
      <Helmet >
        <title>{metaTitle !== undefined && metaTitle !== "" && metaTitle !== null ? metaTitle : "AIToolSpot | Discover the Best AI Tools"}</title>
        <meta name="description" content={metaDescription !== undefined ? metaDescription : "Find the best AI tools and apps at AIToolSpot. Explore our collection of paid and free AI tools and discover innovative solutions for your projects."} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={metaTitle !== undefined ? metaTitle : "AIToolSpot | Discover the Best AI Tools"} />
        <meta property="og:description" content={metaDescription !== undefined ? metaDescription : "Find the best AI tools and apps at AIToolSpot. Explore our collection of paid and free AI tools and discover innovative solutions for your projects."} />
      </Helmet>

    </>
  )
}

export default Seo