import { LOGIN_SUCCESS } from "../actions/type";

const isUserOk = JSON.parse(localStorage.getItem('isUserOk'))
const auth = localStorage.getItem('auth')
const accessToken = localStorage.getItem('accessToken')
const initialState = {
    isUserOk: isUserOk || false,
    auth: auth,
    accessToken: accessToken
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isUserOk: true,
                auth: payload,
                accessToken: accessToken
            }
        default:
            return state
    }
}