import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import download from "../../assets/images/download1.svg";
import verify from "../../assets/images/verify.svg";
import reject from "../../assets/images/reject.svg";

import DeleteModel from '../../assets/images/deleteModel.svg'
import adminService from "../../services/admin.service";
import Pagination from "../../components/pagination/Pagination";
import Loader from "../../components/loader/Loader";
import moment from "moment";
import Addbutton from "../../components/buttons/AddButton";
import { Alert, Snackbar } from "@mui/material";
import loaderimg from '../../assets/images/loder2.gif'
import jsPDF from "jspdf";

const PendingTools = () => {
  const navigate = useNavigate();

  const [pendingList, setPendingList] = useState([])
  const [initialData, setInitialData] = useState([])
  const [toolId, setToolId] = useState('')
  const [searchValue, setSearchValue] = useState('');
  const [loader, setLoader] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [popBtnLoader, setPopBtnLoader] = useState(false)
  const [noRecordFound, setNoRecordFound] = useState(false)
  const [message, setMessage] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20
  const excludeColumns = ["_id"];
  const [rejectReason, setRejectReason] = useState({
    reason: ""
  })

  var DataArray = []
  const [error, setError] = useState({ reason: "" })
  useEffect(() => {
    setLoader(true)
    pendingListApi()
  }, [])

  function createData(_id, toolName, email, category, features, pricing, startingPrice, date, status) {
    return {
      _id,
      toolName,
      email,
      category,
      features,
      pricing,
      startingPrice,
      date,
      status
    };
  }
  async function pendingListApi() {
    try {
      const bodyData = {
        "status": "Pending"
      }
      const response = await adminService.pendinToolListService(bodyData)
      if (response.status === 200) {
        var arr = response.data.data.response;
        if (response.data.data.response.length > 0) {
          var newarr = [];
          for (var i = 0; i < arr.length; i++) {
            var tool = arr[i].toolName;
            var userEmail = arr[i].userId !== undefined && arr[i].userId !== null ? arr[i].userId['email'] : '-';
            var categoryData = arr[i].subCategoryId
            var priceData = arr[i].pricingId;
            var featuresData = arr[i].featuresId

            var featureName = featuresData !== undefined && featuresData !== null && featuresData.length > 0 ? featuresData.map((item, i) => {
              if (item.name !== '' && item.name !== null) {
                return item.name;
              } else {
                return "-"
              }
            }) : "-";
            var priceNames = priceData !== undefined && priceData !== null && priceData.length > 0 ? priceData.map((item, index) => {
              if (item.name !== '' && item.name !== null) {
                return item.name;
              } else {
                return "-"
              }
            }) : "-"

            var categoryNames = categoryData !== undefined && categoryData !== null && categoryData.length > 0 ? categoryData.map((item, i) => {
              if (item.subCategoryName !== null && item.subCategoryName !== "") {
                return item.subCategoryName
              } else {
                return "-"
              }
            }) : "-"
            var date = moment(arr[i].createdAt).format('DD MMM, YYYY')

            var price = arr[i].startingPrice !== "" ? arr[i].startingPrice : "-"

            newarr[i] = createData(
              arr[i]._id,
              tool,
              userEmail,
              categoryNames.toString(),
              featureName.toString(),
              priceNames.toString(),
              price,
              date,
              arr[i].status
            )
          }
          newarr.map((data1) => {
            DataArray = [...DataArray, data1]
          })

          DataArray = DataArray.sort().reverse()
          setPendingList(DataArray);
          setInitialData(DataArray);
          setLoader(false)
          setNoRecordFound(false)
        } else {
          setPendingList(arr)
          setNoRecordFound(true)
          setLoader(false)
        }
      }
    } catch (error) {

    }
  }

  async function verifyToolApi() {
    try {
      const response = await adminService.toolApproveService(toolId)
      if (response.status === 200) {
        document.querySelector("#Approve .btn-close").click()
        setOpenSnackbar(true)
        setPopBtnLoader(false)
        setMessage(response.data.message)
        pendingListApi()
        setLoader(true)
      }
    } catch (error) {

    }
  }

  async function rejectToolApi() {
    const bodyData = {
      unapproveReasone: rejectReason.reason
    }
    try {
      const response = await adminService.toolRejectService(toolId, bodyData)
      if (response.status === 200) {
        setOpenSnackbar(true)
        setMessage(response.data.message)
        setLoader(true)
        document.querySelector("#unApprove .btn-close").click()
        pendingListApi()
      }
    } catch (error) {

    }
  }

  const validate = (reasonReject) => {
    let reasonError = {}
    let isValid = false
    if (!reasonReject.reason) {
      isValid = true
      reasonError.reason = "Enter reason."
    }
    setError(reasonError)
    return isValid
  }

  const onChangeReason = (e) => {
    const { name, value } = e.target
    setRejectReason({ ...rejectReason, [name]: value })
    switch (name) {
      case "reason":
        value === "" ? setError({ ...error, reason: "Enter reason." }) : setError({ ...error, reason: "" })
      default:
        break
    }
  }

  const onClickreject = (e) => {
    e.preventDefault()
    if (!validate(rejectReason)) {
      setPopBtnLoader(true)
      rejectToolApi()
    }
  }
  const onClickApprove = () => {
    verifyToolApi()
    setPopBtnLoader(true)
  }
  const onChangeFilter = (searchValue) => {
    setSearchValue(searchValue)
    if (searchValue !== '') {
      const filteredData = initialData.filter((item) => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      })
      setPendingList(filteredData)
    } else {
      setPendingList(initialData)
    }
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  const currentRecords = pendingList.slice(indexOfFirstRecord, indexOfLastRecord);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(pendingList.length / recordPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onClickgetId = (e, id) => {
    setToolId(id)
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const onClickgetPDF = () => {
    var pdf = new jsPDF('l', 'pt', 'a4')
    pdf.autoTable({ html: "#ai-Tool-data" })
    pdf.save('table.pdf')
  }
  return (
    <>
      <div className="page-wrapper doctris-theme toggled">
        <main className="page-content">
          <div className="container-fluid">
            <div className="layout-specing">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="mb-0">AI Tools</h4>
                {/* <div className="cstm-bre uppercase">dashboard YEAR LONG COURSE <Link to="/topic">TOPICS</Link></div> */}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card  rounded-md  border min-height-sc">
                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                      <div className="col-md-9">
                        <div className="row ">
                          <div className="col-md-12">
                            <i className="fi fi-br-search cstm-search-ro"></i>
                            <input
                              onChange={(e) => onChangeFilter(e.target.value)}
                              name="name"
                              id="name"
                              type="text"
                              className="cstm-input-seacrh"
                              placeholder="Search Tool"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3  text-right">
                        <button
                          className="cstm-btn cstm-btn-text"
                          onClick={() => navigate("/admin/add-tool")}
                        >
                          Add Tool
                        </button>
                        <button onClick={onClickgetPDF} className="cstm-btn cstm-btn-icon">
                          <img alt="download-img" src={download} />
                        </button>
                      </div>


                    </div>

                    <div className="col-md-12 col-lg-12">
                      <div className="table-responsive bg-white rounded">
                        <table className="table mb-0 table-center">
                          <thead>
                            <tr>
                              <th className="border-bottom w-4">Id</th>
                              <th className="border-bottom w-12">Tool Name</th>
                              <th className="border-bottom w-12">User Email</th>
                              <th className="border-bottom w-12">Category</th>
                              <th className="border-bottom w-12">Feature</th>
                              <th className="border-bottom w-12">Pricing</th>
                              <th className="border-bottom w-12">Starting Price</th>
                              <th className="border-bottom w-15">Date</th>
                              <th className="border-bottom w-10">Status</th>
                              <th className="border-bottom w-11">Action</th>
                            </tr>
                          </thead>
                          {loader ?

                            <div className='loader-container'>
                              <Loader />
                            </div> :
                            (pendingList.length === 0 ? noRecordFound && <span className="cstm-norecord">No Record Found</span> :
                              (currentRecords.map((item, i) => (
                                <tbody key={i}>
                                  <tr>
                                    <td className="fw-bold">{i + 1}</td>
                                    <td>{item.toolName}</td>
                                    <td>{item.email}</td>
                                    <td className='cstm-word-break'>{item.category}</td>
                                    <td className='cstm-word-break'>{item.features}</td>
                                    <td className='cstm-word-break'>{item.pricing}</td>
                                    <td>{item.startingPrice}</td>
                                    <td>{item.date}</td>
                                    <td>{item.status}</td>
                                    <td>
                                      <Link onClick={(e) => onClickgetId(e, item._id)} className="cstm-btn6" data-bs-toggle="modal" data-bs-target="#Approve">
                                        <i><img alt="icon-img" src={verify} /></i>
                                      </Link>
                                      <div className="modal fade" id="Approve" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                          <div className="modal-content">
                                            <div className="modal-header border-0 p-4">
                                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                                            </div>
                                            <div className="modal-body p-4 pt-0">
                                              <div className="mb-4">
                                                <h4 className="text-center mb-4">
                                                  Are you sure you want to  approve tool?
                                                </h4>


                                                <div className="text-center pending-cta">



                                                  <div className="text-center cstn-loader-sec cstn-loader-left">
                                                    <button className="cstm-btn cstm-btn-text" disabled={popBtnLoader ? true : false} onClick={onClickApprove}>
                                                      Yes
                                                    </button>
                                                    {popBtnLoader &&
                                                      <div className='loader-container-cstm'>
                                                        <img alt="loader-img" className='btn-pop-loader' src={loaderimg} />
                                                      </div>
                                                    }
                                                  </div>
                                                  <button className="cstm-btn2 cstm-btn-text-bordered" data-bs-dismiss="modal">
                                                    No
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <Link onClick={(e) => onClickgetId(e, item._id)} className="cstm-btn6" data-bs-toggle="modal" data-bs-target="#unApprove">
                                        <i><img alt="icon-img" src={reject} /></i>
                                      </Link>
                                      <div className="modal fade" id="unApprove" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                          <div className="modal-content">
                                            <div className="modal-header border-0 p-4">
                                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                                            </div>
                                            <div className="modal-body p-4 pt-0">
                                              <div className="mb-4">
                                                <h4 className="text-center mb-4">
                                                  Are you sure you want to  unapprove tool?
                                                </h4>

                                                <div className="text-center pending-cta">
                                                  <button className="cstm-btn cstm-btn-text" data-bs-toggle="modal" data-bs-target='#reason'>
                                                    Yes
                                                  </button>
                                                  <button className="cstm-btn cstm-btn-text-bordered" data-bs-dismiss="modal">
                                                    No
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="modal fade" id="reason" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                          <div className="modal-content">
                                            <div className="modal-header border-0 p-4">
                                              <h4 className="modal-title" id="exampleModalLabel1">Reason</h4>
                                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body p-4 pt-0">
                                              <div className="mb-3">
                                                <label className="cstm-label">Reason<span className="cstm-star">*</span></label>
                                                <input type="text" onChange={onChangeReason} value={rejectReason.reason} className="cstm-input" placeholder="Enter reason" name="reason" required="" />
                                                <span className='error-message'>{error.reason}</span>
                                              </div>


                                              <div className="text-center pending-cta cstn-loader-sec" >

                                                <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={onClickreject}>
                                                  Unapprove
                                                </button>
                                                {popBtnLoader &&
                                                  <div className='loader-container-cstm'>
                                                    <img alt="loader-img" className='btn-pop-loader' src={loaderimg} />
                                                  </div>
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>

                              )))
                            )
                          }
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Snackbar
                open={openSnackbar}
                onClose={handleClose}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert onClose={handleClose} variant='filled' severity='success' >{message}</Alert>
              </Snackbar>
              {/* Pagignation */}
              <Pagination
                recordPerPage={recordPerPage}
                totalRecord={pendingList.length}
                paginate={paginate}
                currentPage={currentPage}
                previousPage={previousPage}
                nextPage={nextPage}
              />
            </div>
          </div>

        </main>
      </div>
    </>
  );
};

export default PendingTools;
