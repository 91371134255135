import React, { useEffect, useState } from "react";
import Seo from "../../components/seo/Seo";
import frontendService from "../../services/frontend.service";

const Privacypolicy = () => {
  const [privacy, setPrivacy] = useState();
  
  useEffect(() => {
    window.scrollTo({
      top: -50,
      left: -50,
      behavior: "smooth",
    });
    privacyPolicyApi();
  }, []);
  async function privacyPolicyApi() {
    
    try {
      const response = await frontendService.privacyPolicyService();
      if (response.status === 201) {
        
        setPrivacy(response.data.data.value);
      }
    } catch (error) {}
  }
  return (
    <>
    <Seo title={"AIToolSpot Privacy Policy | Your Data and Security Matters"} description={"Read AIToolSpot's Privacy Policy to understand how we handle your data and prioritize your online security."} />
      <div className="spacer-132"></div>
      <section className="ai-bg-color">
        <div className="ai-container">
          <div className="ai-loading-sapcing">
            <div className="ai-html-content-sec">
              <div className="ai-html-content">
                <h4 className="ai-h4">Privacy Policy</h4>
                <div dangerouslySetInnerHTML={{ __html: privacy }}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacypolicy;
