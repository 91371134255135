import React from 'react'
import "../htmlsitemap/htmlsitemap.css";
import { Link } from 'react-router-dom';

function HtmlSitemap() {
  return (
    <>
    <section className="sitemap-section">
        <div className="ai-container">
           
            <div class="sitemap-box">
            <h2 className='ai-sitemap-title'>Direct Links</h2>
            <div class="sitemap-row">
                <ul>
                <li><Link to="/" target="_blank">Home Page</Link></li>
                <li><Link to="/favourites" target="_blank">Favourites</Link></li>
                <li><Link to="/submit-tool" target="_blank">Submit A Tool</Link></li>
                <li><Link to="/blog" target="_blank">Blog</Link></li> 
                <li><Link to="/recent" target="_blank">Recent Add Tool</Link></li>
                <li><Link to="/sponsorship" target="_blank">Sponsorship</Link></li>
                <li><Link to="/terms-and-conditions">Terms and conditions</Link></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                </ul>
            </div>
            </div>

       </div>
    </section>
    
    </>
  )
}

export default HtmlSitemap