import React, { useEffect, useState } from "react";
import Seo from "../../components/seo/Seo";
import frontendService from "../../services/frontend.service";

const Termsandcondition = () => {
  const [termsAndCondition, setTermsAndCondition] = useState();

  useEffect(() => {
    window.scrollTo({
      top: -50,
      left: -50,
      behavior: "smooth",
    });
    termsAndConditionApi();
  }, []);
  async function termsAndConditionApi() {

    try {
      const response = await frontendService.termsAndConditionService();
      if (response.status === 201) {
        setTermsAndCondition(response.data.data.value);
      }
    } catch (error) { }
  }
  return (
    <>
    <Seo title={"AIToolSpot Terms & Conditions | Website Usage Agreement"} description={"Review AIToolSpot's Terms & Conditions, which outline the rules and agreements for using our website."} />
      <div className="spacer-132"></div>
      <section className="ai-bg-color">
        <div className="ai-container">
          <div className="ai-loading-sapcing">
            <div className="ai-html-content-sec">
              <div className="ai-html-content">
                <h4 className="ai-h4">Terms and Conditions</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: termsAndCondition }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div></div>
    </>
  );
};

export default Termsandcondition;
