import React from "react";
import { Close } from "@mui/icons-material";
// CSS
import "./DeleteModal.css"
// Images
import AiDelete from "../../../../assets/images/frontend/ai-delete.svg"
const Deletemodal = ({ handleClose, handleDeleteReview }) => {
  return (
    <>
      <div className="ai-model-content ai-delete-model">
        <div className="ai-model-heading">
          <button className="ai-close-model" onClick={handleClose}>
            <Close />
          </button>
        </div>

        {/* Model Inner sections */}
        <div className="ai-model-inner">
        <img src={AiDelete} alt="" />
          <h5 className="ai-h5">Are you sure to delete your review?</h5>
        </div>
        {/* Model Footer*/}
        <div className="ai-model-footer">
          <button className="model-btn-cancel" onClick={handleClose}>
            Cancel
          </button>
          <button className="model-btn-action" onClick={handleDeleteReview}>
            Delete Review
          </button>
        </div>
      </div>
    </>
  );
};

export default Deletemodal;
