import React from 'react'
import "./ThankyouContent.css";
import Yes from '../../../assets/images/Yes.png'



const ThankyouContent = () => {
  return (
    <>
    <p className='ai-thankyou-icon'><img src={Yes} alt="" /></p>
    <h3 className='ai-thank-you-title'>Thank you</h3>
    <p className='aithank-you-content'>Thank you for sponsorship your order is pending for now we will update you soon.</p>
    </>
  )
}

export default ThankyouContent