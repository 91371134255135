import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import adminService from '../../../services/admin.service';
import moment from 'moment';
import Deleteicon from '../../../assets/images/delete.svg'
import editIcon from '../../../assets/images/Edit.svg'
import { Alert, Snackbar } from '@mui/material';
import Loader from '../../loader/Loader';
import Pagination from '../../pagination/Pagination';

import loaderimg from '../../../assets/images/loder2.gif'
import DeleteModel from '../../../assets/images/deleteModel.svg'
import Addbutton from '../../buttons/AddButton';
const Category = () => {

    //store api response 
    const [categoryList, setCategoryList] = useState([])
    const [initialData, setInitialData] = useState([])

    //search input
    const [searchValue, setSearchValue] = useState('');

    //input data
    const [addCategory, setAddCategory] = useState({
        categoryName: '',
        icon: '',
        priority: ''
    })

    //edit and view 
    const [viewCategory, setViewCategory] = useState({
        categoryName: '',
        icon: '',
        priority: ''
    })
    const [error, setError] = useState({
        categoryName: '',
        icon: '',
        priority: ''
    })

    //loader
    const [loader, setLoader] = useState(false)
    const [popBtnLoader, setPopBtnLoader] = useState(false)
    const [dataLoader, setDataLoader] = useState(false)

    //get id
    const [categoryId, setCategoryId] = useState('')

    //api response message
    const [message, setMessage] = useState('')

    //toast
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [errorSnackbar, setErorSnackbar] = useState(false)

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 20

    const excludeColumns = ["_id ", "countofSubcategory", "date"];

    const ref = useRef(null)

    useEffect(() => {
        setLoader(true)
        categoryListing()
        setViewCategory({ categoryName: '', icon: '', priority: '' })
    }, [])


    //creat data to display in table and filter
    var DataArray = [];
    function createData(_id, categoryName, countofSubcategory, date) {
        return {
            _id,
            categoryName,
            countofSubcategory,
            date
        };
    }
    //listing api
    async function categoryListing() {
        try {
            const response = await adminService.categoryListService()
            if (response.status === 200) {
                var arr = response.data.data
                if (response.data.data.length > 0) {
                    var newArr = []

                    for (var i = 0; i < arr.length; i++) {
                        var category = arr[i].categoryName
                        var countofSubcategory = arr[i].countSubcategory
                        var date = moment(arr[i].createdAt).format('DD MMM, YYYY')

                        newArr[i] = createData(
                            arr[i]._id,
                            category,
                            countofSubcategory,
                            date
                        )
                    }
                    newArr.map((data1) => {
                        DataArray = [...DataArray, data1]
                    })

                    DataArray = DataArray.sort().reverse()
                    setInitialData(DataArray)
                    if (searchValue !== "" && searchValue !== undefined && searchValue !== null) {

                        var filterData = DataArray.filter((item) => {
                            return Object.keys(item).some(key =>
                                excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
                            );
                        });

                        setCategoryList(filterData)
                        setLoader(false)

                    } else {
                        setCategoryList(DataArray)
                        setLoader(false)

                    }
                }
            }
        } catch (error) {
            setErorSnackbar(true)
            setLoader(false)
        }
    }

    //add api
    async function addCategoryApi() {
        try {
            const response = await adminService.categoryAddService(addCategory)
            if (response.status === 200) {
                setOpenSnackbar(true)
                setLoader(true)
                setMessage(response.data.message)
                categoryListing()
                setPopBtnLoader(false)
                document.querySelector('#AddCat .btn-close').click();
            }
        } catch (error) {
            setErorSnackbar(true)
            setLoader(false)
        }
    }

    //view by id api
    async function viewCategoryApi(id) {
        try {
            const response = await adminService.categoryViewService(id)
            if (response.status === 200) {
                setViewCategory(response.data.data)
                setLoader(false)
                setDataLoader(false)
            }
        } catch (error) {
            setErorSnackbar(true)
            setLoader(false)
        }
    }

    //delete api
    async function deleteCategoryApi(id) {
        try {
            const response = await adminService.categoryDeleteService(id)
            if (response.status === 200) {
                setMessage(response.data.message)
                setOpenSnackbar(true)
                setLoader(true)
                setPopBtnLoader(false)
                categoryListing()
                document.querySelector('#Deletecat .btn-close').click();
            }
        } catch (error) {
            setErorSnackbar(true)
            setLoader(false)
            setMessage(error.response.data.message)
            setPopBtnLoader(false)
            document.querySelector('#Deletecat .btn-close').click();
        }
    }

    //edit api
    async function editcategoryApi() {
        const bodyData = {
            categoryName: viewCategory.categoryName,
            icon: viewCategory.icon,
            priority: viewCategory.priority
        }
        try {
            const response = await adminService.categoryEditService(categoryId, bodyData)
            if (response.status === 200) {
                setPopBtnLoader(false)
                setViewCategory({
                    categoryName: '',
                    icon: '',
                    priority: ''
                })
                setMessage(response.data.message)
                setOpenSnackbar(true)
                setLoader(true)
                categoryListing()
                document.querySelector('#Editcat .btn-close').click();
            }
        } catch (error) {
            setErorSnackbar(true)
            setLoader(false)
        }
    }

    //validation function
    const validate = (addCategory) => {
        let categoryError = {}
        let isValid = false

        if (!addCategory.categoryName) {
            isValid = true
            categoryError.categoryName = 'Please enter the category Name.'
        }
        setError(categoryError)
        return isValid
    }

    //handle add category onchange function
    const handleAddCategoryonChange = (e) => {
        const { name, value } = e.target
        console.log(value, "value");
        setAddCategory({ ...addCategory, [name]: value })
        switch (name) {
            case "categoryName":
                value.trim() === "" ? setError({ ...error, categoryName: "Please enter the category name." }) : setError({ ...error, categoryName: "" })
                break
            case "icon":
                value.trim() === "" ? setError({ ...error, icon: "Please enter the category icon." }) : setError({ ...error, icon: "" })
                break
            case "priority":
                value.trim() === "" ? setError({ ...error, priority: "Please enter the priority." }) : setError({ ...error, priority: "" })
            default:
                break
        }
    }

    // handle add category
    const handleAddcategory = (e) => {
        e.preventDefault()
        if (addCategory.categoryName.trim() !== "" && addCategory.icon.trim() !== "" && addCategory.priority.trim() !== "") {
            setError({ ...error, categoryName: "", icon: "", priority: "" })
            setAddCategory("")
            if (!validate(addCategory)) {
                setPopBtnLoader(true)
                addCategoryApi()
                setAddCategory({
                    categoryName: '',
                    icon: '',
                    priority: ''
                })
            }
        } else {
            setError({ ...error, categoryName: "Please enter the category name.", icon: "Please enter the category icon.", priority: "Please enter the category priority." })
        }
    }

    //handle edit onchange
    const handleEditOnchange = (e) => {
        const { name, value } = e.target
        setViewCategory({ ...viewCategory, [name]: value })
        switch (name) {
            case "categoryName":
                value.trim() === "" ? setError({ ...error, categoryName: "Please enter the category name." }) : setError({ ...error, categoryName: "" })
                break
            case "icon":
                value.trim() === "" ? setError({ ...error, icon: "Please enter the category icon." }) : setError({ ...error, icon: "" })
            case "priority":
                value.trim() === "" ? setError({ ...error, priority: "Please enter the priority." }) : setError({ ...error, priority: "" })
            default:
                break
        }
    }

    //handle edit
    const handleEditCategory = (e) => {
        e.preventDefault()
        if (viewCategory.categoryName.trim() !== "" && viewCategory.icon.trim() !== "" && viewCategory.priority.trim() !== "") {
            setError({ ...error, categoryName: "", icon: "", priority: "" })
            if (!validate(viewCategory)) {
                setPopBtnLoader(true)
                editcategoryApi()
            }
        } else {
            setError({ ...error, categoryName: "Please enter the category name.", icon: "Please enter the category icon.", priority: "Please enter the category priority." })
        }
    }

    //handle delete
    const handleDelete = () => {
        deleteCategoryApi(categoryId)
        setPopBtnLoader(true)
    }

    //toast close
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErorSnackbar(false)
        setOpenSnackbar(false);
    };

    //onclick view 
    const handleViewCategory = (e, id) => {
        setDataLoader(true)
        setError("")
        setCategoryId(id)
        viewCategoryApi(id)
    }

    //onclick get id 
    const onClickgetId = (e, id) => {
        setCategoryId(id)
        setError("")
    }

    //searc filter
    const onChangeFilter = (e) => {
        setSearchValue(e.target.value)
        if (e.target.value !== "") {
            const filteredData = initialData.filter((item) => {
                return Object.keys(item).some(key =>
                    excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(e.target.value.toLowerCase().trim())
                );
            })
            setCategoryList(filteredData)
        } else {
            setCategoryList(initialData)
        }
    }

    //pagination
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastRecord = currentPage * recordPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
    const currentRecords = categoryList.slice(indexOfFirstRecord, indexOfLastRecord);

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(categoryList.length / recordPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <>
            <div className="page-wrapper doctris-theme toggled">
                <main className="page-content">
                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="mb-0">Category</h4>
                                {/* <div className="cstm-bre uppercase">dashboard YEAR LONG COURSE <Link to="/topic">TOPICS</Link></div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card  rounded-md  border min-height-sc">
                                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                            <div className="col-md-10">
                                                <i className="fi fi-br-search cstm-search-ro"></i>
                                                <input name="search-input" id="search-input"
                                                    type="text" className="cstm-input-seacrh" onChange={(e) => onChangeFilter(e)} placeholder="Search Category" />
                                            </div>
                                            <div className='col-md-2 text-right'>
                                                <button className="cstm-btn cstm-btn-text" data-bs-toggle="modal" data-bs-target="#AddCat" onClick={onClickgetId}>Add Category</button>
                                            </div>

                                            <div className="modal fade" id="AddCat" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header border-0 p-4">
                                                            <h4 className="modal-title" id="exampleModalLabel1">Add Category</h4>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body p-4 pt-0">
                                                            <div className="mb-3">
                                                                <label className="cstm-label">Category Name<span className="cstm-star">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="cstm-input"
                                                                    placeholder="Enter category name"
                                                                    name="categoryName"
                                                                    onChange={handleAddCategoryonChange}
                                                                    value={addCategory.categoryName}
                                                                    required="" />
                                                                <span className='error-message'>{error.categoryName}</span>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="cstm-label">Category Icon<span className="cstm-star">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="cstm-input"
                                                                    placeholder="Enter category icon"
                                                                    name="icon"
                                                                    onChange={handleAddCategoryonChange}
                                                                    value={addCategory.icon}
                                                                    required="" />
                                                                <span className='error-message'>{error.icon}</span>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="cstm-label">Priority<span className="cstm-star">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="cstm-input"
                                                                    placeholder="Enter category priority"
                                                                    name="priority"
                                                                    onChange={handleAddCategoryonChange}
                                                                    value={addCategory.priority}
                                                                    required=""
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }} />
                                                                <span className='error-message'>{error.priority}</span>
                                                            </div>

                                                            <div className="text-center cstn-loader-sec cstn-loader-left ">
                                                                <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleAddcategory}>Submit</button>
                                                                {popBtnLoader &&
                                                                    <div className='loader-container-cstm'>
                                                                        <img className='btn-pop-loader' src={loaderimg} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-lg-12">
                                            <div className="table-responsive bg-white rounded">
                                                <table className="table mb-0 table-center">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-bottom w-4">No.</th>
                                                            <th className="border-bottom w-12">Category</th>
                                                            <th className="border-bottom w-15">Count of Sub-Category </th>
                                                            <th className="border-bottom w-10">Date</th>
                                                            <th className="border-bottom w-11">Action</th>
                                                        </tr>
                                                    </thead>
                                                    {loader ?

                                                        <div className='loader-container'>
                                                            <Loader />
                                                        </div>
                                                        :
                                                        (categoryList.length === 0 ? <span className="cstm-norecord">No Record Found</span> :
                                                            <tbody>
                                                                {currentRecords.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td className="fw-bold">{i + 1}</td>
                                                                        <td>{item.categoryName}</td>
                                                                        <td>{item.countofSubcategory}</td>
                                                                        <td>{item.date}</td>
                                                                        <td>
                                                                            <Link onClick={(e) => handleViewCategory(e, item._id)} className="cstm-btn6 action-btn" data-bs-toggle="modal" data-bs-target="#Editcat">
                                                                                <i><img src={editIcon} /> </i>
                                                                            </Link>
                                                                            <div className="modal fade" id="Editcat" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered">
                                                                                    <div className="modal-content">
                                                                                        {dataLoader ?
                                                                                            <div className='model-loader'>
                                                                                                <Loader />
                                                                                            </div>
                                                                                            :
                                                                                            <>
                                                                                                <div className="modal-header border-0 p-4">
                                                                                                    <h4 className="modal-title" id="exampleModalLabel1">Edit Category</h4>
                                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                                </div>
                                                                                                <div className="modal-body p-4 pt-0">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="cstm-label">Category Name<span className="cstm-star">*</span></label>
                                                                                                        <input type="text" className="cstm-input" onChange={handleEditOnchange} placeholder="Enter category name" name="categoryName" value={viewCategory.categoryName} required="" />
                                                                                                        <span className='error-message'>{error.categoryName}</span>
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <label className="cstm-label">Category Icon<span className="cstm-star">*</span></label>
                                                                                                        <input type="text" className="cstm-input" onChange={handleEditOnchange} placeholder="Enter category icon" name="icon" value={viewCategory.icon} required="" />
                                                                                                        <span className='error-message'>{error.icon}</span>
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <label className="cstm-label">Priority<span className="cstm-star">*</span></label>
                                                                                                        <input type="text" className="cstm-input" onChange={handleEditOnchange} placeholder="Enter category icon" name="priority" value={viewCategory.priority} required="" onKeyPress={(event) => {
                                                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                                                event.preventDefault();
                                                                                                            }
                                                                                                        }} />
                                                                                                        <span className='error-message'>{error.priority}</span>
                                                                                                    </div>
                                                                                                    <div className="text-center cstn-loader-sec cstn-loader-left ">
                                                                                                        <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleEditCategory}>Submit</button>
                                                                                                        {popBtnLoader &&
                                                                                                            <div className='loader-container-cstm'>
                                                                                                                <img className='btn-pop-loader' src={loaderimg} />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>

                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <Link onClick={(e) => onClickgetId(e, item._id)} className="cstm-btn6" data-bs-toggle="modal" data-bs-target="#Deletecat">
                                                                                <i><img src={Deleteicon} /></i>
                                                                            </Link>
                                                                            <div className="modal fade cstm-add-bonus" id="Deletecat" tabIndex="-1" aria-labelledby="exampleModalLabel1"
                                                                                aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered">
                                                                                    <div className="modal-content">
                                                                                        <div className="modal-header border-0 p-4">
                                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        </div>
                                                                                        <div className="modal-body p-4 pt-0">
                                                                                            <div className="mb-4">

                                                                                                <div className="text-center mb-4">
                                                                                                    <img src={DeleteModel} />
                                                                                                </div>
                                                                                                <h4 className="text-center mb-4">Are you sure you want to delete category?</h4>
                                                                                                <div className="text-center cstn-loader-sec ">
                                                                                                    <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleDelete}>Delete Category</button>
                                                                                                    {popBtnLoader &&
                                                                                                        <div className='loader-container-cstm'>
                                                                                                            <img className='btn-pop-loader' src={loaderimg} />
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>

                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        )
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Success Toast */}
                            <Snackbar
                                open={openSnackbar}
                                onClose={handleClose}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert onClose={handleClose} variant='filled' severity='success' >{message}</Alert>
                            </Snackbar>
                            {/* Error Toast */}
                            <Snackbar
                                open={errorSnackbar}
                                onClose={handleClose}
                                autoHideDuration={5000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert onClose={handleClose} variant='filled' severity='error'>{message}</Alert>
                            </Snackbar>
                            {/* pagignation */}
                            <Pagination
                                recordPerPage={recordPerPage}
                                totalRecord={categoryList.length}
                                paginate={paginate}
                                currentPage={currentPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                            />

                        </div>
                    </div>

                </main>
            </div>
        </>
    )
}

export default Category;