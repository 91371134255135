import React, { useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { useNavigate } from 'react-router-dom'
import toolbar from '../../config/toolbar'
import localization from '../../config/localization'
import adminService from '../../services/admin.service'
import { useDropzone } from 'react-dropzone'
import AddIcon from '@mui/icons-material/Add';
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Alert, Snackbar } from '@mui/material'
import loaderimg from '../../assets/images/loder2.gif'
import slugify from 'slugify';

const AddBlog = () => {

  const [categoryData, setcategoryData] = useState([])
  const [files, setFiles] = useState([]);
  const [addBlogData, setAddBlogData] = useState({
    blogTitle: "",
    blogCategory: '',
    shortDescription: '',
    blogslug: "",
    metaTitle: "",
    metaDesc: ""
  })
  const [error, setError] = useState({
    blogTitle: '',
    blogCategory: '',
    blogImg: '',
    blogslug: ""
  })
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty(),)
  const _contentState = ContentState.createFromText('Sample content state')
  const raw = convertToRaw(_contentState)
  const [contentState, setContentState] = useState(raw)
  const [contenthtml, setContenthtml] = useState();
  const [message, setMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const [loader, setLoader] = useState(false)
  const navigate = useNavigate()

  const [faqErrors, setFaqErrors] = useState([]);
  const [showFaq, setShowFaq] = useState(false)
  const [faq, setFaq] = useState([{
    que: "",
    ans: ""
  }])

  useEffect(() => {
    if (faq.length === 0) {
      setShowFaq(false)
    }
  }, [faq])

  useEffect(() => {
    const slugData = slugify(addBlogData.blogTitle, { lower: true, strict: true });
    setAddBlogData({ ...addBlogData, blogslug: slugData })
  }, [addBlogData.blogTitle])

  useEffect(() => {
    categoryApi()
  }, [])

  async function categoryApi() {
    try {
      const response = await adminService.blogCategoryService()
      if (response.status === 200) {
        setcategoryData(response.data.data)
      }
    } catch (error) {

    }
  }

  async function addBlogApi() {
    const formData = new FormData()
    formData.append('title', addBlogData.blogTitle)
    formData.append('blogCategoryId', addBlogData.blogCategory)
    formData.append('shortDesc', addBlogData.shortDescription)
    formData.append('longDesc', contenthtml)
    formData.append('imageUrl', files[0])
    formData.append('isFAQ', showFaq)
    formData.append('slug', addBlogData.blogslug)
    formData.append('metaTitle', addBlogData.metaTitle)
    formData.append('metaDesc', addBlogData.metaDesc)
    faq.forEach((object, index) => {
      Object.entries(object).forEach(([key, value]) => {
        formData.append(`FAQ[${index}][${key}]`, value);
      });
    });
    try {
      const response = await adminService.blogAddService(formData)
      if (response.status) {
        setMessage(response.data.message)
        setOpenSnackbar(true)
        setLoader(false)
        setTimeout(() => {
          navigate('/admin/blog')
        }, 2000)
      }
    } catch (error) {

    }
  }

  const validate = () => {
    let blogError = {}
    let isValid = false
    if (addBlogData.blogTitle.trim() === "") {
      isValid = true
      blogError.blogTitle = "Please enter blog title."
    }
    if (addBlogData.blogslug.trim() === "") {
      isValid = true
      blogError.blogslug = "Please enter slug."
    }
    if (!addBlogData.blogCategory) {
      isValid = true
      blogError.blogCategory = "Please select blog category."
    }
    if (files.length === 0) {
      isValid = true
      blogError.blogImg = "Please select the feature image."
    }
    setError(blogError)
    return isValid
  }

  const onClickAddBlog = (e) => {
    e.preventDefault()
    let isValid = true;

    if (showFaq) {
      // Validate question and answer fields in FAQ section
      for (let i = 0; i < faq.length; i++) {
        const { que, ans } = faq[i];
        if (que.trim() === '') {
          setFaqErrors(prevErrors => {
            const updatedErrors = [...prevErrors];
            updatedErrors[i] = {
              ...updatedErrors[i],
              que: 'Please enter the question.'
            };
            return updatedErrors;
          });
          isValid = false;
        }
        if (ans.trim() === '') {
          setFaqErrors(prevErrors => {
            const updatedErrors = [...prevErrors];
            updatedErrors[i] = {
              ...updatedErrors[i],
              ans: 'Please enter the answer.'
            };
            return updatedErrors;
          });
          isValid = false;
        }
      }
    }

    if (!validate(addBlogData) && isValid) {
      setLoader(true)
      addBlogApi()
    }
  }

  const onChangeAddBlog = (e) => {
    const { name, value } = e.target
    setAddBlogData({ ...addBlogData, [name]: value })
    switch (name) {
      case "blogTitle":
        value.trim() === "" ? setError({ ...error, blogTitle: "Please enter blog title.", blogslug: "Please enter slug." }) : setError({ ...error, blogTitle: "", blogslug: "" })
        break
      case 'blogCategory':
        value.trim() === '' ? setError({ ...error, blogCategory: "Please select blog category." }) : setError({ ...error, blogCategory: "" })
      default:
        break
    }
    
  }

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    maxSize: 3000000,
    multiple: false,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    },
    validator: error => {
      setError({ ...error, blogImg: "" })
    },
  });
  const thumbs = files.map(file => (
    <div key={file.name} className='col-1'>
      <div className='ai-uploaded-container'>
        <img
          alt='preview-img'
          className='ai-uploaded-items'
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const handleEditorStateonChange = (editorState) => {
    setContenthtml(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    setEditorState(editorState)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleAddFaq = () => {
    setFaq([...faq, { que: '', ans: '' }])
  }

  const handleOnchangeFaq = (e, i) => {
    const { name, value } = e.target;
    setFaq(prevFaq => {
      const updatedFaq = [...prevFaq];
      updatedFaq[i] = {
        ...updatedFaq[i],
        [name]: value
      };
      return updatedFaq;
    });

    setFaqErrors(prevErrors => {
      const updatedErrors = [...prevErrors];
      switch (name) {
        case 'que':
          updatedErrors[i] = {
            ...updatedErrors[i],
            [name]: value.trim() === '' ? 'Please enter the question.' : ''
          };
          break;
        case 'ans':
          updatedErrors[i] = {
            ...updatedErrors[i],
            [name]: value.trim() === '' ? 'Please enter the answer.' : ''
          };
          break;
        default:
          break;
      }
      return updatedErrors;
    });
  };

  const handleRemoveFaq = (e, i) => {
    const removeFaq = faq.filter((item, index) => index !== i)
    setFaq(removeFaq)
  }

  //Slug onChange
  const handleSlugChange = (e) => {
    const {name, value } = e.target
    const newValue = value.replace(/\s/g, '');
    setAddBlogData({ ...addBlogData, blogslug: newValue })
    switch (name) {
      case "blogslug":
        value.trim() === "" ? setError({ ...error, blogslug: "Please enter slug." }) : setError({ ...error, blogslug: "" })
        break
      default:
        break
    }
  }


  return (
    <>
      <div className="page-wrapper doctris-theme toggled">
        <main className="page-content bg-light">
          <div className="container-fluid">
            <div className="layout-specing">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="mb-0">Add Blog</h4>
                {/* <div className="cstm-bre uppercase">Dashboard > <a href="#">ADD subscription </a></div> */}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card  rounded-md  border min-height-sc add-edit-page">
                    <div className="py-2 px-3">
                      {/* <h5 className="mb-3">Add Tool</h5> */}
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Blog Title<span className='cstm-astix'>*</span></label>
                            <input type="text" onChange={onChangeAddBlog} value={addBlogData.blogTitle} className="cstm-input" placeholder="Enter blog title"
                              name="blogTitle" required="" />
                            <span className='error-message'>{error.blogTitle}</span>
                          </div>
                        </div>
                        {/* slug */}
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Slug<span className='cstm-astix'>*</span></label>
                            <input type="text" value={addBlogData.blogslug} onChange={handleSlugChange} className="cstm-input" placeholder="Enter your blog title"
                              name="blogslug" required="" />
                            <span className='error-message'>{error.blogslug}</span>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Blog Category<span className='cstm-astix'>*</span></label>
                            <select className="cstm-select" onChange={onChangeAddBlog} value={addBlogData.blogCategory} name="blogCategory" id="City">
                              <option value="">Select categories</option>
                              {categoryData.map((item) => (
                                <option value={item._id} key={item._id}>{item.name}</option>
                              ))}
                            </select>
                            <span className='error-message'>{error.blogCategory}</span>
                          </div>

                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Short Description</label>
                            <input type="text" onChange={onChangeAddBlog} value={addBlogData.shortDescription} className="cstm-input" placeholder="Enter short description"
                              name="shortDescription" required="" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Meta Title</label>
                            <input type="text" onChange={onChangeAddBlog} value={addBlogData.metaTitle} className="cstm-input" placeholder="Enter meta title"
                              name="metaTitle" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Meta Description</label>
                            <input type="text" onChange={onChangeAddBlog} value={addBlogData.metaDesc} className="cstm-input" placeholder="Enter meta description"
                              name="metaDesc" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Long Description</label>
                            <Editor
                              editorState={editorState}
                              onEditorStateChange={handleEditorStateonChange}
                              onContentStateChange={setContentState}
                              placeholder='Enter long description'
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              toolbar={toolbar}
                              localization={localization}
                            />
                          </div>
                        </div>

                        <div className='cust-isverfied'>
                          <input type='checkbox' checked={showFaq} onClick={() => setShowFaq(!showFaq)} /> <label>Enable FAQ</label>
                        </div>

                        {showFaq &&
                          <>
                            <div className='add-faq-button'>
                              <button onClick={handleAddFaq}>Add FAQ</button>
                            </div>
                            {faq.map((item, i) => (
                              <>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label className="cstm-label">Question</label>
                                    <input type="text" className="cstm-input" onChange={(e) => handleOnchangeFaq(e, i)} placeholder="Enter your question"
                                      value={item.que} name="que" required="" />
                                    <span className='error-message'>{faqErrors[i]?.que}</span>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label className="cstm-label">Answer</label>
                                    <input type="text" className="cstm-input" placeholder="Enter your Answer" onChange={(e) => handleOnchangeFaq(e, i)}
                                      value={item.ans} name="ans" required="" />
                                    <span className='error-message'>{faqErrors[i]?.ans}</span>
                                  </div>
                                </div>
                                <div className='remove-faq-button'>
                                  <button onClick={(e) => handleRemoveFaq(e, i)}>Remove FAQ</button>
                                </div>
                              </>
                            ))}
                          </>
                        }

                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Feature image<span className='cstm-astix'>*</span></label>
                            <div {...getRootProps({ className: 'dropzone blog-dropzone' })}>
                              <input {...getInputProps()} />
                              <p> <span> <AddIcon /></span>  Drop your image here, or <span> browse</span></p>
                            </div>
                            <aside>
                              <div className='row'>
                                {thumbs}
                              </div>
                            </aside>
                            <span className='error-message'>{error.blogImg || fileRejectionItems}</span>
                          </div>
                        </div>
                        <div className="pb-3 mt-2 button-div">

                          <div className="text-center cstn-loader-sec">
                            <button disabled={loader ? true : false} className="cstm-btn cstm-btn-text" onClick={onClickAddBlog}>Submit</button>
                            {loader &&
                              <div className='loader-container-cstm'>
                                <img alt='loade-img' className='btn-pop-loader' src={loaderimg} />
                              </div>
                            }
                          </div>
                          <button className="cstm-btn2 cstm-btn-text-bordered" onClick={() => navigate("/admin/blog")}>Cancel</button>
                        </div>
                      </div>
                      {/* Success Toast */}
                      <Snackbar
                        open={openSnackbar}
                        onClose={handleClose}
                        autoHideDuration={3000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      >
                        <Alert variant='filled' onClose={handleClose} severity='success'>{message}</Alert>
                      </Snackbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default AddBlog