import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import editIcon from '../../../assets/images/Edit.svg'
import download from '../../../assets/images/download1.svg'
import Deleteicon from '../../../assets/images/delete.svg'
import DeleteModel from '../../../assets/images/deleteModel.svg'
import adminService from '../../../services/admin.service';
import moment from 'moment';
import Loader from '../../loader/Loader';
import Pagination from '../../pagination/Pagination';
import { Alert, Snackbar } from '@mui/material';
import loaderimg from '../../../assets/images/loder2.gif'
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

const Tools = () => {

    //navigate to any page
    const navigate = useNavigate()

    //to store api data
    const [toolListing, setToolListing] = useState([])
    const [initialData, setInitialData] = useState([])

    //loader
    const [popBtnLoader, setPopBtnLoader] = useState(false)

    //filteration input
    const [searchValue, setSearchValue] = useState('')
    const [searchMultiple, setsearchMultiple] = useState([])
    const [statusFilterInput, setStatusFilterInput] = useState('')

    //tool id to pass in delete and edit
    const [toolId, setToolId] = useState('')

    //message
    const [message, setMessage] = useState('')

    //loader
    const [loader, setLoader] = useState(false)

    //toast
    const [openSnackbar, setOpenSnackbar] = useState(false)

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 20
    const excludeColumns = ["_id"];

    var DataArray = [];

    useEffect(() => {
        setLoader(true)
        toolListingApi()
    }, [])


    //creat data to display in table and filter
    function createData(_id, toolName, email, category, features, pricing, startingPrice, date, status) {
        return {
            _id,
            toolName,
            email,
            category,
            features,
            pricing,
            startingPrice,
            date,
            status
        };
    }

    //tool listing api
    async function toolListingApi() {
        try {
            const response = await adminService.toolListingService()

            if (response.status === 200) {
                var arr = response.data.data.response;
                if (response.data.data.response.length > 0) {
                    var newarr = [];

                    //to store nested array data in a state for filteration
                    for (var i = 0; i < arr.length; i++) {
                        var tool = arr[i].toolName;
                        var userEmail = arr[i].userId !== null ? arr[i].userId['email'] : '-';
                        var categoryData = arr[i].subCategoryId
                        var priceData = arr[i].pricingId;
                        var featuresData = arr[i].featuresId

                        var featureName = featuresData !== undefined && featuresData !== null && featuresData.length > 0 ? featuresData.map((item, i) => {
                            if (item.name !== '' && item.name !== null) {
                                return item.name;
                            } else {
                                return "-"
                            }
                        }) : "-";
                        var priceNames = priceData !== undefined && priceData !== null && priceData.length > 0 ? priceData.map((item, index) => {
                            if (item.name !== '' && item.name !== null) {
                                return item.name;
                            } else {
                                return "-"
                            }
                        }) : "-"

                        var categoryNames = categoryData !== undefined && categoryData !== null && categoryData.length > 0 ? categoryData.map((item, i) => {
                            if (item.subCategoryName !== null && item.subCategoryName !== "") {
                                return item.subCategoryName
                            } else {
                                return "-"
                            }
                        }) : "-"
                        var date = moment(arr[i].createdAt).format('DD MMM, YYYY')

                        var price = arr[i].startingPrice !== "" ? arr[i].startingPrice : "-"
                        newarr[i] = createData(
                            arr[i]._id,
                            tool,
                            userEmail,
                            categoryNames.toString(),
                            featureName.toString(),
                            priceNames.toString(),
                            price,
                            date,
                            arr[i].status
                        )
                    }
                    newarr.map((data1) => {
                        DataArray = [...DataArray, data1]
                    })
                    DataArray = DataArray.sort().reverse()
                    setInitialData(DataArray)
                    if (searchValue !== "" && searchValue !== undefined && searchValue !== null) {
                        if (statusFilterInput === "") {
                            var filterData = DataArray.filter((item) => {
                                return Object.keys(item).some(key =>
                                    excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
                                );
                            });

                            setToolListing(filterData)
                            setLoader(false)

                        } else {
                            var filterData = DataArray.filter((item) => {
                                return Object.keys(item).some(key =>
                                    excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
                                );
                            });
                            var filterStatusData = filterData.filter((item) => {
                                return item.status === statusFilterInput
                            });

                            setToolListing(filterStatusData);
                            setLoader(false)
                        }

                    } else {
                        if (statusFilterInput !== "" && statusFilterInput !== undefined && statusFilterInput !== null) {
                            var filterStatusData = DataArray.filter((item) => {
                                return item.status === statusFilterInput
                            });

                            setToolListing(filterStatusData)
                            setLoader(false)


                        } else {
                            setToolListing(DataArray);
                            setLoader(false)
                        }

                    }



                }
            }
        } catch (error) {
            setLoader(false)
        }
    }

    //delete api
    async function toolDeleteApi() {
        try {
            const response = await adminService.toolDeleteService(toolId)
            if (response.status === 200) {
                setOpenSnackbar(true)
                setLoader(true)
                setPopBtnLoader(false)
                setMessage(response.data.message)
                document.querySelector("#DeleteTool .btn-close").click()
                toolListingApi()
            }
        } catch (error) {

        }
    }

    //get tool id 
    const onClickGetId = (e, id) => {
        setToolId(id)
    }

    //delete tool
    const onClickDeleteTool = () => {
        toolDeleteApi()
        setPopBtnLoader(true)
    }

    //onchange filter function
    const onChangeFilter = (searchValue) => {
        setSearchValue(searchValue)

        if (searchValue !== '') {
            if (statusFilterInput === "") {
                const filteredData = initialData.filter(item => {
                    return Object.keys(item).some(key =>
                        excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
                    );
                });

                setToolListing(filteredData)
                setsearchMultiple(filteredData)

            } else {
                var filteredKeywords = initialData.filter((item) => { return item.status === statusFilterInput });
                var finalFiltered = filteredKeywords.filter((item) => {

                    return Object.keys(item).some(key =>
                        excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
                    );
                })

                setToolListing(finalFiltered);
            }

        } else {
            if (statusFilterInput === "") {
                setToolListing(initialData)
            } else {
                var statusFiltered = initialData.filter((item) => {

                    { return item.status === statusFilterInput }
                })

                setToolListing(statusFiltered);
            }

        }
    }
    //status wise filter function
    const onChangeStatusFilter = (e) => {
        setStatusFilterInput(e.target.value)

        if (e.target.value !== "") {
            if (searchValue !== "") {
                var finalFiltered = searchMultiple.filter((item) => { return item.status === e.target.value })
                setToolListing(finalFiltered);
            } else {

                var filteredKeywords = initialData.filter((item) => { return item.status === e.target.value });
                setToolListing(filteredKeywords)
                setsearchMultiple(filteredKeywords)
            }
            setLoader(false)
        } else {
            if (searchValue !== "") {
                const filteredData = initialData.filter(item => {
                    return Object.keys(item).some(key =>
                        excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
                    );
                });

                setToolListing(filteredData)
                setsearchMultiple(filteredData)
            } else {

                setToolListing(initialData)
            }

        }

    }
    //download pdf function
    const onClickgetPDF = () => {
        var pdfTable = document.getElementById('ai-Tool-data'),
            exportTbl = pdfTable.cloneNode(true),
            ttl_tbl = exportTbl.querySelectorAll('table');

        for (var i = 0; i < ttl_tbl.length; i++) {
            ttl_tbl[i].style.width = "100%"


            var cols = ttl_tbl[i].querySelectorAll("td");
            for (var j = 0; j < cols.length; j++) {
                cols[j].style.width = "12%"
            }

            var rm_c = ttl_tbl[i].querySelectorAll(".rmv-cell");
            for (var k = 0; k < rm_c.length; k++) {
                rm_c[k].remove()
            }
        }
        var html = htmlToPdfmake(exportTbl.innerHTML, {
            tableAutoSize: true
        });
        const documentDefinition = { content: [html], pageSize: 'a4' }

        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(documentDefinition).download('toolData.pdf');
    }

    //pagination
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastRecord = currentPage * recordPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
    const currentRecords = toolListing.slice(indexOfFirstRecord, indexOfLastRecord);

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(toolListing.length / recordPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };



    return (
        <>
            <div className="page-wrapper doctris-theme toggled">
                <main className="page-content">
                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="mb-0">AI Tools</h4>
                                {/* <div className="cstm-bre uppercase">dashboard YEAR LONG COURSE <Link to="/topic">TOPICS</Link></div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card  rounded-md  border min-height-sc">
                                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                            <div className="col-md-9">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <i className="fi fi-br-search cstm-search-ro"></i>
                                                        <input name="name" id="name" onChange={(e => onChangeFilter(e.target.value))}
                                                            type="text" className="cstm-input-seacrh" placeholder="Search Tool" />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <select className="cstm-select cstm-om-slc" name="City" id="City" onChange={onChangeStatusFilter}>
                                                            <option value="">Status</option>
                                                            <option value="Approved">Approved</option>
                                                            <option value="Pending">Pending</option>
                                                            <option value="UnApproved">UnApproved</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 text-right">
                                                <button className="cstm-btn cstm-btn-text" onClick={() => navigate('/admin/add-tool')}>Add Tool</button>
                                                <button onClick={onClickgetPDF} className='cstm-btn cstm-btn-icon'><img alt="icon-img" src={download} /></button>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-lg-12">
                                            <div className="table-responsive bg-white rounded" id='ai-Tool-data' >
                                                <table className="table mb-0 table-center" >
                                                    <thead>
                                                        <tr>
                                                            <th className="border-bottom w-4">Id</th>
                                                            <th className="border-bottom w-12">Tool Name</th>
                                                            <th className="border-bottom w-12">User Email</th>
                                                            <th className="border-bottom w-12">Category</th>
                                                            <th className="border-bottom w-12">Feature</th>
                                                            <th className="border-bottom w-12">Pricing</th>
                                                            <th className="border-bottom w-12">Starting Price</th>
                                                            <th className="border-bottom w-15">Date</th>
                                                            <th className="border-bottom w-10">Status</th>
                                                            <th className="border-bottom w-11 rmv-cell">Action</th>
                                                        </tr>
                                                    </thead>
                                                    {loader ?
                                                        <div className='loader-container'>
                                                            <Loader />
                                                        </div>
                                                        :
                                                        (toolListing.length === 0 ?
                                                            <span className="cstm-norecord">No Record Found</span> :
                                                            (currentRecords.map((item, i) => (
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="fw-bold">{i + 1}</td>
                                                                        <td>{item.toolName}</td>
                                                                        <td>{item.email}</td>
                                                                        <td className='cstm-word-break'>{item.category}</td>
                                                                        <td className='cstm-word-break'>{item.features}</td>
                                                                        <td className='cstm-word-break'>{item.pricing}</td>
                                                                        <td>${item.startingPrice}</td>
                                                                        <td>{item.date}</td>
                                                                        <td>{item.status}</td>
                                                                        <td className='rmv-cell'>
                                                                            <Link to={'/admin/tools/edit-tool' + "?id=" + item._id} className="cstm-btn6">
                                                                                <i><img alt="icon-img" src={editIcon} /></i>
                                                                            </Link>
                                                                            <Link onClick={(e) => onClickGetId(e, item._id)} className="cstm-btn6" data-bs-toggle="modal"
                                                                                data-bs-target="#DeleteTool"><i><img alt="icon-img" src={Deleteicon} /></i></Link>
                                                                            <div className="modal fade cstm-add-bonus" id="DeleteTool" tabIndex="-1" aria-labelledby="exampleModalLabel1"
                                                                                aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered">
                                                                                    <div className="modal-content">
                                                                                        <div className="modal-header border-0 p-4">
                                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        </div>
                                                                                        <div className="modal-body p-4 pt-0">
                                                                                            <div className="mb-4">

                                                                                                <div className="text-center mb-4">
                                                                                                    <img alt="icon-img" src={DeleteModel} />
                                                                                                </div>
                                                                                                <h4 className="text-center mb-4">Are you sure you want to delete tool?</h4>
                                                                                                <div className="text-center cstn-loader-sec">
                                                                                                    <button onClick={onClickDeleteTool} disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text">Delete Tool</button>
                                                                                                    {popBtnLoader &&
                                                                                                        <div className='loader-container-cstm'>
                                                                                                            <img alt="loader-img" className='btn-pop-loader' src={loaderimg} />
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )))
                                                        )
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Snackbar
                                open={openSnackbar}
                                onClose={handleClose}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert onClose={handleClose} variant='filled' severity='success' >{message}</Alert>
                            </Snackbar>
                            <Pagination
                                recordPerPage={recordPerPage}
                                totalRecord={toolListing.length}
                                paginate={paginate}
                                currentPage={currentPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                            />
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default Tools;