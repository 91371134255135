import React, { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { Input, Radio } from '@mui/material';
import frontendService from '../../../services/frontend.service';
import { Link } from 'react-router-dom';
//icons
import Calendar from '../../../assets/images/Calendar.svg'
import DeleteIcon from '../../../assets/images/DeleteIcon.svg';
import CalenderIcon from '../../../assets/images/CalenderIcon.svg';
import loaderimg from "../../../assets/images/loder2.gif";
//css
import "./SponsorShipForm.css";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { ArrowBackIos } from '@mui/icons-material';
const SponsorShipForm = () => {
  //formData
  const [sponsorData, setSponsorData] = useState({
    name: '',
    email: '',
    websiteUrl: '',
    toolName: '',
    days: '',
    price: '',
    country: '',
    state: '',
    city: '',
    postal_code: '',
    address: '',
    description: ''
  })
  //error message
  const [error, setError] = useState({
    name: '',
    email: '',
    websiteUrl: '',
    toolName: '',
    days: '',
    terms: '',
    country: '',
    state: '',
    city: '',
    postal_code: '',
    address: '',
    totalDays: '',
    minDate: '',
    description: ''
  })
  //tool list data
  const [toolData, setToolData] = useState([])
  const [initialToolData, setInitialToolData] = useState([])
  var toolDataarr = []
  const [showTtoolList, setShowToolList] = useState(false)
  const excludeColumns = ["_id"];
  //number for days
  const numbers = []
  //checked terms and condition
  const [checked, setChecked] = useState(false)
  //select value for sponsor type
  const [sponsorType, setSponsorType] = useState('Website')
  //outside click close menu
  const closeMenu = useRef(null);
  //LOADER
  const [loader, setLoader] = useState(false)
  //multiple calendar
  const [datePickers, setDatePickers] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }]);
  const [totalDifference, setTotalDifference] = useState(0);
  const selectedDate = []
  //hide show payment option
  const [showPayment, setShowPayment] = useState(false)
  //payment intent
  const [paymentIntent, setPaymentIntent] = useState('')
  //perdayprice
  const [perDayPrice, setPerDayPrice] = useState()
  //stripe integration
    //const stripePromise = loadStripe('pk_test_51NMCh6AwVMGxC1QRXFbIh3SkdOdV2MSMHFZvTxo4u5WoaNdfRFrEbFUXIvEdwzpRgjsIcTmvGxCTxYPOBiw1IOKU00TtJZUEH9')  
  const stripePromise = loadStripe('pk_live_51NMCh6AwVMGxC1QRQeGiL2ychN4KsFfdrRtHRVd8y5xvM0f50KYWdhMrhmahPMlxo7PDEqE8oKLUgk1qUxKbspct00zZY0vuHD')
  useEffect(() => {
    toolListApi()
    pricePerDayApi()
  }, [])
  //price per day api
  async function pricePerDayApi() {
    try {
      const response = await frontendService.pricePerDayService()
      if (response.status === 201) {
        setPerDayPrice(response.data.data.value)
      }
    } catch (error) {

    }
  }
  //toollist Api funcion 
  function createTooldata(_id, toolName) {
    return {
      _id,
      toolName,
    };
  }
  async function toolListApi() {
    try {
      const response = await frontendService.searchListService()
      if (response.status === 200) {
        var toolArr = response.data.data.toolData;
        if (toolArr.length > 0) {
          var newtoolArr = [];
          for (var i = 0; i < toolArr.length; i++) {
            newtoolArr[i] = createTooldata(toolArr[i]._id, toolArr[i].toolName);
          }
          newtoolArr.map((item) => (toolDataarr = [...toolDataarr, item]));
          setToolData(toolDataarr);
          setInitialToolData(toolDataarr)
        }
      }
    } catch (error) { }
  }
  async function sponsershipApi() {
    const bodyData = {
      sponsorshipType: sponsorType,
      toolName: sponsorData.toolName,
      pricing: 'Tool-per-day-price',
      name: sponsorData.name,
      address: {
        line1: sponsorData.address,
        line2: "",
        city: sponsorData.city,
        state: sponsorData.state,
        postal_code: sponsorData.postal_code,
        country: sponsorData.country
      },
      websiteUrl: sponsorData.websiteUrl,
      toolTopdays: sponsorData.days,
      dateSelection: selectedDate,
      termsAccepted: checked,
      email: sponsorData.email,
      description: sponsorData.description !== "" ? sponsorData.description : "-"
    }
    try {
      const response = await frontendService.sponsorshipService(bodyData)
      if (response.status === 200) {
        setPaymentIntent(response.data)
        setShowPayment(true)
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
    }
  }
  //for loop that userd to list days upto 100
  for (let i = 1; i <= 100; i++) {
    numbers.push(i);
  }
  //regex for email validation
  const emailRegex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  //regex for url validation
  const urlRegex = new RegExp(/^https?:\/\/.+/i);
  //valiadation function
  const validate = (sponsorData) => {
    let sponsorError = {}
    let isValid = false
    if (sponsorData.name.trim() === "") {
      isValid = true
      sponsorError.name = 'Please enter the full name.'
    }
    if (checked === false) {
      isValid = true
      sponsorError.terms = 'Please select the term & condition box.'
    }
    if (sponsorData.toolName.trim() === "") {
      isValid = true
      sponsorError.toolName = 'Please select the tool.'
    }
    if (sponsorData.country.trim() === "") {
      isValid = true
      sponsorError.country = 'Please enter country name.'
    }
    if (sponsorData.state.trim() === "") {
      isValid = true
      sponsorError.state = 'Please enter state name.'
    }
    if (sponsorData.city.trim() === "") {
      isValid = true
      sponsorError.city = 'Please enter city name.'
    }
    if (sponsorData.postal_code.trim() === "") {
      isValid = true
      sponsorError.postal_code = 'Please enter postal code.'
    }
    if (sponsorData.address.trim() === "") {
      isValid = true
      sponsorError.address = 'Please enter address.'
    }
    if (!sponsorData.days) {
      isValid = true
      sponsorError.days = 'Please select the days.'
    }
    if (sponsorData.websiteUrl.trim() === "") {
      isValid = true;
      sponsorError.websiteUrl = "Please enter the website url.";
    } else if (!urlRegex.test(sponsorData.websiteUrl)) {
      isValid = true;
      sponsorError.websiteUrl = "Please enter the valid website url.";
    } else {
      sponsorError.websiteUrl = "";
    }
    if (sponsorData.email.trim() === "") {
      isValid = true;
      sponsorError.email = "Please enter the email address.";
    } else if (!urlRegex.test(sponsorData.websiteUrl)) {
      isValid = true;
      sponsorError.email = "Please enter the valid email address.";
    } else {
      sponsorError.email = "";
    }
    if (parseInt(sponsorData.days) !== totalDifference) {
      isValid = true
      sponsorError.totalDays = `Please select ${sponsorData.days} days only.`
    } else {
      sponsorError.days = ''
    }
    setError(sponsorError)
    return isValid
  }
  //handle change for sponsor type
  const handleChangeSponsorType = (event) => {
    setSponsorType(event.target.value);
  };
  //selec tool from list
  const handleSelectToolName = (e, name) => {
    e.preventDefault()
    setSponsorData({ ...sponsorData, toolName: name })
    setShowToolList(false)
    setError({ ...error, toolName: '' })
  }
  //handle change for input fields
  const handleChangeDetail = (e) => {
    const { name, value } = e.target
    setSponsorData({ ...sponsorData, [name]: value })
    switch (name) {
      case 'name':
        value.trim() === ""
          ? setError({ ...error, name: "Please enter the full name." })
          : setError({ ...error, name: "" });
        break;
      case 'country':
        value.trim() === ""
          ? setError({ ...error, country: "Please enter country name." })
          : setError({ ...error, country: "" });
        break;
      case 'city':
        value.trim() === ""
          ? setError({ ...error, city: "Please enter city name." })
          : setError({ ...error, city: "" });
        break;
      case 'state':
        value.trim() === ""
          ? setError({ ...error, state: "Please enter state name." })
          : setError({ ...error, state: "" });
        break;
      case 'postal_code':
        value.trim() === ""
          ? setError({ ...error, postal_code: "Please enter postal code." })
          : setError({ ...error, postal_code: "" });
        break;
      case 'address':
        value.trim() === ""
          ? setError({ ...error, address: "Please enter address." })
          : setError({ ...error, address: "" });
        break;
      case 'days':
        !value
          ? setError({ ...error, days: "Please select the day’s." })
          : setError({ ...error, days: "" });
        break;
      case 'email':
        value.trim() === ""
          ? setError({ ...error, email: "Please enter the email address." })
          : !emailRegex.test(value)
            ? setError({ ...error, email: "Please enter valid email address." })
            : setError({ ...error, email: "" });
        break;
      case 'websiteUrl':
        value.trim() === ""
          ? setError({ ...error, websiteUrl: "Please enter the website url." })
          : !urlRegex.test(value)
            ? setError({ ...error, websiteUrl: "Please enter the valid website url." })
            : setError({ ...error, websiteUrl: "" });
      default:
        break;
    }
  }
  //handle proceed to pay
  const handlePay = (e) => {
    e.preventDefault()
    if (!validate(sponsorData) && checked) {
      datePickers.map((item) => selectedDate.push({
        startDate: moment(item.startDate).format('DD MMM, YYYY'),
        endDate: (item.endDate).setHours(23, 59, 59, 999),


      }))
      sponsershipApi()
      setLoader(true)
    }
  }
  //handle change for search tool input
  const onChangedSearchTool = (e) => {
    const { name, value } = e.target
    setSponsorData({ ...sponsorData, [name]: value })
    switch (name) {
      case 'toolName':
        value.trim() === ""
          ? setError({ ...error, toolName: "Please select the tool." })
          : setError({ ...error, toolName: "" });
      default:
        break;
    }
    if (value !== '') {
      const filteredToolsuggestion = initialToolData.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase().trim())
        );
      });
      setToolData(filteredToolsuggestion)
      setShowToolList(true)
    } else {
      setToolData(initialToolData)
    }
  }
  //handle terms & condition 
  const handleTermsChange = (e) => {
    setChecked(e.target.checked)
    if (e.target.checked) {
      setError({ ...error, terms: '' })
    } else {
      setError({ ...error, terms: 'Please select the term & condition box.' })
    }
  }
  //ref for close tool menu
  const closeOpenMenus = (e) => {
    if (
      closeMenu.current &&
      showTtoolList &&
      !closeMenu.current.contains(e.target)
    ) {
      setShowToolList(false);
    }
  }
  document.addEventListener("mousedown", closeOpenMenus);
  const handleAdd = () => {
    setDatePickers([
      ...datePickers,
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ]);
  }
  //calculate date difference
  const calculateDateDifference = (startDate, endDate) => {
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24) + 1;
    const digitsBeforeDot = Math.floor(differenceInDays);
    return digitsBeforeDot;
  };
  //calculate and total difference days
  const calculateAndSetTotalDifference = (ranges) => {
    const differences = ranges.map((range) =>
      calculateDateDifference(range.startDate, range.endDate)
    );
    const total = differences.reduce((acc, curr) => acc + curr, 0);
    if (total === parseInt(sponsorData.days)) {
      setError({ ...error, totalDays: '' })
    }
    setTotalDifference(total);
  };
  //handle date change
  const handleOnDateChange = (ranges, i) => {
    const { startDate, endDate } = ranges.selection;
    const updatedDateRanges = [...datePickers];
    updatedDateRanges[i] = ranges.selection;
    setDatePickers(updatedDateRanges);
    calculateAndSetTotalDifference(updatedDateRanges);
  }
  //delete calendar index
  const handleDeleteDate = (e, i) => {
    const deletedDate = datePickers.filter((item, index) => index != i)
    setDatePickers(deletedDate)
  }
  return (
    <>
      <div className='ai-sponsorshipform'>
        {!showPayment ?
          <>
            <h4 className='ai-enquiry-title'>Enquiry</h4>

            <div className='ai-sponsorshipform-checkbox'>
              <label htmlFor='Website'><input type='radio' onChange={handleChangeSponsorType} value='Website' id='Website' name='Website' checked={sponsorType === 'Website'} /> Website Sponsorship</label>
              <label htmlFor='Newsletter' ><input type='radio' onChange={handleChangeSponsorType} value='Newsletter' id='Newsletter' name='Newsletter' checked={sponsorType === 'Newsletter'} /> Newsletter Sponsorship</label>
            </div>
            <form onSubmit={handlePay}>
              <div className="row">
                <div className="ai-form-group col-md-6 ">
                  <label htmlFor="toolName">Full name</label>
                  <input
                    onChange={handleChangeDetail}
                    value={sponsorData.name}
                    name='name'
                    type="text"
                    className="ai-form-control"
                    placeholder="Enter full name"
                  />
                  <span className='error-message ai-error-message'>{error.name}</span>
                </div>
                <div className="ai-form-group col-md-6 ">
                  <label htmlFor="toolName">Email address</label>
                  <input
                    onChange={handleChangeDetail}
                    name='email'
                    value={sponsorData.email}
                    type="text"
                    className="ai-form-control"
                    placeholder="Enter email address"
                  />
                  <span className='error-message ai-error-message'>{error.email}</span>
                </div>
                <div className="ai-form-group col-md-12">
                  <label htmlFor="toolName">Website URLs</label>
                  <input
                    onChange={handleChangeDetail}
                    name='websiteUrl'
                    value={sponsorData.websiteUrl}
                    type="url"
                    className="ai-form-control"
                    placeholder="https://www.websitename.com"
                  />
                  <span className='error-message ai-error-message'>{error.websiteUrl}</span>
                </div>
                <div className="ai-form-group col-md-12">
                  <label htmlFor="toolName">Tool search</label>
                  <input
                    onChange={onChangedSearchTool}
                    name='toolName'
                    value={sponsorData.toolName}
                    type="text"
                    className="ai-form-control"
                    placeholder="Search by tool name"
                    autoComplete='off'
                  />
                  <span className='error-message ai-error-message'>{error.toolName}</span>
                  {showTtoolList &&
                    <div className="ai-search-tool" ref={closeMenu}>
                      <ul>
                        {toolData.map((item) => (
                          <li onClick={(e) => handleSelectToolName(e, item.toolName)}>{item.toolName}</li>
                        ))}
                      </ul>
                    </div>
                  }
                </div>
                <div className="ai-form-group col-md-12">
                  <label htmlFor="description">Additional Information</label>
                  <textarea
                    onChange={handleChangeDetail}
                    value={sponsorData.description}
                    name='description'
                    placeholder='Please use this space to provide any extra information you would like us to know about your AI tool or specific requirements for the sponsorship. Feel free to share additional details, special requests, or any other relevant information.'
                    className="ai-form-control"
                    rows="3"
                  />
                </div>
                <div className="ai-form-group col-md-12 mb-3">
                  <label htmlFor="toolName">Number of days to show tool at top</label>
                  <select value={sponsorData.days} onChange={handleChangeDetail} className="ai-form-control ai-select-only" name="days" id="Number" placeholder='Ex. 100 Days'>
                    <option value={0}>Select Days</option>
                    {numbers.map((number) => (
                      <option value={number}>{`${number} Day`}</option>
                    ))
                    }
                  </select>
                  <span className='error-message ai-error-message'>{error.days}</span>
                </div>
                <div className="ai-price-day"><Link>*Price - ${perDayPrice} /day</Link></div>
                <div className="ai-open-calendar" onClick={handleAdd}><img src={Calendar} alt="" />Open calendar to set sponsorship as you want</div>
                <div className='ai-form-group col-md-12'>
                  {datePickers.map((range, i) => (
                    <>
                      <DateRange
                        onChange={(ranges) => handleOnDateChange(ranges, i)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={[range]}
                        direction="horizontal"
                        minDate={new Date()}
                        editableDateInputs={false}
                      />
                      {range.startDate !== null && range.endDate !== null ?
                        <div className='ai-calendar-drop'>
                          <ul>
                            <li>
                              <div className='ai-calender-details'>
                                <img className='ai-calender-icon' src={CalenderIcon} alt="" />
                                <h5 className='ai-calender-date'>{moment(range.startDate).format('DD MMM, YYYY')}  -  {moment(range.endDate).format('DD MMM, YYYY')}</h5>
                                <h6>({differenceInCalendarDays(range.endDate, range.startDate) + 1} days)</h6>
                              </div>
                              <div className='ai-calender-edti-delete'>
                                <img onClick={(e) => handleDeleteDate(e, i)} src={DeleteIcon} alt="" />
                              </div>
                            </li>
                          </ul>
                        </div>
                        : ""
                      }
                    </>
                  ))
                  }
                  <div>
                    <span className='error-message ai-error-message'>{error.minDate}</span><br />
                    <span className='error-message ai-error-message'>{error.totalDays}</span>
                  </div>
                </div>
                <div className="ai-form-group col-md-12">
                  <label htmlFor="toolName">Address</label>
                  <input
                    onChange={handleChangeDetail}
                    name='address'
                    value={sponsorData.address}
                    type='text'
                    className="ai-form-control"
                    placeholder="Enter address"
                  />
                  <span className='error-message ai-error-message'>{error.address}</span>
                </div>
                <div className="ai-form-group col-md-6 ">
                  <label htmlFor="state">State</label>
                  <input
                    onChange={handleChangeDetail}
                    value={sponsorData.state}
                    name='state'
                    type="text"
                    className="ai-form-control"
                    placeholder="Enter state name"
                  />
                  <span className='error-message ai-error-message'>{error.state}</span>
                </div>
                <div className="ai-form-group col-md-6 ">
                  <label htmlFor="City">City</label>
                  <input
                    onChange={handleChangeDetail}
                    value={sponsorData.city}
                    name='city'
                    type="text"
                    className="ai-form-control"
                    placeholder="Enter city name"
                  />
                  <span className='error-message ai-error-message'>{error.city}</span>
                </div>
                <div className="ai-form-group col-md-6">
                  <label htmlFor="country">Country</label>
                  <input
                    onChange={handleChangeDetail}
                    value={sponsorData.country}
                    name='country'
                    type="text"
                    className="ai-form-control"
                    placeholder="Enter country name"
                  />
                  <span className='error-message ai-error-message'>{error.country}</span>
                </div>
                <div className="ai-form-group col-md-6 ">
                  <label htmlFor="postal_code">Postal Code</label>
                  <input
                    onChange={handleChangeDetail}
                    value={sponsorData.postal_code}
                    name='postal_code'
                    type="text"
                    className="ai-form-control"
                    placeholder="Enter postal code"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    max={10}
                  />
                  <span className='error-message ai-error-message'>{error.postal_code}</span>
                </div>
                <p className='ai-i-have-red'><Input onChange={handleTermsChange} type="checkbox" />I have read all the <Link to='/terms-and-conditions' target='_blank'>Terms & conditions.</Link>
                  <span className='error-message ai-error-message'>{error.terms}</span>
                </p>
                <p className="ai-accordion-sub">Note: All sponsorship requests are subject to approval. In the event that your sponsorship request is not accepted, we will issue an automatic refund to the original payment method.</p>
                <p></p>
                <div className='ai-submit-btn-loader ai-proceed-to-pay-loading'>
                  <button disabled={loader ? true : false} className="proceed-pat-btn">Proceed to pay - ${(sponsorData.days * perDayPrice).toFixed(2)}</button>
                  {loader && (
                    <div className="ai-submit-loader">
                      <img src={loaderimg} />
                    </div>
                  )}
                </div>
              </div>
            </form>
          </>
          :
          <>
            <button onClick={() => setShowPayment(false)} className="ai-multi-back-btn">
              <ArrowBackIos /> Back
            </button>
            <Elements stripe={stripePromise}>
              <CheckoutForm paymentIntent={paymentIntent} />
            </Elements>
          </>
        }
      </div>
    </>
  )
}

export default SponsorShipForm