import React from 'react'

const Categoryicon = () => {
  return (
    <>
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    className="ai-side-icons"
  >
    <path
      fill="#011367"
      fillRule="evenodd"
      d="M9.5 3.5H4a.5.5 0 0 0-.5.5v5.5a.5.5 0 0 0 .5.5h5.5a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5ZM4 2a2 2 0 0 0-2 2v5.5a2 2 0 0 0 2 2h5.5a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4ZM9.5 14H4a.5.5 0 0 0-.5.5V20a.5.5 0 0 0 .5.5h5.5a.5.5 0 0 0 .5-.5v-5.5a.5.5 0 0 0-.5-.5ZM4 12.5a2 2 0 0 0-2 2V20a2 2 0 0 0 2 2h5.5a2 2 0 0 0 2-2v-5.5a2 2 0 0 0-2-2H4ZM20 3.5h-5.5a.5.5 0 0 0-.5.5v5.5a.5.5 0 0 0 .5.5H20a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5ZM14.5 2a2 2 0 0 0-2 2v5.5a2 2 0 0 0 2 2H20a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-5.5ZM14 17.25a3.25 3.25 0 1 0 6.5 0 3.25 3.25 0 0 0-6.5 0Zm3.25-4.75a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5Z"
      clipRule="evenodd"
    />
  </svg>
    </>
  )
}

export default Categoryicon
