import React from 'react'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import Facebookshare from "../../../assets/images/frontend/facebookshare.svg";
import Twittershare from "../../../assets/images/frontend/twittershare.svg";
import Linkedinshare from "../../../assets/images/frontend/linkedInshare.svg";
import NewsLetter from '../newsLetter/NewsLetter';
import BlogLetter from './BlogLetter';
import Blogexplore from './Blogexplore';

const BlogSidebar = ({expolreTool}) => {

    return (
        <>
            <div className='ai-blog-sidebar-main'>
                <div className="ai-share-popover-container">
                    <h4>Share it on</h4>
                    <div className="ai-social-share-blog-details">
                        <button>
                            <FacebookShareButton url={window.location.href} quote={'The #1 AI Tools Platform, Empowering Your AI Success.'}>
                                <img src={Facebookshare} alt="" />
                            </FacebookShareButton>
                        </button>
                        <button>
                            <TwitterShareButton url={window.location.href} title={'The #1 AI Tools Platform, Empowering Your AI Success.'}>
                                <img src={Twittershare} alt="" />
                            </TwitterShareButton>
                        </button>
                        <button>
                            <LinkedinShareButton url={window.location.href} summary={'The #1 AI Tools Platform, Empowering Your AI Success.'}>
                                <img src={Linkedinshare} alt="" />
                            </LinkedinShareButton>
                        </button>
                    </div>
                </div>
                {/* news letter component */}
                <BlogLetter/>
                {/* Ai tool expolre in blog detail page */}
                <Blogexplore expolreTool={expolreTool}/>
                
            </div>
        </>
    )
}

export default BlogSidebar