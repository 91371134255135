import React, { useEffect } from 'react'
import Pagebreadcrumb from '../../components/frontend/pageBreadCrumb/PageBreadCrumb'
import ThankyouTable from '../../components/frontend/thankYou/ThankyouTable'
import ThankyouContent from '../../components/frontend/thankYou/ThankyouContent'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const ThankYou = () => {
    const { res_data } = useSelector(state => state.resData)
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo({
            top: -50,
            left: -50,
            behavior: "smooth",
        });
        if (res_data.paymentIntentId === undefined) {
            navigate('/')
        }
    }, [])
    return (
        <>
            <div className="spacer-132"></div>
            <section className="ai-bg-color">
                <div className="ai-container">
                    <Pagebreadcrumb path={'Thankyou'} />
                    <div className="ai-thank-you">
                        <ThankyouContent />
                        <ThankyouTable resData={res_data} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ThankYou