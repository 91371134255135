import React, { useEffect, useState } from "react";
import Pagebreadcrumb from "../../components/frontend/pageBreadCrumb/PageBreadCrumb";
import Tooldetailscontent from "../../components/frontend/toolDetailsContent/ToolDetailsContent";
import frontendService from "../../services/frontend.service";
import Cookies from "js-cookie";

const Tooldetails = () => {
  //tooldetaildata
  const [toolDetailData, setToolDetailData] = useState([]);
  //alternativetools
  const [alternativeData, setAlternativeData] = useState([]);
  //reviewdata
  const [reviewData, setReviewData] = useState([]);
  const [avgReviewCount, setAvgReviewCount] = useState({});
  var toolName = window.location.pathname.substring(6);

  const getId = Cookies.get("toolId");
  useEffect(() => {
    toolDetailApi();
    toolReviewGetApi();
  }, []);
  //tooldetailapi
  async function toolDetailApi() {

    try {
      const response = await frontendService.toolDetailservice(toolName);
      if (response.status === 200) {

        setToolDetailData(response.data.data.response);
        setAlternativeData(response.data.data.alternativeData);
      }
    } catch (error) { }
  }
  //toolreview data
  async function toolReviewGetApi() {
    const bodyData = {
      toolId: getId,
    };
    try {
      const response = await frontendService.toolReviewGet(bodyData);
      if (response.status === 200) {
        setAvgReviewCount(response.data.data.ratingAvg[0]);
        setReviewData(response.data.data.reviewData);
      }
    } catch (error) { }
  }
  return (
    <>
      <div className="spacer-132"></div>
      <section className="ai-bg-color">
        <div className="ai-container">
          <div className="ai-loading-sapcing">
            {toolDetailData.map((item, i) => (
              <Pagebreadcrumb data={item} path={item.toolName}/>
            ))}

            {/* Tooldetails */}
            {toolDetailData.map((item) => (
              <Tooldetailscontent
                detailData={item}
                similarData={alternativeData}
                reviewData={reviewData}
                toolReviewGetApi={toolReviewGetApi}
                avgReviewCount={avgReviewCount}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Tooldetails;
