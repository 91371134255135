import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./components/admin/adminUser/Login";
import Sidebar from "./components/navcomponents/Sidebar";
import Header from "./components/navcomponents/Header";
import Dashboard from "./components/admin/dashboard/Dashboard";
import User from "./components/admin/userslisting/User";
import Categories from './components/admin/categories/Categories'
import ResetPassword from "./components/admin/adminUser/ResetPassword";
import Features from "./components/admin/features/Features";
import SubCategory from "./components/admin/categories/SubCategory";
import Tools from "./components/admin/aiTools/Tools";
import Feedback from "./components/admin/feedback/Feedback";
import Pricing from "./components/admin/pricing/Pricing";
import { useSelector } from "react-redux";
import ForgotPassword from "./components/admin/adminUser/ForgotPassword";
import AddTool from "./pages/tools/AddTool";
import EditTool from "./pages/tools/EditTool";
import AddBlog from "./pages/blogs/AddBlog";
import EditBlog from "./pages/blogs/EditBlog";
import PendingTools from "./pages/tools/PendingTools";
import BlogListing from "./components/admin/blog/BlogListing";
import { useEffect, useState } from "react";
import Homepage from "./pages/frontend/HomePage";
import Media from "./pages/blogs/Media";
import Tooldetails from "./pages/frontend/ToolDetails";
import CategorywiseTools from "./pages/frontend/CategoryWiseTools";
import Submittool from "./pages/frontend/SubmitTool";
import Favorites from "./pages/frontend/Favorite";
import Blog from "./pages/frontend/Blog";
import FrontendHeader from './components/frontend/navComponents/Header'
import Toollist from "./pages/frontend/ToolList";
import Blogdetails from "./pages/frontend/BlogDetails";
import Review from "./components/admin/reviewManagement/Review";
import Pendingreview from "./components/admin/reviewManagement/PendingReview";
import Legal from "./components/admin/legal/Legal";
import Privacypolicy from "./pages/frontend/PrivacyPolicy";
import Termsandcondition from "./pages/frontend/TermsAndCondition";
import Footer from "./components/frontend/navComponents/Footer";
import RecentAddedTool from "./pages/frontend/RecentAddedTool";
import SponsorshipPrice from "./components/admin/sponsorship/SponsorshipPrice";
import Sponsor from './pages/frontend/Sponsor'
import Thankyou from './pages/frontend/ThankYou'
import UserSponsorList from './pages/frontend/UserSponsorList'
import SponsorshipListing from './components/admin/sponsorship/SponsorshipListing'
import PendingSponsorship from './components/admin/sponsorship/PendingSponsorship'
import HtmlSitemap from "./htmlsitemap/HtmlSitemap";
import Page404 from "./Page404";
import AllCategories from "./components/frontend/allCategories/AllCategories";

function App() {
  const { isLoggedIn } = useSelector(state => state.auth)
  const [showNav, setShowNav] = useState(false)
  const [frontendHeader, setFrontendHeader] = useState(true)
  const pathnName = window.location.pathname

  useEffect(() => {
    if (isLoggedIn) {
      setShowNav(true)
    } else {
      setShowNav(false)
    }
    const detailPath = pathnName.substring(6)
    const categoryPath = pathnName.substring(10)
    const editBlogPath = pathnName.substring(22)
    const isAdminPath = pathnName.startsWith('/admin')

    if (isAdminPath) {
      setFrontendHeader(false)
    } 
    if (pathnName === '/admin/reset-password' || pathnName === '/admin/forgot-password') {
      setShowNav(false)
    }
    if (pathnName === '/admin/dashboard' || pathnName === '/admin/tools/edit-tool' || pathnName === '/admin/user' || pathnName === '/admin/tools' || pathnName === '/admin/pending-tools' || pathnName === '/admin/add-tool' || pathnName === '/admin/categories' || pathnName === '/admin/sub-categories' || pathnName === '/admin/pricing' || pathnName === '/admin/features' || pathnName === '/admin/blog' || pathnName === '/admin/media' || pathnName === '/admin/feedback' || pathnName === '/admin/review' || pathnName === '/admin/pending-reviews' || pathnName === '/admin/legal' || pathnName === '/admin/sponsor-price' || pathnName === '/admin/sponsors' || pathnName === '/admin/pending-sponsors' || pathnName === "/admin/blog/add-blog" || pathnName === '/admin/blog/edit-blog/' + editBlogPath) {
      setShowNav(true)
      setFrontendHeader(false)
    } else {
      setShowNav(false)
    }
    if (pathnName === '/' || pathnName === '/submit-tool' || pathnName === "/tool-list" || pathnName === '/tool/' + detailPath || pathnName === '/favourites' || pathnName === '/blog' || pathnName === '/blog/' + detailPath || pathnName === '/ai-tools/' + categoryPath || pathnName === '/terms-and-conditions' || pathnName === '/privacy-policy' || pathnName === '/recent' || pathnName === '/sponsorship' || pathnName === '/sponsorship-list' || pathnName === '/htmlsitemap') {
      setFrontendHeader(true)
      setShowNav(false)
    }
  })
  // redirection setup from non www url to www url
  // const { hostname, protocol } = window.location;
  // console.log(`https:${window.location.href.substring(protocol.length)}`, "iqwufgkasjbfaklsf");
  // if ((hostname && !hostname.startsWith('www.')) && protocol === 'http:') {
  //   const newURL = `https:${window.location.href.substring(protocol.length)}` + hostname + window.location.pathname;
  //   return <Navigate to={newURL} />;
  // }
  // console.log = console.warn = console.error = () => { };

  // redirection setup from non www url to www url
  const { hostname } = window.location;
  if ((hostname && !hostname.startsWith('www.'))) {
    const newURL = '//www.' + window.location.host + window.location.pathname;
    return <Navigate to={newURL} />;
  }
  console.log = console.warn = console.error = () => { };
  return (
    <>
      {frontendHeader &&
        <>
          <FrontendHeader />
        </>
      }
      <Routes>
        <Route path="*" element={<Page404 />} />
        <Route exact path="/categories" element={<AllCategories />} />
        <Route exact path="/sitemap" element={<HtmlSitemap />} />
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/recent" element={<RecentAddedTool />} />
        <Route exact path="/submit-tool" element={<Submittool />} />
        <Route exact path="/tool-list" element={<Toollist />} />
        <Route exact path="/tool/:id" element={<Tooldetails />} />
        <Route exact path="/favourites" element={<Favorites />} />
        <Route exact path="/thankyou" element={<Thankyou />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blog/:name" element={<Blogdetails />} />
        <Route exact path="/ai-tools/:name" element={<CategorywiseTools />} />
        <Route exact path="/privacy-policy" element={<Privacypolicy />} />
        <Route exact path="/terms-and-conditions" element={<Termsandcondition />} />
        <Route exact path="/sponsorship" element={<Sponsor />} />
        <Route exact path="/sponsorship-list" element={<UserSponsorList />} />
        <Route exact path="/admin" element={<Login />} />
        <Route exact path="/admin/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/admin/reset-password" element={<ResetPassword />} />
        <Route exact path="/admin/dashboard" element={<RestrictPage><Dashboard /></RestrictPage>} />
        <Route exact path="/admin/user" element={<RestrictPage><User /></RestrictPage>} />
        <Route exact path="/admin/categories" element={<RestrictPage> <Categories /></RestrictPage>} />
        <Route exact path="/admin/sub-categories" element={<RestrictPage> <SubCategory /></RestrictPage>} />
        <Route exact path="/admin/features" element={<RestrictPage> <Features /></RestrictPage>} />
        <Route exact path="/admin/feedback" element={<RestrictPage> <Feedback /></RestrictPage>} />
        <Route exact path="/admin/pricing" element={<RestrictPage> <Pricing /></RestrictPage>} />
        <Route exact path="/admin/tools" element={<RestrictPage> <Tools /></RestrictPage>} />
        <Route exact path="/admin/pending-tools" element={<RestrictPage> <PendingTools /></RestrictPage>} />
        <Route exact path="/admin/add-tool" element={<RestrictPage> <AddTool /></RestrictPage>} />
        <Route exact path="/admin/tools/edit-tool" element={<RestrictPage> <EditTool /></RestrictPage>} />
        <Route exact path="/admin/blog/add-blog" element={<RestrictPage><AddBlog /></RestrictPage>} />
        <Route exact path="/admin/blog" element={<RestrictPage><BlogListing /></RestrictPage>} />
        <Route exact path="/admin/media" element={<RestrictPage><Media /></RestrictPage>} />
        <Route exact path="/admin/blog/edit-blog/:id" element={<RestrictPage><EditBlog /></RestrictPage>} />
        <Route exact path="/admin/review" element={<RestrictPage><Review /></RestrictPage>} />
        <Route exact path="/admin/pending-reviews" element={<RestrictPage><Pendingreview /></RestrictPage>} />
        <Route exact path="/admin/legal" element={<RestrictPage><Legal /></RestrictPage>} />
        <Route exact path="/admin/sponsor-price" element={<RestrictPage><SponsorshipPrice /></RestrictPage>} />
        <Route exact path="/admin/sponsors" element={<RestrictPage><SponsorshipListing /></RestrictPage>} />
        <Route exact path="/admin/pending-sponsors" element={<RestrictPage><PendingSponsorship /></RestrictPage>} />
      </Routes>
      {frontendHeader &&
        <Footer />
      }
      {showNav &&
        <>
          <Header />
          <Sidebar />
        </>
      }
    </>
  );
}

export default App;

function RestrictPage({ children }) {
  const { isLoggedIn } = useSelector(state => state.auth)
  return (isLoggedIn) ? children : <Navigate to="/admin" replace />;
}