import React from "react";

const Aitoolsicon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        className="ai-side-icons"
      >
        <path
          fill="#011367"
          d="M21.96 10.22v3.606a1.145 1.145 0 0 1-1.113 1.252l-.86.127-.527.078a.644.644 0 0 1-.675-.293.587.587 0 0 1 0-.724.977.977 0 0 1 .49-.273c.449-.079.898-.137 1.357-.196V10.25l-1.065-.206-1.104-.156a.86.86 0 0 1-.625-.46 14.163 14.163 0 0 0-.713-1.29.91.91 0 0 1-.108-.9c.205-.478.39-.977.586-1.465l.235-.616-2.316-1.369-.615-.38-.342.43-1.075 1.319a.89.89 0 0 1-.791.362h-1.486a.84.84 0 0 1-.771-.362c-.342-.47-.694-.919-1.046-1.378l-.283-.372-.743.43-2.257 1.32c.127.313.254.625.372.977.117.352.293.753.449 1.125a.91.91 0 0 1-.098.88c-.254.41-.498.84-.723 1.29a.85.85 0 0 1-.684.508l-1.006.107-1.143.196v3.538l1.084.177 1.085.156a.83.83 0 0 1 .684.508c.205.41.44.831.742 1.32a.821.821 0 0 1 .088.811c-.156.401-.312.812-.478 1.212-.166.401-.235.626-.362.929l2.218 1.32.713.42L10.1 19.3l.39-.488a.713.713 0 0 1 .626-.294h1.68a.781.781 0 0 1 .655.333l1.25 1.642.108.147.586-.323c.117-.058.176-.098.254-.127a.644.644 0 1 1 .616 1.134c-.284.166-.577.323-.87.479l-.4.225h-.44a1.603 1.603 0 0 1-.811-.626c-.205-.293-.42-.567-.635-.85l-.508-.733H11.37c-.215.254-.391.479-.567.703l-.625.841a1.632 1.632 0 0 1-.82.626H8.887l-.117-.059-.303-.156-3.039-1.78a1.125 1.125 0 0 1-.527-1.534l.176-.47c.176-.459.351-.918.547-1.378a.225.225 0 0 0 0-.244 7.491 7.491 0 0 1-.528-.929.127.127 0 0 0-.127-.088l-.684-.107-1.104-.147A1.26 1.26 0 0 1 2 14.188v-4.34a1.261 1.261 0 0 1 1.182-.91l1.095-.146.703-.107c.068 0 .078 0 .127-.098.166-.342.361-.655.567-.978-.254-.645-.499-1.349-.743-2.013A1.144 1.144 0 0 1 5.43 4.04l3.039-1.818a1.123 1.123 0 0 1 1.622.313l1.231 1.642h1.221c.41-.498.782-.977 1.143-1.446l.137-.176a1.124 1.124 0 0 1 1.642-.333l3.009 1.79a1.143 1.143 0 0 1 .547 1.563l-.205.43c-.196.499-.381.978-.577 1.486V7.6c.209.339.404.68.587 1.026h.049l.869.127 1.055.147a1.152 1.152 0 0 1 1.163 1.32Z"
        />
        <path
          fill="#011367"
          d="m21.326 18.03-.498-.509-.978-.928c-.977-.99-1.954-1.968-2.93-2.933a2.025 2.025 0 0 1-.675-1.564 3.647 3.647 0 0 0-.684-2.16 4.739 4.739 0 0 0-2.15-1.896 3.985 3.985 0 0 0-4.044.567.752.752 0 0 0-.323.596.763.763 0 0 0 .43.606l.4.235 1.838 1.104a1.957 1.957 0 0 1-.87 1.31h-.137l-1.954-1.193a.723.723 0 0 0-.723-.049.733.733 0 0 0-.351.646 4.45 4.45 0 0 0 .38 1.955 4.29 4.29 0 0 0 4.69 2.434c.059 0 .079 0 .147.049l3.117 3.128 1.827 1.798c.3.32.676.56 1.094.694l.244.059h.831a2.375 2.375 0 0 0 1.348-3.91l-.03-.04Zm-8.315-7.126a.743.743 0 0 0-.38-.714l-1.379-.821-.254-.176a2.52 2.52 0 0 1 .684-.127 2.842 2.842 0 0 1 2.55 1.241c.356.395.59.884.675 1.408.01.13.01.26 0 .391v.362a3.314 3.314 0 0 0 1.045 2.092l1.886 1.789 2.433 2.492a1.095 1.095 0 0 1 .322 1.183.978.978 0 0 1-.703.674 1.035 1.035 0 0 1-.977-.185c-.08-.061-.151-.13-.215-.206l-1.163-1.153-3.908-3.91a.908.908 0 0 0-.655-.303.975.975 0 0 0-.283 0 2.93 2.93 0 0 1-3.41-1.574 2.127 2.127 0 0 1-.147-.342c.391.234.753.45 1.114.684a.87.87 0 0 0 .977 0 3.264 3.264 0 0 0 1.788-2.805Z"
        />
      </svg>
    </>
  );
};

export default Aitoolsicon;
