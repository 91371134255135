import React from 'react'
import { Link } from 'react-router-dom'

function Page404() {
    return (
        <>
            <div className="spacer-132"></div>
            <div className='text-center' style={{ background: "#ffff" }}>
                <h1 style={{ margin: 0, padding: 49 }}>404 Page Not Found</h1>
                <div className='col-lg-4 page-404'>
                    <Link to="/" className='ai-subscribe-btn cstm-page-404'>
                        Back to Home
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Page404