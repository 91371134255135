import React from 'react'
import { Rating } from '@mui/material'
import { Close, Send } from "@mui/icons-material";
import { Editor } from 'react-draft-wysiwyg';
import localization1 from '../../../../config/localization1';
import toolbar1 from '../../../../config/toolbar1';


const Editratingmodal = ({ rating, handleRating, handleClose, handleClickReview, toolName, editorData, handleEditorStateonChange }) => {
    return (
        <>
            <div className="ai-model-content ai-rating-model">
                <div className="ai-model-heading">
                    <h6 className="ai-h6-18">What are your thoughts on {toolName}?</h6>
                    <button className="ai-close-model" onClick={handleClose}>
                        <Close />
                    </button>
                </div>

                {/* Model Inner sections */}
                <div className="ai-model-inner">
                    <div className="ai-what-rating">
                        <h6 className="ai-h6">Rate the tool:</h6>
                        <div className="ai-rating-star">
                            <Rating
                                onChange={(e, newValue) => handleRating(e, newValue)}
                                value={rating}
                            />
                        </div>
                    </div>

                    {/* Review write tool */}
                    <div className="ai-review-write-tool">
                        <h6 className="ai-h6">Write your review of the tool:</h6>
                        <Editor
                            editorState={editorData}
                            onEditorStateChange={handleEditorStateonChange}
                            placeholder="Enter long description"
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            toolbar={toolbar1}
                            localization={localization1}
                        />
                        <p className="ai-p-14">
                        Your review will undergo approval before being visible to other users. We do not accept profanity, promo links, impersonation, or inappropriate content. Reviews are typically approved within 1-3 business days. Thank you for understanding and contributing to our valuable community.
                        </p>
                    </div>
                </div>
                {/* Model Footer*/}
                <div className="ai-model-footer">
                    <button className="model-btn-cancel" onClick={handleClose}> Cancel</button>
                    <button className="model-btn-action" onClick={handleClickReview}>
                        Post Review <Send />
                    </button>
                </div>
            </div>
        </>
    )
}

export default Editratingmodal