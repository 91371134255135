import React, { useEffect, useRef, useState } from "react";
import Homebanner from "../../components/frontend/homeBanner/HomeBanner";
import Aicardcomponent from "../../components/frontend/aiCard/AiCardComponent";
// image
import Feedback from "../../assets/images/frontend/feedback.svg";
import frontendService from "../../services/frontend.service";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import Feedbackpop from "../../components/frontend/feedBack/FeedBackPop";
import NewsLetter from "../../components/frontend/newsLetter/NewsLetter";
import Seo from "../../components/seo/Seo";

const Homepage = () => {
  //data
  const [toolList, setToolList] = useState([]);
  const [pageLimit, setPageLimit] = useState();
  const [subCtegoryData, setSubCatgoryData] = useState([]);
  const [featureData, setFeatureData] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  let arrayPrice = [];
  const [priceArray, setPriceArray] = useState([]);
  const [featureArray, setFeatureArray] = useState([]);
  let arrayFeature = [];
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedList, setSelectedList] = useState(false);
  //loader
  const [isLoading, setLoading] = useState(false);
  //no record data found
  const [noRecordFound, setNoRecordFound] = useState(false);
  //pagination
  const [page, setPage] = useState(1);
  const [openDropdown, setOpenDropdown] = useState(false);
  //dropDown
  const [catOpen, setCateOpen] = useState(false);
  //filteration states
  const [subCategoryList, setSubCatgorylist] = useState([]);
  const [searchToolList, setSearchToolList] = useState([]);
  var categoryDataarr = [];
  var toolDataarr = [];
  const [text, setText] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [displayToolSuggestions, setDisplayToolSuggestions] = useState([]);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [displaytoolSuggestionsList, setDisplaytolSuggestionsList] = useState(false);
  const [suggestionlist, setSuggestionList] = useState(false);
  const excludeColumns = ["_id"];
  //feedback
  const [openFeedBack, setOpenFeedBack] = useState(null);
  const [feedbackShow, setFeedBackShow] = useState(false);
  const open = Boolean(openFeedBack);
  const id = open ? "simple-popover" : undefined;
  //outside click close menu
  const closeMenu = useRef(null);
  const closeDropDown = useRef(null);
  const closeCategoryDropDown = useRef(null);
  //current location
  const location = useLocation().search;
  //search field or slug get
  const searchTerm = new URLSearchParams(location).get("searchTerm");
  const sortby = new URLSearchParams(location).get("sortby");
  const navigate = useNavigate();
  useEffect(() => {
    featureListApi();
    subCategoryListApi();
    priceListApi();
    filterBykeyWordApi(
      1,
      searchTerm,
      priceArray,
      selectedFeature,
      selectedStatus
    );
    searchListApi();
  }, []);
  useEffect(() => {
    //scroll listner
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, toolList]);
  // pricelist dropdown api
  async function priceListApi() {
    try {
      const response = await frontendService.priceGetService();
      if (response.status === 200) {
        setPriceData(response.data.data);
      }
    } catch (error) { }
  }
  //filterBykeyWord api
  async function filterBykeyWordApi(
    page,
    searchTerm,
    priceArray,
    selectedFeature,
    selectedStatus
  ) {
    setLoading(true);
    let queryString = "?";
    //updating querystring and payload
    let sortByData =
      selectedStatus !== undefined &&
        selectedStatus !== null &&
        selectedStatus !== ""
        ? selectedStatus
        : "";
    if (searchTerm !== null) {
      if (priceArray.length === 0 && selectedFeature.length === 0) {
        queryString +=
          "&keyword=" +
          searchTerm.split("-").join(" ") +
          "&sortData=" +
          sortByData;
      } else {
        queryString +=
          "&keyword=" +
          searchTerm.split("-").join(" ") +
          "&sortData=" +
          sortByData;
      }
    } else {
      queryString += "keyword=&sortData=" + sortByData;
    }

    queryString += `&limit=10&page=${page}`;
    const bodyData = {
      pricing: priceArray !== undefined ? priceArray : [],
      features: selectedFeature !== undefined ? selectedFeature : [],
    };
    try {
      const response = await frontendService.filterBykeyWord(
        queryString,
        bodyData
      );
      if (response.status === 200) {

        if (response.data.data.tool_data.length === 0) {
          setNoRecordFound(true);
          setLoading(false);
        } else {
          setNoRecordFound(false);
        }
        setLoading(false);
        if (
          priceArray.length === 0 &&
          selectedFeature.length === 0 &&
          searchTerm === null &&
          sortByData === "" &&
          page === 1
        ) {
          setToolList(response.data.data.tool_data);
          setPageLimit(response.data.data.state.page_limit);
          setPage(page + 1);
        } else {
          if (page === 1) {
            setToolList(response.data.data.tool_data);
          } else {
            setToolList([...toolList, ...response.data.data.tool_data]);
          }
          setPageLimit(response.data.data.state.page_limit);
          setPage(page + 1);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  }
  //searchlist api
  function createcategorydata(_id, subCategoryName, slug) {
    return {
      _id,
      subCategoryName,
      slug
    };
  }

  function createTooldata(_id, toolName, slug) {
    return {
      _id,
      toolName,
      slug
    };
  }
  async function searchListApi() {
    try {
      const response = await frontendService.searchListService();
      if (response.status === 200) {
        var categoryArr = response.data.data.subcategoryData;
        if (response.data.data.subcategoryData.length > 0) {
          var newArr = [];

          for (var i = 0; i < categoryArr.length; i++) {
            var slugData = categoryArr[i].slug !== undefined ? categoryArr[i].slug : "-"
            newArr[i] = createcategorydata(
              categoryArr[i]._id,
              categoryArr[i].subCategoryName,
              slugData
            );
          }
          newArr.map((item) => (categoryDataarr = [...categoryDataarr, item]));
          setSubCatgorylist(categoryDataarr);
        }

        var toolArr = response.data.data.toolData;
        if (toolArr.length > 0) {
          var newtoolArr = [];
          for (var i = 0; i < toolArr.length; i++) {
            var slugData = toolArr[i].slug !== undefined ? toolArr[i].slug : "-"
            newtoolArr[i] = createTooldata(toolArr[i]._id, toolArr[i].toolName, slugData);
          }
          newtoolArr.map((item) => (toolDataarr = [...toolDataarr, item]));
          setSearchToolList(toolDataarr);
        }
      }
    } catch (error) { }
  }

  // subcategory dropdown api
  let dataArray = []
  function createSubCategoryData(_id, subCategoryName, slug) {
    return {
      _id,
      subCategoryName,
      slug
    }
  }
  async function subCategoryListApi() {
    try {
      const response = await frontendService.footerSubCategoryGetService();
      if (response.status === 200) {
        var arr = response.data.data;
        if (response.data.data.length > 0) {
          var newarr = [];
          for (var i = 0; i < arr.length; i++) {
            var dataSlug = arr[i].slug !== undefined ? arr[i].slug : "-"
            newarr[i] = createSubCategoryData(
              arr[i]._id,
              arr[i].subCategoryName,
              dataSlug
            )
          }
          newarr.map((data1) => {
            dataArray = [...dataArray, data1]
          })
          dataArray = dataArray.sort((a, b) => a.subCategoryName.localeCompare(b.subCategoryName))
          setSubCatgoryData(dataArray);
        }

      }
    } catch (error) { }
  }
  //featurelist dropdown api
  async function featureListApi() {
    try {
      const response = await frontendService.featureGetService();
      if (response.status === 200) {
        setFeatureData(response.data.data);
      }
    } catch (error) { }
  }
  const handleScroll = () => {
    // Check if user has scrolled to the bottom of the page
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 500
    ) {
      if (page <= pageLimit) {
        filterBykeyWordApi(
          page,
          searchTerm,
          priceArray,
          selectedFeature,
          selectedStatus
        );
      }
    }
  };
  //categorywise filter function
  const onClickcategory = (e, id, name) => {
    setSelectedCategory(name);
    navigate("/ai-tools/" + name);
    setCateOpen(false);
  };
  //price filter
  const onClickPriceFilter = (selectedList) => {

    if (selectedList.length === 0) {
      setSelectedList(false);
    }
    setSelectedPrice(selectedList);
    setPage(1);

    selectedList.map((item) => {
      arrayPrice = [...arrayPrice, item.pricingName];
    });
    setPriceArray(arrayPrice);
    setSelectedList(true);
    filterBykeyWordApi(
      1,
      searchTerm,
      arrayPrice,
      selectedFeature,
      selectedStatus
    );
  };
  const onRemovePriceFilter = (selectedList) => {

    if (selectedList.length === 0) {
      setSelectedList(false);
    }
    setSelectedPrice(selectedList);
    setPage(1);
    if (selectedList.length !== 0) {
      setToolList(toolList);
    }
    selectedList.map((item) => {
      arrayPrice = [...arrayPrice, item.pricingName];
    });
    setSelectedList(true);
    setPriceArray(arrayPrice);
    filterBykeyWordApi(
      1,
      searchTerm,
      arrayPrice,
      selectedFeature,
      selectedStatus
    );
  };
  //feature filter
  const onClickfilterFeature = (selectedList) => {

    if (selectedList.length === 0) {
      setSelectedList(false);
    }
    setFeatureArray(selectedList);
    setSelectedList(true);
    setPage(1);
    if (selectedList.length === 0) {
      setToolList(toolList);
    }

    selectedList.map((item) => {
      arrayFeature = [...arrayFeature, item.featureName];
    });
    setSelectedFeature(arrayFeature);
    filterBykeyWordApi(1, searchTerm, priceArray, arrayFeature, selectedStatus);
  };
  //open status dropdown
  const openDropdownStatus = () => {
    setOpenDropdown(!openDropdown);
  };
  //status filter
  const handleStatus = (e) => {

    setSelectedStatus(e);
    if (e) {
      filterBykeyWordApi(1, searchTerm, priceArray, arrayFeature, e);
    }
  };
  const onRemoveSelectedData = (e, id) => {

    const removedPriceData = selectedPrice.filter(
      (item) => item.pricing_id !== id
    );
    removedPriceData.map((item) => {
      arrayPrice = [...arrayPrice, item.pricingName];
    });
    setPriceArray(arrayPrice);
    setSelectedPrice(removedPriceData);
    // remove feature from selected list
    const removedFeatureData = featureArray.filter(
      (item) => item.feature_id !== id
    );
    setFeatureArray(removedFeatureData);
    removedFeatureData.map((item) => {
      arrayFeature = [...arrayFeature, item.featureName];
    });
    setSelectedFeature(arrayFeature);
    filterBykeyWordApi(1, searchTerm, arrayPrice, arrayFeature, selectedStatus);
    if (removedFeatureData.length === 0 && removedPriceData.length === 0) {
      setSelectedList(false);
    }
  };
  const handleClearAll = () => {

    setFeatureArray([]);
    setSelectedPrice([]);
    setPriceArray([]);
    setSelectedFeature([]);
    filterBykeyWordApi(1, searchTerm, [], [], selectedStatus);
    setSelectedList(false);
  };
  const onTextChanged = (e) => {
    const value = e.target.value;
    setText(value);

    const filteredSuggestions = subCategoryList.filter((item) => {
      return Object.keys(item).some((key) =>
        excludeColumns.includes(key)
          ? false
          : item[key]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase().trim())
      );
    });
    const filteredToolsuggestion = searchToolList.filter((item) => {
      return Object.keys(item).some((key) =>
        excludeColumns.includes(key)
          ? false
          : item[key]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase().trim())
      );
    });
    setDisplayToolSuggestions(filteredToolsuggestion);
    setFilteredSuggestions(filteredSuggestions);
    setDisplaySuggestions(true);
    setSuggestionList(true);
    if (value === "") {
      setDisplaySuggestions(false);
      setSuggestionList(false);
    }
    setDisplaytolSuggestionsList(true);
    if (displayToolSuggestions.length === 0) {
      setDisplaytolSuggestionsList(false);
    }
  };
  const handleClickgetquery = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setDisplaySuggestions(false);
      setSuggestionList(false);
      navigate("/" + "?searchTerm=" + text.replace(/\s+/g, "-"));
      filterBykeyWordApi(1, text, priceArray, selectedFeature, selectedStatus);
    }
  };
  const toggleCategory = () => {
    setCateOpen(!catOpen);
  };
  const closeOpenMenus = (e) => {
    if (
      closeMenu.current &&
      displaySuggestions &&
      suggestionlist &&
      !closeMenu.current.contains(e.target)
    ) {
      setDisplaySuggestions(false);
      setSuggestionList(false);
    }
    if (
      closeDropDown.current &&
      openDropdown &&
      !closeDropDown.current.contains(e.target)
    ) {
      setOpenDropdown(false);
    }
    if (
      closeCategoryDropDown.current &&
      catOpen &&
      !closeCategoryDropDown.current.contains(e.target)
    ) {
      setCateOpen(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);
  //toggle feedback
  const handleFeedbackClick = (event) => {
    setOpenFeedBack(event.currentTarget);
    setFeedBackShow(false);
    document.body.classList.add("ai-backdrop-feedback");
  };
  const handleFeedbackClose = () => {
    setOpenFeedBack(null);
    document.body.classList.remove("ai-backdrop-feedback");
  };
  return (
    <>
    <Seo title={"AIToolSpot | Discover the Best AI Tools"} description={"Find the best AI tools and apps at AIToolSpot. Explore our collection of paid and free AI tools and discover innovative solutions for your projects."}/>
      {/* Hero section */}
      <section className="ai-hero-section">
        <div className="ai-container">
          <Homebanner
            subCtegoryData={subCtegoryData}
            featureData={featureData}
            priceData={priceData}
            categoryFilter={onClickcategory}
            selectedPrice={selectedPrice}
            onClickPrice={onClickPriceFilter}
            onRemovePriceFilter={onRemovePriceFilter}
            filterBykeyWordApi={filterBykeyWordApi}
            onClickfilterFeature={onClickfilterFeature}
            selectedFeatureData={selectedFeature}
            selectedPriceData={priceArray}
            handleStatus={handleStatus}
            openDropdownStatus={openDropdownStatus}
            openDropdown={openDropdown}
            selectedStatus={selectedStatus}
            onRemoveSelectedData={onRemoveSelectedData}
            featureArray={featureArray}
            handleClearAll={handleClearAll}
            selectedList={selectedList}
            selectedCategory={selectedCategory}
            searchToolList={searchToolList}
            suggestionlist={suggestionlist}
            subCategoryList={subCategoryList}
            handleClickgetquery={handleClickgetquery}
            onTextChanged={onTextChanged}
            closeMenu={closeMenu}
            closeDropDown={closeDropDown}
            closeCategoryDropDown={closeCategoryDropDown}
            text={text}
            filteredSuggestions={filteredSuggestions}
            displaySuggestions={displaySuggestions}
            displayToolSuggestions={displayToolSuggestions}
            displaytoolSuggestionsList={displaytoolSuggestionsList}
            setCateOpen={setCateOpen}
            catOpen={catOpen}
            toggleCategory={toggleCategory}
          />
        </div>
      </section>
      {/* Card Components */}
      <section className="ai-card-section">
        <div className="ai-container">
          <div className="row">
            {toolList.map((item, i) => (
              <>
                <div className="col-sm-6 col-lg-4" key={item._id}>
                  <Aicardcomponent 
                    
                    data={item}
                    filterBykeyWordApi={filterBykeyWordApi}
                    searchTerm={searchTerm}
                    priceArray={priceArray}
                    selectedFeature={selectedFeature}
                    selectedStatus={selectedStatus}
                  />
                </div>
                {i === 2 && <NewsLetter />}
              </>
            ))}
          </div>
          {noRecordFound && (
            <div className="ai-norecord">
              <span>Unfortunately, we couldn't find any results that match your criteria. Try broadening your search for more results.</span>
            </div>
          )}
          {isLoading && (
            <div className="ai-front-loader">
              <Loader />
            </div>
          )}
        </div>
      </section>
      {/* Feedback tool tip */}
      <section className="feedback-tool-tip">
        <button type="button" onClick={handleFeedbackClick}>
          <img src={Feedback} alt="" />
        </button>
      </section>
      <Feedbackpop
        id={id}
        openFeedBack={openFeedBack}
        open={open}
        handleFeedbackClose={handleFeedbackClose}
        setFeedBackShow={setFeedBackShow}
        feedbackShow={feedbackShow}
      />
    </>
  );
};

export default Homepage;
