import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/Logo.png";

import Dashboardicon from "./icons/Dashboardicon";
import Usericon from "./icons/Usericon";
import Aitoolsicon from "./icons/Aitoolsicon";
import Categoryicon from "./icons/Categoryicon";
import Priceicon from "./icons/Priceicon";
import Featureicon from "./icons/Featureicon";
import Blogicon from "./icons/Blogicon";
import Feedbackicon from "./icons/Feedbackicon";
import Reviewicons from "./icons/Reviewicons";
import Legalicon from "./icons/Legalicon";
import { Sponsoricon } from "./icons/Sponsoricon";

const Sidebar = () => {
  const [AIOpen, setAIOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [blogOpen, setBlogOpen] = useState(false);
  const [reviewOpen, setReviewOpen] = useState(false)
  const [openSponsor, setOpenSponsor] = useState(false)

  let pathName = window.location.pathname;
  useEffect(() => {
    if (
      pathName === "/admin/tools" ||
      pathName === "/admin/pending-tools" ||
      pathName === "/admin/add-tool"
    ) {
      setAIOpen(true);
    } else {
      setAIOpen(false);
    }
    if (
      pathName === "/admin/categories" ||
      pathName === "/admin/sub-categories"
    ) {
      setCategoryOpen(true);
    } else {
      setCategoryOpen(false);
    }
    if (
      pathName === "/admin/review" ||
      pathName === "/admin/pending-reviews"
    ) {
      setReviewOpen(true);
    } else {
      setReviewOpen(false);
    }
    if (
      pathName === "/admin/sponsor-price" ||
      pathName === "/admin/pending-sponsors" ||
      pathName === "/admin/sponsors"
    ) {
      setOpenSponsor(true);
    } else {
      setOpenSponsor(false);
    }
  }, []);

  const toogleCategory = () => {
    setCategoryOpen(!categoryOpen);
    setAIOpen(false);
    setReviewOpen(false)
    setBlogOpen(false)
    setOpenSponsor(false)
  };

  const toggleAI = () => {
    setAIOpen(!AIOpen);
    setReviewOpen(false)
    setCategoryOpen(false);
    setBlogOpen(false);
    setOpenSponsor(false)
  };

  const handleClosedropdown = () => {
    setCategoryOpen(false);
    setReviewOpen(false)
    setAIOpen(false);
    setBlogOpen(false);
    setOpenSponsor(false)
  };

  const toggleBlog = () => {
    setBlogOpen(!blogOpen);
    setCategoryOpen(false);
    setAIOpen(false);
    setReviewOpen(false)
    setOpenSponsor(false)
  };
  const toggleReview = () => {
    setReviewOpen(!reviewOpen)
    setBlogOpen(false);
    setCategoryOpen(false);
    setAIOpen(false);
    setOpenSponsor(false)
  }
  const toggleSponsor = () => {
    setOpenSponsor(!openSponsor)
    setReviewOpen(false)
    setBlogOpen(false);
    setCategoryOpen(false);
    setAIOpen(false);
  }
  return (
    <>
      <div className="page-wrapper doctris-theme toggled">
        <nav id="sidebar" className="sidebar-wrapper">
          <div className="sidebar-content">
            <div className="sidebar-brand">
              <Link to="/admin/dashboard">
                <img src={logo} className="cstm-sidebar-logo" />
              </Link>
            </div>
            <ul className="sidebar-menu active">
              <li>
                <NavLink onClick={handleClosedropdown} to="/admin/dashboard">
                  <Dashboardicon />
                  {/* <i><img src={dashboard} /></i> */}
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li onClick={handleClosedropdown}>
                <NavLink to="/admin/user">
                  <Usericon />
                  <span>User Management</span>
                </NavLink>
              </li>
              <li className={`sidebar-dropdown ${AIOpen ? "active" : null}`}>
                <Link onClick={toggleAI}>
                  <Aitoolsicon />
                  <span>AI Tools</span>
                </Link>
                <div className={`sidebar-submenu ${AIOpen ? "d-block" : null}`}>
                  <ul>
                    <li>
                      <NavLink to="/admin/tools"> All Tools List</NavLink>
                    </li>
                    <li>
                      <NavLink to="/admin/pending-tools">Pending Tools</NavLink>
                    </li>
                    <li>
                      <NavLink to="/admin/add-tool">Add Tools</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className={`sidebar-dropdown ${categoryOpen ? "active" : ""}`}
              >
                <Link onClick={toogleCategory}>
                  <Categoryicon />
                  <span>Categories</span>
                </Link>
                <div
                  className={`sidebar-submenu ${categoryOpen ? "d-block" : null
                    }`}
                >
                  <ul>
                    <li>
                      <NavLink to="/admin/categories"> Category</NavLink>
                    </li>
                    <li>
                      <NavLink to="/admin/sub-categories"> Subcategory</NavLink>
                    </li>
                  </ul>
                </div>
              </li>

              <li onClick={handleClosedropdown}>
                <NavLink to="/admin/pricing">
                  <Priceicon />
                  <span>Pricing</span>
                </NavLink>
              </li>
              <li onClick={handleClosedropdown}>
                <NavLink to="/admin/features">
                  <Featureicon />
                  <span>Features</span>
                </NavLink>
              </li>
              <li className={`sidebar-dropdown ${blogOpen ? "active" : ""}`}>
                <Link onClick={toggleBlog}>
                  <Blogicon />
                  <span>Blog</span>
                </Link>
                <div
                  className={`sidebar-submenu ${blogOpen ? "d-block" : null}`}
                >
                  <ul>
                    <li>
                      <NavLink to="/admin/blog">Blog</NavLink>
                    </li>
                    <li>
                      <NavLink to="/admin/media"> Media</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li onClick={handleClosedropdown}>
                <NavLink to="/admin/feedback">
                  <Feedbackicon />
                  <span>Feedback Listing</span>
                </NavLink>
              </li>
              <li className={`sidebar-dropdown ${reviewOpen ? "active" : ""}`}>
                <Link onClick={toggleReview}>
                  <Reviewicons />
                  <span>Review Management</span>
                </Link>
                <div className={`sidebar-submenu ${reviewOpen ? "d-block" : null}`}>
                  <ul>
                    <li>
                      <NavLink to="/admin/review">Reviews</NavLink>
                    </li>
                    <li>
                      <NavLink to="/admin/pending-reviews"> Pending Reviews</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li onClick={handleClosedropdown}>
                <NavLink to="/admin/legal">
                  <Legalicon />
                  <span>Legal</span>
                </NavLink>
              </li>

              <li className={`sidebar-dropdown ${openSponsor ? "active" : ""}`}>
                <Link onClick={toggleSponsor}>
                  <Sponsoricon />
                  <span>Sponsors</span>
                </Link>
                <div className={`sidebar-submenu ${openSponsor ? "d-block" : null}`}>
                  <ul>
                    <li>
                      <NavLink to="/admin/sponsor-price">Sponsorship Price</NavLink>
                    </li>
                    <li>
                      <NavLink to="/admin/sponsors">Sponsors</NavLink>
                    </li>
                    <li>
                      <NavLink to="/admin/pending-sponsors"> Pending Sponsors</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
