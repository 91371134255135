import React from 'react'

const Dashboardicon = () => {
  return (
   <>
     <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    className='ai-side-icons'
  >
    <path
      fill="#011367"
      fillRule="evenodd"
      d="M8.5 17.5a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v2a1 1 0 0 0 1 1h4v-7.464a1 1 0 0 0-.293-.708l-7.5-7.5a1 1 0 0 0-1.414 0l-7.5 7.5a1 1 0 0 0-.293.708V19.5a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-2ZM10 20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7.172a2 2 0 0 1 .586-1.414l8-8a2 2 0 0 1 2.828 0l8 8A2 2 0 0 1 22 12.828V20a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-2a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v2Z"
      clipRule="evenodd"
    />
  </svg>
   </>
  )
}

export default Dashboardicon
