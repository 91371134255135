import React, { useEffect, useState } from "react";
// Components
import Aicardcomponent from "../aiCard/AiCardComponent";
// CSS
import "./RecentAddedToolDetails.css";

// images
import Document from "../../../assets/images/frontend/document.svg";
import DocumentHover from "../../../assets/images/frontend/document-hover.svg";
import frontendService from "../../../services/frontend.service";
import Loader from "../../loader/Loader";
import { useNavigate } from "react-router-dom";

const RecentAddedToolDetails = ({filterBykeyWordApi}) => {
  const [recentTool, setRecentTool] = useState([]);
  const [pageLimit, setPageLimit] = useState();
  const [page, setPage] = useState(1);
  //Page count limit state
  const [pageCount, setPageCount] = useState();
  //loader
  const [isLoading, setLoading] = useState(false);
  //no record data found
  const [noRecordFound, setNoRecordFound] = useState(false);
  //navigate
  const navigate = useNavigate();
  let date = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });
  useEffect(() => {
    setLoading(true);
    recentAddedToolApi(page);
  }, []);
  useEffect(() => {
    //scroll listner
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, recentTool]);

  const handleScroll = () => {
    // Check if user has scrolled to the bottom of the page
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeigh - 500
    ) {
      if (page !== pageLimit) {
        recentAddedToolApi(page + 1);
      }
    }
  };
  async function recentAddedToolApi(page) {
    setLoading(true)
    let queryString = "?";
    queryString += `page=${page}&limit=10`;
    try {
      const response = await frontendService.recentAddedToolService(
        queryString
      );
      if (response.status === 200) {

        if (response.data.data.tool_data.length === 0) {
          setNoRecordFound(true);
        } else {
          setNoRecordFound(false);
        }
        setPageLimit(response.data.data.state.page_limit);
        if (page === 1) {
          setLoading(false);
          setPage(page)
          setPageCount(response.data.data.state.dataCount)
          setRecentTool(response.data.data.tool_data);
        } else {
          setLoading(false);
          setRecentTool([...recentTool, ...response.data.data.tool_data]);
        }
      }
    } catch (error) { }
  }
  return (
    <>
      <div className="ai-recent-submit">
        <button
          type="button"
          className="ai-button-secondary ai-recent-submit-btn"
          onClick={() => navigate("/submit-tool")}
        >
          <img src={Document} alt="" className="doc-normal" />
          <img src={DocumentHover} alt="" className="doc-hover" />
          Submit A Tool
        </button>

        <div className="ai-recent-header-sec">
          <h4 className="ai-h4">
            Today
            <span> {date}</span>
          </h4>
          <p className="ai-p-base">{pageCount} AI tools added today.</p>
        </div>
      </div>

      {/* Card sections */}
      <div className="ai-card-section">
        <div className="row">
          {recentTool.map((item, i) => (
            <div className="col-sm-6 col-lg-4">
              <Aicardcomponent data={item} filterBykeyWordApi={filterBykeyWordApi} />
            </div>
          ))}
        </div>
        {noRecordFound && (
          <div className="ai-norecord ai-norecord-recent">
            <span>There are no new AI tools uploaded for today. Please check back later.</span>
          </div>
        )}
        {isLoading && (
          <div className="ai-front-loader">
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

export default RecentAddedToolDetails;
