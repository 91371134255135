import React, { useEffect, useState } from "react";
import frontendService from "../../../services/frontend.service";
import { Link } from "react-router-dom";

// CSS
import "./AllCategories.css";
// Image
import Loader from "../../loader/Loader";

const AllCategories = () => {
  //allcategory main state
  const [allcategory, setAllcategory] = useState([]);
  const [loader, setLoader] = useState(true);

  //subcategory listing api
  async function subCategoryListApi() {
    try {
      const response = await frontendService.Allcategorylist();
      if (response.status === 200) {
        const sortedCategories = response.data.data.map((item) => ({
          ...item,
          subCategoryData: item.subCategoryData.sort((a, b) =>
            a.subCategoryName.localeCompare(b.subCategoryName)
          ),
        }));
        setAllcategory(sortedCategories);
        setLoader(false)
      }
    } catch (error) { }
  }
  useEffect(() => {
    subCategoryListApi();
  }, []);

  return (
    <>

      {loader ?
        <div className="ai-front-loader">
            <Loader />
          </div>
        :
        <>
          <section className="sitemap-section">
            <div className="ai-container">
              <div class="sitemap-box">
                <h2 className="ai-sitemap-title">All Categories</h2>
                <p className="ai-sitemap-para">Discover the world of AI tools with our comprehensive AI tools directory. Our AI tool categories page provides an overview of all main categories and subcategories, making it easy to navigate through the various AI tools and find what you need. Browse through our AI tool categories and discover the best AI tools for your needs.</p>
                {allcategory.map((item) => {
                  return (
                    <>
                      <div className="ai-categories-sections">
                        <div class="ai-categories-title">
                          <div className="ai-catrgories-icon">
                            {/* <img src={AiBadge} alt="" /> */}
                            {item.icon !== null && item.icon !== "" && item.icon !== undefined ?
                              <i class={item.icon}></i>
                              : <i class="fa-regular fa-clipboard"></i>}
                          </div>
                          <h3 className="ai-h3">{item.categoryName}</h3>
                        </div>
                        <div className="row">
                          {item.subCategoryData.sort().map((item) => {
                            return (
                              <>
                                <div className="col-sm-6 col-lg-4">
                                  <Link to={"/ai-tools" + "/" + item.slug}>
                                    <div className="ai-sub-cate-card">
                                      <div className="ai-sub-cate-card-container">
                                        <div className="ai-sub-cate-title-sec">
                                          <div className="ai-sub-cate-title">
                                            {/* Sub categories icon */}
                                            <div className="ai-sub-cate-img">
                                              {/* <img src={AiBadge} alt="" />   */}
                                              {item.icon !== "" && item.icon !== undefined ?
                                                <i class={item.icon}></i>
                                                : <i class="fa-solid fa-fire"></i>}
                                            </div>
                                            {/* Sub categories Heading */}
                                            <h6 class="ai-h6">
                                              {item.subCategoryName}
                                            </h6>
                                          </div>

                                          {/* Links for redirect to new page */}
                                          <div className="ai-sub-cate-link">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={24}
                                              height={24}
                                              fill="none"
                                            >
                                              <path
                                                fill=""
                                                d="m3 6.026.075-.334c.238-.965 1.044-1.622 2.073-1.637a235.3 235.3 0 0 1 4.19 0c.78.005 1.223.592 1.05 1.353-.11.473-.498.762-1.05.762-1.297.005-2.595 0-3.892 0h-.293v12.69h12.671v-4.165c0-.787.557-1.24 1.328-1.08.392.08.68.363.755.761.025.13.035.264.035.393.005 1.314.005 2.633 0 3.947 0 1.388-.895 2.284-2.277 2.284H5.272c-1.198 0-1.999-.667-2.232-1.836-.01-.045-.025-.09-.04-.14V6.026Z"
                                              />
                                              <path
                                                fill=""
                                                d="M20.2 3c.6.209.81.642.8 1.264-.02 1.687-.005 3.374-.01 5.061 0 .811-.676 1.284-1.447 1.025-.432-.149-.666-.502-.67-1.045-.006-.796 0-1.587 0-2.383v-.3c-.105.095-.17.15-.23.215-1.774 1.777-3.55 3.558-5.329 5.335-.512.512-1.188.482-1.615-.06a1.003 1.003 0 0 1 .02-1.299 4.08 4.08 0 0 1 .203-.214l5.225-5.23c.065-.065.124-.13.219-.23-.125-.004-.214-.014-.299-.014-.8 0-1.6.01-2.406-.005-.82-.015-1.287-.717-1.009-1.483.13-.353.413-.518.741-.637h5.806Z"
                                              />
                                            </svg>
                                            {/* <FontAwesomeIcon icon={faShareFromSquare}/> */}
                                            {/* <i class= {item.icon}></i> */}
                                          </div>
                                        </div>

                                        {/* Description */}
                                        <div className="ai-sub-cate-text">
                                          <p className="ai-p-17">{item.subCategoryShortDesc}</p>
                                        </div>
                                        {/* Tools Count */}
                                        <div className="ai-tools-count">
                                          {item.countToolData} Tools
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                              </>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      }
    </>
  );
};

export default AllCategories;
