import React, { useEffect, useState } from 'react'
//loaderimg
import loaderimg from '../../../assets/images/loder2.gif'
import adminService from '../../../services/admin.service'
import { Alert, Snackbar } from '@mui/material'
const SponsorshipPrice = () => {
    const [loader, setLoader] = useState(false)
    const [price, setPrice] = useState('')
    const [error, setError] = useState('')
    const [toastOpen, setToastOpen] = useState(false)
    const [message, setMessage] = useState('')
    useEffect(() => {
        getPerDayPriceApi()
    }, [])
    async function getPerDayPriceApi() {
        try {
            const response = await adminService.getPerDayPriceService()
            if (response.status === 201) {
                setPrice(response.data.data.value)
            }
        } catch (error) {

        }
    }
    async function addPerDayPriceApi() {
        const bodyData = {
            key: "Tool-per-day-price",
            value: price
        }
        try {
            const response = await adminService.addPerDayPriceService(bodyData)
            if (response.status === 201) {
                setLoader(false)
                setToastOpen(true)
                setMessage(response.data.message)
                getPerDayPriceApi()
            }
        } catch (error) {

        }
    }
    const handleOnChange = (e) => {
        setPrice(e.target.value)
        if (e.target.value.trim() !== '') {
            setError('')
        } else {
            setError('Please enter price.')
        }
    }

    const handleClick = (e) => {
        e.preventDefault()
        if (price.trim() !== '') {
            setError('')
            addPerDayPriceApi()
            setLoader(true)
        } else {
            setError('Please enter price.')
        }
    }
    //allow only number
    const handleKeyPress = (event) => {
        const charCode = event.which || event.keyCode;
        const inputChar = String.fromCharCode(charCode);

        // Allow decimal point (ASCII code 46) and digits (ASCII codes 48 to 57)
        if (charCode === 46 || (charCode >= 48 && charCode <= 57)) {
            // Ensure only one decimal point is entered
            if (charCode === 46 && price.indexOf(".") !== -1) {
                event.preventDefault();
            }
        } else {
            event.preventDefault();
        }
    };
    //toast close
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };
    return (
        <>
            <div className="page-wrapper doctris-theme toggled">
                <main className="page-content">
                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="mb-0">Sponsorship Price</h4>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card border-0 shadow rounded">
                                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                            <div className="mb-3">
                                                <div className="p-3 col-md-10 col-lg-12 cstm-terms-condidtion">
                                                    <label className="cstm-label">Sponsorship Price<span className="cstm-star">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="cstm-input"
                                                        placeholder="Enter category name"
                                                        value={price}
                                                        onChange={handleOnChange}
                                                        onKeyPress={handleKeyPress}
                                                        required="" />
                                                    <span className='error-message'>{error}</span>
                                                    <div className="cstn-loader-sec">
                                                        <button onClick={handleClick} disabled={loader ? true : false} className="cstm-btn cstm-btn-text">Submit</button>
                                                        {loader &&
                                                            <div className='loader-container-cstm'>
                                                                <img className='btn-pop-loader' src={loaderimg} />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Success Toast */}
                        <Snackbar
                            open={toastOpen}
                            onClose={handleClose}
                            autoHideDuration={3000}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                            <Alert onClose={handleClose} variant='filled' severity='success' >{message}</Alert>
                        </Snackbar>
                    </div>
                </main>
            </div>
        </>
    )
}

export default SponsorshipPrice