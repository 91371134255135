const localization1 = {
    locale: "en-us",
    translations: {
      "generic.add": "Add",
      "generic.cancel": "Cancel",
  
      "components.controls.blocktype.normal": "Normal",
      "components.controls.blocktype.h2": "Heading 1",
      "components.controls.blocktype.h3": "Heading 2",
      "components.controls.blocktype.h4": "Heading 3",
      "components.controls.blocktype.blockquote": "Blockquote"
    }
  };
  
  export default localization1;
  
  
  export const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent'
  ]