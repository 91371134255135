import React, { useEffect } from "react";

// Components
import Pagebreadcrumb from "../../components/frontend/pageBreadCrumb/PageBreadCrumb";
import Submitdetails from "../../components/frontend/submitTool/SubmitDetails";
import Seo from "../../components/seo/Seo";

const Submittool = () => {
  useEffect(()=>{window.scrollTo(0, 0)},[])
  return (
    <>
    <Seo title={"Submit Your AI Tool and App | AIToolSpot"} description={"Submit your AI tool and share your innovation with the AI community. Submit your favorite AI tool on AIToolSpot and reach a broader audience."}/>
      <div className="spacer-132"></div>
      <section className="ai-bg-color">
        <div className="ai-container">
          <Pagebreadcrumb path={'Submit A Tool'} />
          
          <Submitdetails />
        </div>
      </section>
    </>
  );
};

export default Submittool;
