import React, { useEffect, useState } from "react";
// components
import Blogdetailscontent from "../../components/frontend/blogDetails/BlogDetailsContent";
import frontendService from "../../services/frontend.service";
import Pagebreadcrumb from "../../components/frontend/pageBreadCrumb/PageBreadCrumb";

const Blogdetails = () => {
  const name = window.location.pathname.substring(6)
  const [blogDetailData, setBlogDetailData] = useState({});
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [expolreTool, setExpolreTool] = useState([]);
  const [pageNotfound, setPageNotfound] = useState(false);

  //loadingBar
  useEffect(() => {
    setLoader(true);
    blogdetailApi();
  }, []);
  async function blogdetailApi() {
    const param = name;
    try {
      const response = await frontendService.blogdetailService(param);
      if (response.status === 200) {
        setBlogDetailData(response.data.data.blog);
        setRelatedBlogs(response.data.data.relatedBlogs);
        setExpolreTool(response.data.data.featuedTools)
        setLoader(false);
      }
    } catch (error) { 
      setPageNotfound(true)
    }
  }
  return (
    <>
      <div className="spacer-132"></div>
      <section className="ai-bg-color">
        <div className="ai-container">
          <div className="ai-loading-sapcing">
            <Pagebreadcrumb path={blogDetailData.title} data={blogDetailData} />
            {/* €Blog details */}
            <Blogdetailscontent
              blogDetailData={blogDetailData}
              relatedBlogs={relatedBlogs}
              loader={loader}
              expolreTool={expolreTool}
              pageNotfound={pageNotfound}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogdetails;
