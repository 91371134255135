const toolbar1 = {
    options: [
      "blockType",
      "inline",
      "list"
    ],
    blockType: {
      inDropdown: true,
      options: ["H2", "H3", "H4", "Normal", "Blockquote"],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined
    },
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ["bold", "italic", "underline"]
    },
    list: {
      options: ["ordered", "unordered"]
    }
  };
  
  export default toolbar1;
  
  export const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['table']
      ],
  }