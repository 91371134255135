import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import localization from '../../../config/localization'
import toolbar from '../../../config/toolbar'
import adminService from '../../../services/admin.service'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Alert, Snackbar } from '@mui/material'
import loaderimg from '../../../assets/images/loder2.gif'

const Legal = () => {

    const [privacy, setPrivacy] = useState(EditorState.createEmpty());
    const [privacyhtml, setPrivacyhtml] = useState()
    const [terms, setTerms] = useState(EditorState.createEmpty());
    const [termshtml, setTermshtml] = useState()
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState('')
    useEffect(() => {
        getPrivacyPolicyApi()
        getTermsAndConditionApi()
    }, [])
    async function getPrivacyPolicyApi() {
        try {
            const response = await adminService.getPrivacyPolicyService()
            if (response.status === 201) {
                const contentBlock = htmlToDraft(response.data.data.value);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setPrivacy(editorState)
            }
        } catch (error) {

        }
    }
    async function addPrivacyPolicyApi() {
        const bodyData = {
            key: "privacy-policy",
            value: privacyhtml
        }
        try {
            const response = await adminService.addPrivacyPolicyService(bodyData)
            if (response.status === 201) {
                setLoader(false)
                setOpenSnackbar(true)
                setMessage(response.data.message)
            }
        } catch (error) {

        }
    }
    const handleAddPrivacyPolicy = (e) => {
        e.preventDefault()
        setLoader(true)
        addPrivacyPolicyApi()
    }
    const handleEditorPriacyonChange = (editorState) => {
        setPrivacyhtml(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        setPrivacy(editorState)
    }
    async function getTermsAndConditionApi() {
        try {
            const response = await adminService.getTermsAndConditionService()
            if (response.status === 201) {
                const contentBlock = htmlToDraft(response.data.data.value);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setTerms(editorState)
            }
        } catch (error) {

        }
    }
    async function addTermsAndConditionApi() {
        const bodyData = {
            key: "terms-and-condition",
            value: termshtml
        }
        try {
            const response = await adminService.addTermsAndConditionService(bodyData)
            if (response.status === 201) {
                setLoader(false)
                setOpenSnackbar(true)
                setMessage(response.data.message)
            }
        } catch (error) {

        }
    }
    const handleEditorTermsonChange = (editorState) => {
        setTermshtml(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        setTerms(editorState)
    }
    const handleAddTerms = (e) => {
        e.preventDefault()
        setLoader(true)
        addTermsAndConditionApi()
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false)
    }
    return (
        <>
            <div className="page-wrapper doctris-theme toggled">
                <main className="page-content">
                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="mb-0">Term & Condition And privacy policy</h4>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card border-0 shadow rounded">
                                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                            <h4 className="mb-0">Privacy Policy</h4>
                                        </div>

                                        <div className="p-3 col-md-10 col-lg-12 cstm-terms-condidtion">
                                            <Editor
                                                editorState={privacy}
                                                onEditorStateChange={handleEditorPriacyonChange}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                toolbar={toolbar}
                                                localization={localization}
                                            />
                                            <div className="cstn-loader-sec">
                                                <button onClick={handleAddPrivacyPolicy} className="cstm-btn cstm-btn-text">Submit</button>
                                                {loader &&
                                                    <div className='loader-container-cstm'>
                                                        <img className='btn-pop-loader' src={loaderimg} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card border-0 shadow rounded">
                                                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                                        <h4 className='mb-0'>Terms & Conditions</h4>
                                                    </div>
                                                    <div className="p-3 col-md-10 col-lg-12 cstm-terms-condidtion">
                                                        <Editor
                                                            editorState={terms}
                                                            onEditorStateChange={handleEditorTermsonChange}
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            toolbar={toolbar}
                                                            localization={localization}
                                                        />
                                                        <div className="cstn-loader-sec">
                                                            <button onClick={handleAddTerms} className="cstm-btn cstm-btn-text">Submit</button>
                                                            {loader &&
                                                                <div className='loader-container-cstm'>
                                                                    <img className='btn-pop-loader' src={loaderimg} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Snackbar
                            open={openSnackbar}
                            onClose={handleClose}
                            autoHideDuration={5000}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                            <Alert onClose={handleClose} variant='filled' severity='success'>{message}</Alert>
                        </Snackbar>
                    </div>
                </main>
            </div>
        </>
    )
}

export default Legal