import React from 'react'

const Featureicon = () => {
  return (
    <>
          <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    className="ai-side-icons"
  >
    <path
      fill="#011367"
      fillRule="evenodd"
      d="M5 6.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM5 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM5 13.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM5 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM5 20.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM5 22a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM9.25 5a.75.75 0 0 1 .75-.75h11.25a.75.75 0 0 1 0 1.5H10A.75.75 0 0 1 9.25 5ZM9.25 12a.75.75 0 0 1 .75-.75h11.25a.75.75 0 0 1 0 1.5H10a.75.75 0 0 1-.75-.75ZM9.25 19a.75.75 0 0 1 .75-.75h11.25a.75.75 0 0 1 0 1.5H10a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
  </svg>
    </>
  )
}

export default Featureicon
