import { RESPONSE_DATA } from "../actions/type";

const initialState = {
    res_data: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case RESPONSE_DATA:
            return {
                ...state,
                res_data: payload
            }
       default:
            return state
    }
}