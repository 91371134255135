import React from 'react'
import Pagebreadcrumb from '../../components/frontend/pageBreadCrumb/PageBreadCrumb'
import SponsorList from '../../components/frontend/userSponsorList/SponsorList'

const UserSponsorList = () => {
  return (
    <>
       <div className="spacer-132"></div>
      {/* Tool List */}
      <section className="ai-bg-color">
        <div className="ai-container">
          <Pagebreadcrumb path={"Sponsored tools"} />
          {/* Listing Screen */}
          <SponsorList
            
          />
        </div>
      </section>
    </>
  )
}

export default UserSponsorList