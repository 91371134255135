import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
// css
import "./Header.css";

// icons
import Menu from "@mui/icons-material/Menu";
// images
import aiLogo from "../../../assets/images/frontend/ai-logo.svg";
import Google from "../../../assets/images/frontend/google.svg";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import frontendService from "../../../services/frontend.service";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import Cookies from "js-cookie";

const Header = () => {
  const dispatch = useDispatch();
  const { isUserOk } = useSelector((state) => state.userAuth);
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [loginBtn, setLoginBtn] = useState(true);
  const [userData, setUserData] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoggedin, setLoggedin] = useState(false);
  const closeMenu = useRef(null);
  const showMenu = useRef(null);
  let userImg = localStorage.getItem("userimg");
  // header sticky
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });

    if (isUserOk) {
      setLoginBtn(false);
    } else {
      setLoginBtn(true);
    }

    if (isUserOk) {
      setUserData(true);
    } else {
      setUserData(false);
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("isUserOk")) {
      setLoggedin(true);
    } else {
      setLoggedin(false);
    }
  });
  useEffect(() => {
    if (user.length !== 0) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          localStorage.setItem("userimg", res.data.picture);
          setProfile(res.data);
        });
    }
  }, [user]);
  useEffect(() => {
    if (profile.length !== 0) {
      userLoginApi();
    }
  }, [profile]);

  async function userLoginApi() {
    const bodyData = {
      email: profile.email,
      googleId: profile.id,
      name: profile.name,
    };
    try {
      const response = await frontendService.userLoginService(bodyData);
      if (response.status === 200) {
        window.location.reload(true);
        Cookies.set("userId", response.data.data.user._id);
        localStorage.setItem("accessToken", response.data.data.token);
        localStorage.setItem("userName", response.data.data.user.name);
        localStorage.setItem("auth", response.data.data.user.email);
        localStorage.setItem("isUserOk", true);
        localStorage.setItem("loggedIn", 1);
        setUserData(true);
        setLoginBtn(false);
        dispatch({
          type: "USER_LOGGEDIN",
          payload: response.data.data.user.email,
        });
      }
    } catch (error) {}
  }
  // Toggle menu
  const [show, setShow] = useState();
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => setUser(tokenResponse),
    redirect_uri: "https://www.aitoolspot.com",
  });

  const handleLogout = () => {
    localStorage.clear();
    localStorage.setItem("loggedIn", 0);
    Cookies.remove("userId");
    setLoginBtn(true);
    setUserData(false);
    setDropDown(false);
    window.location.reload(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const closeOpenMenus = (e) => {
    if (
      closeMenu.current &&
      dropDown &&
      !closeMenu.current.contains(e.target)
    ) {
      setDropDown(false);
    }
    if (showMenu.current && show && !showMenu.current.contains(e.target)) {
      setShow(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);
  return (
    <>
      <div
        className={`ai-header fixed-top ${scroll ? "sticky-header" : ""} ${
          show ? "active" : ""
        } `}
      >
        <div className="ai-container-fluid">
          <nav className="ai-nav">
            <div className="ai-brand">
              <Link to="/">
                <img src={aiLogo} alt="aitoolspot" className="ai-logo-image" />
              </Link>

              {/* toggler */}
              <div className="ai-toggler-sec">
                {/* Responsive profile */}
                {loginBtn && (
                  <button
                    type="button"
                    id="google-login"
                    className="ai-button-primary-border ai-google-login"
                    onClick={googleLogin}
                  >
                    <img src={Google} alt="" />
                  </button>
                )}
                {userData && (
                  <>
                    <button
                      type="button"
                      className="ai-button-primary-border ai-avtar-button"
                    >
                      <img src={userImg} alt="profileImg" />
                    </button>
                  </>
                )}

                {/* Toggle button */}
                <button
                  type="button"
                  onClick={() => setShow(!show)}
                  className="ai-toggler ai-button-primary-border"
                >
                  <Menu />
                </button>
              </div>
            </div>

            <div className={`ai-collapse ai-header-menu ${show ? "show" : ""}`}>
              <div className="ai-nav-menu">
                <ul>
                <li className="ai-nav-item">
                    <NavLink
                      onClick={() => setShow(false)}
                      to="/categories"
                      className="ai-nav-links"
                    >
                      Categories
                    </NavLink>
                  </li>
                  <li className="ai-nav-item">
                    <NavLink
                      onClick={() => setShow(false)}
                      to="/favourites"
                      className="ai-nav-links"
                    >
                      Favourites
                    </NavLink>
                  </li>
                  <li className="ai-nav-item">
                    <NavLink
                      onClick={() => setShow(false)}
                      to={"/submit-tool"}
                      className="ai-nav-links"
                    >
                      Submit A Tool
                    </NavLink>
                  </li>
                  <li className="ai-nav-item">
                    <NavLink
                      onClick={() => setShow(false)}
                      to="/blog"
                      className="ai-nav-links"
                    >
                      Blog
                    </NavLink>
                  </li>
                </ul>

                {/* For Mobile */}
                {userData && (
                  <>
                    <div className="ai-list-mobile ">
                      <ul>
                        <li>
                          <Link onClick={() => setShow(false)} to="/favourites">
                            Your Favourites
                          </Link>
                        </li>
                        <li>
                          <Link onClick={() => setShow(false)} to="/tool-list">
                            Your Tools
                          </Link>
                        </li>
                        <li>
                          <Link className="ai-logout" onClick={handleLogout}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
              <div className="ai-nav-button">
                {loginBtn && (
                  <button
                    type="button"
                    id="google-login"
                    onClick={googleLogin}
                    className="ai-button-primary-border"
                  >
                    <img src={Google} alt="" />
                    Login
                  </button>
                )}
                {userData && (
                  <>
                    <button
                      type="button"
                      onClick={() => setDropDown(!dropDown)}
                      className="ai-button-primary-border ai-avtar-button"
                    >
                      <img src={userImg} alt="profileImg" />{" "}
                      <span>{localStorage.getItem("userName")}</span>
                    </button>
                  </>
                )}
                {dropDown && userData && (
                  <>
                    <div className="ai-nav-extra" ref={closeMenu}>
                      <ul>
                        <li>
                          <Link to="/favourites">Your Favourites</Link>
                        </li>
                        <li>
                          <Link to="/tool-list">Your Tools</Link>
                        </li>
                        <li>
                          <Link onClick={handleLogout}>Logout</Link>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        onClose={handleClose}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Header;
