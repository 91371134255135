import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// CSS
import "./BlogCards.css";
// Images
import BlogImage from "../../../assets/images/frontend/blog-img.png";
import AiBadge from "../../../assets/images/frontend/ai-badge.svg";
import AiBlogshare from "../../../assets/images/frontend/ai-blog-share.svg";
import aiBadgeoutline from "../../../assets/images/frontend/ai-badge-outline.svg";
import { bucketUrl } from "../../../services/admin.service";
import moment from "moment";
import frontendService from "../../../services/frontend.service";
import { Alert, Snackbar } from "@mui/material";
import ShareCard from "../shareCard/ShareCard";
const Blogcards = ({ data }) => {
  const [favourite, setFavourite] = useState(false);
  const [favoritesCount, setFavouriteCount] = useState(
    data !== undefined ? data.countFavouritesBlogData : 0
  );
  const [errorToast, setErrorToast] = useState(false);
  const [message, setMessage] = useState("");
  const [toggleShareBtn, setToggleShareBtn] = useState(null);
  var isUserOk = localStorage.getItem("isUserOk");
  const open = Boolean(toggleShareBtn);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    //check user tool favourite or not
    if (
      data !== undefined &&
      data.userfavouritesblogData !== undefined &&
      data.userfavouritesblogData.length > 0
    ) {
      setFavourite(true);
    } else {
      setFavourite(false);
    }
  }, []);
  async function addBlogFavoriteApi(blogId) {
    const bodyData = {
      blogId: blogId,
    };
    try {
      const response = await frontendService.addBlogFavoriteService(bodyData);
      if (response.status === 200) {
      }
    } catch (error) { }
  }
  async function removeBlogFavoriteApi(blogId) {
    const bodyData = {
      blogId: blogId,
    };
    try {
      const response = await frontendService.removeBlogFavoriteService(
        bodyData
      );
      if (response.status === 200) {
      }
    } catch (error) { }
  }
  const handleFavourite = (blogId, status) => {
    if (status === true && localStorage.getItem("isUserOk")) {
      removeBlogFavoriteApi(blogId);
      setFavourite(false);
      setFavouriteCount(favoritesCount - 1);
    } else if (status === false && localStorage.getItem("isUserOk")) {
      addBlogFavoriteApi(blogId);
      setFavouriteCount(favoritesCount + 1);
      setFavourite(true);
    } else {
      setErrorToast(true);
      setMessage("Please login to save to favourites");
    }
  };
  //handleclose toast
  const handleClosetoast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorToast(false);
  };
  //toggle share popover
  const handleShareBtnClick = (event) => {
    setToggleShareBtn(event.currentTarget);
  };
  const handleShareBtnClose = () => {
    setToggleShareBtn(null);
  };

  return data !== undefined ? (
    <div className="card-main blog-card-main">
      <div className="card-container">
        <Link to={"/blog/" + data.slug} target="_blank" className="card-link">
          <div className="card-image-container">
            <img
              src={
                data.imageUrl !== undefined
                  ? bucketUrl + data.imageUrl
                  : BlogImage
              }
              alt=""
              className="card-img"
            />
          </div>

          <div className="ai-blogs-details">
            <div className="ai-blog-categories-sec">
              <p className="ai-p-15">
                {data.blogCategoryData.length > 0
                  ? data.blogCategoryData.map((item) => item.name)
                  : ""}
              </p>
              <div className="ai-blog-save-count">
                <p className="ai-p-base">
                  <img src={AiBadge} alt="" />
                  <span>{favoritesCount}</span>
                </p>
              </div>
            </div>
            <div className="ai-blogs-content">
              <h5 className="ai-h5">{data.title}</h5>
              <p className="ai-p-15">{data.shortDesc}</p>
            </div>
            <div className="ai-blog-published">
              <p className="ai-p-15">
                {moment(data.createdAt).format("DD MMM, YYYY")}
              </p>
            </div>
          </div>
        </Link>
        <div className="card-button-sec">
          <button type="button" className="btn-links" onClick={handleShareBtnClick}>
            <img src={AiBlogshare} alt="" />
          </button>
          {favourite && isUserOk ? (
            <button
              type="button"
              className="btn-save"
              onClick={() => handleFavourite(data._id, true)}
            >
              <img src={AiBadge} alt="aiBadge" />
            </button>
          ) : (
            <button
              type="button"
              className="btn-save"
              onClick={() => handleFavourite(data._id, false)}
            >
              <img src={aiBadgeoutline} alt="aiBadge" />
            </button>
          )}
        </div>
        <ShareCard
          quote={'The #1 AI Tools Platform, Empowering Your AI Success.'}
          url={window.location.href + '/' + data.slug}
          toggleShareBtn={toggleShareBtn}
          open={open}
          id={id}
          handleShareBtnClose={handleShareBtnClose}
        />
      </div>
      <Snackbar
        open={errorToast}
        onClose={handleClosetoast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClosetoast} variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
    </div>
  ) : (
    <div className="card-main blog-card-main">
      <div className="card-container">
        <Link className="card-link">
          <div className="card-image-container">
            <img src={BlogImage} alt="" className="card-img" />
          </div>

          <div className="ai-blogs-details">
            <div className="ai-blog-categories-sec">
              <p className="ai-p-15">All</p>
              <div className="ai-blog-save-count">
                <p className="ai-p-base">
                  <img src={AiBadge} alt="" />
                  <span>1116</span>
                </p>
              </div>
            </div>
            <div className="ai-blogs-content">
              <h5 className="ai-h5">
                How to Hire a Long-Term Writer for Your Business
              </h5>
              <p className="ai-p-15">
                A good writer is an extremely valuable asset for any business.
                Follow this step-by-step guide to hire a long-term writer
                today!`
              </p>
            </div>
            <div className="ai-blog-published">
              <p className="ai-p-15">Apr 27, 2023</p>
            </div>
          </div>
        </Link>
        <div className="card-button-sec">
          <button
            type="button"
            className="btn-links"
          >
            <img src={AiBlogshare} alt="" />
          </button>
          <button type="button" className="btn-save">
            <img src={AiBadge} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Blogcards;
