import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import adminService from '../../services/admin.service';
import Multiselect from 'multiselect-react-dropdown';
import { Alert, Snackbar } from '@mui/material';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import toolBar from '../../config/toolbar'
import localization from '../../config/localization';
import loaderimg from '../../assets/images/loder2.gif'
import AddIcon from '@mui/icons-material/Add';
import { useDropzone } from 'react-dropzone';
import slugify from 'slugify';

const AddTool = () => {
  const [toolData, setToolData] = useState({
    toolName: '',
    url: '',
    category: [],
    features: [],
    price: [],
    starting_price: '',
    short_description: '',
    slug: '',
    metaTitle: '',
    metaDesc: '',
  })
  const [isAffilated, setIsAffilated] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty(),)
  const _contentState = ContentState.createFromText('Sample content state')
  const raw = convertToRaw(_contentState)
  const [contentState, setContentState] = useState(raw)
  const [selectedSubCategory, setSelectedSubcategory] = useState([])
  const [selectedPrice, setSelectedPrice] = useState([])
  const [selectedFuture, setSelectedFuture] = useState([])
  const [error, setError] = useState({
    toolName: '',
    url: '',
    category: '',
    price: '',
    img: '',
    que: '',
    ans: '',
    slug: ''
  })
  const [faqErrors, setFaqErrors] = useState([]);
  const [showFaq, setShowFaq] = useState(false)
  const [faq, setFaq] = useState([{
    que: "",
    ans: ""
  }])
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [loader, setLoader] = useState(false)
  const [categoryList, setcategortList] = useState([])
  const [priceList, setPriceList] = useState([])
  const [files, setFiles] = useState([])
  const [featureList, setFeatureList] = useState([])
  const [message, setMessage] = useState('')
  const navigate = useNavigate()
  const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()))
  useEffect(() => {
    categoryListAPI()
    featureListApi()
    priceListApi()
  }, [])
  useEffect(() => {
    if (faq.length === 0) {
      setShowFaq(false)
    }
  }, [faq])
  useEffect(() => {
    const slugData = slugify(toolData.toolName, { lower: true, strict: true });
    setToolData({ ...toolData, slug: slugData })
  }, [toolData.toolName])
  async function categoryListAPI() {
    const response = await adminService.subCategoryListingService()
    if (response.status === 200) {
      setcategortList(response.data.data)
    }
  }

  async function priceListApi() {
    const response = await adminService.pricingListingService()
    if (response.status === 200) {
      setPriceList(response.data.data)
    }
  }

  async function featureListApi() {
    const response = await adminService.featureListingService()
    if (response.status === 200) {
      setFeatureList(response.data.data)
    }
  }
  async function toolAddAPI() {
    const formData = new FormData()
    formData.append('toolName', toolData.toolName)
    formData.append('websiteUrl', toolData.url)
    toolData.category.map((item) => (
      formData.append('subCategoryId', item)
    ))
    toolData.features.map((item) => (
      formData.append('featuresId', item)
    ))
    toolData.price.map((item) => (
      formData.append('pricingId', item)
    ))
    formData.append('shortDesc', toolData.short_description)
    formData.append('startingPrice', toolData.starting_price)
    formData.append('longDesc', editorData)
    formData.append('isAffiliated', isAffilated)
    formData.append('isVerified', isVerified)
    formData.append('imageUrl', files[0])
    formData.append('isFAQ', showFaq)
    formData.append('slug', toolData.slug)
    formData.append('metaTitle', toolData.metaTitle)
    formData.append('metaDesc', toolData.metaDesc)
    faq.forEach((object, index) => {
      Object.entries(object).forEach(([key, value]) => {
        formData.append(`FAQ[${index}][${key}]`, value);
      });
    });
    try {
      const response = await adminService.toolAddService(formData)
      if (response.status === 200) {
        setOpenSnackbar(true)
        setLoader(false)
        setMessage(response.data.message)
        setTimeout(() => {
          navigate('/admin/tools')
        }, 2000);
      }
    } catch (error) {
      setLoader(false)
    }
  }

  const regex = new RegExp(/^https?:\/\/.+/i)
  const validate = (toolData) => {
    let toolError = {}
    let isValid = false
    if (toolData.toolName.trim() === "") {
      isValid = true
      toolError.toolName = 'Please enter the tool name.'
    }
    if (selectedSubCategory.length === 0) {
      isValid = true
      toolError.category = 'Please select category.'
    }
    if (selectedPrice.length === 0) {
      isValid = true
      toolError.price = 'Please select price.'
    }
    if (toolData.url.trim() === "") {
      isValid = true
      toolError.url = 'Please enter the website url.'
    } else if (!regex.test(toolData.url)) {
      isValid = true
      toolError.url = 'Please enter valid url.'
    } else {
      toolError.url = ''
    }
    if (files.length === 0) {
      isValid = true
      toolError.img = "Please select the tool image."
    }

    setError(toolError)
    return isValid
  }

  const handleAddTool = (e) => {
    e.preventDefault()
    let isValid = true;

    if (showFaq) {
      // Validate question and answer fields in FAQ section
      for (let i = 0; i < faq.length; i++) {
        const { que, ans } = faq[i];
        if (que.trim() === '') {
          setFaqErrors(prevErrors => {
            const updatedErrors = [...prevErrors];
            updatedErrors[i] = {
              ...updatedErrors[i],
              que: 'Please enter the question.'
            };
            return updatedErrors;
          });
          isValid = false;
        }
        if (ans.trim() === '') {
          setFaqErrors(prevErrors => {
            const updatedErrors = [...prevErrors];
            updatedErrors[i] = {
              ...updatedErrors[i],
              ans: 'Please enter the answer.'
            };
            return updatedErrors;
          });
          isValid = false;
        }
      }
    }
    if (!validate(toolData) && isValid) {
      setLoader(true)
      selectedSubCategory.map((item) => (toolData.category.push(item._id)))
      selectedPrice.map((item) => (toolData.price.push(item._id)))
      selectedFuture.map((item) => (toolData.features.push(item._id)))
      toolAddAPI()
    }
  }

  const handleSelectCategory = (selectedList) => {
    setSelectedSubcategory(selectedList)
    selectedList.length === 0 ? setError({ ...error, category: "Please select category." }) : setError({ ...error, category: "" })
  }
  const handleSelectfeature = (selectedList) => {
    setSelectedFuture(selectedList)
  }

  const handleSelectprice = (selectedList) => {
    setSelectedPrice(selectedList)
    selectedList.length === 0 ? setError({ ...error, price: "Please select price." }) : setError({ ...error, price: "" })
  }
  const handleOnchange = (e) => {
    const { name, value } = e.target
    setToolData({ ...toolData, [name]: value })
    switch (name) {
      case "toolName":
        value.trim() === "" ? setError({ ...error, toolName: "Please enter the tool name.", slug: "Please enter slug." }) : setError({ ...error, toolName: "", slug: "" })
        break
      case "url":
        value.trim() === "" ? setError({ ...error, url: "Please enter the Website url." }) : !regex.test(value) ? setError({ ...error, url: "Please enter valid url." }) : setError({ ...error, url: "" })
        break
      default:
        break
    }
  }

  //Slug onChange
  const handleSlugChange = (e) => {
    const {name, value } = e.target
    const newValue = value.replace(/\s/g, '');
    setToolData({ ...toolData, slug: newValue })
    switch (name) {
      case "slug":
        value.trim() === "" ? setError({ ...error, slug: "Please enter slug." }) : setError({ ...error, slug: "" })
        break
      default:
        break
    }
  }
  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    multiple: false,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    },
    validator: error => {
      setError({ ...error, img: "" })
    }
  });

  const thumbs = files.map(file => (
    <div key={file.name}>
      <img
        style={{ height: '84px', width: '149px' }}
        src={file.preview}
        // Revoke data uri after image is loaded
        onLoad={() => { URL.revokeObjectURL(file.preview) }}
      />
    </div>
  ));


  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }
  //allow only number
  const handleKeyPress = (event) => {
    const charCode = event.which || event.keyCode;
    const inputChar = String.fromCharCode(charCode);

    // Allow decimal point (ASCII code 46) and digits (ASCII codes 48 to 57)
    if (charCode === 46 || (charCode >= 48 && charCode <= 57)) {
      // Ensure only one decimal point is entered
      if (charCode === 46 && toolData.starting_price.indexOf('.') !== -1) {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };
  const handleAddFaq = () => {
    setFaq([...faq, { que: '', ans: '' }])
  }
  const handleRemoveFaq = (e, i) => {
    const removeFaq = faq.filter((item, index) => index !== i)
    setFaq(removeFaq)
  }
  const handleOnchangeFaq = (e, i) => {
    const { name, value } = e.target;
    setFaq(prevFaq => {
      const updatedFaq = [...prevFaq];
      updatedFaq[i] = {
        ...updatedFaq[i],
        [name]: value
      };
      return updatedFaq;
    });

    setFaqErrors(prevErrors => {
      const updatedErrors = [...prevErrors];
      switch (name) {
        case 'que':
          updatedErrors[i] = {
            ...updatedErrors[i],
            [name]: value.trim() === '' ? 'Please enter the question.' : ''
          };
          break;
        case 'ans':
          updatedErrors[i] = {
            ...updatedErrors[i],
            [name]: value.trim() === '' ? 'Please enter the answer.' : ''
          };
          break;
        default:
          break;
      }
      return updatedErrors;
    });
  };
  return (
    <>
      <div className="page-wrapper doctris-theme toggled">
        <main className="page-content bg-light">
          <div className="container-fluid">
            <div className="layout-specing">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="mb-0">Add Tool</h4>
                {/* <div className="cstm-bre uppercase">Dashboard > <a href="#">ADD subscription </a></div> */}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card  rounded-md border min-height-sc add-edit-page">
                    <div className="py-2 px-3">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="cstm-label">Tool Name<span className='cstm-astix'>*</span></label>
                            <input type="text" value={toolData.toolName} onChange={handleOnchange} className="cstm-input" placeholder="Enter your tool name"
                              name="toolName" required="" />
                            <span className='error-message'>{error.toolName}</span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="cstm-label">Website URL<span className='cstm-astix'>*</span></label>
                            <input type="text" onChange={handleOnchange} value={toolData.url} className="cstm-input" placeholder="Enter website url"
                              name="url" required="" />
                            <span className='error-message cstm-error message'>{error.url}</span>
                            <div className='cust-isaffilate'>
                              <input type='checkbox' onChange={() => setIsAffilated(!isAffilated)} /><label>Affilated Link</label>
                            </div>
                          </div>
                        </div>
                        {/* slug */}
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Slug<span className='cstm-astix'>*</span></label>
                            <input type="text" value={toolData.slug} onChange={handleSlugChange} className="cstm-input" placeholder="Enter your tool name"
                              name="slug" required="" />
                              <span className='error-message'>{error.slug}</span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="cstm-label">Select Categories (max 3)<span className='cstm-astix'>*</span></label>
                            <Multiselect
                              onSearch={function noRefCheck() { }}
                              selectionLimit={3}
                              placeholder={'Select category'}
                              hidePlaceholder
                              name='subCategoryName'
                              displayValue='subCategoryName'
                              onSelect={handleSelectCategory}
                              onRemove={handleSelectCategory}
                              selectedValues={selectedSubCategory}
                              options={categoryList.map((item, i) => ({
                                subCategoryName: item.subCategoryName,
                                _id: item._id
                              }))}
                            />
                            <span className='error-message'>{error.category}</span>

                          </div>

                        </div>
                        <div className="col-lg-6 cstm-select-features">
                          <div className="mb-4">
                            <label className="cstm-label">Select Features (Optional)</label>
                            <Multiselect
                              placeholder='Select features'
                              hidePlaceholder 
                              name='name'
                              displayValue='name'
                              onSelect={handleSelectfeature}
                              onRemove={handleSelectfeature}
                              selectedValues={toolData.features}
                              options={featureList.map((item, i) => ({
                                name: item.name,
                                _id: item._id
                              }))}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 cstm-select-features">
                          <div className="mb-4">
                            <label className="cstm-label">Pricing-Select freemium if your tool has both paid and free versions<span className='cstm-astix'>*</span></label>
                            <Multiselect
                              placeholder={'Select pricing'}
                              hidePlaceholder
                              name='name'
                              displayValue='name'
                              onSelect={handleSelectprice}
                              onRemove={handleSelectprice}
                              selectedValues={toolData.price}
                              options={priceList.map((item, i) => (
                                {
                                  name: item.name,
                                  _id: item._id
                                }
                              ))}
                            />
                            <span className='error-message'>{error.price}</span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="cstm-label">Starting Price (Optional)</label>
                            <input type="text" onChange={handleOnchange} value={toolData.starting_price} className="cstm-input" placeholder="Enter starting price"
                              name="starting_price" required="" onKeyPress={handleKeyPress} />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="cstm-label">Meta Title</label>
                            <input type="text" onChange={handleOnchange} value={toolData.metaTitle} className="cstm-input" placeholder="Enter meta title"
                              name="metaTitle" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="cstm-label">Meta Description</label>
                            <input type="text" onChange={handleOnchange} value={toolData.metaDesc} className="cstm-input" placeholder="Enter meta description"
                              name="metaDesc" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Tools Short Description(Optional)</label>
                            <input type="text" value={toolData.short_description} className="cstm-input" placeholder="Enter short description"
                              name="short_description" onChange={handleOnchange} required="" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Tool Description (Optional)</label>
                            <Editor
                              placeholder='Enter long description'
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              editorState={editorState}
                              defaultContentState={contentState}
                              onContentStateChange={setContentState}
                              onEditorStateChange={setEditorState}
                              toolbar={toolBar}
                              localization={localization}

                            />
                          </div>
                        </div>
                        <div className='cust-isverfied'>
                          <input type='checkbox' checked={showFaq} onClick={() => setShowFaq(!showFaq)} /> <label>Enable FAQ</label>
                        </div>
                        {showFaq &&
                          <>
                            <div className='add-faq-button'>
                              <button onClick={handleAddFaq}>Add FAQ</button>
                            </div>
                            {faq.map((item, i) => (
                              <>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label className="cstm-label">Question</label>
                                    <input type="text" className="cstm-input" onChange={(e) => handleOnchangeFaq(e, i)} placeholder="Enter your question"
                                      value={item.que} name="que" required="" />
                                    <span className='error-message'>{faqErrors[i]?.que}</span>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label className="cstm-label">Answer</label>
                                    <input type="text" className="cstm-input" placeholder="Enter your Answer" onChange={(e) => handleOnchangeFaq(e, i)}
                                      value={item.ans} name="ans" required="" />
                                    <span className='error-message'>{faqErrors[i]?.ans}</span>
                                  </div>
                                </div>
                                <div className='remove-faq-button'>
                                  <button onClick={(e) => handleRemoveFaq(e, i)}>Remove FAQ</button>
                                </div>
                              </>
                            ))}
                          </>
                        }
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Feature image<span className='cstm-astix'>*</span></label>
                            <div {...getRootProps({ className: 'dropzone blog-dropzone' })}>
                              <input {...getInputProps()} />
                              <p> <span> <AddIcon /></span>  Drop your image here, or <span> browse</span></p>
                            </div>
                            <aside>
                              <div className='row'>
                                {thumbs}
                              </div>
                            </aside>
                            <span className='error-message'>{error.img || fileRejectionItems}</span>
                          </div>
                        </div>

                        <div className='cust-isverfied'>
                          <input type='checkbox' defaultChecked={isVerified} onChange={() => setIsVerified(!isVerified)} /> <label>isverified</label>
                        </div>
                        <div className="pb-3 mt-2 button-div">

                          <div className="text-center cstn-loader-sec">
                            <button disabled={loader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleAddTool}>Submit</button>
                            {loader &&
                              <div className='loader-container-cstm'>
                                <img className='btn-pop-loader' src={loaderimg} />
                              </div>
                            }
                          </div>
                          <button className="cstm-btn2 cstm-btn-text-bordered" onClick={() => navigate("/admin/tools")}>Cancel</button>
                        </div>

                        <Snackbar
                          open={openSnackbar}
                          onClose={handleClose}
                          autoHideDuration={5000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                          <Alert onClose={handleClose} variant='filled' severity='success'>{message}</Alert>
                        </Snackbar>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default AddTool;