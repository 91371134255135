import React from 'react'

export const Sponsoricon = () => {
  return (
    <>
     <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    className="ai-side-icons"
  >
    <path
      fill="#011367"
      fillRule="evenodd"
      d="m8.37 16.802.408 3.62a.676.676 0 0 1-.684.774c-.934.006-1.869.006-2.803 0-.385 0-.645-.254-.7-.628-.174-1.199-.344-2.398-.513-3.596-.114-.803-.227-1.607-.342-2.41h-.721c-1.282 0-2.214-.94-2.215-2.219V9.7c.003-1.245.906-2.16 2.151-2.168.447-.003.89-.002 1.33-.002.519 0 1.036.001 1.558-.004.16 0 .32-.03.47-.086 3.956-1.517 7.91-3.04 11.858-4.57.554-.215 1.059.136 1.057.729-.01 3.722-.01 7.444-.009 11.167v3.723c0 .648-.467.945-1.064.715L8.215 15.38l.009.085.147 1.338Zm3.892-1.31-3.485-1.34-1.816-.695c-.072-.028-.074-.04-.074-.118a754.644 754.644 0 0 0 0-4.624v-.01c0-.027 0-.038.005-.045.005-.006.013-.01.027-.014l.008-.003L17.86 4.445l.002 13.197-.347-.132c-1.753-.669-3.504-1.343-5.254-2.017Zm-6.749-2.274H2.993c-.52-.002-.848-.332-.848-.853-.002-.88-.002-1.76 0-2.641.001-.522.322-.851.846-.851h2.522v4.345Zm-.416 1.348.754 5.278h1.504c-.185-1.665-.371-3.33-.56-4.994a.057.057 0 0 0-.024-.026c-.509-.286-1.056-.284-1.674-.258Z"
      clipRule="evenodd"
    />
    <path
      fill="#011367"
      d="M20.957 10.423a.626.626 0 1 0 0 1.252h2.087a.626.626 0 1 0 0-1.252h-2.087ZM20.555 6.21a.626.626 0 0 1 .229-.855l1.807-1.043a.626.626 0 0 1 .626 1.084L21.41 6.439a.626.626 0 0 1-.855-.229ZM20.784 16.74a.626.626 0 1 1 .626-1.083l1.807 1.043a.626.626 0 1 1-.626 1.084l-1.807-1.043Z"
    />
  </svg>

    </>
  )
}
