import React, { useEffect, useState } from 'react'
import appLogo from '../../../assets/images/Logo.png'
import { Link } from 'react-router-dom'
import adminService from '../../../services/admin.service'
import { Alert, Snackbar } from '@mui/material'
import loaderimg from '../../../assets/images/loder2.gif'

const ForgotPassword = () => {

    //data states
    const [emailData, setemailData] = useState({
        Email: '',
    })
    const [error, setError] = useState({
        Email: '',
    })

    //loader state
    const [loader, setLoader] = useState(false)
    //toast state
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [errorSnackbar, setErrorSnackbar] = useState(false)
    //message state
    const [message, setMessage] = useState('')

    //email regex
    const emailRegex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    //validation function
    const validate = (emailData) => {
        let formErrors = {}
        let isValid = false
        if (!emailData.Email) {
            isValid = true
            formErrors.Email = 'Please enter  email';
        } else if (!emailRegex.test(emailData.Email)) {
            isValid = true
            formErrors.Email = "Please enter valid email"
        } else {
            formErrors.Email = ''
        }
        setError(formErrors)
        return isValid
    }

    //handle change
    const handleForgotPasswordChange = (e) => {
        const { name, value } = e.target
        setemailData({ ...emailData, [name]: value })
        switch (name) {
            case "Email":
                value === "" ? setError({ ...error, Email: "Please enter email" }) : !emailRegex.test(value) ? setError({ ...error, Email: "Please enter valid email" }) : setError({ ...error, Email: "" })
            default:
                break;
        }
    }

    //forgotpassword Api
    async function forgotPasswordApi() {
        const bodyData = {
            email: emailData.Email
        }
        try {
            const response = await adminService.forgotPasswordService(bodyData)
            if (response.status === 200) {
                setOpenSnackbar(true)
                setLoader(false)
                setMessage(response.data.message)
                setTimeout(() => {
                    window.location.href = '/admin'
                }, 2000)
            }
        } catch (error) {
            setErrorSnackbar(true)
            setMessage(error.response.data.message)
            setLoader(false)
        }
    }

    //handleClick forgotpassword
    const handleForgotPassword = (e) => {
        e.preventDefault()
        if (!validate(emailData)) {
            setLoader(true)
            forgotPasswordApi()
        }
    }

    //handleclose toast
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
        setErrorSnackbar(false)
    };
    return (
        <section className="bg-home d-flex bg-light align-items-center cstm-fm-all">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-8">
                        <div className="card login-page bg-white border rounded-20">
                            <div className="card-body">
                                <div className="text-center logo-container">
                                    <img src={appLogo} className='cstm-logo' />
                                </div>

                                <h4>Forgot Password</h4>

                                <form className="login-form mt-4">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="mb-4">
                                                <label htmlFor='Email' className="cstm-label">Email</label>
                                                <input onChange={handleForgotPasswordChange} value={emailData.Email} type="email" className="cstm-input" placeholder="Enter your email address" name="Email" />
                                                {error.Email && <span className="error-message"> {error.Email} </span>}
                                            </div>

                                        </div>
                                        <div className="col-lg-12">
                                            <div className="d-grid">
                                                <button disabled={loader ? true : false} onClick={handleForgotPassword} className="cstm-btn1">Submit</button>
                                                {loader &&
                                                    <div className="btn-loader-main login-btn-div">
                                                        <img className="btn-loaderImg login-img" src={loaderimg} /></div>
                                                }
                                                <Link to='/admin' className='cstm-back'>Back</Link>
                                            </div>
                                            <Snackbar
                                                open={openSnackbar}
                                                onClose={handleClose}
                                                autoHideDuration={5000}
                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            >
                                                <Alert variant='filled' onClose={handleClose} severity='success'>{message}</Alert>
                                            </Snackbar>
                                            <Snackbar
                                                open={errorSnackbar}
                                                onClose={handleClose}
                                                autoHideDuration={5000}
                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            >
                                                <Alert variant='filled' onClose={handleClose} severity='error'>{message}</Alert>
                                            </Snackbar>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword;