import React from 'react'


// Images
import aiToolShare from "../../../assets/images/frontend/ai-tool-share.svg";

// Icons
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import moment from 'moment';
import { Rating } from '@mui/material';

const Temptoolcomment = ({ userReviewData, handleClick, handleDeleteOpen }) => {
    return (
        <>
            {userReviewData !== undefined ?
                <>
                    <p className="MuiTypography-root MuiTypography-body1 css-1s9zzhr">
                        Your review is currently awaiting approval
                    </p>
                    <div className="ai-given-rating">
                        <div className="ai-given-rating-heading">
                            <h6 className="ai-h6-18">{localStorage.getItem('userName')}</h6>
                            <p className="ai-review-date ai-p-14">{moment(userReviewData.createdAt).format("DD/MM/YYYY")}</p>
                        </div>
                        <div className="ai-rating-comment-sec">
                            <div className="ai-rating-comments" dangerouslySetInnerHTML={{ __html: userReviewData.review }}>
                            </div>
                            <div className="ai-rating-reviews">
                                <div className="ai-reviews-star-sec">
                                    <Rating readOnly value={userReviewData.rating} />
                                    <span>({userReviewData.rating})</span>
                                </div>

                                <div className="ai-rating-social-sec">
                                    <button className="ai-share-tooltip" onClick={(e) => handleClick(e, userReviewData._id)}  ><ModeEditIcon /></button>
                                    <button className="ai-share-tooltip" onClick={(e) => handleDeleteOpen(e, userReviewData._id)}><DeleteForeverIcon /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : ""
            }
        </>
    )
}

export default Temptoolcomment
