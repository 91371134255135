import React from 'react'
import { Link } from 'react-router-dom';
import { bucketUrl } from '../../../services/admin.service';
import aiLogo from '../../../assets/images/frontend/ai-logo.svg'

function Blogexplore({ expolreTool }) {
    return (
        <>
            {
                expolreTool.length > 0 ?
                    <div className='ai-blog-detilsnewsletter'>
                        <h4 className='ai-expore-ourtools-title'>Explore our AI tools</h4>
                        <div className='ai-expore-our-ai-tools-box'>
                            {
                                expolreTool.map((item) => (
                                    <>
                                        <Link to={"/tool/" + item.slug} target="_blank">

                                            <div className='ai-expore-our-ai-tools-box-in'>
                                                <div className='ai-expore-our-ai-tools-box-in-img'>
                                                    <img src={bucketUrl + item.imageUrl} />
                                                </div>
                                                <div className='ai-expore-our-ai-tools-box-in-cont'>
                                                    <h6>{item.subCategoryData[0].subCategoryName}</h6>
                                                    <h5>{item.toolName}</h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </>
                                ))

                            }

                        </div>
                    </div>
                    :
                    <div className="ai-blog-detilsnewsletter ai-boost-only">
                        <p className='ai-boost-logo'>
                            <img src={aiLogo} />
                        </p>
                        <h4>Boost Your Al Tool<br /> - Promote Now!</h4>
                        <p className="ai-sunscribe-sub-title">Reach a wider audience and showcase your AI tool to the world by promoting them on AIToolSpot!</p>
                        <p>
                            <Link to="/sponsorship">
                                <button
                                    type="submit"
                                    className="ai-subscribe-blog-btn">
                                    Get Started
                                </button>
                            </Link>
                        </p>
                        <p className='ai-offer'>Limited Time Discount</p>
                    </div>
            }

        </>
    )
}

export default Blogexplore