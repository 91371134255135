import React, { useEffect, useState } from 'react'

// CSS
import "./ToolComments.css"
// Images
import aiToolShare from "../../../assets/images/frontend/ai-tool-share.svg";

// Icons
import {
  ThumbDownAlt,
  ThumbDownOffAlt,
  ThumbUpAlt,
} from "@mui/icons-material";
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import moment from 'moment';
import { Alert, Rating, Snackbar } from '@mui/material';
import frontendService from '../../../services/frontend.service';
import Cookies from 'js-cookie';

const Toolcomments = ({ data, userReviewData, handleClick, handleDeleteOpen }) => {
  //toast
  const [toastOpen, setToastOpen] = useState(false)
  const [successToastOpen, setSuccessToastOpen] = useState(false)
  //message
  const [message, setMessage] = useState('')
  //liked 
  const [liked, setLiked] = useState(false)
  const [likeCount, setLikeCount] = useState(data !== undefined ? data.countLikes : "")
  //unliked
  const [unliked, setUnliked] = useState(false)
  const [unLikeCount, setUnLikeCount] = useState(data !== undefined ? data.countUnLikes : "")
  //reviewEdit & delete
  const [reviewEdit, setReviewEdit] = useState(false)

  //check if user loggedin or not
  var isUserOk = localStorage.getItem("isUserOk")
  useEffect(() => {
    //true or false for like and unlike
    if (data.userLikesReviewData.length > 0 && data.userLikesReviewData[0].Like !== undefined && data !== undefined && data.userLikesReviewData[0].Like === true) {
      setLiked(true)
    } else {
      setLiked(false)
    }
    if (data.userLikesReviewData.length > 0 && data !== undefined && data.userLikesReviewData[0].UnLike !== undefined && data.userLikesReviewData[0].UnLike === true) {
      setUnliked(true)
    } else {
      setUnliked(false)
    }
    if (data.userData !== undefined && data.userData.length > 0 && data.userData[0]._id === Cookies.get('userId')) {
      setReviewEdit(true)
    } else {
      setReviewEdit(false)
    }
  }, [])
  //reviewlike 
  async function reviewLikeApi(id) {
    const bodyData = {
      reviewId: id
    }
    try {
      const response = await frontendService.reviewLikeService(bodyData)
      if (response.status === 200) {
      }
    } catch (error) {
    }
  }
  //review unlike
  async function reviewDisLikeApi(id) {
    const bodyData = {
      reviewId: id
    }
    try {
      const response = await frontendService.reviewDisLikeService(bodyData)
      if (response.status === 200) {
      }
    } catch (error) {
    }
  }
  //handle like
  const handleLike = (e, id) => {
    if (liked === false && unliked === true && localStorage.getItem('isUserOk')) {
      reviewLikeApi(id)
      setLiked(true)
      setUnliked(false)
      setLikeCount(likeCount + 1)
      setUnLikeCount(unLikeCount - 1)
    } else if (liked === false && localStorage.getItem('isUserOk')) {
      reviewLikeApi(id)
      setLiked(true)
      setLikeCount(likeCount + 1)
    } else if (liked === true && localStorage.getItem('isUserOk')) {
      reviewLikeApi(id)
      setLiked(false)
      setLikeCount(likeCount - 1)
    } else {
      setToastOpen(true)
      setMessage('You need to login to like a review')
    }
  }
  //handle dislike
  const handleUnLike = (e, id) => {
    if (unliked === false && liked === true && localStorage.getItem('isUserOk')) {
      reviewDisLikeApi(id)
      setLiked(false)
      setUnliked(true)
      setLikeCount(likeCount - 1)
      setUnLikeCount(unLikeCount + 1)
    } else if (unliked === false && localStorage.getItem('isUserOk')) {
      reviewDisLikeApi(id)
      setUnliked(true)
      setUnLikeCount(unLikeCount + 1)
    } else if (unliked === true && localStorage.getItem('isUserOk')) {
      reviewDisLikeApi(id)
      setUnliked(false)
      setUnLikeCount(unLikeCount - 1)
    } else {
      setToastOpen(true)
      setMessage('You need to login to like a review')
    }
  }
  //handle close toast
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessToastOpen(false)
    setToastOpen(false)
  }
  return (
    <>
      <div className="ai-given-rating">
        <div className="ai-given-rating-heading">
          {data !== undefined ? data.userData.map((item) => (
            <h6 className="ai-h6-18">{item.name}</h6>
          )) : ""}
          <p className="ai-review-date ai-p-14">{moment(data !== undefined ? data.createdAt : userReviewData.createdAt).format("DD/MM/YYYY")}</p>
        </div>
        <div className="ai-rating-comment-sec">
          <div className="ai-rating-comments" dangerouslySetInnerHTML={{ __html: data !== undefined ? data.review : userReviewData.review }}>
          </div>
          <div className="ai-rating-reviews">
            <div className="ai-reviews-star-sec">
              <Rating readOnly value={data !== undefined ? data.rating : userReviewData.rating} />
              <span>({data !== undefined ? data.rating : userReviewData.rating})</span>
            </div>

            <div className="ai-rating-social-sec">
              {reviewEdit && isUserOk ?
                <>
                  <button className="ai-share-tooltip" onClick={(e) => handleClick(e, data._id)}><ModeEditIcon /></button>
                  <button className="ai-share-tooltip" onClick={(e) => handleDeleteOpen(e, data._id)} ><DeleteForeverIcon /></button>
                </> : ""
              }
              <button type="button" className="ai-comment-likes" onClick={(e) => handleLike(e, data._id)}>
                {liked && isUserOk ?
                  <ThumbUpAlt className="ai-click-yes" /> :
                  <ThumbUpOffAltOutlinedIcon />
                }
                <span>{likeCount}</span>
              </button>
              <button type="button" className="ai-comment-dislikes" onClick={(e) => handleUnLike(e, data._id)}>
                {unliked && isUserOk ?
                  <ThumbDownAlt className="ai-click-yes" /> :
                  <ThumbDownOffAlt />
                }
                <span>{unLikeCount}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={toastOpen}
        onClose={handleClose}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} variant='filled' severity='info'>{message}</Alert>
      </Snackbar>
      <Snackbar
        open={successToastOpen}
        onClose={handleClose}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} variant='filled' severity='success'>{message}</Alert>
      </Snackbar>
    </>
  )
}

export default Toolcomments
