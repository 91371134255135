import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';

// Frontend CSS
import "./assets/css/frontend/style.css"
import "./assets/css/frontend/globalStyle.css"
import "./components/frontend/modalBox/GlobalModel.css"
// Fonts CSS
import "./assets/css/font.css"
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider
    clientId="104692070040-tvd8eqlqsr2u47rkv5e8ilkfo7qpdd2b.apps.googleusercontent.com"
  >
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>
);

reportWebVitals();
