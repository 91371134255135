import React from 'react'
import "./ThankyouTable.css";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const ThankyouTable = ({ resData }) => {
  const navigate = useNavigate()
  return (
    <>
      <div className="ai-table-thankyou">
        <div className="ai-table-container">
          <div className="table-responsive">
            <table className="table">
              <tbody>
                <tr>
                  <td >Transaction Id </td>
                  <td>{resData.paymentIntentId}</td>
                </tr>
                <tr>
                  <td >Price</td>
                  <td>${resData.totalprice}</td>
                </tr>
                <tr>
                  <td >sponsorship </td>
                  <td>{resData.sponsorshipType}</td>
                </tr>
                <tr>
                  <td >Full name </td>
                  <td>{resData.name}</td>
                </tr>
                <tr>
                  <td >Email address </td>
                  <td>{resData.email}</td>
                </tr>
                <tr>
                  <td >Website url </td>
                  <td>{resData.websiteUrl}</td>
                </tr>
                <tr>
                  <td >ToolName</td>
                  <td>{resData.toolName}</td>
                </tr>
                <tr>
                  <td >Number of sponsor days </td>
                  <td>{resData.toolTopdays}</td>
                </tr>
                <tr>
                  <td >From Date - To Date</td>
                  <td>{resData.dateSelection !== undefined ? resData.dateSelection.map((item, i) => (moment(item.startDate).format('DD MMM, YYYY') + ' ' + '-' + ' ' + moment(item.endDate).format('DD MMM, YYYY'))).join(', ') : ""}   <br /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='ai-thankyou-btn-main'><button onClick={() => navigate('/')} type="submit" className="ai-thankyou-btn">Return to homepage</button></div>
    </>
  )
}

export default ThankyouTable