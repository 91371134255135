const localization = {
  locale: "en-us",
  translations: {
    "generic.add": "Add",
    "generic.cancel": "Cancel",

    "components.controls.blocktype.normal": "Normal",
    "components.controls.blocktype.h2": "Heading 1",
    "components.controls.blocktype.h3": "Heading 2",
    "components.controls.blocktype.h4": "Heading 3",
    "components.controls.blocktype.blockquote": "Blockquote",

    "components.controls.embedded.embedded": "Embedded",
    "components.controls.embedded.embeddedlink": "Embedded Link",
    "components.controls.embedded.enterlink": "Enter link",


    "components.controls.image.image": "Image",
    "components.controls.image.fileUpload": "File Upload",
    "components.controls.image.byURL": "URL",
    "components.controls.image.dropFileText": "Drop the file or click to upload"
  }
};

export default localization;


export const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
]