import React, { useState } from "react";
import { Alert, Popover, Snackbar } from "@mui/material";
import { Link } from "react-router-dom";
// CSS
import "./ShareCard.css";
// images
import Facebookshare from "../../../assets/images/frontend/facebookshare.svg";
import Twittershare from "../../../assets/images/frontend/twittershare.svg";
import Linkedinshare from "../../../assets/images/frontend/linkedInshare.svg";
import Aicodecopy from "../../../assets/images/frontend/ai-code-copy.svg";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";

const ShareCard = ({ toggleShareBtn, id, open, handleShareBtnClose, url,quote }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [message, setMessage] = useState('')
  const onClickCopyLink = () => {
    navigator.clipboard.writeText(url)
    setOpenSnackbar(true)
    setMessage('Copied to clipboard!')
  }
  //handleclose toast
  const handleClosetoast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false)
  };
  return (
    <>
      <div className="ai-share-popover">
        <Popover
          className="ai-share-popover-sec"
          anchorEl={toggleShareBtn}
          id={id}
          open={open}
          onClose={handleShareBtnClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="ai-share-popover-container">
            <div className="ai-social-share">
              <button>
                <FacebookShareButton url={url} quote={quote}>
                  <img src={Facebookshare} alt="" />
                </FacebookShareButton>
              </button>
              <button>
                <TwitterShareButton url={url} title={quote}>
                  <img src={Twittershare} alt="" />
                </TwitterShareButton>
              </button>
              <button>
                <LinkedinShareButton url={url} summary={quote}>
                  <img src={Linkedinshare} alt="" />
                </LinkedinShareButton>
              </button>
            </div>
            <div className="ai-copy-link">
              <Link onClick={onClickCopyLink}>
                <img
                  src={Aicodecopy}
                  alt=""
                />
                Copy Link
              </Link>
            </div>
          </div>
        </Popover>
      </div>
      <Snackbar
        open={openSnackbar}
        onClose={handleClosetoast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClosetoast} variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ShareCard;
