import React, { useState } from 'react'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
//images
import card from '../../../assets/images/frontend/card.svg'
import CalenderIcon from '../../../assets/images/CalenderIcon.svg';
import loaderimg from '../../../assets/images/loder2.gif'

import frontendService from '../../../services/frontend.service';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './CheckoutForm.css'
import { Alert, Snackbar } from '@mui/material';

const CheckoutForm = ({ paymentIntent }) => {
    //for use stripe element and stripe component
    const stripe = useStripe();
    const elements = useElements();
    //navigate to page
    const navigate = useNavigate()
    const dispatch = useDispatch()
    //toast
    const [openToast, setOpenToast] = useState(false)
    //message
    const [message, setMessage] = useState('')
    //LOADER
    const [loader, setLoader] = useState(false)
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoader(true)
        if (!stripe || !elements) {
            return;
        }
        //get data from card element
        const cardElement = elements.getElement(CardNumberElement);
        //confirmCardPayment stripe api for payment
        const response = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
            payment_method: {
                card: cardElement,
            },
        });
        try {
            if (response.error) {
                setMessage(response.error.message)
                setOpenToast(true)
            }
            if (response.paymentIntent.status === 'succeeded') {
                const bodydata = {
                    paymentIntentId: paymentIntent.data.paymentIntentId,
                    status: response.paymentIntent.status
                }
                //if stripe payment succeeded then this api will call and update payment status in database
                const successResponse = await frontendService.sponsershipPaymentService(bodydata)
                if (successResponse.status === 200) {
                    setLoader(false)
                    dispatch({ type: "RESPONSE_DATA", payload: successResponse.data.data })
                    navigate('/thankyou')
                }
            }
        } catch (error) {
            setLoader(false)
        }
    };
    //handle toast
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };
    return (
        <>
            <h4 className='ai-enquiry-title'>Make a payment</h4>
            <p className="ai-accordion-sub">You have to <b>Pay ${paymentIntent.data.totalprice}</b> for <b>{paymentIntent.data.toolTopdays}  days</b> use of tools.</p>
            <form className='ai-payment-form' onSubmit={handleSubmit}>
                <div className='row'>
                    <div className="ai-form-group col-md-12">
                        <label>Card number</label>
                        <CardNumberElement className="ai-form-control" placeholder='xxxx-xxxx-xxxx-xxxx' />
                        <img src={card} className='ai-card-number-icon' />
                    </div>
                    <div className="ai-form-group col-md-6">
                        <label>Expiry date</label>
                        <CardExpiryElement className="ai-form-control" />
                        <img src={CalenderIcon} className="ai-card-number-icon" />
                    </div>
                    <div className="ai-form-group col-md-6">
                        <label>CVV</label>
                        <CardCvcElement className="ai-form-control" />
                    </div>
                </div>
                <div className='ai-submit-btn-loader'>
                    <button className="proceed-pat-btn" type="submit" disabled={!stripe}>
                        Checkout
                    </button>
                    {loader && (
                        <div className="ai-submit-loader">
                            <img src={loaderimg} />
                        </div>
                    )}
                </div>
            </form>
            <Snackbar
                open={openToast}
                onClose={handleClose}
                autoHideDuration={10000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleClose} variant='filled' severity='error' >{message}</Alert>
            </Snackbar>
        </>
    )
}

export default CheckoutForm