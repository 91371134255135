import axios from "axios"
import authHeader from "./auth.header"

// const API_URL = 'http://13.50.216.140/api/AS/'
const API_URL = 'https://aitoolspot.com/api/AS/'

export const bucketUrl = 'https://aitoolspot1.s3.amazonaws.com/'

const adminLogin = (data) => {
    return axios.post(API_URL + 'signin', data)
}

const forgotPasswordService = (data) => {
    return axios.post(API_URL + 'forgot-password', data)
}

const resetPasswordService = (data) => {
    return axios.post(API_URL + 'reset-password', data, {
        headers: authHeader()
    })
}

const categoryListService = () => {
    return axios.get(API_URL + 'category/getAll', {
        headers: authHeader()
    })
}

const categoryAddService = (data) => {
    return axios.post(API_URL + 'category/add', data, {
        headers: authHeader()
    })
}

const categoryViewService = (id) => {
    return axios.get(API_URL + 'category/get-by-id/' + id, {
        headers: authHeader()
    })
}

const categoryDeleteService = (id) => {
    return axios.delete(API_URL + 'category/' + id, {
        headers: authHeader()
    })
}

const categoryEditService = (id, data) => {
    return axios.put(API_URL + 'category/' + id, data, {
        headers: authHeader()
    })
}

const subCategoryListingService = () => {
    return axios.get(API_URL + 'Subcategory/view', {
        headers: authHeader()
    })
}

const subCategoryAddService = (data) => {
    return axios.post(API_URL + 'Subcategory/add', data, {
        headers: authHeader()
    })
}

const subCategoryViewService = (id) => {
    return axios.get(API_URL + 'Subcategory/get-by-id/' + id, {
        headers: authHeader()
    })
}

const subCategoryEditService = (id, data) => {
    return axios.put(API_URL + 'Subcategory/' + id, data, {
        headers: authHeader()
    })
}

const subCategoryDeleteService = (id) => {
    return axios.delete(API_URL + 'Subcategory/' + id, {
        headers: authHeader()
    })
}

const pricingListingService = () => {
    return axios.get(API_URL + 'pricing/getAll', {
        headers: authHeader()
    })
}

const priceAddService = (data) => {
    return axios.post(API_URL + 'pricing/add', data, {
        headers: authHeader()
    })
}

const priceViewService = (id) => {
    return axios.get(API_URL + 'pricing/get-by-id/' + id, {
        headers: authHeader()
    })
}

const priceEditService = (id, data) => {
    return axios.put(API_URL + 'pricing/' + id, data, {
        headers: authHeader()
    })
}

const priceDeleteService = (id) => {
    return axios.delete(API_URL + 'pricing/' + id, {
        headers: authHeader()
    })
}

const featureListingService = () => {
    return axios.get(API_URL + 'Feature/getAll', {
        headers: authHeader()
    })
}

const featureAddService = (data) => {
    return axios.post(API_URL + 'Feature/add', data, {
        headers: authHeader()
    })
}

const featureViewService = (id) => {
    return axios.get(API_URL + 'Feature/get-by-id/' + id, {
        headers: authHeader()
    })
}

const featureEditService = (id, data) => {
    return axios.put(API_URL + 'Feature/' + id, data, {
        headers: authHeader()
    })
}

const featureDeleteService = (id) => {
    return axios.delete(API_URL + 'Feature/' + id, {
        headers: authHeader()
    })
}

const userListingService = () => {
    return axios.get(API_URL + 'user/listing', {
        headers: authHeader()
    })
}

const userGetService = (id) => {
    return axios.get(API_URL + 'user/get-by-id/' + id, {
        headers: authHeader()
    })
}

const userEditService = (id, data) => {
    return axios.put(API_URL + 'user/' + id, data, {
        headers: authHeader()
    })
}

const userDeleteService = (id) => {
    return axios.get(API_URL + 'user/' + id, {
        headers: authHeader()
    })
}

const toolListingService = () => {
    return axios.get(API_URL + 'tool/getAll', {
        headers: authHeader()
    })
}

const toolAddService = (data) => {
    return axios.post(API_URL + 'tool/add', data, {
        headers: authHeader()
    })
}

const toolDeleteService = (id) => {
    return axios.get(API_URL + 'tool/' + id, {
        headers: authHeader()
    })
}

const toolViewService = (id) => {
    return axios.get(API_URL + 'tool/get-by-id/' + id, {
        headers: authHeader()
    })
}

const toolEditService = (id, data) => {
    return axios.post(API_URL + 'tool/' + id, data, {
        headers: authHeader()
    })
}

const pendinToolListService = (data) => {
    return axios.post(API_URL + 'tool/status-wise-filter-list', data, {
        headers: authHeader()
    })
}

const toolApproveService = (id) => {
    return axios.get(API_URL + 'tool/Approved/' + id, {
        headers: authHeader()
    })
}

const toolRejectService = (id, data) => {
    return axios.put(API_URL + "tool/UnApprove/" + id, data, {
        headers: authHeader()
    })
}

const feedBackListService = () => {
    return axios.get(API_URL + 'feedback/getAll', {
        headers: authHeader()
    })
}

const feedbackViewService = (id) => {
    return axios.get(API_URL + 'feedback/get-by-id/' + id, {
        headers: authHeader()
    })
}

const blogListService = () => {
    return axios.get(API_URL + 'blog/getAll', {
        headers: authHeader()
    })
}

const blogCategoryService = () => {
    return axios.get(API_URL + 'blogCategory/getAll', {
        headers: authHeader()
    })
}

const blogAddService = (data) => {
    return axios.post(API_URL + 'blog/add', data, {
        headers: authHeader()
    })
}

const blogViewService = (id) => {
    return axios.get(API_URL + 'blog/get-by-id/' + id, {
        headers: authHeader()
    })
}

const blogEditService = (id, data) => {
    return axios.put(API_URL + 'blog/' + id, data, {
        headers: authHeader()
    })
}

const blogDeleteService = (id) => {
    return axios.get(API_URL + 'blog/' + id, {
        headers: authHeader()
    })
}

const mediaGetService = () => {
    return axios.get(API_URL + 'media/getAll', {
        headers: authHeader()
    })
}

const mediaAddService = (data) => {
    return axios.post(API_URL + 'media/add', data, {
        headers: authHeader()
    })
}

const mediaDeleteService = (id) => {
    return axios.delete(API_URL + 'media/' + id, {
        headers: authHeader()
    })
}

const reviewListingService = () => {
    return axios.get(API_URL + 'review/getAll', {
        headers: authHeader()
    })
}

const getReviewById = (id) => {
    return axios.get(API_URL + 'review/get-by-id/' + id, {
        headers: authHeader()
    })
}

const approveReviewService = (id) => {
    return axios.get(API_URL + 'review/reviewApproved/' + id, {
        headers: authHeader()
    })
}

const unApproveReviewService = (id) => {
    return axios.get(API_URL + 'review/review-UnApproved/' + id, {
        headers: authHeader()
    })
}

const addPrivacyPolicyService = (data) => {
    return axios.post(API_URL + 'privacy-policy', data, {
        headers: authHeader()
    })
}

const getPrivacyPolicyService = () => {
    return axios.get(API_URL + 'get-privacy-policy/privacy-policy', {
        headers: authHeader()
    })
}

const addTermsAndConditionService = (data) => {
    return axios.post(API_URL + 'terms-and-condition', data, {
        headers: authHeader()
    })
}
const getTermsAndConditionService = () => {
    return axios.get(API_URL + 'get-terms-and-condition/terms-and-condition', {
        headers: authHeader()
    })
}
const addPerDayPriceService = (data) => {
    return axios.post(API_URL + 'tool-per-day-price', data, {
        headers: authHeader()
    })
}
const getPerDayPriceService = () => {
    return axios.get(API_URL + 'get-tool-per-day-price/Tool-per-day-price', {
        headers: authHeader()
    })
}
const getSponsorShipService = () => {
    return axios.get(API_URL + 'sponsorship/getSponsoredTool', {
        headers: authHeader()
    })
}
const approveSponsorshipService = (id) => {
    return axios.post(API_URL + 'sponsorship/approve/' + id, {}, {
        headers: authHeader()
    })
}
const getSponsorShipByIdService = (id) => {
    return axios.get(API_URL + 'sponsorship/getBySponsoredToolId/' + id, {
        headers: authHeader()
    })
}
export default {
    adminLogin,
    forgotPasswordService,
    resetPasswordService,
    categoryListService,
    categoryAddService,
    categoryViewService,
    categoryDeleteService,
    categoryEditService,
    subCategoryListingService,
    subCategoryAddService,
    subCategoryViewService,
    subCategoryEditService,
    subCategoryDeleteService,
    pricingListingService,
    priceAddService,
    priceViewService,
    priceEditService,
    priceDeleteService,
    featureListingService,
    featureAddService,
    featureViewService,
    featureEditService,
    featureDeleteService,
    userListingService,
    userGetService,
    userEditService,
    userDeleteService,
    toolListingService,
    toolAddService,
    toolViewService,
    toolDeleteService,
    toolApproveService,
    pendinToolListService,
    toolEditService,
    feedBackListService,
    feedbackViewService,
    toolRejectService,
    blogListService,
    blogAddService,
    blogCategoryService,
    blogViewService,
    blogEditService,
    blogDeleteService,
    mediaGetService,
    mediaAddService,
    mediaDeleteService,
    reviewListingService,
    getReviewById,
    approveReviewService,
    unApproveReviewService,
    addPrivacyPolicyService,
    getPrivacyPolicyService,
    addTermsAndConditionService,
    getTermsAndConditionService,
    addPerDayPriceService,
    getPerDayPriceService,
    getSponsorShipService,
    approveSponsorshipService,
    getSponsorShipByIdService
}