import React, { useEffect, useRef, useState } from "react";
// Components
import Blogcards from "./BlogCards";
// CSS
import "./BlogDetailsContent.css";
// Images
import Loader from "../../loader/Loader";
//url
import { bucketUrl } from "../../../services/admin.service";
//mui accodian
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BlogSidebar from "./BlogSidebar";
import Seo from "../../seo/Seo";
import { useNavigate } from "react-router-dom";

function Blogdetailscontent({ blogDetailData, relatedBlogs, loader, expolreTool, pageNotfound }) {
  const [tableOfContents, setTableOfContents] = useState([]);
  const navigate = useNavigate();
  const contentRef = useRef(null);

  useEffect(() => {
    if (pageNotfound){
      navigate("*")
    }
  }, [pageNotfound])

  //accordion faq
  const initialAccordionState = Array.from(blogDetailData.FAQ !== undefined && { length: blogDetailData.FAQ.length }, () => false);
  const [accordionState, setAccordionState] = useState(initialAccordionState)

  //accordion function for index
  const toggleAccordionItem = (index) => {
    const updatedAccordionState = [...accordionState];
    updatedAccordionState[index] = !updatedAccordionState[index];
    setAccordionState(updatedAccordionState);
  };
  return (
    <>
    <Seo title={blogDetailData.metaTitle} description={blogDetailData.metaDesc}/>
      <div className="ai-blog-detail-main">
        <div className="ai-loading-sapcing">
          {blogDetailData.blogCategoryId !== undefined ?
            <p className="ai-blog-detail-label">
              {blogDetailData.blogCategoryId.name}
            </p>
            : ""}
          <h1 className="ai-h1 ai-40">{blogDetailData.title}</h1>
          <div className="ai-blog-detail-banner">
            {blogDetailData.imageUrl !== undefined ?
              <img
                className="ai-blog-heading-img"
                src={bucketUrl + blogDetailData.imageUrl}
                alt={blogDetailData.blogCategoryId.name}
              /> : ""
            }
          </div>


          <div className="ai-blog-details-main-all">
          <div className="ai-blog-detail-main ai-html-content" ref={contentRef}>
            <div dangerouslySetInnerHTML={{ __html: blogDetailData.longDesc }} />
          </div>
          <div className="ai-blog-details-sidebar">
          <BlogSidebar expolreTool={expolreTool}/>
        </div>
        </div>

        </div>

       

        {/* FAQ */}
        {
          blogDetailData.isFAQ !== undefined && blogDetailData.FAQ !== undefined && blogDetailData.isFAQ && blogDetailData.FAQ.length > 0 ?

            <>
              <div className="ai-html-content">
                <h2 className="ai-h4 mb-4 text-left">Frequently Asked Questions</h2>
              </div>
              {blogDetailData.FAQ.map((item, index) => (
                <>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="ai-html-content">
                        <h4>{item.que}</h4>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="ai-html-content">
                        <p className="ai-faq-content-main">
                          {item.ans}
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}
            </>
            :
            ""
        }

        {/* Related Blog */}
        <div className="ai-related-blogs">
          <h2 className="ai-h2">Related Blog</h2>
          <div className="row">
            {loader ? (
              <div className="ai-front-loader">
                <Loader />
              </div>
            ) : relatedBlogs.length === 0 ? (
              <div className="ai-norecord">
                <span>No Record Found</span>
              </div>
            ) : (
              relatedBlogs.map((item) => (
                <div className="col-sm-6 col-lg-4 align-items-stretch">
                  <Blogcards data={item} />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Blogdetailscontent;
