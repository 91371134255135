import React from "react";
// components
import Blogcards from "./BlogCards";
// CSS
import "./BlogListing.css";

// images
import searchDark from "../../../assets/images/frontend/search-dark.svg";
import categoryIcon from "../../../assets/images/frontend/category-icon.svg";
import arrowDown from "../../../assets/images/frontend/arrow-down.svg";
import Loader from "../../loader/Loader";

function Bloglisting({ totalBlog,closeMenu, blogListing, onTextChanged, handleClickgetquery, noRecordFound, isLoading, blogCategoryListing, openDropDown, setOpenDropdown, onChangeCategory, selectedCategory }) {
  return (
    <div className="ai-blogs-container">
      <div className="ai-blog-search-sec">
        <div className="row">
          <div className="col-md-8 col-lg-9">
            <form>
              <div className="ai-form-inputes">
                <label className="ai-label-form">
                  <img src={searchDark} alt="searchDark" />
                </label>
                <input
                  type="text"
                  className="ai-inputs"
                  aria-describedby="search"
                  placeholder={`Search ${totalBlog} blogs`}
                  onChange={onTextChanged}
                  onKeyDown={handleClickgetquery}
                />
              </div>
            </form>
          </div>
          <div className="col-md-4 col-lg-3">
            <div className="ai-sort-selection" ref={closeMenu} onClick={() => setOpenDropdown(!openDropDown)}>
              <label className="ai-sort-label"> Sort by</label>
              <div className="ai-sort-container">
                <img src={categoryIcon} alt="category-Icon" />
                <span>{selectedCategory !== "" ? selectedCategory : "Category"}</span>
              </div>
              <img src={arrowDown} alt="" className={`ai-sort-down ${openDropDown ? "ai-sort-open" : ""}`} />
              {/* Dropdown */}
              {openDropDown &&
                <div className="ai-banner-categorymenu">
                  <ul>
                    {blogCategoryListing.map((item) => (
                      <li onClick={(e) => onChangeCategory(e, item.name)}>{item.name}</li>
                    ))
                    }
                  </ul>
                </div>
              }
            </div>

          </div>
        </div>
      </div>
      <div className="row">
        {blogListing.map((item) =>
        (
          <div className="col-sm-6 col-lg-4 align-items-stretch">
            <Blogcards key={item._id} data={item} />
          </div>)
        )}
        {noRecordFound &&
          <div className="ai-norecord">
            <span>Unfortunately, we couldn't find any results that match your criteria. Try broadening your search for more results.</span>
          </div>
        }
        {isLoading &&
          <div className="ai-front-loader">
            <Loader />
          </div>
        }
      </div>
    </div>
  );
}

export default Bloglisting;
