import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import editIcon from '../../../assets/images/Edit.svg'
import Deleteicon from '../../../assets/images/delete.svg'
import adminService from '../../../services/admin.service';
import Pagination from '../../pagination/Pagination';
import Loader from '../../loader/Loader';
import moment from 'moment';
import loaderimg from '../../../assets/images/loder2.gif'

import DeleteModel from '../../../assets/images/deleteModel.svg'
import Addbutton from '../../buttons/AddButton';
import { Alert, Snackbar } from '@mui/material';

const User = () => {

    const [userlisting, setUserListing] = useState([])
    const [userToolCount, setUserToolCount] = useState([])
    const [initialData, setInitialData] = useState([])
    const [userView, setUserView] = useState({
        name: ""
    })
    const [error, setError] = useState({
        name: ""
    })
    const [searchValue, setSearchValue] = useState('');
    const [userId, setUserId] = useState('')
    const [loader, setLoader] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [message, setMessage] = useState('')
    const [dataLoader, setDataLoader] = useState(false)
    const [errorSnackbar, setErorSnackbar] = useState(false)
    const [popBtnLoader, setPopBtnLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 20
    const excludeColumns = ["_id"];
    

    var DataArray = []
    useEffect(() => {
        setLoader(true)
        userlistingApi()
    }, [])

    function createData(_id, userName, email, numberOftool, date) {
        return {
            _id, userName, email, numberOftool, date
        }
    }

    async function userlistingApi() {
        try {
            const response = await adminService.userListingService()
            if (response.status === 200) {
                var arr = response.data.data
                if (arr.length > 0) {
                    var newarr = [];
                    for (var i = 0; i < arr.length; i++) {

                        var date = moment(arr[i].createdAt).format('DD MMM, YYYY')

                        newarr[i] = createData(
                            arr[i]._id,
                            arr[i].name,
                            arr[i].email,
                            arr[i].userData.length,
                            date
                        )

                    }
                    newarr.map((data1) => {
                        DataArray = [...DataArray, data1]
                    })
                    DataArray = DataArray.sort().reverse()
                    setInitialData(DataArray)
                    if (searchValue !== "" && searchValue !== undefined &&  searchValue !== null){

                        var filterData = DataArray.filter((item) => { 
                            return Object.keys(item).some(key =>
                                excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
                            );
                        });
                        
                        setUserListing(filterData)
                        setLoader(false)
    
                    } else {
                        setUserListing(DataArray)
                        setLoader(false)
    
                    }

                }
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            setErorSnackbar(true)
        }
    }

    async function userViewApi(id) {
        try {
            const response = await adminService.userGetService(id)
            if (response.status === 200) {
                setUserView(response.data.data)
                setDataLoader(false)
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            setErorSnackbar(true)
        }
    }

    async function userEditApi() {
        const bodyData = {
            name: userView.name
        }
        try {
            const response = await adminService.userEditService(userId, bodyData)
            if (response.status === 200) {
                setOpenSnackbar(true)
                setPopBtnLoader(false)
                setMessage(response.data.message)
                setLoader(true)
                document.querySelector("#Edituser .btn-close").click()
                userlistingApi()
            }
        } catch (error) {
            setLoader(false)
            setErorSnackbar(true)
        }
    }

    async function userDeleteApi() {
        try {
            const response = await adminService.userDeleteService(userId)
            if (response.status === 200) {
                setMessage(response.data.message)
                setPopBtnLoader(false)
                setOpenSnackbar(true)
                setLoader(true)
                document.querySelector('#Deleteuser .btn-close').click()
                userlistingApi()
            }
        } catch (error) {
            setMessage(error.response.data.message)
            setLoader(false)
            setErorSnackbar(true)
        }
    }
    const validate = (userData) => {
        let userError = {}
        let isValid = false
        if (!userData.name) {
            isValid = true
            userError.name = "Please enter the user name"
        }
        setError(userError)
        return isValid
    }

    const handleEdit = (e) => {
        e.preventDefault()
        if (userView.name.trim() !== "") {
            setError({ ...error, name: "" })
            if (!validate(userView)) {
                setPopBtnLoader(true)
                userEditApi()
            }
        } else {
            setError({ ...error, name: "Please enter the user name" })
        }
    }
    const getUserId = (e, id) => {
        setDataLoader(true)
        setUserId(id)
        userViewApi(id)
    }

    const handleEditOnchange = (e) => {
        const { name, value } = e.target
        setUserView({ ...userView, [name]: value })
        switch (name) {
            case "name":
                value.trim() === "" ? setError({ ...error, name: "Please enter the user name" }) : setError({ ...error, name: "" })
            default:
                break
        }
    }

    const handleDelete = () => {
        userDeleteApi()
        setPopBtnLoader(true)
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastRecord = currentPage * recordPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
    const currentRecords = userlisting.slice(indexOfFirstRecord, indexOfLastRecord);

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(userlisting.length / recordPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const onChangeFilter = (searchValue) => {
        setSearchValue(searchValue)
        if (searchValue !== '') {
            const filteredData = initialData.filter((item) => {
                return Object.keys(item).some(key =>
                    excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
                );
            })
            setUserListing(filteredData)
        } else {
            setUserListing(initialData)
        }
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErorSnackbar(false)
        setOpenSnackbar(false);
    };
    return (
        <>
            <div className="page-wrapper doctris-theme toggled">
                <main className="page-content">
                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="mb-0">Users</h4>
                                {/* <div className="cstm-bre uppercase">dashboard YEAR LONG COURSE <Link to="/topic">TOPICS</Link></div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card rounded-md border min-height-sc">
                                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                            <div className="col-md-12">

                                                <i className="fi fi-br-search cstm-search-ro"></i>
                                                <input onChange={(e) => onChangeFilter(e.target.value)}
                                                    type="text" className="cstm-input-seacrh" placeholder="Search User" />

                                            </div>
                                        </div>

                                        <div className="col-md-12 col-lg-12">
                                            <div className="table-responsive bg-white rounded">
                                                <table className="table mb-0 table-center">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-bottom w-4">No.</th>
                                                            <th className="border-bottom w-12">User Name</th>
                                                            <th className="border-bottom w-12">Email</th>
                                                            <th className="border-bottom w-15">Number of Tool</th>
                                                            <th className="border-bottom w-10">Date</th>
                                                            <th className="border-bottom w-11">Action</th>
                                                        </tr>
                                                    </thead>
                                                    {loader ?
                                                        <div className='loader-container'>
                                                            <Loader />
                                                        </div> :
                                                        (userlisting.length === 0 ?
                                                            <span className="cstm-norecord">No Record Found</span> :
                                                            (currentRecords.map((item, i) => (
                                                                <tbody>
                                                                    <tr key={item._id}>
                                                                        <td className="fw-bold">{i + 1}</td>
                                                                        <td>{item.userName}</td>
                                                                        <td>{item.email}</td>
                                                                        <td>{item.numberOftool}</td>
                                                                        <td>{item.date}</td>
                                                                        <td>
                                                                            <Link onClick={(e) => getUserId(e, item._id)} className="cstm-btn6 action-btn" data-bs-toggle="modal" data-bs-target="#Edituser">
                                                                                <i><img src={editIcon} /></i>
                                                                            </Link>
                                                                            <div className="modal fade" id="Edituser" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered">
                                                                                    <div className="modal-content">
                                                                                        {dataLoader ?
                                                                                            <div className='model-loader'>
                                                                                                <Loader /></div> :
                                                                                            <>
                                                                                                <div className="modal-header border-0 p-4">
                                                                                                    <h4 className="modal-title" id="exampleModalLabel1">Edit User</h4>
                                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                                </div>
                                                                                                <div className="modal-body p-4 pt-0">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="cstm-label">User Name<span className="cstm-star">*</span></label>
                                                                                                        <input type="text" onChange={handleEditOnchange} className="cstm-input" placeholder="Enter user name" name="name" required="" value={userView.name ? userView.name : ""} />
                                                                                                        <span className='error-message'>{error.name}</span>
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <label className="cstm-label">Email<span className="cstm-star">*</span></label>
                                                                                                        <input disabled type="email" className="cstm-input" placeholder="Enter Email" name="email" required="" value={userView.email} />
                                                                                                    </div>


                                                                                                    <div className="text-center cstn-loader-sec cstn-loader-left">
                                                                                                        <button disabled={popBtnLoader ? true : false} className=" cstm-btn" onClick={handleEdit}>Update</button>
                                                                                                        {popBtnLoader &&
                                                                                                            <div className='loader-container-cstm'>
                                                                                                                <img className='btn-pop-loader' src={loaderimg} />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <Link onClick={(e) => getUserId(e, item._id)} className="cstm-btn6" data-bs-toggle="modal" data-bs-target="#Deleteuser">
                                                                                <i><img src={Deleteicon} /></i>
                                                                            </Link>
                                                                            <div className="modal fade cstm-add-bonus" id="Deleteuser" tabIndex="-1" aria-labelledby="exampleModalLabel1"
                                                                                aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered">
                                                                                    <div className="modal-content">
                                                                                        <div className="modal-header border-0 p-4">
                                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        </div>
                                                                                        <div className="modal-body p-4 pt-0">
                                                                                            <div className="mb-4">

                                                                                                <div className="text-center mb-4">
                                                                                                    <img src={DeleteModel} />
                                                                                                </div>
                                                                                                <h4 className="text-center mb-4">Are you sure you want to delete user?</h4>


                                                                                                <div className="text-center  cstn-loader-sec ">
                                                                                                    <button className="cstm-btn cstm-btn-text" disabled={popBtnLoader ? true : false} onClick={handleDelete} >Delete User</button>
                                                                                                    {popBtnLoader &&
                                                                                                        <div className='loader-container-cstm'>
                                                                                                            <img className='btn-pop-loader' src={loaderimg} />
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )))
                                                        )
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Snackbar
                                open={openSnackbar}
                                onClose={handleClose}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert onClose={handleClose} variant='filled' severity='success' >{message}</Alert>
                            </Snackbar>
                            {/* Error Toast */}
                            <Snackbar
                                open={errorSnackbar}
                                onClose={handleClose}
                                autoHideDuration={5000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert onClose={handleClose} variant='filled' severity='error'>{message}</Alert>
                            </Snackbar>
                            {/* Pagination */}
                            <Pagination
                                recordPerPage={recordPerPage}
                                totalRecord={userlisting.length}
                                paginate={paginate}
                                currentPage={currentPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                            />
                        </div>
                    </div>

                </main>
            </div>
        </>
    )
}

export default User;