import React, { useState } from "react";
import Pagebreadcrumb from "../../components/frontend/pageBreadCrumb/PageBreadCrumb";
import RecentAddedToolDetails from "../../components/frontend/recentAddedTool/RecentAddedToolDetails";
import Seo from "../../components/seo/Seo";
import frontendService from "../../services/frontend.service";

const RecentAddedTool = () => {
  //filterBykeyWord api
  async function filterBykeyWordApi(
    page,
    searchTerm,
    priceArray,
    selectedFeature,
    selectedStatus
  ) {
    let queryString = "?";
    //updating querystring and payload
    let sortByData =
      selectedStatus !== undefined &&
        selectedStatus !== null &&
        selectedStatus !== ""
        ? selectedStatus
        : "";
    

    queryString += `&limit=10&page=${1}`;
    const bodyData = {
      pricing: priceArray !== undefined ? priceArray : [],
      features: selectedFeature !== undefined ? selectedFeature : [],
    };
    const response = await frontendService.filterBykeyWord(
      queryString,
      bodyData
    );
    
  }
  return (
    <>
    <Seo title={"Recent AI Tools | Stay Ahead with the Latest Innovations"} description={"Explore the newest AI tools and innovations recently added to AIToolSpot. Stay up-to-date with cutting-edge AI tools."} />
      <div className="spacer-132"></div>
      {/* Tool List */}

      <section className="ai-bg-color">
        <div className="ai-container">
          <Pagebreadcrumb path={"Recent"} />

          {/* Recent added  Screen */}
          <RecentAddedToolDetails
          filterBykeyWordApi={filterBykeyWordApi}
          />
        </div>
      </section>
    </>
  );
};

export default RecentAddedTool;
