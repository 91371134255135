import React, { useState } from 'react'
import adminService from '../../../services/admin.service'
import { Alert, Snackbar } from '@mui/material'
import appLogo from '../../../assets/images/Logo.png'
import { Link } from 'react-router-dom'
import loaderimg from '../../../assets/images/loder2.gif'
const ResetPassword = () => {

    //data
    const [password, setPassword] = useState({
        currentpassword: "",
        newPassword: "",
        confirmPassword: ""
    })

    //show hide password
    const [showPassword, setShowPassword] = useState(false)

    //errors
    const [error, setError] = useState({
        currentpassword: "",
        newPassword: "",
        confirmPassword: "",
        matchError: ""
    })

    //messages
    const [message, setMessage] = useState('')

    //loader
    const [loader, setLoader] = useState(false)

    //toast
    const [errorSnackbar, setErrorSnackbar] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)

    //validation
    const validate = () => {
        let passError = {}
        let isValid = false
        if (!password.currentpassword) {
            isValid = true
            passError.currentpassword = "Please enter current password"
        }
        if (!password.newPassword) {
            isValid = true
            passError.newPassword = "Please enter new password"
        }
        if (!password.confirmPassword) {
            isValid = true
            passError.confirmPassword = "Please enter confirm password"
        }
        if (password.confirmPassword !== password.newPassword) {
            isValid = true
            passError.matchError = "New Password & confirm Password not match "
        }
        setError(passError)
        return isValid
    }

    //handle onchange
    const handleOnChange = (e) => {
        const { name, value } = e.target
        setPassword({ ...password, [name]: value })
        switch (name) {
            case "currentpassword":
                value === "" ? setError({ ...error, currentpassword: "Please enter current password" }) : setError({ ...error, currentpassword: "" })
                break;
            case "newPassword":
                value === "" ? setError({ ...error, newPassword: "Please enter new password" }) : setError({ ...error, newPassword: "" })
                break;
            case "confirmPassword":
                value === "" ? setError({ ...error, confirmPassword: "Please enter confirm password" }) : setError({ ...error, confirmPassword: "" })
                break;
        }
    }

    //reset password api
    async function resetPasswordApi() {
        const bodyData = {
            oldpassword: password.currentpassword,
            password: password.newPassword
        }
        try {
            const response = await adminService.resetPasswordService(bodyData)
            if (response.status === 200) {
                setMessage(response.data.message)
                setOpenSnackbar(true)
                setLoader(false)
                setPassword({
                    currentpassword: "",
                    newPassword: "",
                    confirmPassword: ""
                })
            }
        } catch (error) {
            setMessage(error.response.data.message)
            setLoader(false)
            setErrorSnackbar(true)
        }
    }

    //handle submit
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!validate(password)) {
            setLoader(true)
            resetPasswordApi()
        }
    }

    //onclick password show hide
    const onClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    //onclick close toast
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
        setErrorSnackbar(false)
    };
    return (
        <section className="bg-home d-flex bg-light align-items-center cstm-fm-all">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-8">
                        <div className="card login-page bg-white border rounded-20">
                            <div className="card-body">
                                <div className="text-center mb-4">
                                    <img src={appLogo} className='cstm-logo' />
                                </div>

                                <h4>Reset Password</h4>

                                <form className="login-form mt-4">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="mb-4">
                                                <label htmlFor='currentpassword' className="cstm-label">Current Password</label>
                                                <input
                                                    onChange={handleOnChange}
                                                    value={password.currentpassword}
                                                    type={showPassword ? "text" : "password"} className="cstm-input" placeholder="Enter your current password"
                                                    name="currentpassword" />
                                                <span className="error-message"> {error.currentpassword} </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="mb-4">
                                                <label htmlFor='newPassword' className="cstm-label">New Password</label>
                                                <input
                                                    onChange={handleOnChange}
                                                    value={password.newPassword}
                                                    type={showPassword ? "text" : "password"} className="cstm-input" placeholder="Enter your new password"
                                                    name="newPassword" />
                                                <span className="error-message"> {error.newPassword} </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="mb-4">
                                                <label htmlFor='confirmPassword' className="cstm-label">Confirm Password</label>
                                                <input onChange={handleOnChange} value={password.confirmPassword} type={showPassword ? "text" : "password"} className="cstm-input" placeholder="Enter your confirm password " name="confirmPassword" />
                                                <span className="error-message"> {error.confirmPassword} </span>
                                            </div>
                                        </div>
                                        <span className="error-message"> {error.matchError} </span>
                                        <div className="col-lg-12  mb-4">
                                            <div className="d-flex justify-content-between">
                                                <div className="cstm-remeber-me">
                                                    <input type='checkbox' id="rememberMe" onChange={onClickShowPassword} />
                                                    <label className='remeber-label' htmlFor="rememberMe" >Show Password</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="d-grid">
                                                <button disabled={loader ? true : false} onClick={handleSubmit} className="cstm-btn1">Reset Password</button>
                                                {loader &&
                                                    <div className="btn-loader-main login-btn-div">
                                                        <img className="btn-loaderImg login-img" src={loaderimg} /></div>
                                                }
                                            </div>
                                        </div>
                                        <Link className='cstm-back' to={-1}>Back</Link>
                                    </div>
                                    <Snackbar
                                        open={openSnackbar}
                                        onClose={handleClose}
                                        autoHideDuration={5000}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    >
                                        <Alert variant='filled' onClose={handleClose} severity='success'>{message}</Alert>
                                    </Snackbar>
                                    <Snackbar
                                        open={errorSnackbar}
                                        onClose={handleClose}
                                        autoHideDuration={5000}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    >
                                        <Alert variant='filled' onClose={handleClose} severity='error'>{message}</Alert>
                                    </Snackbar>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ResetPassword;