import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import adminService from '../../../services/admin.service';
import Loader from '../../loader/Loader';
import moment from 'moment';
import Pagination from '../../pagination/Pagination';
import editIcon from '../../../assets/images/Edit.svg'
import Deleteicon from '../../../assets/images/delete.svg'
import { Alert, Snackbar } from '@mui/material';
import Addbutton from '../../buttons/AddButton';
import DeleteModel from '../../../assets/images/deleteModel.svg'
import loaderimg from '../../../assets/images/loder2.gif'
const Features = () => {

    //listing api data
    const [featureListing, setFeatureListing] = useState([])
    const [initialData, setInitialData] = useState([])

    //search input
    const [searchValue, setSearchValue] = useState('');

    //loader
    const [loader, setLoader] = useState(false)
    const [dataLoader, setDataLoader] = useState(false)
    const [popBtnLoader, setPopBtnLoader] = useState(false)

    //toast
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [errorSnackbar, setErorSnackbar] = useState(false)

    //api response message
    const [message, setMessage] = useState('')

    //feature id to delete, update and view
    const [featureId, setFeatureId] = useState('')

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 20
    const excludeColumns = ["_id ", "date"];


    //view and add and update
    const [addFeature, setAddFeature] = useState({
        name: ''
    })
    const [editFeature, setEditFeature] = useState({
        name: ''
    })
    const [error, setError] = useState({
        name: ''
    })


    useEffect(() => {
        setEditFeature({ name: '' })
        setLoader(true)
        featureListingApi()
    }, [])

    //creat data to display in table and filter
    var DataArray = [];
    function createData(_id, features, status, date) {
        return {
            _id,
            features,
            status,
            date
        };
    }
    //listing api
    async function featureListingApi() {
        try {
            const response = await adminService.featureListingService()
            if (response.status === 200) {
                var arr = response.data.data
                if (response.data.data.length > 0) {
                    var newArr = []

                    for (var i = 0; i < arr.length; i++) {
                        var feature = arr[i].name
                        var status = arr[i].status === true ? 'Active' : 'Inactive'
                        var date = moment(arr[i].createdAt).format('DD MMM, YYYY')

                        newArr[i] = createData(
                            arr[i]._id,
                            feature,
                            status,
                            date
                        )
                    }
                    newArr.map((data1) => {
                        DataArray = [...DataArray, data1]
                    })
                    DataArray = DataArray.sort().reverse()
                    setInitialData(DataArray)
                    if (searchValue !== "" && searchValue !== undefined && searchValue !== null) {

                        var filterData = DataArray.filter((item) => {
                            return Object.keys(item).some(key =>
                                excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
                            );
                        });

                        setFeatureListing(filterData)
                        setLoader(false)

                    } else {
                        setFeatureListing(DataArray)
                        setLoader(false)

                    }
                }
            }
        } catch (error) {
            setErorSnackbar(true)
            setLoader(false)
        }
    }

    //add api
    async function featureAddApi() {
        const bodyData = {
            name: addFeature.name
        }
        try {
            const response = await adminService.featureAddService(bodyData)
            if (response.status === 200) {
                setPopBtnLoader(false)
                setLoader(true)
                setOpenSnackbar(true)
                setMessage(response.data.message)
                document.querySelector('#Addfeature .btn-close').click()
                featureListingApi()
                setAddFeature({ name: '' })
            }
        } catch (error) {
            setErorSnackbar(true)
            setLoader(false)
        }
    }

    //view api
    async function featureViewApi(id) {
        try {
            const response = await adminService.featureViewService(id)
            if (response.status === 200) {
                setEditFeature(response.data.data)
                setDataLoader(false)
                setLoader(false)
            }
        } catch (error) {
            setErorSnackbar(true)
            setLoader(false)
        }
    }

    //edit api
    async function featureEditApi() {
        const bodyData = {
            name: editFeature.name
        }
        try {
            const response = await adminService.featureEditService(featureId, bodyData)
            if (response.status === 200) {
                setOpenSnackbar(true)
                setPopBtnLoader(false)
                setMessage(response.data.message)
                featureListingApi()
                setLoader(true)
                document.querySelector('#Editfeature .btn-close').click()
            }
        } catch (error) {
            setErorSnackbar(true)
            setLoader(false)
        }
    }

    //delete api
    async function featureDeleteApi() {
        try {
            const response = await adminService.featureDeleteService(featureId)
            if (response.status === 200) {
                setOpenSnackbar(true)
                setPopBtnLoader(false)
                setLoader(true)
                setMessage(response.data.message)
                document.querySelector('#Deletefeature .btn-close').click()
                featureListingApi()
            }
        } catch (error) {
            setErorSnackbar(true)
            setMessage(error.response.data.message)
            setLoader(false)
            setPopBtnLoader(false)
            document.querySelector('#Deletefeature .btn-close').click()
        }
    }

    //validation
    const validate = (featureData) => {
        let featureError = {}
        let isValid = false
        if (!featureData.name) {
            isValid = true
            featureError.name = 'Please enter the feature name.'
        }
        setError(featureError)
        return isValid
    }

    //handle add feature
    const handleAddFeature = (e) => {
        e.preventDefault()
        if (addFeature.name.trim() !== "") {
            setError({ ...error, name: "" })
            if (!validate(addFeature)) {
                setPopBtnLoader(true)
                featureAddApi()
            }
        } else {
            setError({ ...error, name: 'Please enter the feature name.' })
        }
    }

    //handle add onchange feature
    const handleAddOnchange = (e) => {
        const { name, value } = e.target
        setAddFeature({ ...addFeature, [name]: value })
        switch (name) {
            case "name":
                value.trim() === '' ? setError({ ...error, name: 'Please enter the feature name.' }) : setError({ ...error, name: '' })
            default:
                break
        }
    }

    //handle edit feature
    const handleEditFeature = (e) => {
        e.preventDefault()
        if (editFeature.name.trim() !== "") {
            setError({ ...error, name: "" })
            if (!validate(editFeature)) {
                setPopBtnLoader(true)
                featureEditApi()
            }
        } else {
            setError({ ...error, name: "Please enter the feature name." })
        }
    }

    // handle edit onchange feature
    const handleEditOnchange = (e) => {
        const { name, value } = e.target
        setEditFeature({ ...editFeature, [name]: value })
        switch (name) {
            case "name":
                value.trim() === '' ? setError({ ...error, name: "Please enter the feature name." }) : setError({ ...error, name: '' })
            default:
                break
        }
    }

    //handle delete feature
    const handleDelete = (e) => {
        featureDeleteApi()
        setPopBtnLoader(true)
    }

    //handle get id
    const getId = (e, id) => {
        setDataLoader(true)
        setFeatureId(id)
        featureViewApi(id)
    }

    //handle search filter
    const onChangeFilter = (searchValue) => {
        setSearchValue(searchValue)
        if (searchValue !== '') {
            const filteredData = initialData.filter((item) => {
                return Object.keys(item).some(key =>
                    excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
                );
            })
            setFeatureListing(filteredData)
        } else {
            setFeatureListing(initialData)
        }
    }

    //pagination
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastRecord = currentPage * recordPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
    const currentRecords = featureListing.slice(indexOfFirstRecord, indexOfLastRecord);

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(featureListing.length / recordPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    //handle close toast
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErorSnackbar(false)
        setOpenSnackbar(false);
    };

    return (
        <>
            <div className="page-wrapper doctris-theme toggled">
                <main className="page-content">
                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="mb-0">Features</h4>
                                {/* <div className="cstm-bre uppercase">dashboard YEAR LONG COURSE <Link to="/topic">TOPICS</Link></div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card rounded-md border min-height-sc">
                                        <div className="d-flex justify-content-between align-items-center p-3 border-top">
                                            <div className="col-md-10">
                                                <i className="fi fi-br-search cstm-search-ro"></i>
                                                <input name="name" id="name" onChange={(e) => onChangeFilter(e.target.value)}
                                                    type="text" className="cstm-input-seacrh" placeholder="Search Features" />
                                            </div>
                                            <div>
                                                <Link className="cstm-btn cstm-btn-text" data-bs-toggle="modal" data-bs-target="#Addfeature">Add Features</Link>
                                            </div>
                                            <div className="modal fade" id="Addfeature" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header border-0 p-4">
                                                            <h4 className="modal-title" id="exampleModalLabel1">Add Feature</h4>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body p-4 pt-0">
                                                            <div className="mb-3">
                                                                <label className="cstm-label">Feature<span className="cstm-star">*</span></label>
                                                                <input type="text" className="cstm-input" placeholder="Enter feature" value={addFeature.name} name="name" onChange={handleAddOnchange} required="" />
                                                                <span className='error-message'>{error.name}</span>
                                                            </div>


                                                            <div className="text-center cstn-loader-sec cstn-loader-left ">
                                                                <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleAddFeature}>Submit</button>
                                                                {popBtnLoader &&
                                                                    <div className='loader-container-cstm'>
                                                                        <img className='btn-pop-loader' src={loaderimg} />
                                                                    </div>
                                                                }
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-lg-12">
                                            <div className="table-responsive bg-white rounded">
                                                <table className="table mb-0 table-center">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-top w-4">No.</th>
                                                            <th className="border-top w-12">Features</th>
                                                            <th className="border-top w-15">Status</th>
                                                            <th className="border-top w-10">Date</th>
                                                            <th className="border-top w-11">Action</th>
                                                        </tr>
                                                    </thead>
                                                    {loader ?
                                                        <div className='loader-container'>
                                                            <Loader />
                                                        </div>
                                                        :
                                                        (featureListing.length === 0 ?
                                                            <span className="cstm-norecord">No Record Found</span>
                                                            :
                                                            (currentRecords.map((item, i) => (
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="fw-bold">{i + 1}</td>
                                                                        <td>{item.features}</td>
                                                                        <td>{item.status}</td>
                                                                        <td>{item.date}</td>
                                                                        <td>
                                                                            <Link onClick={(e) => getId(e, item._id)} className="cstm-btn6 action-btn" data-bs-toggle="modal" data-bs-target="#Editfeature">
                                                                                <i><img src={editIcon} /></i>
                                                                            </Link>
                                                                            <div className="modal fade" id="Editfeature" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered">
                                                                                    <div className="modal-content">
                                                                                        {dataLoader ?
                                                                                            <div className='model-loader'>
                                                                                                <Loader /> </div> :
                                                                                            <>
                                                                                                <div className="modal-header border-0 p-4">
                                                                                                    <h4 className="modal-title" id="exampleModalLabel1">Edit Feature</h4>
                                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                                                </div>
                                                                                                <div className="modal-body p-4 pt-0">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="cstm-label">Tool Name<span className="cstm-star">*</span></label>
                                                                                                        <input type="text" className="cstm-input" placeholder="Enter feature" value={editFeature.name} onChange={handleEditOnchange} name="name" required="" />
                                                                                                        <span className='error-message'>{error.name}</span>
                                                                                                    </div>
                                                                                                    <div className="text-center cstn-loader-sec cstn-loader-left ">
                                                                                                        <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleEditFeature}>Submit</button>
                                                                                                        {popBtnLoader &&
                                                                                                            <div className='loader-container-cstm'>
                                                                                                                <img className='btn-pop-loader' src={loaderimg} />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>


                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <Link className="cstm-btn6" onClick={(e) => getId(e, item._id)} data-bs-toggle="modal" data-bs-target="#Deletefeature">
                                                                                <i><img src={Deleteicon} /></i>
                                                                            </Link>
                                                                            <div className="modal fade cstm-add-bonus" id="Deletefeature" tabIndex="-1" aria-labelledby="exampleModalLabel1"
                                                                                aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered">
                                                                                    <div className="modal-content">
                                                                                        <div className="modal-header border-0 p-4">
                                                                                            <button type="button" className="btn-close" id='modla-close' data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        </div>
                                                                                        <div className="modal-body p-4 pt-0">
                                                                                            <div className="mb-4">

                                                                                                <div className="text-center mb-4">
                                                                                                    <img src={DeleteModel} />
                                                                                                </div>
                                                                                                <h4 className="text-center mb-4">Are you sure you want to delete feature?</h4>

                                                                                                <div className="text-center cstn-loader-sec ">
                                                                                                    <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleDelete}>Delete Feature</button>
                                                                                                    {popBtnLoader &&
                                                                                                        <div className='loader-container-cstm'>
                                                                                                            <img className='btn-pop-loader' src={loaderimg} />
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>

                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            ))
                                                            )
                                                        )
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Success Toast */}
                            <Snackbar
                                open={openSnackbar}
                                onClose={handleClose}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert variant='filled' onClose={handleClose} severity='success'>{message}</Alert>
                            </Snackbar>
                            {/* Error Toast */}
                            <Snackbar
                                open={errorSnackbar}
                                onClose={handleClose}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert variant='filled' onClose={handleClose} severity='error'>{message}</Alert>
                            </Snackbar>
                            {/* Pagination */}
                            <Pagination
                                recordPerPage={recordPerPage}
                                totalRecord={featureListing.length}
                                paginate={paginate}
                                currentPage={currentPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                            />
                        </div>
                    </div>

                </main>
            </div>
        </>
    )
}

export default Features;