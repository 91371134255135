import React, { useEffect, useState } from "react";
// CSS
import "./Footer.css"
// images
import aiLogo from "../../../assets/images/frontend/ai-logo.svg";
import linkedIn from "../../../assets/images/frontend/linkedin.svg";
import Twitter from "../../../assets/images/frontend/twitter.svg";
import youTube from "../../../assets/images/frontend/youtube.svg";
import frontendService from "../../../services/frontend.service";
import { Link } from "react-router-dom";

const Footer = () => {
  //category state
  const [categoryData, setCategoryData] = useState([])
  const data = [
    { categoryData: "Productivity", slug: "productivity" },
    { categoryData: "Copywriting", slug: "copywriting" },
    { categoryData: "Image Generator", slug: "image-generator" },
    { categoryData: "Text to Speech", slug: "text-to-speech" },
    { categoryData: "Marketing", slug: "marketing" },
  ]

  //category api calling
  useEffect(() => {
    categoryDataApi()
  }, [])

  // create category api function
  async function categoryDataApi() {
    try {
      const response = await frontendService.footerSubCategoryGetService()
      if (response.status === 200) {
        let data = (response.data.data).sort().reverse().slice(0, 5)
        setCategoryData(data)
      }
    } catch (error) {
    }
  }
  return (
    <section className="ai-footer">
      <div className="ai-container">
        <div className="row">
          <div className="col-lg-5 col-xl-6">
            <div className="ai-footer-brand">
              <Link>
                <img src={aiLogo} alt="" className="ai-logo-image" />
              </Link>
              <p className="ai-p-18-28">
                Discover the power of AI with AIToolSpot - your go-to platform
                for discovering, learning, and sharing the latest AI tools and
                software.
              </p>

              <div className="social-icons">
                <Link target="_blank" to='https://www.linkedin.com/company/aitoolspot/'>
                  <img src={linkedIn} alt="" />
                </Link>
                <Link target="_blank" to='https://twitter.com/aitoolspot'>
                  <img src={Twitter} alt="" />
                </Link>
                <Link target="_blank" to='https://www.youtube.com/@aitoolspot'>
                  <img src={youTube} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-xl-6">
            <div className="row">
              <div className="col-6 col-md-4 ">
                <div className="footer-links">
                  <h3 className="ai-h3">Categories</h3>
                  {data.map((item, i) => (
                    <ul >
                      <li key={i}>
                        <Link target="_blank" to={"/ai-tools/" + item.slug}>{item.categoryData}</Link>
                      </li>
                    </ul>
                  ))}
                  {/* {categoryData.map((item, i) => (
                    <ul >
                      <li key={i}>
                        <Link target="_blank" to={"/ai-tools/" + item.slug}>{item.subCategoryName}</Link>
                      </li>
                    </ul>
                  ))} */}
                </div>
              </div>
              <div className="col-6 col-md-4 ">
                <div className="footer-links">
                  <h3 className="ai-h3">Explore</h3>
                  <ul>
                    <li>
                      <Link to='/favourites'>Favourites</Link>
                    </li>
                    <li>
                      <Link to='/submit-tool'>Submit A Tool</Link>
                    </li>
                    <li>
                      <Link to='/blog'>Blog</Link>
                    </li>
                    <li>
                      <Link to="/categories">All Categories</Link>
                    </li>
                    <li>
                      <Link to="/sitemap">Sitemap</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-md-4 ">
                <div className="footer-links">
                  <h3 className="ai-h3">Support</h3>
                  <ul>
                    <li>
                      <Link to='/sponsorship'>Sponsor Us</Link>
                    </li>
                    <li>
                      <a href="mailto:info@aitoolspot.com">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ai-copy-right">
        <div className="ai-container">
          <div className="ai-copy-right-sec">
            <div className="ai-copy">
              <p className="ai-p-15">Copyright 2023 - AIToolSpot</p>
            </div>
            <div className="ai-privacy">
              <Link to='/privacy-policy'>
                Privacy Policy
              </Link>
              <Link to='/terms-and-conditions'>
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
