import React, { useEffect, useState } from "react";
import "./NewsLetter.css";
import axios from "axios";
const NewsLetter = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({
    email: "",
  });
  const [success, setSuccess] = useState(false);
  const emailRegex = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  //api key for Authorization that we use for subscription
  const API_KEY = `Bearer ${"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNWMyZDg3N2VhYjJmOThjNjNkMDZkYjlhYWZjOWZiODRhZTAxNmNlNzE4YTM1YTcyMDY0YTc4ZjA4MjU5ZWQwMDI1MTkwZGFiZGYxOWUyMDYiLCJpYXQiOjE2ODY4MjY4MTQuNDcxODUsIm5iZiI6MTY4NjgyNjgxNC40NzE4NTQsImV4cCI6NDg0MjUwMDQxNC40NjQwNTksInN1YiI6IjQ4NTQ1OCIsInNjb3BlcyI6W119.As6ry-omdoPYE_gj3yNJtVKPJd9BUy4XKUXp1C4EHBpuoNLsUKUMBl-1gHdkcgIcGgRLQvBeSK0mj6GpE8oWUzUdqdD3VgkhdHSVEB061ZEnWKwg-BLXHMjCg2Op68uPrDlFyVPGnD_iBTrvK8EZj1g8hMiC0vfTEAx19mVNMyT336HJai4uk10Sttg-MbXyFI3OsPZi05HiQPnFAFcvQKQoOls8tOyfArA7eBcDN1L5RwEOG1OyOE9A4SedsaMRrsZcDz6f6pwMwpBneFfY5I-6dOPE-Chq_6ZPGSFlc3YB056ipuGOw6F2QPNyFaHZyp1d5nyQPUUFjeqsRO4gA3ZfrgW_kjJPKkJ-zpsWAfRWwW2V5qPfR__B9n-OY44bMBZElh_2AT7qw6NLmE5kV28jMKTEd6tVuP8nIOKuaFMWkJPleWKpleJNkHnEJuvfsYAeavU0-D2sXFYw_tmdN1mCOmPWQush19cBRcOc1ICgCehgZ_Jf--RUBXRNzQFIZoMdKwATlE9kpCMwJdj_JRXrt2T4u5prLIRCT7GmmwC_PA0iGjITkc0mlrMS9pbwEpyu0IkeX89MpHXjInbwymKVTdcKzzeZOXTD-MXPQ0rDPJAb6-hkQyhsoE8-7sYxZvH0acGCSN1Y_EK-yVadMqGFoM8Qx0X_e9Iv9ARWq4o"}`;
  //handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.trim() === "") {
      setError({ ...error, email: "Please enter email." });
    } else if (!emailRegex.test(email)) {
      setError({ ...error, email: "Please enter valid email." });
    } else {
      setError({ ...error, email: "" });
      //when user subscribe this api will call if user subscribe first time we get status code 201 where subscriber will create if user is already subscriber then we get status code 200
      const response = await axios.post(
        "https://connect.mailerlite.com/api/subscribers",
        {
          email,
        },
        {
          headers: {
            Authorization: API_KEY,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      try {
        if (response.status) {
          setSuccess(true);
        }
      } catch (error) { }
      //after successful use subscribe user will add in group for newsletter and user receive confirmation mail
      try {
        await axios.post(
          `https://connect.mailerlite.com/api/subscribers/${response.data.data.id}/groups/89413673658877287`,
          {},
          {
            headers: {
              Authorization: API_KEY,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
      } catch (error) { }
    }
  };
  //handle onchange for email
  const handleOnChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value.trim() === "") {
      setError({ ...error, email: "Please enter email." });
    } else if (!emailRegex.test(e.target.value)) {
      setError({ ...error, email: "Please enter valid email." });
    } else {
      setError({ ...error, email: "" });
    }
  };
  return (
    <>
      <div className="col-sm-6 col-lg-12">
        <div className="row ai-subscribe-main">
          <div className="col-lg-8">
            <h4 className="newsletter-text">Discover Cutting-Edge AI Tools.</h4>
            <p>
              Subscribe to our free weekly newsletter now and stay informed about the latest AI tools and useful insights.
            </p>
          </div>
          <div className="col-lg-4">
            {!success && (
              <>
                <p>
                  <input
                    onChange={handleOnChange}
                    type="email"
                    value={email}
                    placeholder="Email"
                    className="ai-subscribe-field"
                  />
                  <span className="error-message">{error.email}</span>
                </p>
                <p>
                  <button
                    type="submit"
                    className="ai-subscribe-btn"
                    onClick={handleSubmit}
                  >
                    Subscribe
                  </button>
                </p>
                <p className="ai-unsubscribe-msg">Unsubscribe at any time.</p>
              </>
            )}
            {success && (
              <>
                <p>Congratulations! You have successfully signed up for the AIToolSpot newsletter.</p>
                <p>Check your inbox for confirmation email.</p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsLetter;
