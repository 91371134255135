import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import adminService, { bucketUrl } from '../../services/admin.service'
import { Editor } from 'react-draft-wysiwyg'
import toolbar from '../../config/toolbar'
import localization from '../../config/localization'
import htmlToDraft from 'html-to-draftjs'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { useDropzone } from 'react-dropzone'
import AddIcon from '@mui/icons-material/Add';
import { Alert, Snackbar } from '@mui/material'
import loaderimg from '../../assets/images/loder2.gif'
import slugify from 'slugify';

const Editblog = () => {

  const [categoryData, setcategoryData] = useState([])
  const [message, setMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [loader, setLoader] = useState(false)
  const [viewBlogData, setViewBlogData] = useState({
    blogCategoryId: "",
    longDesc: "",
    imageUrl: "",
    shortDesc: "",
    title: "",
    slug: "",
    metaTitle: "",
    metaDesc: ""
  })
  const [content, setContent] = useState(EditorState.createEmpty());
  const [image, setImage] = useState([])
  const [contenthtml, setContenthtml] = useState();
  const [files, setFiles] = useState();
  const [hideimagePrev, setHideimagePrev] = useState();
  const [error, setError] = useState({
    blogCategoryId: "",
    longDesc: "",
    imageUrl: "",
    shortDesc: "",
    title: "",
    slug: ""
  })

  const [faq, setFaq] = useState([{
    que: "",
    ans: ""
  }])
  const [faqErrors, setFaqErrors] = useState([]);
  const [showFaq, setShowFaq] = useState(false)

  const [contentDis, setContentDis] = useState("")

  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    categoryApi()
    viewBlogApi()
  }, [])

  useEffect(() => {
    if (faq.length === 0) {
      setShowFaq(false)
    }
  }, [faq])
  async function categoryApi() {
    try {
      const response = await adminService.blogCategoryService()
      if (response.status === 200) {
        setcategoryData(response.data.data)
      }
    } catch (error) {

    }
  }

  async function viewBlogApi() {
    try {
      const response = await adminService.blogViewService(id)
      if (response.status === 200) {
        setViewBlogData(response.data.data)
        setFiles(response.data.data.imageUrl)
        setContentDis(response.data.data.longDesc)
        const contentBlock = htmlToDraft(response.data.data.longDesc);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setContent(editorState)
        setShowFaq(response.data.data.isFAQ)
        setFaq(response.data.data.FAQ)
      }
    } catch (error) {

    }
  }
  async function editBlogApi() {
    const formData = new FormData()
    formData.append('title', viewBlogData.title)
    formData.append('blogCategoryId', viewBlogData.blogCategoryId)
    formData.append('shortDesc', viewBlogData.shortDesc)
    formData.append('longDesc', contenthtml !== undefined ? contenthtml : contentDis)
    formData.append('imageUrl', image.length === 0 ? files : image)
    formData.append('isFAQ', showFaq);
    formData.append('slug', viewBlogData.slug)
    formData.append('metaTitle', viewBlogData.metaTitle)
    formData.append('metaDesc', viewBlogData.metaDesc)
    const excludedKey = '_id';
    faq.map((object, index) => {
      const filteredObject = Object.entries(object).reduce((acc, [key, value]) => {
        if (key !== excludedKey) {
          acc[key] = value;
        }
        return acc;
      }, {});
      Object.entries(filteredObject).forEach(([key, value]) => {
        formData.append(`FAQ[${index}][${key}]`, value);
      });
    })
    try {
      const response = await adminService.blogEditService(id, formData)
      if (response.status === 200) {
        setOpenSnackbar(true)
        setLoader(false)
        setMessage(response.data.message)
        setTimeout(() => {
          navigate('/admin/blog')
        }, 2000)
      }
    } catch (error) {

    }
  }
  const validate = () => {
    let blogError = {}
    let isValid = false
    if (viewBlogData.title.trim()==="") {
      isValid = true
      blogError.title = "Please enter blog title."
    }
    if (!viewBlogData.blogCategoryId) {
      isValid = true
      blogError.blogCategoryId = "Please select blog category."
    }
    if (files.length === 0) {
      isValid = true
      blogError.blogImg = "Please Select the feature image."
    }
    setError(blogError)
    return isValid
  }

  const handleEditorStateonChange = (editorState) => {
    setContenthtml(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    setContent(editorState)
  }

  const handleonClickEdit = (e) => {
    e.preventDefault()
    let isValid = true;

    if (showFaq) {
      // Validate question and answer fields in FAQ section
      for (let i = 0; i < faq.length; i++) {
        const { que, ans } = faq[i];
        if (que.trim() === '') {
          setFaqErrors(prevErrors => {
            const updatedErrors = [...prevErrors];
            updatedErrors[i] = {
              ...updatedErrors[i],
              que: 'Please enter the question.'
            };
            return updatedErrors;
          });
          isValid = false;
        }
        if (ans.trim() === '') {
          setFaqErrors(prevErrors => {
            const updatedErrors = [...prevErrors];
            updatedErrors[i] = {
              ...updatedErrors[i],
              ans: 'Please enter the answer.'
            };
            return updatedErrors;
          });
          isValid = false;
        }
      }
    }

    if (!validate(viewBlogData) && isValid) {
      setLoader(true)
      editBlogApi()
    }
  }

  const handleEditOnchange = (e) => {
    const { name, value } = e.target
    setViewBlogData({ ...viewBlogData, [name]: value })
    switch (name) {
      case "title":
        value.trim() === "" ? setError({ ...error, title: "Please enter blog title.", slug: "Please enter slug." }) : setError({ ...error, title: "", slug: "" })
        break
      case 'blogCategoryId':
        value.trim() === '' ? setError({ ...error, blogCategoryId: "Please select blog category." }) : setError({ ...error, blogCategoryId: "" })
      default:
        break
    }
  }


  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    multiple: false,
    onDrop: e => {
      setHideimagePrev(!hideimagePrev)
      setFiles(URL.createObjectURL(e[0]))
      setImage(e[0])
    }
  });
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  //handle change for slug
  const handleSlugChange = (e) => {
    const {name, value } = e.target
    const newValue = slugify(value, { lower: true, strict: true });
    setViewBlogData({ ...viewBlogData, slug: newValue })
    switch (name) {
      case "slug":
        value.trim() === "" ? setError({ ...error, slug: "Please enter slug." }) : setError({ ...error, slug: "" })
        break
      default:
        break
    }
  }

  //to add more faq
  const handleAddFaq = () => {
    setFaq([...faq, { que: '', ans: '' }])
  }
  //to remove faq
  const handleRemoveFaq = (e, i) => {
    const removeFaq = faq.filter((item, index) => index !== i)
    setFaq(removeFaq)
  }
  //handle change for faq
  const handleOnchangeFaq = (e, i) => {
    const { name, value } = e.target
    setFaq(prevFaq => {
      const updatedFaq = [...prevFaq];
      updatedFaq[i] = {
        ...updatedFaq[i],
        [name]: value
      };
      return updatedFaq;
    });

    setFaqErrors(prevErrors => {
      const updatedErrors = [...prevErrors];
      switch (name) {
        case 'que':
          updatedErrors[i] = {
            ...updatedErrors[i],
            [name]: value.trim() === '' ? 'Please enter the question.' : ''
          };
          break;
        case 'ans':
          updatedErrors[i] = {
            ...updatedErrors[i],
            [name]: value.trim() === '' ? 'Please enter the answer.' : ''
          };
          break;
        default:
          break;
      }
      return updatedErrors;
    });
  }


  return (
    <>
      <div className="page-wrapper doctris-theme toggled">
        <main className="page-content bg-light">
          <div className="container-fluid">
            <div className="layout-specing">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="mb-0">Edit Blog</h4>
                {/* <div className="cstm-bre uppercase">Dashboard > <a href="#">ADD subscription </a></div> */}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card  rounded-md  border min-height-sc add-edit-page">
                    <div className="py-2 px-3">
                      {/* <h5 className="mb-3">Add Tool</h5> */}
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Blog Title<span className='cstm-astix'>*</span></label>
                            <input type="text" value={viewBlogData.title} onChange={handleEditOnchange} className="cstm-input" placeholder="Enter blog bame"
                              name="title" required="" />
                            <span className='error-message'>{error.title}</span>
                          </div>
                        </div>
                          {/* slug */}
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Slug<span className='cstm-astix'>*</span></label>
                            <input type="text" value={viewBlogData.slug} className="cstm-input" placeholder="Enter your blog name"
                              name="slug" onChange={handleSlugChange} required=""/>
                              <span className='error-message'>{error.slug}</span>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Blog Category<span className='cstm-astix'>*</span></label>
                            <select className="cstm-select" value={viewBlogData.blogCategoryId} onChange={handleEditOnchange} name="blogCategoryId" id="City">
                              <option value="">Select category</option>
                              {categoryData.map((item) => (
                                <option value={item._id} key={item._id}>{item.name}</option>
                              ))}
                            </select>
                            <span className='error-message'>{error.blogCategoryId}</span>
                          </div>

                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Short Description</label>
                            <input type="text" value={viewBlogData.shortDesc} onChange={handleEditOnchange} className="cstm-input" placeholder="Enter short description"
                              name="shortDesc" required="" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Meta Title</label>
                            <input type="text" onChange={handleEditOnchange} value={viewBlogData.metaTitle} className="cstm-input" placeholder="Enter meta title"
                              name="metaTitle" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Meta Description</label>
                            <input type="text" onChange={handleEditOnchange} value={viewBlogData.metaDesc} className="cstm-input" placeholder="Enter meta description"
                              name="metaDesc" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Long Description</label>
                            <Editor
                              editorState={content}
                              onEditorStateChange={handleEditorStateonChange}
                              placeholder='Enter long description'
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              toolbar={toolbar}
                              localization={localization}
                            />
                          </div>
                        </div>

                        <div className='cust-isverfied'>
                          <input type='checkbox' checked={showFaq} onClick={() => setShowFaq(!showFaq)} /> <label>Enable FAQ</label>
                        </div>
                        {showFaq &&
                          <>
                            <div className='add-faq-button'>
                              <button onClick={handleAddFaq}>Add FAQ</button>
                            </div>
                            {faq.map((item, i) => (
                              <>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label className="cstm-label">Question<span className='cstm-astix'>*</span></label>
                                    <input type="text" className="cstm-input" onChange={(e) => handleOnchangeFaq(e, i)} placeholder="Enter your question"
                                      value={item.que} name="que" required="" />
                                    <span className='error-message'>{faqErrors[i]?.que}</span>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label className="cstm-label">Answer<span className='cstm-astix'>*</span></label>
                                    <input type="text" className="cstm-input" placeholder="Enter your answer" onChange={(e) => handleOnchangeFaq(e, i)}
                                      value={item.ans} name="ans" required="" />
                                    <span className='error-message'>{faqErrors[i]?.ans}</span>
                                  </div>
                                </div>
                                <div className='remove-faq-button'>
                                  <button onClick={(e) => handleRemoveFaq(e, i)}>Remove FAQ</button>
                                </div>
                              </>
                            ))}
                          </>
                        }


                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="cstm-label">Feature image<span className='cstm-astix'>*</span></label>
                            <div {...getRootProps({ className: 'dropzone blog-dropzone' })}>
                              <input {...getInputProps()} />
                              <p> <span> <AddIcon /></span>  Drop your image here, or <span> browse</span></p>
                            </div>
                            {files && !hideimagePrev ? (
                              <>
                                <div className='col-1'>
                                  <div className='ai-uploaded-container'>
                                    <img
                                      className='ai-uploaded-items'
                                      src={bucketUrl + files}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='col-1'>
                                  <div className='ai-uploaded-container'>
                                    <img className='ai-uploaded-items' src={files ? files : ''} />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <span className='error-message'>{error.imageUrl || fileRejectionItems}</span>
                        </div>
                        <div className="pb-3 mt-2 button-div">

                          <div className="text-center cstn-loader-sec">
                            <button disabled={loader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleonClickEdit}>Submit</button>
                            {loader &&
                              <div className='loader-container-cstm'>
                                <img className='btn-pop-loader' src={loaderimg} />
                              </div>
                            }
                          </div>
                          <button className="cstm-btn2 cstm-btn-text-bordered" onClick={() => navigate("/admin/blog")}>Cancel</button>
                        </div>
                      </div>
                    </div>
                    {/* Success Toast */}
                    <Snackbar
                      open={openSnackbar}
                      onClose={handleClose}
                      autoHideDuration={3000}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                      <Alert variant='filled' onClose={handleClose} severity='success'>{message}</Alert>
                    </Snackbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default Editblog