import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../../assets/images/Logo.png'
import { useDispatch, useSelector } from 'react-redux'
import adminService from '../../../services/admin.service'
import loaderimg from '../../../assets/images/loder2.gif'
import { Alert, Snackbar } from '@mui/material'
import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'

const Login = () => {

  //dispatch state to redux
  const dispatch = useDispatch()

  //selector state that took from redux
  const { isLoggedIn } = useSelector(state => state.auth)

  //data state
  const [loginData, setLoginData] = useState({
    Email: '',
    password: ''
  })
  const [cookieData, setCookieData] = useState({
    Email: Cookies.get("email") || ""
  })
  const [error, setError] = useState({
    Email: '',
    password: ''
  })

  //loader state
  const [loader, setLoader] = useState(false)

  //toast
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [success, setsuccess] = useState(false)
  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState()
  const navigate = useNavigate()

  //remember me 
  var email_remember = Cookies.get("email");

  //email regex
  const emailRegex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

  //first render
  useEffect(() => {
    if (email_remember !== undefined && email_remember !== "") {
      setChecked(true)
      setLoginData({
        Email: email_remember,
      })
    } else {
      setChecked(false)
    }

    isLoggedIn && navigate('/admin/dashboard')
  }, [])

  //onChange remember
  const onChangeRememberMe = (e) => {
    setChecked(e.target.checked)
  }

  //valdation function
  const validate = (loginData) => {
    let formErrors = {}
    let isValid = false

    if (!loginData.password) {
      isValid = true
      formErrors.password = "Please enter eassword";
    }
    if (!loginData.Email) {
      isValid = true
      formErrors.Email = 'Please enter email';
    } else if (!emailRegex.test(loginData.Email)) {
      isValid = true
      formErrors.Email = "Please enter valid email"
    } else {
      formErrors.Email = ''
    }
    setError(formErrors)
    return isValid
  }

  //handle onchnage
  const handleLoginChange = (e) => {
    const { name, value } = e.target
    setLoginData({ ...loginData, [name]: value })
    setCookieData({...cookieData,[name]:value})
    switch (name) {
      case "password":
        value === "" ? setError({ ...error, password: "Please enter eassword" }) : setError({ ...error, password: "" })
        break;
      case "Email":
        value === "" ? setError({ ...error, Email: "Please enter email" }) : !emailRegex.test(value) ? setError({ ...error, Email: "Please enter valid email" }) : setError({ ...error, Email: "" })
      default:
        break;
    }
  }

  //login api function
  async function authAdmin() {
    const bodyData = {
      email: loginData.Email,
      password: loginData.password
    }
    try {
      const response = await adminService.adminLogin(bodyData)
      if (response.status) {

        if (checked === true) {
          Cookies.set("email", loginData.Email, { expires: 1 });
        } else {
          Cookies.set("email", "");
        }
        setsuccess(true)
        setTimeout(() => {
          navigate('/admin/dashboard')
          localStorage.setItem("auth", response.data.data.admin.email)
          localStorage.setItem("token", response.data.data.token)
          localStorage.setItem("name", response.data.data.admin.name)
          localStorage.setItem("isLoggedIn", true)
          dispatch({ type: "LOGIN_SUCCESS", payload: response.data.data.admin.email })
        }, 1000)
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      setOpenSnackbar(true)
      setMessage(error.response.data.message)
    }
  }
  //login button onSubmit
  const handleLogin = (e) => {
    e.preventDefault()
    if (!validate(loginData)) {
      authAdmin()
      setLoader(true)
    }
  }

  //toast close function
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };
  return (
    <>
      <section className="bg-home d-flex bg-light align-items-center cstm-fm-all">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-8">
              <div className="card login-page bg-white border rounded-20">
                <div className="card-body">
                  <div className="text-center logo-container">
                    <Link>
                      <img src={logo} alt='app-logo' className='cstm-logo' />
                    </Link>
                  </div>
                  <h4>Sign in your account</h4>

                  <form className="login-form mt-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label htmlFor='Email' className="cstm-label">Email <span className="text-danger">*</span></label>
                          <input onChange={handleLoginChange} value={loginData.Email || cookieData.Email} type="email" className="cstm-input" placeholder="Enter your email address" name="Email" />
                          {error.Email && <span className="error-message"> {error.Email} </span>}
                        </div>

                      </div>

                      <div className="col-lg-12">
                        <div className="mb-3">
                          <label htmlFor='password' className="cstm-label">Password <span className="text-danger">*</span></label>
                          <input onChange={handleLoginChange} type="password" value={loginData.password} name='password' className="cstm-input" placeholder="Enter password" />
                          <span className="error-message"> {error.password} </span>
                        </div>
                      </div>

                      <div className="col-lg-12  mb-4">
                        <div className="d-flex justify-content-between cstm-remeber-me-container">
                          <div className="cstm-remeber-me">
                            <input type='checkbox' id="rememberMe" checked={checked} onChange={(e) => onChangeRememberMe(e)} />
                            <label className='remeber-label' htmlFor="rememberMe" >Remember me </label>

                          </div>
                          <Link to='/admin/forgot-password'>Forgot password?</Link>
                        </div>
                      </div>
                      <Snackbar
                        open={openSnackbar}
                        onClose={handleClose}
                        autoHideDuration={5000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      >
                        <Alert onClose={handleClose} variant='filled' severity='error'>{message}</Alert>
                      </Snackbar>
                      <Snackbar
                        open={success}
                        onClose={handleClose}
                        autoHideDuration={5000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      >
                        <Alert variant='filled' onClose={handleClose} severity='success'>Login Success</Alert>
                      </Snackbar>

                      <div className="col-lg-12">
                        <div className="d-grid">
                          <button disabled={loader ? true : false} onClick={handleLogin} className="cstm-btn login-ldr-btn">Sign In</button>
                          {loader &&
                            <div className="btn-loader-main login-btn-div">
                              <img className="btn-loaderImg login-img" src={loaderimg} /></div>
                          }
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login;