import React from 'react'

const Legalicon = () => {
  return (
   <>
    
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    className='ai-side-icons'
  >
    <path
      fill="#011367"
      d="M20.608 9.259v-2.43a1.47 1.47 0 0 0-1.22-1.46l-.91-.13a14.9 14.9 0 0 1-5.36-2 1.688 1.688 0 0 0-.41-.19 1.56 1.56 0 0 0-1.29.24 14.76 14.76 0 0 1-5.92 2 1.5 1.5 0 0 0-1.48 1.73v4.22c.001.485.038.97.11 1.45a8.88 8.88 0 0 0 4.2 6.33c1 .65 2.11 1.19 3.17 1.79a1.51 1.51 0 0 0 1.62 0l2.77-1.54a8.52 8.52 0 0 0 1.74-1.25 8.87 8.87 0 0 0 2.93-6c.07-.94.05-1.85.05-2.76Zm-1.32 2.67a7.548 7.548 0 0 1-3.4 5.81c-.93.63-2 1.1-2.92 1.69a1.08 1.08 0 0 1-1.31 0c-.87-.54-1.8-1-2.66-1.52a7.6 7.6 0 0 1-3.66-6c-.07-.71 0-1.43 0-2.15v-2.83c0-.25 0-.3.29-.33a16 16 0 0 0 6.52-2.23.36.36 0 0 1 .43 0c1.469.9 3.078 1.548 4.76 1.92.58.13 1.18.21 1.77.3.2 0 .26.12.26.31v2.4c-.04.87-.01 1.72-.08 2.63Z"
    />
    <path
      stroke="#011367"
      strokeWidth={0.2}
      d="M20.608 9.259v-2.43a1.47 1.47 0 0 0-1.22-1.46l-.91-.13a14.9 14.9 0 0 1-5.36-2 1.688 1.688 0 0 0-.41-.19 1.56 1.56 0 0 0-1.29.24 14.76 14.76 0 0 1-5.92 2 1.5 1.5 0 0 0-1.48 1.73v4.22c.001.485.038.97.11 1.45a8.88 8.88 0 0 0 4.2 6.33c1 .65 2.11 1.19 3.17 1.79a1.51 1.51 0 0 0 1.62 0l2.77-1.54a8.52 8.52 0 0 0 1.74-1.25 8.87 8.87 0 0 0 2.93-6c.07-.94.05-1.85.05-2.76Zm-1.32 2.67a7.548 7.548 0 0 1-3.4 5.81c-.93.63-2 1.1-2.92 1.69a1.08 1.08 0 0 1-1.31 0c-.87-.54-1.8-1-2.66-1.52a7.6 7.6 0 0 1-3.66-6c-.07-.71 0-1.43 0-2.15v-2.83c0-.25 0-.3.29-.33a16 16 0 0 0 6.52-2.23.36.36 0 0 1 .43 0c1.469.9 3.078 1.548 4.76 1.92.58.13 1.18.21 1.77.3.2 0 .26.12.26.31v2.4c-.04.87-.01 1.72-.08 2.63Z"
    />
    <path
      fill="#011367"
      d="M15.798 9.679a.761.761 0 0 1-.18.69c-.22.25-.46.5-.69.75l-.23.24-2.19 2.33a.86.86 0 0 1-.61.33.93.93 0 0 1-.61-.29l-1.12-1-.67-.6a.73.73 0 0 1-.22-.81.76.76 0 0 1 .94-.45.65.65 0 0 1 .27.17l1.26 1.12.1.08 1.08-1.16 1.59-1.7a.72.72 0 0 1 .82-.21.7.7 0 0 1 .46.51Z"
    />
  </svg>
   </>
  )
}

export default Legalicon
