import React, { useEffect, useState } from "react";

// Components
import Aicardcomponent from "../aiCard/AiCardComponent";
import Blogcards from "../blogDetails/BlogCards";
// CSS
import "./FavouriteDetails.css";

// Images
import Google from "../../../assets/images/frontend/google.svg";
import frontendService from "../../../services/frontend.service";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useDispatch } from "react-redux";

const Favouritedetails = () => {
  const dispatch = useDispatch();
  //toggle tab
  const [toolsTab, setTooltab] = useState(true);
  const [blogTab, setBlogtab] = useState(false);
  //favouritetool list
  const [favoriteToolList, setFavoriteToolList] = useState([]);
  const [favoriteBlogList, setFavoriteBlogList] = useState([]);
  const [pageLimit, setPageLimit] = useState();
  const [page, setPage] = useState(1);
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const [loggedIn, setLoggedin] = useState(localStorage.getItem("loggedIn"));
  const isUserOk = localStorage.getItem("isUserOk");
  useEffect(() => {
    if (user.length !== 0) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          localStorage.setItem("userimg", res.data.picture);
          setProfile(res.data);
        });
    }
  }, [user]);
  useEffect(() => {
    if (localStorage.getItem("isUserOk")) {
      favoriteToolListApi();
      favoriteBlogListApi(page);
    }
  }, []);
  useEffect(() => {
    if (profile.length !== 0) {
      userLoginApi();
    }
  }, [profile]);
  async function userLoginApi() {
    const bodyData = {
      email: profile.email,
      googleId: profile.id,
      name: profile.name,
    };
    try {
      const response = await frontendService.userLoginService(bodyData);
      if (response.status === 200) {
        window.location.reload(true);
        localStorage.setItem("accessToken", response.data.data.token);
        localStorage.setItem("userName", response.data.data.user.name);
        localStorage.setItem("auth", response.data.data.user.email);
        localStorage.setItem("isUserOk", true);
        localStorage.setItem("loggedIn", 1);
        dispatch({
          type: "USER_LOGGEDIN",
          payload: response.data.data.user.email,
        });
      }
    } catch (error) { }
  }
  //favoriteToolListApi call api function
  async function favoriteToolListApi() {
   
    try {
      const response = await frontendService.getToolFavoriteService();
      if (response.status === 200) {
       setFavoriteToolList(response.data.data);
      }
    } catch (error) { }
  }
  //favoriteBlogListApi call api function
  async function favoriteBlogListApi(page) {
    let queryString = "?";
    queryString += `page=${page}&limit=20`;
    try {
      const response = await frontendService.favoriteBlogListService(
        queryString
      );
      if (response.status === 200) {
        setFavoriteBlogList(response.data.data);
        setPageLimit(response.data.pagination.totalPages);
      }
    } catch (error) { }
  }
  const handleScroll = () => {
    // Check if user has scrolled to the bottom of the page
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      if (page <= pageLimit) {
        favoriteBlogListApi(page);
      }
    }
  };
  //onclick toggle tabs
  const handleTools = () => {
    setTooltab(!toolsTab);
    setBlogtab(false);
  };
  const handleBlog = () => {
    setTooltab(false);
    setBlogtab(!blogTab);
  };
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => setUser(tokenResponse),
  });
  return (
    <>
      <div className="ai-page-heading">
        <h4 className="ai-h4">Your Favourites</h4>
        <p className="ai-p-base">
          These are the tools and posts you have favourited. You can remove them
          from your favourites by clicking the bookmark icon.
        </p>
      </div>
      {/* While user not have login */}
      {localStorage.getItem("isUserOk") === null ? (
        <>
          <div className="ai-no-user-login">
            <p className="ai-p-20">
              Please sign up or login to view your favourited AI tools and
              posts.
            </p>
            <button
              type="button"
              className="ai-button-primary-border"
              onClick={googleLogin}
            >
              <img src={Google} alt="" />
              Sign in with Google
            </button>
          </div>
        </>
      ) : (
        ""
      )}
      {/* Favorite Cards sections */}
      {isUserOk ? (
        <div className="ai-favorite-card-sec">
          {/* Tabs section */}
          <div className="ai-fav-tabs">
            <button
              className={`ai-tabs-button ${toolsTab ? "active" : ""} `}
              onClick={handleTools}
            >
              Tools
            </button>
            <button
              className={`ai-tabs-button ${blogTab ? "active" : ""} `}
              onClick={handleBlog}
            >
              Blog
            </button>
          </div>
          {/* Tabs inner section */}
          <div className="ai-tabs-container">
            {toolsTab && (
              <div className="ai-fav-tool-container">
                <h5 className="ai-h5 ai-count-sec">
                  <span>{favoriteToolList.length}</span> tools favourited
                </h5>
                <div className="row">
                  {favoriteToolList.length > 0 ? (
                    favoriteToolList.map((item) => (
                      <div className="col-sm-6 col-lg-4">
                        <Aicardcomponent
                          favoriteToolListApi={favoriteToolListApi}
                          data={item}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="ai-norecord">
                      <span>No Tools favourited Yet.</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {blogTab && (
              <div className="ai-fav-tool-container">
                <h5 className="ai-h5 ai-count-sec">
                  <span>{favoriteBlogList.length}</span> blogs favourited
                </h5>
                <div className="row">
                  {favoriteBlogList.length > 0 ? (
                    favoriteBlogList.map((item) => (
                      <div className="col-sm-6 col-lg-4">
                        <Blogcards
                          data={item}
                          favoriteBlogListApi={favoriteBlogListApi}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="ai-norecord">
                      <span>No Blogs favourited Yet.</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Favouritedetails;
