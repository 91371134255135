import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
//images
import download from "../../../assets/images/download1.svg";
import viewIcon from '../../../assets/images/view.svg'

// CSS
import "./SponsorshipDetailsPopup.css"
import adminService from '../../../services/admin.service';
import Loader from '../../loader/Loader';
import moment from 'moment';
import Pagination from '../../pagination/Pagination';

const SponsorshipListing = () => {
   const [sponsorshipData, setSponsorshipData] = useState([])
   const [initialData, setInitialData] = useState([])
   //filteration
   const [searchValue, setSearchValue] = useState('')
   const [searchMultiple, setsearchMultiple] = useState([])
   const [statusFilterInput, setStatusFilterInput] = useState('')
   const [categoryFilterInput, setCategoryFilterInput] = useState('')
   //view sponsor data
   const [viewSponsorData, setViewSponsorData] = useState({})
   //loader
   const [loader, setLoader] = useState(false)
   const [dataLoader, setDataLoader] = useState(false)
   //pagination
   const [currentPage, setCurrentPage] = useState(1);
   const recordPerPage = 20
   const excludeColumns = ["_id", "dateSelection", "chargeId", "createdAt", "customer", 'description', "paymentIntentId", "paymentSuccess", "pricing", "termsAccepted", "updatedAt", "totalprice", "updatedAt", "activeStatus"];
   useEffect(() => {
      getSponsorShipApi()
      setLoader(true)
   }, [])
   async function getSponsorShipApi() {
      try {
         const response = await adminService.getSponsorShipService()
         if (response.status === 200) {
            let data = response.data.data.sort().reverse()
            setSponsorshipData(data)
            setInitialData(data)
            setLoader(false)
         }
      } catch (error) {
         setLoader(false)
      }
   }
   async function getSponsorShipByIdApi(id) {
      setDataLoader(true)
      try {
         const response = await adminService.getSponsorShipByIdService(id)
         if (response.status === 200) {
            setViewSponsorData(response.data.data)
            setDataLoader(false)
         }
      } catch (error) {

      }
   }
   //onchange filter 
   const onChangeFilter = (searchValue) => {
      setSearchValue(searchValue)
      if (searchValue !== "" && statusFilterInput === "" && categoryFilterInput === "") {
         const filteredData = initialData.filter(item => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         });
         setSponsorshipData(filteredData)
         setsearchMultiple(filteredData)
      } else if (statusFilterInput !== "" && categoryFilterInput === "" && searchValue !== "") {

         var filteredKeywords = initialData.filter((item) => { return item.status === statusFilterInput });
         var finalFiltered = filteredKeywords.filter((item) => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         })
         setSponsorshipData(finalFiltered);
      } else if (statusFilterInput !== "" && categoryFilterInput === "" && searchValue === "") {
         var filteredKeywords = initialData.filter((item) => { return item.status === statusFilterInput });
         setSponsorshipData(filteredKeywords)
      } else if (statusFilterInput === "" && categoryFilterInput !== "" && searchValue === "") {
         var filteredKeywords = initialData.filter((item) => { return item.sponsorshipType === categoryFilterInput });
         setSponsorshipData(filteredKeywords)
      } else if (statusFilterInput !== "" && searchValue !== "" && categoryFilterInput === "") {

         var filteredKeywords = searchMultiple.filter((item) => { return item.status === statusFilterInput });
         var finalFiltered = filteredKeywords.filter((item) => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         })
         setSponsorshipData(finalFiltered);
      } else if (statusFilterInput !== "" && searchValue === "" && categoryFilterInput === "") {
         var filteredKeywords = searchMultiple.filter((item) => { return item.status === statusFilterInput });
         var finalFiltered = filteredKeywords.filter((item) => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         })
         setSponsorshipData(finalFiltered);
      } else if (categoryFilterInput !== "" && searchValue !== "" && statusFilterInput === "") {

         var filteredKeywords = searchMultiple.filter((item) => { return item.sponsorshipType === categoryFilterInput });
         var finalFiltered = filteredKeywords.filter((item) => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         })
         setSponsorshipData(finalFiltered);
      } else if (categoryFilterInput !== "" && searchValue === "" && statusFilterInput === "") {

         var filteredKeywords = searchMultiple.filter((item) => { return item.sponsorshipType === categoryFilterInput });
         var finalFiltered = filteredKeywords.filter((item) => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         })
         setSponsorshipData(finalFiltered);
      } else if (categoryFilterInput !== "" && searchValue === "" && statusFilterInput !== "") {
         var filteredKeywords = searchMultiple.filter((item) => { return item.sponsorshipType === categoryFilterInput && item.status === statusFilterInput });
         var finalFiltered = filteredKeywords.filter((item) => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         })
         setSponsorshipData(finalFiltered);
      } else if (categoryFilterInput !== "" && searchValue !== "" && statusFilterInput !== "") {
         var filteredKeywords = searchMultiple.filter((item) => { return item.sponsorshipType === categoryFilterInput && item.status === statusFilterInput });
         var finalFiltered = filteredKeywords.filter((item) => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         })
         setSponsorshipData(finalFiltered);
      } else {
         setSponsorshipData(initialData)
      }
   }
   //status filter
   const onChangeStatusFilter = (e) => {
      setStatusFilterInput(e.target.value)
      if (e.target.value !== "" && searchValue === "" && categoryFilterInput === "") {
         var filteredKeywords = initialData.filter((item) => { return item.status === e.target.value });
         setSponsorshipData(filteredKeywords)
         setsearchMultiple(filteredKeywords)
      } else if (e.target.value !== "" && searchValue !== "" && categoryFilterInput === "") {
         var finalFiltered = searchMultiple.filter((item) => { return item.status === e.target.value })
         setSponsorshipData(finalFiltered);
      } else if (e.target.value === "" && searchValue !== "" && categoryFilterInput === "") {
         var finalFiltered = searchMultiple.filter((item) => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         })
         setSponsorshipData(finalFiltered);
      } else if (e.target.value !== "" && categoryFilterInput !== "" && searchValue === "") {
         var finalFiltered = initialData.filter((item) => { return item.status === e.target.value && item.sponsorshipType === categoryFilterInput })
         setSponsorshipData(finalFiltered);
      } else if (e.target.value !== "" && categoryFilterInput !== "" && searchValue !== "") {
         var finalFiltered = searchMultiple.filter((item) => { return item.status === e.target.value && item.sponsorshipType === categoryFilterInput })
         const filteredData = finalFiltered.filter(item => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         });
         setSponsorshipData(filteredData);
      } else if (e.target.value === "" && categoryFilterInput !== "" && searchValue === "") {
         var finalFiltered = initialData.filter((item) => { return item.sponsorshipType === categoryFilterInput })
         setSponsorshipData(finalFiltered);
      } else if (e.target.value === "" && categoryFilterInput !== "" && searchValue !== "") {
         var finalFiltered = searchMultiple.filter((item) => { return item.sponsorshipType === categoryFilterInput })
         const filteredData = finalFiltered.filter(item => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         });
         setSponsorshipData(filteredData)
      } else if (e.target.value === "" && searchValue !== "" && categoryFilterInput === "") {
         const filteredData = searchMultiple.filter(item => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         });
         setSponsorshipData(filteredData)
      } else {
         setSponsorshipData(initialData)
      }
   }
   //type filter
   const onChangeCategoryFilter = (e) => {
      setCategoryFilterInput(e.target.value)
      if (e.target.value !== "" && searchValue === "" && statusFilterInput === "") {
         var filteredKeywords = initialData.filter((item) => { return item.sponsorshipType === e.target.value });
         setSponsorshipData(filteredKeywords)
         setsearchMultiple(filteredKeywords)
      } else if (e.target.value !== "" && searchValue !== "" && statusFilterInput === "") {
         var finalFiltered = searchMultiple.filter((item) => { return item.sponsorshipType === e.target.value })
         setSponsorshipData(finalFiltered);
      } else if (e.target.value !== "" && statusFilterInput !== "" && searchValue === "") {
         var finalFiltered = searchMultiple.filter((item) => { return item.sponsorshipType === e.target.value })
         setSponsorshipData(finalFiltered);
      } else if (e.target.value !== "" && statusFilterInput !== "" && searchValue !== "") {
         var finalFiltered = searchMultiple.filter((item) => { return item.sponsorshipType === e.target.value && item.status === statusFilterInput })
         const filteredData = finalFiltered.filter(item => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         });
         setSponsorshipData(filteredData)
      } else if (e.target.value === "" && searchValue === "" && statusFilterInput !== "") {
         var finalFiltered = searchMultiple.filter((item) => { return item.status === statusFilterInput })
         setSponsorshipData(finalFiltered);
      } else if (e.target.value !== "" && searchValue === "" && statusFilterInput !== "") {
         var finalFiltered = searchMultiple.filter((item) => { return item.sponsorshipType === e.target.value && item.status === statusFilterInput })
         setSponsorshipData(finalFiltered);
      } else if (e.target.value === "" && statusFilterInput !== "" && searchValue !== "") {
         var finalFiltered = searchMultiple.filter((item) => { return item.status === statusFilterInput })
         const filteredData = finalFiltered.filter(item => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         });
         setSponsorshipData(filteredData)
      } else if (e.target.value === "" && statusFilterInput === "" && searchValue !== "") {
         const filteredData = searchMultiple.filter(item => {
            return Object.keys(item).some(key =>
               excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
            );
         });
         setSponsorshipData(filteredData)
      } else {
         setSponsorshipData(initialData);
      }
   }
   //download pdf function
   const onClickgetPDF = () => {
      var pdfTable = document.getElementById('ai-sponsor-data'),
         exportTbl = pdfTable.cloneNode(true),
         ttl_tbl = exportTbl.querySelectorAll('table');
      for (var i = 0; i < ttl_tbl.length; i++) {
         ttl_tbl[i].style.width = "100%"
         var cols = ttl_tbl[i].querySelectorAll("td");
         for (var j = 0; j < cols.length; j++) {
            cols[j].style.width = "12%"
         }
         var rm_c = ttl_tbl[i].querySelectorAll(".rmv-cell");
         for (var k = 0; k < rm_c.length; k++) {
            rm_c[k].remove()
         }
      }
      var html = htmlToPdfmake(exportTbl.innerHTML, {
         tableAutoSize: true
      });
      const documentDefinition = { content: [html], pageSize: 'a4' }
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.createPdf(documentDefinition).download('sponsorData.pdf');
   }
   //pagination
   const paginate = (pageNumber) => {
      setCurrentPage(pageNumber);
   };
   const indexOfLastRecord = currentPage * recordPerPage;
   const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
   const currentRecords = sponsorshipData.slice(indexOfFirstRecord, indexOfLastRecord);
   const previousPage = () => {
      if (currentPage !== 1) {
         setCurrentPage(currentPage - 1);
      }
   };
   const nextPage = () => {
      if (currentPage !== Math.ceil(sponsorshipData.length / recordPerPage)) {
         setCurrentPage(currentPage + 1);
      }
   };
   return (
      <>
         <div className="page-wrapper doctris-theme toggled">
            <main className="page-content">
               <div className="container-fluid">
                  <div className="layout-specing">
                     <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="mb-0">Sponsors</h4>
                        {/* <p className='mb-0 ai-breadcrumbs'>Dashboard &#62; <a href='#'>SPONSORS</a></p> */}
                     </div>

                     <div className="row">
                        <div className="col-md-12">
                           <div className="card rounded-md border min-height-sc">
                              <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                 <div className="col-md-11">
                                    <div className="row">
                                       <div className="col-md-8">
                                          <i className="fi fi-br-search cstm-search-ro"></i>
                                          <input name="name" className="cstm-input-seacrh" onChange={(e => onChangeFilter(e.target.value))} />
                                       </div>
                                       <div className="col-md-2">
                                          <select className="cstm-select cstm-om-slc" name="City" id="City" onChange={onChangeStatusFilter}>
                                             <option value="">Status</option>
                                             <option value="Pending">Pending</option>
                                             <option value="In-process">In-process</option>
                                             <option value="Expired">Expired</option>
                                             <option value='Approved'>Approved</option>
                                          </select>
                                       </div>
                                       <div className="col-md-2">
                                          <select className="cstm-select cstm-om-slc" name="Sponsorship" id="Sponsorship" onChange={onChangeCategoryFilter}>
                                             <option value="">Sponsorship for</option>
                                             <option value="Website">Website</option>
                                             <option value="Newsletter">Newsletter</option>
                                          </select>
                                       </div>

                                    </div>

                                 </div>
                                 <div className="col-md-1 text-right ">
                                    <button onClick={onClickgetPDF} className="cstm-btn cstm-btn-icon"><img alt="download-img" src={download} /></button>
                                 </div>
                              </div>
                              <div className="col-md-12 col-lg-12" id='ai-sponsor-data'>
                                 <div className="table-responsive bg-white rounded"  >
                                    <table className="table mb-0 table-center" >
                                       <thead>
                                          <tr>
                                             <th className="border-bottom w-4">No.</th>
                                             <th className="border-bottom w-12">Full name</th>
                                             <th className="border-bottom w-12">User email</th>
                                             <th className="border-bottom w-12">Website</th>
                                             <th className="border-bottom w-12">Tool name</th>
                                             <th className="border-bottom w-12">Sponsorship for</th>
                                             <th className="border-bottom w-12">Sponsorship days</th>
                                             <th className="border-bottom w-15">Sponsorship between</th>
                                             <th className="border-bottom w-10">Status</th>
                                             <th className="border-bottom w-11 rmv-cell">Action</th>
                                          </tr>
                                       </thead>
                                       {loader ?
                                          <div className='loader-container'>
                                             <Loader />
                                          </div>
                                          : (sponsorshipData.length === 0 ?
                                             <span className="cstm-norecord">No Record Found</span> :
                                             (currentRecords.map((item, i) => (
                                                <tbody>
                                                   <tr key={i}>
                                                      <td>{i + 1}</td>
                                                      <td>{item.name}</td>
                                                      <td>{item.email}</td>
                                                      <td>{item.websiteUrl}</td>
                                                      <td>{item.toolName}</td>
                                                      <td><span className={item.sponsorshipType === "Website" ? "blue-color" : "green-color"}>{item.sponsorshipType}</span></td>
                                                      <td>{item.toolTopdays}</td>
                                                      <td>{item.dateSelection.map((item) => (
                                                         <span className='date-break'>{moment(item.startDate).format('DD MMM, YYYY')} - {moment(item.endDate).format('DD MMM, YYYY')}</span>
                                                      ))}
                                                      </td>
                                                      <td><span className={item.status === 'In-process' ? 'green-color' : item.status === 'Expired' ? "blue-color" : item.status === 'Pending' ? "red-color" : ""}>{item.status}</span></td>
                                                      <td className='rmv-cell'>
                                                         <Link onClick={() => getSponsorShipByIdApi(item._id)} className='cstm-btn6' data-bs-toggle="modal" data-bs-target="#viewSponsorshipDetails">
                                                            <i><img src={viewIcon} /></i>
                                                         </Link>
                                                         <div className="modal fade cstm-add-bonus model-sponsorshiplisting" id="viewSponsorshipDetails" tabIndex="-1" aria-labelledby="exampleModalLabel1"
                                                            aria-hidden="true">
                                                            <div className="modal-dialog modal-dialog-centered">
                                                               <div className="modal-content ">
                                                                  {dataLoader ?
                                                                     <div className='model-loader'>
                                                                        <Loader />
                                                                     </div> :
                                                                     <>
                                                                        <div className="modal-header p-4 pb-3">
                                                                           <h4 className="modal-title">Sponsorship Details</h4>
                                                                           <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>

                                                                        <div className="modal-body p-4">
                                                                           <div className="row mb-3">
                                                                              <div className="col-12 col-md-6"><h5>Sponsor name</h5></div>
                                                                              <div className="col-12 col-md-6 text-right"><h6>{viewSponsorData.name}</h6></div>
                                                                           </div>
                                                                           <div className="row mb-3">
                                                                              <div className="col-12 col-md-6"><h5>Sponsor email</h5></div>
                                                                              <div className="col-12 col-md-6 text-right"><h6>{viewSponsorData.email}</h6></div>
                                                                           </div>
                                                                           <div className="row mb-3">
                                                                              <div className="col-12 col-md-6"><h5>Tool name</h5></div>
                                                                              <div className="col-12 col-md-6 text-right"><h6>{viewSponsorData.toolName}</h6></div>
                                                                           </div>
                                                                           <div className="row mb-3">
                                                                              <div className="col-12 col-md-6"><h5>Website</h5></div>
                                                                              <div className="col-12 col-md-6 text-right"><a href={viewSponsorData.websiteUrl} target='_blank' className='wedsite-link'>{viewSponsorData.websiteUrl}</a></div>
                                                                           </div>
                                                                           <div className="row mb-3">
                                                                              <div className="col-12 col-md-6"><h5>Sponsorship for</h5></div>
                                                                              <div className="col-12 col-md-6 text-right"><h6>{viewSponsorData.sponsorshipType}</h6></div>
                                                                           </div>
                                                                           <div className="row mb-3">
                                                                              <div className="col-12 col-md-6"><h5>Sponsorship days</h5></div>
                                                                              <div className="col-12 col-md-6 text-right"><h6>{viewSponsorData.toolTopdays} days</h6></div>
                                                                           </div>
                                                                           <div className="row mb-3">
                                                                              <div className="col-12 col-md-6"><h5>Sponsorship between</h5></div>
                                                                              <div className="col-12 col-md-6 text-right">{viewSponsorData.dateSelection !== undefined && viewSponsorData.dateSelection.map((date) => (
                                                                                 <h6>{moment(date.startDate).format('DD MMM, YYYY')} - {moment(date.endDate).format('DD MMM, YYYY')}</h6>
                                                                              ))}</div>
                                                                           </div>
                                                                           <div className="row mb-3">
                                                                              <div className="col-12 col-md-6"><h5>Payment</h5></div>
                                                                              <div className="col-12 col-md-6 text-right"><h6>${viewSponsorData.totalprice}</h6></div>
                                                                           </div>
                                                                           <div className="row mb-3">
                                                                              <div className="col-12 col-md-6"><h5>Additional Information</h5></div>
                                                                              <div className="col-12 col-md-6 text-right"><h6>{viewSponsorData.description}</h6></div>
                                                                           </div>
                                                                           <div className="row">
                                                                              <div className="col-12 col-md-6"><h5>Status</h5></div>
                                                                              <div className="col-12 col-md-6 text-right">
                                                                                 <span className={viewSponsorData.status === "In-process" ? "status-in-progress" : viewSponsorData.status === "Expired" ? 'status-expired' : viewSponsorData.status === "Pending" ? "status-padding" : ""}>{viewSponsorData.status}</span>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        <div className="modal-footer p-3">
                                                                           <button className="cancel-btn mr-3" data-bs-dismiss="modal">Cancel</button>
                                                                        </div>
                                                                     </>
                                                                  }
                                                               </div>
                                                            </div>
                                                         </div>

                                                      </td>
                                                   </tr>
                                                </tbody>
                                             ))))}
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <Pagination
                        recordPerPage={recordPerPage}
                        totalRecord={sponsorshipData.length}
                        paginate={paginate}
                        currentPage={currentPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                     />
                  </div>
               </div>
            </main>
         </div>

      </>
   )
}

export default SponsorshipListing