import axios from "axios"
import frontendAuth from "./forntendAuth.service"

// const API_URL = 'http://13.50.216.140/api/FS/'
const API_URL = 'https://aitoolspot.com/api/FS/'

const userLoginService = (data) => {
    return axios.post(API_URL + 'user/signin', data)
}

const homeBannerService = () => {
    return axios.get(API_URL + 'home/Banner-Section-Counts')
}

const categoryGetService = () => {
    return axios.get(API_URL + 'category/top5-category-list')
}

const featureGetService = () => {
    return axios.get(API_URL + 'Feature/feature-listing')
}
const Allcategorylist = () => {
    return axios.get(API_URL + 'category/all-category-list')
}

const priceGetService = () => {
    return axios.get(API_URL + "pricing/pricing-listing")
}

const footerSubCategoryGetService = () => {
    return axios.get(API_URL + 'SubCategory/sub-category-listing')
}

const homePageToolListService = (data) => {
    return axios.get(API_URL + 'tool/tool-list' + data)
}

const filterBycategory = (id) => {
    return axios.get(API_URL + 'home/sub-category-wise-filter/' + id)
}

const filterBypriceService = (id, data) => {
    return axios.post(API_URL + 'home/pricing-Wise-filter' + id, data)
}

const filterBykeyWord = (params, data) => {
    return axios.post(API_URL + 'home/keyword-Pricing-Features-Filtration' + params, data, {
        headers: frontendAuth()
    })
}

const searchListService = () => {
    return axios.get(API_URL + "home/search-list")
}

const toolDetailservice = (id) => {
    return axios.get(API_URL + 'tool/tool-detail/' + id, {
        headers: frontendAuth()
    })
}

const toolReviewadd = (data) => {
    return axios.post(API_URL + 'user/add-Review', data, {
        headers: frontendAuth()
    })
}

const toolReviewGet = (id) => {
    return axios.post(API_URL + 'user/get-specific-tool-all-Rating', id, {
        headers: frontendAuth()
    })
}

const addToolFavoriteService = (data) => {
    return axios.post(API_URL + 'user/add-tool-favourite', data, {
        headers: frontendAuth()
    })
}

const removeToolFavoriteService = (data) => {
    return axios.post(API_URL + 'user/remove-tool-from-favourite', data, {
        headers: frontendAuth()
    })
}

const getToolFavoriteService = () => {
    return axios.get(API_URL + 'user/user-favourite-tool-list', {
        headers: frontendAuth()
    })
}

const addToolService = (data) => {
    return axios.post(API_URL + 'tool/add', data, {
        headers: frontendAuth()
    })
}

const reviewEditService = (id, data) => {
    return axios.put(API_URL + 'user/edit-Review/' + id, data, {
        headers: frontendAuth()
    })
}
const reviewViewService = (id) => {
    return axios.get(API_URL + 'user/get-by-id/' + id, {
        headers: frontendAuth()
    })
}

const reviewLikeService = (id) => {
    return axios.post(API_URL + 'user/reviewLike/add-review-like', id, {
        headers: frontendAuth()
    })
}

const reviewDisLikeService = (id) => {
    return axios.post(API_URL + 'user/reviewLike/add-review-Unlike', id, {
        headers: frontendAuth()
    })
}

const reviewDeleteService = (id) => {
    return axios.delete(API_URL + 'user/delete-Review/' + id, {
        headers: frontendAuth()
    })
}

const blogListingService = (data) => {
    return axios.get(API_URL + 'blog/blog-list' + data, {
        headers: frontendAuth()
    })
}

const blogCategoryListService = () => {
    return axios.get(API_URL + 'blogCategory/getAll')
}
const blogdetailService = (name) => {
    return axios.get(API_URL + 'blog/blog-detail/' + name)
}

const addBlogFavoriteService = (data) => {
    return axios.post(API_URL + 'user/add-blog-favourite', data, {
        headers: frontendAuth()
    })
}

const removeBlogFavoriteService = (data) => {
    return axios.post(API_URL + 'user/remove-blog-from-favourite', data, {
        headers: frontendAuth()
    })
}

const userToolListService = () => {
    return axios.get(API_URL + 'tool/user-tools-list', {
        headers: frontendAuth()
    })
}

const favoriteBlogListService = (data) => {
    return axios.get(API_URL + 'user/user-favourite-blog-list' + data, {
        headers: frontendAuth()
    })
}

const privacyPolicyService = () => {
    return axios.get(API_URL + 'user/get-privacy-policy/privacy-policy')
}

const termsAndConditionService = () => {
    return axios.get(API_URL + 'user/get-terms-and-condition/terms-and-condition')
}

const addFeedBackService = (data) => {
    return axios.post(API_URL + 'feedback/add', data, {
        headers: frontendAuth()
    })
}
const recentAddedToolService = (data) => {
    return axios.get(API_URL + 'home/todayaddedtoolist' + data, {
        headers: frontendAuth()
    })
}

const toolReasonService = (id) => {
    return axios.get(API_URL + 'tool/unapprovereasone/' + id, {
        headers: frontendAuth()
    })
}

const sponsorshipService = (data) => {
    return axios.post(API_URL + 'sponsership/web-sponsor-submit', data)
}

const pricePerDayService = () => {
    return axios.get(API_URL + 'get-tool-per-day-price/Tool-per-day-price')
}

const sponsershipPaymentService = (data) => {
    return axios.post(API_URL + 'sponsership/processPayment', data)
}

const sponsershipGetService = (data) => {
    return axios.get(API_URL + 'sponsership/userSponsorToolslisting' + data)
}
export default {
    homeBannerService,
    userLoginService,
    categoryGetService,
    footerSubCategoryGetService,
    homePageToolListService,
    filterBycategory,
    featureGetService,
    priceGetService,
    filterBypriceService,
    filterBykeyWord,
    toolDetailservice,
    searchListService,
    toolReviewadd,
    toolReviewGet,
    addToolFavoriteService,
    getToolFavoriteService,
    removeToolFavoriteService,
    addToolService,
    reviewLikeService,
    reviewDisLikeService,
    reviewEditService,
    reviewViewService,
    reviewDeleteService,
    blogListingService,
    blogCategoryListService,
    blogdetailService,
    addBlogFavoriteService,
    removeBlogFavoriteService,
    userToolListService,
    favoriteBlogListService,
    privacyPolicyService,
    termsAndConditionService,
    addFeedBackService,
    recentAddedToolService,
    toolReasonService,
    sponsorshipService,
    sponsershipPaymentService,
    pricePerDayService,
    sponsershipGetService,
    Allcategorylist
}