import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Delete from '../../../assets/images/delete.svg'
import adminService from '../../../services/admin.service';
import moment from 'moment';
import Loader from '../../loader/Loader';
import Pagination from '../../pagination/Pagination';
import editIcon from '../../../assets/images/Edit.svg'
import Deleteicon from '../../../assets/images/delete.svg'
import { Alert, Snackbar } from '@mui/material';

import loaderimg from '../../../assets/images/loder2.gif'

import DeleteModel from '../../../assets/images/deleteModel.svg'
import Addbutton from '../../buttons/AddButton';

const Pricing = () => {

    const [priceListing, setPriceListing] = useState([])
    const [initialData, setInitialData] = useState([])
    const [searchValue, setSearchValue] = useState('');
    const [priceAdd, setPriceAdd] = useState({
        name: ''
    })
    const [error, setError] = useState({
        name: ''
    })
    const [priceEdit, setPriceEdit] = useState({
        name: ''
    })
    const [loader, setLoader] = useState(false)
    const [dataLoader, setDataLoader] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [errorSnackbar, setErorSnackbar] = useState(false)
    const [popBtnLoader, setPopBtnLoader] = useState(false)
    const [message, setMessage] = useState('')
    const [priceId, setPriceId] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 20
    const excludeColumns = ["_id ", "date"];

    useEffect(() => {
        setPriceEdit('')
        setLoader(true)
        priceListingApi()
    }, [])
    useEffect(() => {
        setPriceListing(initialData)
    }, [])

    //creat data to display in table and filter
    var DataArray = [];
    function createData(_id, priceName, status, date) {
        return {
            _id,
            priceName,
            status,
            date
        };
    }
    async function priceListingApi() {
        try {
            const response = await adminService.pricingListingService()
            if (response.status === 200) {
                var arr = response.data.data
                if (response.data.data.length > 0) {
                    var newArr = []

                    for (var i = 0; i < arr.length; i++) {
                        var price = arr[i].name
                        var status = arr[i].status === true ? 'Active' : 'Inactive'
                        var date = moment(arr[i].createdAt).format('DD MMM, YYYY')

                        newArr[i] = createData(
                            arr[i]._id,
                            price,
                            status,
                            date
                        )
                    }
                    newArr.map((data1) => {
                        DataArray = [...DataArray, data1]
                    })
                    DataArray = DataArray.sort().reverse()
                    setInitialData(DataArray)
                    if (searchValue !== "" && searchValue !== undefined &&  searchValue !== null){

                        var filterData = DataArray.filter((item) => { 
                            return Object.keys(item).some(key =>
                                excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
                            );
                        });
                        
                        setPriceListing(filterData)
                        setLoader(false)
    
                    } else {
                        setPriceListing(DataArray)
                        setLoader(false)
    
                    }
                }
            }
        } catch (error) {
            setErorSnackbar(true)
            setLoader(false)
        }
    }

    async function priceAddApi() {
        const bodyData = {
            name: priceAdd.name
        }
        try {
            const response = await adminService.priceAddService(bodyData)
            if (response.status === 200) {
                setPriceAdd({
                    name: ''
                })
                setOpenSnackbar(true)
                setPopBtnLoader(false)
                setMessage(response.data.message)
                priceListingApi()
                setLoader(true)
                document.querySelector('#AddPrice .btn-close').click()
            }
        } catch (error) {
            setErorSnackbar(true)
            setLoader(false)
            setPopBtnLoader(false)
            setMessage(error.response.data.message)
        }
    }

    async function priceViewApi(id) {
        try {
            const response = await adminService.priceViewService(id)
            if (response.status === 200) {
                setLoader(false)
                setDataLoader(false)
                setPriceEdit(response.data.data)
            }
        } catch (error) {
            setLoader(false)
            setErorSnackbar(true)
            setPopBtnLoader(false)
            setMessage(error.response.data.message)
        }
    }

    async function priceEditApi() {
        const bodyData = {
            name: priceEdit.name
        }
        try {
            const response = await adminService.priceEditService(priceId, bodyData)
            if (response.status === 200) {
                setPriceEdit({
                    name: ''
                })
                setPopBtnLoader(false)
                setMessage(response.data.message)
                setOpenSnackbar(true)
                document.querySelector('#Editprice .btn-close').click()
                priceListingApi()
                setLoader(true)
            }
        } catch (error) {
            setErorSnackbar(true)
            setLoader(false)
            setPopBtnLoader(false)
            setMessage(error.response.data.message)
        }
    }

    async function priceDeleteApi(id) {
        try {
            const response = await adminService.priceDeleteService(id)
            if (response.status === 200) {
                setLoader(true)
                setPopBtnLoader(false)
                setMessage(response.data.message)
                setOpenSnackbar(true)
                priceListingApi()
                document.querySelector('#Deleteprice .btn-close').click()
            }
        } catch (error) {
            setErorSnackbar(true)
            document.querySelector('#Deleteprice .btn-close').click()
            setLoader(false)
            setPopBtnLoader(false)
            setMessage(error.response.data.message)
        }
    }

    const validate = (priceData) => {
        let priceError = {}
        let isValid = false
        if (!priceData.name) {
            isValid = true
            priceError.name = 'Please enter the pricing name.'
        }
        setError(priceError)
        return isValid
    }

    const handleAddonClick = (e) => {
        e.preventDefault()
        if (priceAdd.name.trim() !== "") {
            setError({ ...error, name: "" })
            if (!validate(priceAdd)) {
                priceAddApi()
                setPopBtnLoader(true)
            }
        } else {
            setError({ ...error, name: "Please enter the pricing name." })
        }
    }

    const handleEditonClick = (e) => {
        e.preventDefault()
        if (priceEdit.name.trim() !== "") {
            setError({ ...error, name: "" })
            if (!validate(priceEdit)) {
                priceEditApi()
                setPopBtnLoader(true)
            }
        }
        else {
            setError({ ...error, name: "Please enter the pricing name." })
        }
    }

    const handleAddOnchange = (e) => {
        const { name, value } = e.target
        setPriceAdd({ ...priceAdd, [name]: value })
        switch (name) {
            case "name":
                value.trim() === '' ? setError({ ...error, name: "Please enter the pricing name." }) : setError({ ...error, name: '' })
            default:
                break
        }
    }

    const handleEditOnchange = (e) => {
        const { name, value } = e.target
        setPriceEdit({ ...priceEdit, [name]: value })
        switch (name) {
            case "name":
                value.trim() === '' ? setError({ ...error, name: "Please enter the pricing name." }) : setError({ ...error, name: '' })
            default:
                break
        }
    }

    const onClickgetId = (e, id) => {
        setDataLoader(true)
        setPriceId(id)
        priceViewApi(id)
    }

    const handleDeletePrice = (e) => {
        e.preventDefault()
        priceDeleteApi(priceId)
        setPopBtnLoader(true)
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastRecord = currentPage * recordPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
    const currentRecords = priceListing.slice(indexOfFirstRecord, indexOfLastRecord);

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(priceListing.length / recordPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
        setErorSnackbar(false)
    };

    const onChangeFilter = (searchValue) => {
        setSearchValue(searchValue)
        // let filterData = [...initialData]
        if (searchValue !== '') {
            const filteredData = initialData.filter((item) => {
                return Object.keys(item).some(key =>
                    excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
                );
            })
            
            setPriceListing(filteredData)
        } else {
            setPriceListing(initialData)
        }
    }
    return (
        <>
            <div className="page-wrapper doctris-theme toggled">
                <main className="page-content">
                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="mb-0">Pricing</h4>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card rounded-md border min-height-sc">
                                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                            <div className="col-md-10">

                                                <i className="fi fi-br-search cstm-search-ro"></i>
                                                <input name="name" id="name" onChange={(e) => onChangeFilter(e.target.value)}
                                                    type="text" className="cstm-input-seacrh" placeholder="Search Price" />

                                            </div>
                                            <div className="col-md-2 text-right "><button className="cstm-btn cstm-btn-text" data-bs-toggle="modal" data-bs-target="#AddPrice" >Add Price</button></div>

                                            <div className="modal fade" id="AddPrice" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header border-0 p-4">
                                                            <h4 className="modal-title" id="exampleModalLabel1">Add Price</h4>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body p-4 pt-0">
                                                            <div className="mb-3">
                                                                <label className="cstm-label">Price<span className="cstm-star">*</span></label>
                                                                <input type="text" className="cstm-input" placeholder="Enter price" name="name" value={priceAdd.name} onChange={handleAddOnchange} />
                                                                <span className='error-message'>{error.name}</span>
                                                            </div>

                                                            <div className="text-center cstn-loader-sec cstn-loader-left ">
                                                                <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleAddonClick}>Submit</button>
                                                                {popBtnLoader &&
                                                                    <div className='loader-container-cstm'>
                                                                        <img className='btn-pop-loader' src={loaderimg} />
                                                                    </div>
                                                                }
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-lg-12">
                                            <div className="table-responsive bg-white rounded">
                                                <table className="table mb-0 table-center">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-bottom w-4">No.</th>
                                                            <th className="border-bottom w-12">Price</th>
                                                            <th className="border-bottom w-15">Status</th>
                                                            <th className="border-bottom w-10">Date</th>
                                                            <th className="border-bottom w-11">Action</th>
                                                        </tr>
                                                    </thead>
                                                    {loader ?
                                                        <div className='loader-container'>
                                                            <Loader />
                                                        </div> :
                                                        (priceListing.length === 0 ? <span className="cstm-norecord">No Record Found</span> :
                                                            (currentRecords.map((item, i) => (
                                                                <tbody key={item._id}>
                                                                    <tr>
                                                                        <td className="fw-bold">{i + 1}</td>
                                                                        <td>{item.priceName}</td>
                                                                        <td>{item.status}</td>
                                                                        <td>{item.date}</td>
                                                                        <td>
                                                                            <Link onClick={(e) => onClickgetId(e, item._id)} className="cstm-btn6 action-btn" data-bs-toggle="modal" data-bs-target="#Editprice">
                                                                                <i><img src={editIcon} /></i>
                                                                            </Link>
                                                                            <div className="modal fade" id="Editprice" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered">
                                                                                    <div className="modal-content">
                                                                                        {dataLoader ?
                                                                                            <div className='model-loader'>
                                                                                                <Loader />
                                                                                            </div> :
                                                                                            <>
                                                                                                <div className="modal-header border-0 p-4">
                                                                                                    <h4 className="modal-title" id="exampleModalLabel1">Edit Price</h4>
                                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                                </div>
                                                                                                <div className="modal-body p-4 pt-0">
                                                                                                    <div className="mb-3">
                                                                                                        <label className="cstm-label">Price<span className="cstm-star">*</span></label>
                                                                                                        <input type="text" className="cstm-input" placeholder="Enter price" name="name" value={priceEdit.name} onChange={handleEditOnchange} required="" />
                                                                                                        <span className='error-message'>{error.name}</span>
                                                                                                    </div>

                                                                                                    <div className="text-center cstn-loader-sec cstn-loader-left ">
                                                                                                        <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleEditonClick}>Submit</button>
                                                                                                        {popBtnLoader &&
                                                                                                            <div className='loader-container-cstm'>
                                                                                                                <img className='btn-pop-loader' src={loaderimg} />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>

                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <Link onClick={(e) => onClickgetId(e, item._id)} className="cstm-btn6" data-bs-toggle="modal"
                                                                                data-bs-target="#Deleteprice"><i><img src={Deleteicon} /></i></Link>
                                                                            <div className="modal fade cstm-add-bonus" id="Deleteprice" tabIndex="-1" aria-labelledby="exampleModalLabel1"
                                                                                aria-hidden="true">
                                                                                <div className="modal-dialog modal-dialog-centered">
                                                                                    <div className="modal-content">
                                                                                        <div className="modal-header border-0 p-4">
                                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        </div>
                                                                                        <div className="modal-body p-4 pt-0">
                                                                                            <div className="mb-4">

                                                                                                <div className="text-center mb-4">
                                                                                                    <img src={DeleteModel} />
                                                                                                </div>
                                                                                                <h4 className="text-center mb-4">Are you sure you want to delete price?</h4>

                                                                                                <div className="text-center cstn-loader-sec ">
                                                                                                    <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleDeletePrice}>Delete Price</button>
                                                                                                    {popBtnLoader &&
                                                                                                        <div className='loader-container-cstm'>
                                                                                                            <img className='btn-pop-loader' src={loaderimg} />
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>

                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )))
                                                        )
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Success Toast */}
                            <Snackbar
                                open={openSnackbar}
                                onClose={handleClose}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert variant='filled' onClose={handleClose} severity='success'>{message}</Alert>
                            </Snackbar>
                            {/* Error Toast */}
                            <Snackbar
                                open={errorSnackbar}
                                onClose={handleClose}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert variant='filled' onClose={handleClose} severity='error'>{message}</Alert>
                            </Snackbar>

                            <Pagination
                                recordPerPage={recordPerPage}
                                totalRecord={priceListing.length}
                                paginate={paginate}
                                currentPage={currentPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                            />
                        </div>
                    </div>

                </main>
            </div>
        </>
    )
}

export default Pricing;