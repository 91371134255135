import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';

import { useDropzone } from 'react-dropzone';
import adminService, { bucketUrl } from '../../services/admin.service';
import Loader from '../../components/loader/Loader';
import { Alert, Snackbar } from '@mui/material';
import { Delete } from '@mui/icons-material';
import loaderimg from '../../assets/images/loder2.gif'
import DeleteModel from '../../assets/images/deleteModel.svg'

const Media = () => {

    const [error, setError] = useState({
        media: ""
    })
    const [files, setFiles] = useState([])
    const [openSnacskbar, setOpenSnackbar] = useState(false)
    const [errorSnackbar, setErorSnackbar] = useState(false)
    const [getMedia, setGetMedia] = useState([])
    const [loader, setLoader] = useState(false)
    const [popBtnLoader, setPopBtnLoader] = useState(false)
    const [message, setMessage] = useState('')
    const [mediaId, setMediaId] = useState('')


    useEffect(() => {
        setLoader(true)
        mediaGetApi()
    }, [])

    async function mediaUploadApi() {
        const formData = new FormData()
        files.map((item) => (
            formData.append('multtimage', item)
        ))
        try {
            const response = await adminService.mediaAddService(formData)
            if (response.status === 200) {
                setLoader(true)
                setMessage(response.data.message)
                setOpenSnackbar(true)
                setPopBtnLoader(false)
                mediaGetApi()
                setFiles([])
            }
        } catch (error) {
            setMessage(error.response.data.message)
            setErorSnackbar(true)
        }
    }

    async function mediaDeleteApi(id) {
        try {
            const response = await adminService.mediaDeleteService(id)
            if (response.status === 200) {
                setLoader(true)
                mediaGetApi()
                setOpenSnackbar(true)
                setPopBtnLoader(false)
                setMessage(response.data.message)
                document.querySelector("#DeletemediaImage .btn-close").click()
            }
        } catch (error) {
            setMessage(error.response.data.message)
            setErorSnackbar(true)
        }
    }

    async function mediaGetApi() {
        try {
            const response = await adminService.mediaGetService()
            if (response.status === 200) {
                setGetMedia(response.data.data)
                setLoader(false)
            }
        } catch (error) {
            setMessage(error.response.data.message)
            setErorSnackbar(true)
        }
    }

    const { fileRejections, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        maxSize: 3000000,
        multiple: true,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        },
        validator: error => {
            setError({ ...error, media: "" })
        },
    });

    function deleteFile(e) {
        const s = files.filter((item, index) => index !== e);
        setFiles(s);
    }

    const thumbs = files.map((file, i) => (
        <div key={file.name} className='col-1'>
            <div className='ai-uploaded-container'>
                <img
                    alt='preview-image'
                    className='ai-uploaded-items'
                    src={file.preview}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />

                <button className='ai-upload-delete' onClick={() => deleteFile(i)}><span> <Delete /></span></button>
            </div>

        </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    const validate = () => {
        let mediaError = {}
        let isvalid = false
        if (files.length === 0) {
            isvalid = true
            mediaError.media = "Please select image"
        }
        setError(mediaError)
        return isvalid
    }
    const onClickAddImages = () => {
        if (!validate(files)) {
            setPopBtnLoader(true)
            mediaUploadApi()
        }
    }

    const copyToClipboard = (url) => {
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setOpenSnackbar(true)
        setMessage('Url copied to clipboard')
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false)
    }

    const onClickgetId = (e, id) => {
        setMediaId(id)
    }

    const onClickDeleteImage = () => {
        setPopBtnLoader(true)
        mediaDeleteApi(mediaId)
    }

    return (
        <>
            <div className="page-wrapper doctris-theme toggled">
                <main className="page-content bg-light">
                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="mb-0">Media Image</h4>
                                {/* <div className="cstm-bre uppercase">Dashboard > <a href="#">ADD subscription </a></div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card  rounded-md  border min-height-sc add-edit-page">
                                        <div className="py-2 px-3">
                                            <div className="tab-content mt-2" id="pills-tabContent">
                                                
                                                <div className="col-lg-12">
                                                    <div className="mb-4">
                                                        <label className="cstm-label">Attachment Media Image<span className='cstm-astix'>*</span></label>
                                                        <div {...getRootProps({ className: 'dropzone blog-dropzone' })}>
                                                            <input {...getInputProps()} />
                                                            <p> <span> <AddIcon /></span>  Drop your image here, or <span> browse</span></p>
                                                        </div>
                                                        <aside>
                                                            <div className='row'>
                                                                {thumbs}
                                                            </div>
                                                        </aside>
                                                        <span className='error-message'>{error.media || fileRejectionItems}</span>
                                                    </div>
                                                    
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center py-3">
                                                <h6 className='m-0'>Media List</h6>
                                                <div className="faq-add-bonus cstn-loader-sec text-center cstn-loader-right">
                                                        <button onClick={onClickAddImages} className="cstm-btn cstm-btn-text">Add Images</button>
                                                        {popBtnLoader &&
                                                            <div className='loader-container-cstm'>
                                                                <img alt='loader-img' className='btn-pop-loader' src={loaderimg} />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                
                                             
                                                <div className="row p-3">
                                                    {loader ?
                                                        <div className='loader-container'><Loader /></div>
                                                        : getMedia.map((item) =>
                                                            <div className="col-sm-6 col-lg-2" key={item._id}>
                                                                <div className="ai-media-blog-container" >
                                                                    <div className="ai-media-img-sec" id='img-id'>
                                                                        <img  onClick={() => copyToClipboard(bucketUrl + item.multtimage)} alt='media-img' src={bucketUrl + item.multtimage} />
                                                                    </div>
                                                                    <div className='medal-hover'>    <span>Click on image to copy url</span></div>
                                                                    <button className="ai-delete-icon" onClick={(e) => onClickgetId(e, item._id)} data-bs-toggle="modal"
                                                                        data-bs-target="#DeletemediaImage">
                                                                        <Delete />
                                                                    </button>
                                                                    <div className="modal fade cstm-add-bonus" id="DeletemediaImage" tabIndex="-1" aria-labelledby="exampleModalLabel1"
                                                                        aria-hidden="true">
                                                                        <div className="modal-dialog modal-dialog-centered">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header border-0 p-4">
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                </div>
                                                                                <div className="modal-body p-4 pt-0">
                                                                                    <div className="mb-4">

                                                                                        <div className="text-center mb-4">
                                                                                            <img alt="icon-img" src={DeleteModel} />
                                                                                        </div>
                                                                                        <h4 className="text-center mb-4">Are you sure you want to delete media image?</h4>



                                                                                        <div className="text-center cstn-loader-sec">
                                                                                            <button onClick={onClickDeleteImage} disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text">Delete Image</button>
                                                                                            {popBtnLoader &&
                                                                                                <div className='loader-container-cstm'>
                                                                                                    <img alt="loader-img" className='btn-pop-loader' src={loaderimg} />
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Snackbar
                                open={openSnacskbar}
                                onClose={handleClose}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert onClose={handleClose} variant='filled' severity='success'>{message}</Alert>
                            </Snackbar>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default Media;