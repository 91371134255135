import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import viewIcon from '../../../assets/images/view.svg'
import adminService from '../../../services/admin.service';
import Pagination from '../../pagination/Pagination';
import Loader from '../../loader/Loader';
import moment from 'moment';
const Feedback = () => {

  //listing api data 
  const [feedBackList, setFeedBackList] = useState([])
  const [initialData, setInitialData] = useState([])

  //loader
  const [loader, setLoader] = useState(false)
  const [dataLoader, setDataLoader] = useState(false)

  //view by id
  const [feedbackView, setFeedbackView] = useState({})

  //filter input
  const [searchValue, setSearchValue] = useState('');

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20
  const excludeColumns = ["_id ", "date", "uname"];

  var DataArray = [];
  function createData(_id, uname, email, category, message, date) {
    return {
      _id,
      uname,
      email,
      category,
      message,
      date
    };
  }

  useEffect(() => {
    setLoader(true)
    feedbackListAPI()
  }, [])

  //feedback list api
  async function feedbackListAPI() {
    try {
      const response = await adminService.feedBackListService()
      if (response.status === 200) {
        var arr = response.data.data
        if (response.data.data.length > 0) {
          var newArr = []

          for (var i = 0; i < arr.length; i++) {

            var date = moment(arr[i].createdAt).format('DD MMM, YYYY')

            // var username =  arr[i].userData !== undefined && arr[i].userData.length > 0 ? arr[i].userData.name?arr[i].userData.name : '-' :'-'
            var email = arr[i].email !== undefined ? arr[i].email ? arr[i].email : '-' : '-'

            newArr[i] = createData(
              arr[i]._id,
              arr[i].userId ? arr[i].userId : '-',
              email,
              arr[i].categoryOfFeedback ? arr[i].categoryOfFeedback : '-',
              arr[i].message ? arr[i].message : '-',
              date
            )
          }
          newArr.map((data1) => {
            DataArray = [...DataArray, data1]
          })

          DataArray = DataArray.sort().reverse()
          setInitialData(DataArray)
          if (searchValue !== "" && searchValue !== undefined && searchValue !== null) {

            var filterData = DataArray.filter((item) => {
              return Object.keys(item).some(key =>
                excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
              );
            });

            setFeedBackList(filterData)
            setLoader(false)

          } else {
            setFeedBackList(DataArray)
            setLoader(false)

          }
        } else {
          setLoader(false)
        }
      } else {
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)

    }
  }

  //view by id api
  async function feedbackViewAPI(id) {
    try {
      const response = await adminService.feedbackViewService(id)
      if (response.status === 200) {
        setFeedbackView(response.data.data)
        setDataLoader(false)
      }
    } catch (error) {

    }
  }

  //onclick get by id 
  const onClickFeedback = (e, id) => {
    setDataLoader(true)
    feedbackViewAPI(id)
  }

  //search filter
  const onChangeFilter = (searchValue) => {
    setSearchValue(searchValue)
    if (searchValue !== '') {
      const filteredData = initialData.filter((item) => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      })
      setFeedBackList(filteredData)
    } else {
      setFeedBackList(initialData)
    }
  }

  //pagination
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  const currentRecords = feedBackList.slice(indexOfFirstRecord, indexOfLastRecord);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(feedBackList.length / recordPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <>
      <div className="page-wrapper doctris-theme toggled">
        <main className="page-content">
          <div className="container-fluid">
            <div className="layout-specing">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="mb-0">Feedback</h4>
                {/* <div className="cstm-bre uppercase">dashboard YEAR LONG COURSE <Link to="/topic">TOPICS</Link></div> */}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card rounded-md border min-height-sc">
                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                      <div className="col-md-12">
                        <i className="fi fi-br-search cstm-search-ro"></i>
                        <input name="name" id="name" onChange={(e) => onChangeFilter(e.target.value)}
                          type="text" className="cstm-input-seacrh" placeholder="Search User" />

                      </div>
                      {/* <div className="col-md-2 text-right">
                        <Link to='/admin/blog/add-blog' className="cstm-btn cstm-btn-text">Add Blog</Link>
                      </div> */}
                    </div>

                    <div className="col-md-12 col-lg-12">
                      <div className="table-responsive bg-white rounded">
                        <table className="table mb-0 table-center">
                          <thead>
                            <tr>
                              <th className="border-bottom w-4">No.</th>
                              <th className="border-bottom w-12">Email</th>
                              <th className="border-bottom w-12">Category</th>
                              <th className="border-bottom w-12">Message</th>
                              <th className="border-bottom w-10">Date</th>
                              <th className="border-bottom w-11">Action</th>
                            </tr>
                          </thead>
                          {loader ?
                            <div className='loader-container'>
                              <Loader />
                            </div> :
                            (feedBackList.length === 0 ? <span className="cstm-norecord">No Record Found</span> :
                              (currentRecords.map((item, i) => (
                                <tbody>
                                  <tr key={i}>
                                    <td className="fw-bold">{i + 1}</td>
                                    <td>{item.userId !== undefined ? item.userId.email : item.email}</td>
                                    <td>{item.category}</td>
                                    <td><span className='over-flow'>{item.message}</span></td>
                                    <td>{item.date}</td>
                                    <td>
                                      <Link onClick={(e) => onClickFeedback(e, item._id)} className="cstm-btn6" data-bs-toggle="modal" data-bs-target="#viewFeedback">
                                        <i><img src={viewIcon} /></i>
                                      </Link>
                                      <div className="modal fade cstm-add-bonus model-feedback" id="viewFeedback" tabIndex="-1" aria-labelledby="exampleModalLabel1"
                                        aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                          <div className="modal-content ">
                                            {dataLoader ?
                                              <div className='model-loader'>
                                                <Loader />
                                              </div> :
                                              <>
                                                <div className="modal-header border-0 p-4">
                                                  <h4 className="modal-title" id="exampleModalLabel1">View</h4>
                                                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body p-4 pt-0">
                                                  <div className="mb-3">
                                                    <label className="cstm-label">User Name</label>
                                                    <input disabled value={feedbackView.userId !== undefined ? feedbackView.userId.name : "-"} type="text" className="cstm-input" placeholder="Enter User name" name="name" required="" />
                                                  </div>
                                                  <div className="mb-3">
                                                    <label className="cstm-label">Email</label>
                                                    <input disabled value={feedbackView.userId !== undefined ? feedbackView.userId.email : feedbackView.email} type="email" className="cstm-input" placeholder="Enter Email" name="email" required="" />
                                                  </div>
                                                  <div className="mb-3">
                                                    <label className="cstm-label">Category</label>
                                                    <input disabled value={feedbackView.categoryOfFeedback} type="text" className="cstm-input" placeholder="Enter Email" name="email" required="" />
                                                  </div>
                                                  <div className="mb-3">
                                                    <label className="cstm-label">Message</label>
                                                    <textarea disabled value={feedbackView.message} type="text" className="cstm-textarea" placeholder="enter your plan duration"
                                                      name="email" required="" />
                                                  </div>
                                                  <div className="d-flex justify-content-end">
                                                    <button className="cstm-btn cstm-btn-text" data-bs-dismiss="modal">OK</button>
                                                  </div>
                                                </div>
                                              </>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              )))
                            )
                          }
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Pagignation */}
              <Pagination
                recordPerPage={recordPerPage}
                totalRecord={feedBackList.length}
                paginate={paginate}
                currentPage={currentPage}
                previousPage={previousPage}
                nextPage={nextPage}
              />
            </div>
          </div>

        </main>
      </div>
    </>
  )
}

export default Feedback;