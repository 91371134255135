import React, { useEffect, useState } from "react";

// Components
import Pagebreadcrumb from "../../components/frontend/pageBreadCrumb/PageBreadCrumb";
import Favouritedetails from "../../components/frontend/favorite/FavouriteDetails";
import Seo from "../../components/seo/Seo";

const Favorites = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Seo title={"Curate your AI tools list - Explore your Favorite AI tools | AIToolSpot"} description={"These are the resources and articles you have bookmarked. By selecting a bookmark symbol, you are able to eliminate them from the list of favorites."}/>
      <div className="spacer-132"></div>
      <section className="ai-bg-color">
        <div className="ai-container">
          <Pagebreadcrumb path={"Favourites"} />

          <Favouritedetails
          
          />
        </div>
      </section>
    </>
  );
};

export default Favorites;
