import React, { useState } from "react";
// CSS
import "./Toollist.css";
// images
import searchDark from "../../../assets/images/frontend/search-dark.svg";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import frontendService from "../../../services/frontend.service";
import loaderImg from '../../../assets/images/loder2.gif'
import Loader from "../../loader/Loader";

const Toollist = ({ toolList, onChangeFilter, onChangeStatusFilter, loader }) => {
  const [reason, setReason] = useState('')
  const [popLoader, setPopLoader] = useState(false)
  const handleClick = (e, id) => {
    Cookies.set("toolId", id)
  }
  async function reasonGetApi(id) {
    try {
      const response = await frontendService.toolReasonService(id)
      if (response.status === 200) {
        setReason(response.data.data)
        setPopLoader(false)
      }
    } catch (error) {
    }
  }
  const handleReason = (e, id) => {
    reasonGetApi(id)
    setPopLoader(true)
  }
  return (
    <>
      <div className="toollistHeader">
        <h4 className="ai-h4">Tools</h4>
      </div>
      <div className="ai-tool-table-sec">
        {/* Search section */}
        <div className="ai-tool-search-container">
          <form>
            <div className="ai-form-inputes">
              <label className="ai-label-form">
                <img src={searchDark} alt="searchDark" />
              </label>
              <input
                type="text"
                className="ai-inputs"
                aria-describedby="search"
                placeholder="Search tool"
                onChange={(e => onChangeFilter(e.target.value))}
              />
            </div>
          </form>

          <div className="ai-sort-selection">
            <label className="ai-sort-label"> Status</label>
            <select onChange={onChangeStatusFilter}>
              <option style={{ fontFamily: 'Gilroy' }} value="">Status</option>
              <option style={{ fontFamily: 'Gilroy' }} value="Approved">Approved</option>
              <option style={{ fontFamily: 'Gilroy' }} value="Pending">Pending</option>
              <option style={{ fontFamily: 'Gilroy' }} value="UnApproved">UnApproved</option>
            </select>
          </div>
        </div>
        {/* Table section */}
        <div className="ai-table-container">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Tool Name</th>
                  <th scope="col">Category</th>
                  <th scope="col">Feature</th>
                  <th scope="col">Pricing</th>
                  <th scope="col">Starting Price</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {loader ?  <div className='ai-front-loader-list'>
                    <img src={loaderImg} />
                  </div> : toolList.length > 0 ? toolList.map((item, i) => (
                <tbody>
                  <tr>
                    <td scope="row">{i + 1}</td>
                    <td>{item.toolName}</td>
                    <td className='cstm-word-break'>{item.category}</td>
                    <td className='cstm-word-break'>{item.features}</td>
                    <td className='cstm-word-break'>{item.pricing}</td>
                    <td>{item.price !== "-" ? `$${item.price}` : "-"}</td>
                    <td className={item.status === "Approved" ? "ai-approved" : "ai-unapproved"}>{item.status}</td>
                    <td>
                      {item.status === "Approved" ?
                        <Link target='_blank' type="button" to={"/tool/" + item.toolName.replace(/\s+/g, "-")} onClick={(e) => handleClick(e, item._id)}>View</Link>
                        :
                        item.status === 'UnApproved' ?
                          <>
                            <button type="button" data-bs-toggle="modal" data-bs-target="#viewReason" onClick={(e) => handleReason(e, item._id)}>Reason</button>

                          </>
                          : "-"
                      }
                    </td>
                  </tr>
                </tbody>
              )) : <div className="ai-norecord-toollist">
                No Record Found
              </div>}
            </table>
            <div className="modal fade" id="viewReason" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  {popLoader ?
                    <div className='model-loader'>
                      <Loader />
                    </div> :
                    <>
                      <div className="modal-header border-0 p-4">
                        <h4 className="modal-title" id="exampleModalLabel1">Reason</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body p-4 pt-0">
                        <div className="mb-3">
                          <label className="cstm-label">{reason}</label>
                        </div>
                        <div className="text-center cstn-loader-sec cstn-loader-left ">
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="spacer-132"></div>
    </>


  );
};

export default Toollist;
