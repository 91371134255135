import React from 'react'

const Addbutton = ({onClick,child}) => {
    return (
        <>
            <div className="d-flex justify-content-end">
                <button onClick={onClick} className=" cstm-btn">{child}</button>
            </div>
        </>
    )
}

export default Addbutton;