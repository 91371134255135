import React from 'react'
import loader from '../../assets/images/loder3.gif'
const Loader = () => {
    return (
        <>
            <div className='loader-component'>
                <img src={loader} />
            </div>
        </>
    )
}

export default Loader;