import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import editIcon from '../../../assets/images/Edit.svg'
import deleteIcon from '../../../assets/images/delete.svg'

import DeleteModel from '../../../assets/images/deleteModel.svg'
import adminService, { bucketUrl } from '../../../services/admin.service';
import Loader from '../../loader/Loader';
import moment from 'moment';
import Pagination from '../../pagination/Pagination';
import { Alert, Snackbar } from '@mui/material';
import loaderimg from '../../../assets/images/loder2.gif'

const BlogListing = () => {

  //store api response
  const [blogList, setBlogList] = useState([])
  const [initialData, setInitialData] = useState([])

  //search filter input
  const [searchValue, setSearchValue] = useState('')

  //api response
  const [message, setMessage] = useState('')

  //blogid to pass in edit and delete
  const [blogId, setBlogId] = useState('')

  //loader
  const [loader, setLoader] = useState(false)

  //toast
  const [openSnackbar, setOpenSnackbar] = useState(false)

  //btn loader
  const [popBtnLoader, setPopBtnLoader] = useState(false)

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20
  const excludeColumns = ["_id ", "date", "imageUrl"];

  //creat data to display in table and filter
  var DataArray = [];
  function createData(_id, title, image, date) {
    return {
      _id,
      title,
      image,
      date
    };
  }
  useEffect(() => {
    setLoader(true)
    blogListApi()
  }, [])

  //listing api
  async function blogListApi() {
    try {
      const response = await adminService.blogListService()

      if (response.status === 200) {
        var arr = response.data.data
        if (response.data.data.length > 0) {
          var newArr = []

          for (var i = 0; i < arr.length; i++) {

            var date = moment(arr[i].createdAt).format('DD MMM, YYYY')
            newArr[i] = createData(
              arr[i]._id,
              arr[i].title,
              bucketUrl + arr[i].imageUrl,
              date
            )
          }
          newArr.map((data1) => {
            DataArray = [...DataArray, data1]
          })

          DataArray = DataArray.sort().reverse()
          setInitialData(DataArray)
          if (searchValue !== "" && searchValue !== undefined && searchValue !== null) {

            var filterData = DataArray.filter((item) => {
              return Object.keys(item).some(key =>
                excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
              );
            });

            setBlogList(filterData)
            setLoader(false)

          } else {
            setBlogList(DataArray)
            setLoader(false)

          }
        }
      }
    } catch (error) {

    }
  }

  //delete api
  async function blogDeleteApi() {
    try {
      const response = await adminService.blogDeleteService(blogId)
      if (response.status === 200) {
        setMessage(response.data.message)
        setLoader(true)
        setOpenSnackbar(true)
        blogListApi()
        setPopBtnLoader(false)
        document.querySelector('#Deleteblog .btn-close').click()
      }
    } catch (error) {

    }
  }

  //delete blog
  const handleDeleteBlog = () => {
    blogDeleteApi()
    setPopBtnLoader(true)
  }

  //search filter function
  const onChangeFilter = (searchValue) => {
    setSearchValue(searchValue)
    // let filterData = [...initialData]
    if (searchValue !== '') {
      const filteredData = initialData.filter((item) => {
        // return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase().trim())
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(searchValue.toLowerCase().trim())
        );
      })
      setBlogList(filteredData)
    } else {
      setBlogList(initialData)
    }
  }

  //get blogid
  const getId = (e, id) => {
    setBlogId(id)
  }

  //pagination
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  const currentRecords = blogList.slice(indexOfFirstRecord, indexOfLastRecord);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(blogList.length / recordPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  //close toast
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  return (
    <>
      <div className="page-wrapper doctris-theme toggled">
        <main className="page-content">
          <div className="container-fluid">
            <div className="layout-specing">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="mb-0">Blog</h4>
                {/* <div className="cstm-bre uppercase">dashboard YEAR LONG COURSE <Link to="/topic">TOPICS</Link></div> */}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card rounded-md border min-height-sc">
                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                      <div className="col-md-10">
                        <i className="fi fi-br-search cstm-search-ro"></i>
                        <input name="name" id="name"
                          type="text" onChange={(e) => onChangeFilter(e.target.value)} className="cstm-input-seacrh" placeholder="Search Blog" />

                      </div>
                      <div className='col-md-2 text-right'>
                        <Link to='/admin/blog/add-blog' className="cstm-btn cstm-btn-text">Add Blog</Link>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-12">
                      <div className="table-responsive bg-white rounded">
                        <table className="table mb-0 table-center">
                          <thead>
                            <tr>
                              <th className="border-bottom w-4">No.</th>
                              <th className="border-bottom w-12">Blog Title</th>
                              <th className="border-bottom w-12">Feature Image</th>
                              <th className="border-bottom w-10">Date</th>
                              <th className="border-bottom w-11">Action</th>
                            </tr>
                          </thead>
                          {loader ?
                            <div className='loader-container'>
                              <Loader />
                            </div> :
                            (blogList.length === 0 ? <span className="cstm-norecord">No Record Found</span> :
                              <tbody>
                                {currentRecords.map((item, i) => (
                                  <tr key={i}>
                                    <td className="fw-bold">{i + 1}</td>
                                    <td>{item.title}</td>
                                    <td><img src={item.image} style={{ height: '84px', width: '149px' }} /></td>
                                    <td>{item.date}</td>
                                    <td>
                                      <Link to={'/admin/blog/edit-blog/' + item._id} className="cstm-btn6 action-btn">
                                        <i><img src={editIcon} /></i>
                                      </Link>
                                      <Link onClick={(e) => getId(e, item._id)} className="cstm-btn6" data-bs-toggle="modal" data-bs-target="#Deleteblog">
                                        <i><img src={deleteIcon} /></i>
                                      </Link>
                                      <div className="modal fade cstm-add-bonus" id="Deleteblog" tabIndex="-1" aria-labelledby="exampleModalLabel1"
                                        aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                          <div className="modal-content">
                                            <div className="modal-header border-0 p-4">
                                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body p-4 pt-0">
                                              <div className="mb-4">

                                                <div className="text-center mb-4">
                                                  <img src={DeleteModel} />
                                                </div>
                                                <h4 className="text-center mb-4">Are you sure you want to delete blog?</h4>
                                                <div className="text-center cstn-loader-sec">
                                                  <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleDeleteBlog}>Delete Blog</button>
                                                  {popBtnLoader &&
                                                    <div className='loader-container-cstm'>
                                                      <img className='btn-pop-loader' src={loaderimg} />
                                                    </div>
                                                  }
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            )
                          }
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Snackbar
                open={openSnackbar}
                onClose={handleClose}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert onClose={handleClose} variant='filled' severity='success' >{message}</Alert>
              </Snackbar>
              {/* Pagignation */}
              <Pagination
                recordPerPage={recordPerPage}
                totalRecord={blogList.length}
                paginate={paginate}
                currentPage={currentPage}
                previousPage={previousPage}
                nextPage={nextPage}
              />
            </div>
          </div>

        </main>
      </div>
    </>
  )
}

export default BlogListing;