import React from 'react'

// CSS
import "./PageBreadCrumb.css"
// images
import aiRightArrow from "../../../assets/images/frontend/ai-right-arrow.svg"
import { Link } from 'react-router-dom'
const Pagebreadcrumb = ({ data, path }) => {
  return (
    <>
      <div className="ai-tool-tabs">
        <Link to="/" className='ai-tool-preivous'>Home</Link>
        {data !== undefined ?
          <>
            <img src={aiRightArrow} alt="" />
            <Link to={data !== undefined && data.subCategoryData !== undefined ? "/ai-tools/" + data.subCategoryData[0].slug : ""} className='ai-tool-preivous'>{data !== undefined && data.subCategoryData !== undefined ? data.subCategoryData[0].subCategoryName.charAt(0).toUpperCase() + data.subCategoryData[0].subCategoryName.slice(1).toLowerCase() : data.blogCategoryId !== undefined ? data.blogCategoryId.name : ''}</Link>
          </> : ""
        }
        <img src={aiRightArrow} alt="" />
        <p className='ai-tool-active'>{path}</p>
      </div>
    </>
  )
}

export default Pagebreadcrumb
