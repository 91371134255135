import { Alert, Popover, Snackbar } from "@mui/material";
import React, { useState } from "react";

// CSS
import "./FeedBackPop.css";
// ICONS
import { Close } from "@mui/icons-material";
import frontendService from "../../../services/frontend.service";
const Feedbackpop = ({ openFeedBack, id, open, handleFeedbackClose, setFeedBackShow, feedbackShow }) => {
  const [headerText, setHeaderText] = useState('')
  const [feedBackCategory, setFeedBackCategory] = useState('')
  const [message, setMessage] = useState('')
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [feedBackData, setFeedBackData] = useState({
    email: "",
    feedBack: ""
  })
  const [error, setError] = useState({
    email: "",
    feedBack: ""
  })
  async function addFeedBackApi() {
    const bodyData = {
      email: feedBackData.email !== '' ? feedBackData.email : undefined,
      message: feedBackData.feedBack,
      categoryOfFeedback: feedBackCategory
    }
    try {
      const response = await frontendService.addFeedBackService(bodyData)
      if (response.status === 200) {
        setMessage(response.data.message)
        setOpenSnackBar(true)
        setFeedBackShow(!feedbackShow)
        setFeedBackData({
          email: "",
          feedBack: ""
        })
      }
    } catch (error) {
    }
  }
  const handleFeedBackSubmit = (e) => {
    e.preventDefault()
    if (feedBackData.feedBack.trim() !== "") {
      setError({ ...error, feedBack: "" })
      addFeedBackApi()
    } else {
      setError({ ...error, feedBack: "Please enter your feedback" })
    }
  }
  const handleFeedBackOnChange = (e) => {
    const { name, value } = e.target
    setFeedBackData({ ...feedBackData, [name]: value })
  }
  const handleBack = () => {
    setError({})
    setFeedBackShow(false)
    setFeedBackCategory('')
    setHeaderText('')
    setFeedBackData({
      email: "",
      feedBack: ""
    })
  }
  const handleToggleFeedBack = () => {
    setFeedBackShow(!feedbackShow)
    setFeedBackCategory('General Feedback')
    setHeaderText('General Feedback')
  }
  const handleToggleIdea = () => {
    setFeedBackShow(!feedbackShow)
    setFeedBackCategory('I have an idea')
    setHeaderText('I have an idea')
  }
  const handleToggleBug = () => {
    setFeedBackShow(!feedbackShow)
    setFeedBackCategory('I found a bug')
    setHeaderText('I found a bug')
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };
  return (
    <div className="ai-feedback-popover">
      <Popover
        className="ai-feedback-popover-sec"
        anchorEl={openFeedBack}
        id={id}
        open={open}
        onClose={handleFeedbackClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div className="ai-feedback-popover-container">
          <button className="ai-popover-close" onClick={handleFeedbackClose}>
            <Close />
          </button>
          {!feedbackShow &&
            <div>
              <h5 className="ai-h5"> Feedback</h5>
              <p className="ai-p-15"> What feedback do you have?</p>
              <div className="ai-popover-buttons">
                <button onClick={handleToggleFeedBack}>
                  General Feedback
                </button>
                <button onClick={handleToggleIdea}>
                  I have an idea
                </button>
                <button onClick={handleToggleBug}>
                  I found a bug
                </button>
              </div>
            </div>
          }
          {feedbackShow &&
            <>
              <div className="">
                <h5 className="ai-h5"> {headerText}</h5>
                <div className="ai-popover-form">
                  {localStorage.getItem("isUserOk") ? "" :
                    <input type='email' placeholder="Email address(optional)" value={feedBackData.email} name="email" onChange={handleFeedBackOnChange} />
                  }
                  <textarea value={feedBackData.feedBack} onChange={handleFeedBackOnChange} name="feedBack" placeholder="Your feedback goes here!" />
                  <span className="ai-feednack-error">{error.feedBack}</span>
                </div>
                <div className="ai-feedback-btn">
                  <button className="ai-feedback-back" onClick={handleBack}>Back</button>
                  <button className="ai-feedback-submit" onClick={handleFeedBackSubmit}>Submit</button>
                </div>
              </div>
            </>
          }
        </div>
      </Popover>
      <Snackbar
        open={openSnackBar}
        onClose={handleClose}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} variant='filled' severity='success'>{message}</Alert>
      </Snackbar>
    </div>
  );
};

export default Feedbackpop;
