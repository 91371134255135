import React, { useEffect, useState } from "react";

// CSS
import "./AiCardComponent.css"
// images
import cardImg from "../../../assets/images/frontend/card-img.png";
import Verify from "../../../assets/images/frontend/verified.svg";
import Star from "../../../assets/images/frontend/star.svg";
import aiBadge from "../../../assets/images/frontend/ai-badge.svg";
import aiBadgeoutline from "../../../assets/images/frontend/ai-badge-outline.svg";
import aiStars from "../../../assets/images/frontend/ai-stars.svg";
import aiLinks from "../../../assets/images/frontend/ai-links.svg";
import { Link } from "react-router-dom";
import { bucketUrl } from "../../../services/admin.service";
import Cookies from "js-cookie";
import frontendService from "../../../services/frontend.service";
import { Alert, Chip, Rating, Snackbar, Tooltip } from "@mui/material";


const Aicardcomponent = ({ data, filterBykeyWordApi, searchTerm, priceArray, selectedFeature, selectedStatus }) => {
  //favourite toggle
  const [favourite, setFavourite] = useState(false)
  //toast toggle
  const [errorToast, setErrorToast] = useState(false)
  const [successToast, setSuccessToast] = useState(false)
  //favourite count
  const [favoritesCount, setFavouriteCount] = useState(data !== undefined ? data.countFavouritesToolData : 0)
  const [message, setMessage] = useState("")
  //featured
  const [featured, setFeatured] = useState(false)
  //check if user loggedin or not
  var isUserOk = localStorage.getItem("isUserOk")
  //get tool id from cookie to pass in api payload
  const onClickCard = (e, id) => {
    Cookies.set("toolId", id)
  }
  useEffect(() => {
    {
      data.sponsorData !== undefined && data.sponsorData.length > 0 && data.sponsorData.map((item) => (
        item.status === "In-process" ? setFeatured(true) : setFeatured(false)
      ))
    }
  })
  useEffect(() => {
    //check user tool favourite or not 
    if (data !== undefined && data.userfavouritesToolData !== undefined && data.userfavouritesToolData.length > 0) {
      setFavourite(true)
    } else {
      setFavourite(false)
    }
  }, [])
  //addfavourite tool api
  async function addToolFavoriteApi(toolId) {
    const bodydata = {
      toolId: toolId
    }
    try {
      const response = await frontendService.addToolFavoriteService(bodydata)
      if (response.status === 200) {
      }
    } catch (error) {
    }
  }
  //remove tool from favourite
  async function removeToolFavoriteApi(toolId) {
    const bodydata = {
      toolId: toolId
    }
    try {
      const response = await frontendService.removeToolFavoriteService(bodydata)
      if (response.status === 200) {
      }
    } catch (error) {
    }
  }
  //handle favourite 
  const handleFavourite = (toolId, status) => {
    if (status === true && localStorage.getItem("isUserOk")) {
      removeToolFavoriteApi(toolId)
      setFavourite(false)
      setFavouriteCount(favoritesCount - 1)
    } else if (status === false && localStorage.getItem("isUserOk")) {
      addToolFavoriteApi(toolId)
      setFavouriteCount(favoritesCount + 1)
      setFavourite(true)
    } else {
      setErrorToast(true)
      setMessage("Please login to save to favourites")
    }
  }
  //handleclose toast
  const handleClosetoast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorToast(false);
    setSuccessToast(false)
  };
  const onClickCategory = (e, name) => {

    filterBykeyWordApi(1, searchTerm, priceArray, selectedFeature, selectedStatus, name)
  }
  return (
    <>
      {data !== undefined ?
        <div className="card-main">
          <div className="card-container">
            <Link
              to={"/tool/" + data.slug}
              rel="noreferrer" target={'_blank'}
              onClick={(e) => onClickCard(e, data._id)} className="card-link">
              <div className="card-image-container">
                {data.imageUrl !== undefined ?
                  <img src={bucketUrl + data.imageUrl} alt={data.toolName} className="card-img" />
                  : <img src={cardImg} alt='' className="card-img" />
                }
                {data.startingPrice !== "" ?
                  <div className="card-badge">
                    <p className="ai-p-14">${data.startingPrice + "/Mo"}</p>
                  </div> : ""
                }
                {featured &&
                  <>
                    <div className="ai-featured-tag">
                      <Chip label="Featured" color='primary' variant="outlined" />
                    </div>
                  </>}
              </div>

              <div className="card-content">
                <div className="card-heading-sec">
                  <div className="card-heading">
                    <h5 className="ai-h5">
                      {data.toolName}
                      {data.isVerified ?
                        <div className="verify-icon">
                          <img src={Verify} alt="" />
                        </div> : ""
                      }
                    </h5>

                    <div className="ai-rating-sec">
                      <div className="ai-stars">
                        <Rating readOnly precision={0.5} value={data.countRatingAvg !== null ? data.countRatingAvg : 0} />
                      </div>
                      <span className="ai-count">({' '} {data.countReviewsData} {' '})</span>
                    </div>
                  </div>
                  <div className="ai-saved-sec">
                    <Tooltip title={`${data.countFavouritesToolData} people favourited this tool`} arrow placement='top'>
                      <div className="ai-save-rate">
                        <>
                          <img src={aiBadge} alt="" />
                          <span className="ai-count">{favoritesCount}</span>
                        </>
                      </div>
                    </Tooltip>
                    {data.featuresData.length > 0 ?
                      <Tooltip title={data.featuresData.map((item) => (item.name)).join(', ')} arrow>
                        <div className="ai-save-rate">
                          <>
                            <img src={aiStars} alt="" />
                            <span className="ai-count">{data.featuresData.length}</span>
                          </>
                        </div>
                      </Tooltip>
                      : ""
                    }
                  </div>
                </div>
              </div>
            </Link>
            <div className="card-content-container">
              <div className="ai-badge-tab-container">
                {data.pricingsData.length > 0 ?
                  (data.pricingsData.map((item, i) => (
                    <div className="ai-badge-tab" key={i}>
                      <span>{item.name}</span>
                    </div>
                  )))
                  : <div className="ai-badge-tab"></div>
                }
              </div>
              <div className="card-para-sec">
                {data.shortDesc !== "" ?
                  <p className="ai-p-17">{data.shortDesc}</p> : <p className="ai-p-18"></p>
                }
                <div className="ai-tags" >
                  {data.subCategoryData.length > 0 ?
                    (data.subCategoryData.map((item, i) => (
                      <Link to={"/ai-tools/" + item.slug} onClick={(e) => onClickCategory(e, item.slug)} >#{item.subCategoryName}</Link>
                    )))
                    : ""
                  }
                </div>
              </div>

              <div className="card-button-sec">
                <Link type="button" className="btn-links" target="_blank" to={data.isAffiliated ? data.websiteUrl : data.websiteUrl + '?utm_source=aitoolspot&utm_medium=website&utm_campaign=aitoolspot'}>
                  <img src={aiLinks} alt="" />
                </Link>
                {favourite && isUserOk ?
                  <>
                    <button type="button" className="btn-save" onClick={() => handleFavourite(data._id, true)}>
                      {/* <BookmarkAddedIcon /> */}
                      <img src={aiBadge} alt="aiBadge" />
                    </button>
                  </>
                  :
                  <button type="button" className="btn-save" onClick={() => handleFavourite(data._id, false)}>
                    <img src={aiBadgeoutline} alt="aiBadge" />
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        :
        <div className="card-main">
          <div className="card-container">
            <Link to="" className="card-link">
              <div className="card-image-container">
                <img src={cardImg} alt="" className="card-img" />
                <div className="card-badge">
                  <p className="ai-p-14">$99/Mo</p>
                </div>
              </div>

              <div className="card-content">
                <div className="card-heading-sec">
                  <div className="card-heading">
                    <h5 className="ai-h5">
                      GPTGO
                      <div className="verify-icon">
                        <img src={Verify} alt="" />
                      </div>
                    </h5>

                    <div className="ai-rating-sec">
                      <div className="ai-stars">
                        <img src={Star} alt="" />
                        <img src={Star} alt="" />
                        <img src={Star} alt="" />
                        <img src={Star} alt="" />
                        <img src={Star} alt="" />
                      </div>
                      <span className="ai-count">({' '} 6 {' '})</span>
                    </div>
                  </div>
                  <div className="ai-saved-sec">
                    <div className="ai-save-rate">
                      <img src={aiBadge} alt="" />
                      <span className="ai-count">1166</span>
                    </div>
                    <div className="ai-save-rate">
                      <img src={aiStars} alt="" />
                      <span className="ai-count">2</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <div className="card-content-container">
              <div className="ai-badge-tab">
                <span>Free</span>
              </div>
              <div className="card-para-sec">
                <p className="ai-p-18">ChatGPT combined with Google search</p>
                <div className="ai-tags">
                  <a href="#">#life assistent</a>
                  <a href="#">#search engine</a>
                </div>
              </div>

              <div className="card-button-sec">
                <button type="button" className="btn-links">
                  <img src={aiLinks} alt="" />
                </button>
                <button type="button" className="btn-save">
                  <img src={aiBadge} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      <Snackbar
        open={errorToast}
        onClose={handleClosetoast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClosetoast} variant='filled' severity='error' >{message}</Alert>
      </Snackbar>
      <Snackbar
        open={successToast}
        onClose={handleClosetoast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClosetoast} variant='filled' severity='success' >{message}</Alert>
      </Snackbar>
    </>
  );
};

export default Aicardcomponent;
