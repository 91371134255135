import React from "react";

const Usericon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        className="ai-side-icons"
      >
        <path
          fill="#011367"
          d="M19.597 20.997c-.014-1.803-.577-3.42-1.727-4.805-1.28-1.539-2.916-2.477-4.913-2.716-2.311-.278-4.344.369-6.07 1.933-1.415 1.283-2.216 2.888-2.438 4.783-.032.267-.028.537-.042.805H3v-.528c.01-.049.025-.102.028-.15.063-.932.264-1.832.623-2.69 1.016-2.417 2.75-4.104 5.194-5.06l.2-.08-.179-.138c-1.3-1.022-2.008-2.358-2.064-4.013a5 5 0 0 1 .855-2.99c.713-1.065 1.684-1.793 2.926-2.14.348-.1.71-.141 1.065-.208h.704c.281.05.57.074.844.148C15.148 3.664 16.47 4.884 17 6.83c.556 2.034-.01 3.798-1.544 5.25-.151.144-.32.263-.478.397.01.014.01.021.014.024.032.015.063.032.095.043a8.807 8.807 0 0 1 3.21 2.03c1.37 1.347 2.23 2.963 2.561 4.858.06.344.095.692.141 1.04V21c-.464-.003-.936-.003-1.403-.003ZM11.997 12a3.806 3.806 0 0 0 3.798-3.785c.014-2.087-1.702-3.802-3.791-3.806a3.806 3.806 0 0 0-3.799 3.785c-.014 2.087 1.702 3.802 3.791 3.806Z"
        />
      </svg>
    </>
  );
};

export default Usericon;
