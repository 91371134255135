import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import adminService from '../../../services/admin.service';
import Pagination from '../../pagination/Pagination';
import Loader from '../../loader/Loader';
import moment from 'moment';
import { Alert, Snackbar } from '@mui/material';
import Deleteicon from '../../../assets/images/delete.svg'
import editIcon from '../../../assets/images/Edit.svg'

import DeleteModel from '../../../assets/images/deleteModel.svg'
import Addbutton from '../../buttons/AddButton';
import loaderimg from '../../../assets/images/loder2.gif'
import slugify from 'slugify';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import toolbar from "../../../config/toolbar"
import localization from "../../../config/localization"
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const SubCategory = () => {

    //listing api data
    const [subCategoryListing, setSubCategoryListing] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [initialData, setInitialData] = useState([])

    //filterartion
    const [searchValue, setSearchValue] = useState('')

    //loader
    const [loader, setLoader] = useState(false)
    const [dataLoader, setDataLoader] = useState(false)
    const [popBtnLoader, setPopBtnLoader] = useState(false)

    //toast
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [errorSnackbar, setErorSnackbar] = useState(false)

    // api response message
    const [message, setMessage] = useState('')

    //get id
    const [subCategoryId, setSubCategoryId] = useState('')

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 20

    //view, add data
    const [subCategoryData, setSubCategoryData] = useState({
        categoryId: '',
        subCategoryName: '',
        icon: '',
        subCategoryShortDesc: '',
        metaTitle: '',
        metaDesc: ''
    })

    //Editor state
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty(),)
    const _contentState = ContentState.createFromText('Sample content state')
    const raw = convertToRaw(_contentState)
    const [contentState, setContentState] = useState(raw)
    const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    //editor edit state
    const [content, setContent] = useState(EditorState.createEmpty());
    const [contenthtml, setContenthtml] = useState();

    const [error, setError] = useState({
        categoryId: '',
        subCategoryName: '',
        icon: '',
        subCategoryShortDesc: '',
        metaTitle: '',
        metaDesc: ''
    })
    const [subCategoryEdit, setSubCategoryEdit] = useState({
        categoryId: '',
        subCategoryName: '',
        icon: '',
        subCategoryShortDesc: '',
        metaTitle: '',
        metaDesc: ''
    })
    var slug = slugify(subCategoryData.subCategoryName, { lower: true, strict: true })
    var editSlug = subCategoryEdit.subCategoryName !== undefined ? slugify(subCategoryEdit.subCategoryName, { lower: true, strict: true }) : ""

    useEffect(() => {
        setSubCategoryEdit('')
        categoryApi()
        subCategoryListingApi()
        setLoader(true)
    }, [])

    //categpry listing api
    async function categoryApi() {
        try {
            const response = await adminService.categoryListService()
            if (response.status === 200) {
                setLoader(false)
                setCategoryData(response.data.data)
            }
        } catch (error) {
            setLoader(false)
            setErorSnackbar(true)
        }
    }

    //subcategory listing api
    async function subCategoryListingApi() {
        try {
            const response = await adminService.subCategoryListingService()
            if (response.status === 200) {
                let data = (response.data.data).sort().reverse()
                setLoader(false)
                setInitialData(data)
                if (searchValue !== "" && searchValue !== undefined) {

                    var filterData = data.filter((item) => { return item.categoryId._id === searchValue });
                    setSubCategoryListing(filterData)

                } else {
                    setSubCategoryListing(data)

                }
            }
        } catch (error) {
            setLoader(false)
            setErorSnackbar(true)
        }
    }

    //add subacategory api
    async function subCategoryAddApi() {
        const bodyData = {
            categoryId: subCategoryData.categoryId,
            subCategoryName: subCategoryData.subCategoryName,
            icon: subCategoryData.icon,
            subCategoryShortDesc: subCategoryData.subCategoryShortDesc,
            subCategoryDescription: editorData,
            metaTitle: subCategoryData.metaTitle,
            metaDesc: subCategoryData.metaDesc,
            slug: slug
        }
        try {
            const response = await adminService.subCategoryAddService(bodyData)
            if (response.status === 200) {
                setPopBtnLoader(false)
                setSubCategoryData({
                    categoryId: '',
                    subCategoryName: '',
                    icon: '',
                    subCategoryShortDesc: '',
                    metaTitle: '',
                    metaDesc: ''
                })
                setLoader(true)
                setOpenSnackbar(true)
                subCategoryListingApi()
                categoryApi()
                setMessage(response.data.message)
                document.querySelector('#AddsubCat .btn-close').click()
            }
        } catch (error) {
            setLoader(false)
            setErorSnackbar(true)
        }
    }

    //view by id subcategory
    async function SubcategoryViewApi(id) {
        try {
            const response = await adminService.subCategoryViewService(id)
            if (response.status === 200) {
                setSubCategoryEdit(response.data.data)
                setDataLoader(false)
                const contentBlock = htmlToDraft(response.data.data.subCategoryDescription);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setContent(editorState)
            }
        } catch (error) {
            setDataLoader(false)
            setLoader(false)
        }
    }

    //delete api
    async function subCategoryDeleteApi() {
        try {
            const response = await adminService.subCategoryDeleteService(subCategoryId)
            if (response.status === 200) {
                setOpenSnackbar(true)
                setPopBtnLoader(false)
                setMessage(response.data.message)
                document.querySelector('#DeletesubCategory .btn-close').click()
                setLoader(true)
                subCategoryListingApi()
            }
        } catch (error) {
            setLoader(false)
            setErorSnackbar(true)
            document.querySelector('#DeletesubCategory .btn-close').click()
            setPopBtnLoader(false)
            setMessage(error.response.data.message)
        }
    }

    //edit api
    async function subCategoryEditApi() {
        const bodyData = {
            categoryId: subCategoryEdit.categoryId,
            subCategoryName: subCategoryEdit.subCategoryName,
            icon: subCategoryEdit.icon,
            subCategoryShortDesc: subCategoryEdit.subCategoryShortDesc,
            subCategoryDescription: contenthtml,
            metaTitle: subCategoryEdit.metaTitle,
            metaDesc: subCategoryEdit.metaDesc,
            slug: editSlug
        }
        try {
            const response = await adminService.subCategoryEditService(subCategoryId, bodyData)
            if (response.status === 200) {
                setPopBtnLoader(false)
                setSubCategoryEdit({
                    categoryId: '',
                    subCategoryName: '',
                    icon: '',
                    subCategoryShortDesc: '',
                    metaTitle: '',
                    metaDesc: ''
                })
                setMessage(response.data.message)
                setOpenSnackbar(true)
                setLoader(true)
                subCategoryListingApi()
                document.querySelector('#EditSubCate .btn-close').click()
            }
        } catch (error) {
            setLoader(false)
            setErorSnackbar(true)
        }
    }

    //pagination
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastRecord = currentPage * recordPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
    const currentRecords = subCategoryListing.slice(indexOfFirstRecord, indexOfLastRecord);

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(subCategoryListing.length / recordPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    //select filter function
    const subCategoryFilter = (e) => {
        setSearchValue(e.target.value)
        let filterdata = [...initialData]
        if (e.target.value === '') {
            setSubCategoryListing([...filterdata])
        } else {
            var filteredKeywords = filterdata.filter((item) => { return item.categoryId._id === e.target.value });
            setSubCategoryListing([...filteredKeywords])
            setLoader(false)
        }
    }

    //validation
    const validate = (subCategoryData) => {
        let subCategoryError = {}
        let isValid = false
        if (subCategoryData.subCategoryName.trim() === "") {
            isValid = true
            subCategoryError.subCategoryName = 'Please enter the subcategory name.'
        }
        if (subCategoryData.icon.trim() === "") {
            isValid = true
            subCategoryError.icon = 'Please enter the subcategory icon.'
        }
        if (subCategoryData.subCategoryShortDesc.trim() === "") {
            isValid = true
            subCategoryError.subCategoryShortDesc = 'Please enter the subcategory short description.'
        }
        if (!subCategoryData.categoryId) {
            isValid = true
            subCategoryError.categoryId = 'Please select the category'
        }
        setError(subCategoryError)
        return isValid
    }

    //handle add on change
    const handleAddOnchange = (e) => {
        const { name, value } = e.target
        setSubCategoryData({ ...subCategoryData, [name]: value })
        switch (name) {
            case "subCategoryName":
                value.trim() === "" ? setError({ ...error, subCategoryName: 'Please enter the subcategory name.' }) : setError({ ...error, subCategoryName: '' })
                break
            case "icon":
                value.trim() === "" ? setError({ ...error, icon: 'Please enter the subcategory icon.' }) : setError({ ...error, icon: '' })
                break
            case "subCategoryShortDesc":
                value.trim() === "" ? setError({ ...error, subCategoryShortDesc: 'Please enter the subcategory short description.' }) : setError({ ...error, subCategoryShortDesc: '' })
                break
            case "categoryId":
                value.trim() === "" ? setError({ ...error, categoryId: 'Please select the category' }) : setError({ ...error, categoryId: '' })
                break
            default:
                break
        }
    }

    //handle edit onchange
    const handleEditOnchange = (e) => {
        const { name, value } = e.target
        setSubCategoryEdit({ ...subCategoryEdit, [name]: value })
        switch (name) {
            case "subCategoryName":
                value.trim() === "" ? setError({ ...error, subCategoryName: 'Please enter the subcategory name.' }) : setError({ ...error, subCategoryName: '' })
                break
            case "icon":
                value.trim() === "" ? setError({ ...error, icon: 'Please enter the subcategory icon.' }) : setError({ ...error, icon: '' })
                break
            case "subCategoryShortDesc":
                value.trim() === "" ? setError({ ...error, subCategoryShortDesc: 'Please enter the subcategory short description.' }) : setError({ ...error, subCategoryShortDesc: '' })
                break
            case "categoryId":
                value.trim() === "" ? setError({ ...error, categoryId: 'Please select the category' }) : setError({ ...error, categoryId: '' })
                break
            default:
                break
        }
    }

    //handle add subcategory
    const handleAddSubcategory = (e) => {
        e.preventDefault()
        if (!validate(subCategoryData)) {
            subCategoryAddApi()
            setPopBtnLoader(true)
        }
    }

    //handle edit subctegory
    const handleEditSubcategory = (e) => {
        e.preventDefault()
        if (!validate(subCategoryEdit)) {
            subCategoryEditApi()
            setPopBtnLoader(true)
        }
    }

    //handle delete subcategory
    const handleDeleteSubcategory = (e) => {
        e.preventDefault()
        subCategoryDeleteApi()
        setPopBtnLoader(true)
    }

    //toast close
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErorSnackbar(false)
        setOpenSnackbar(false);
    };

    //handle view category 
    const handleViewsubCategory = (e, id) => {
        setDataLoader(true)
        setSubCategoryId(id)
        SubcategoryViewApi(id)
        setError("")
        setContent("")
    }

    //get subcategory id
    const onClickgetId = (e, id) => {
        setSubCategoryId(id)
        setError("")
    }

    const handleEditorStateonChange = (editorState) => {
        setContenthtml(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        setContent(editorState)
    }


    return (
        <>
            <div className="page-wrapper doctris-theme toggled">
                <main className="page-content">
                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="mb-0">SubCategory</h4>
                                {/* <div className="cstm-bre uppercase">dashboard YEAR LONG COURSE <Link to="/topic">TOPICS</Link></div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card  rounded-md  border min-height-sc">
                                        <div className="d-flex justify-content-between align-items-center p-3 border-top">
                                            <div className="col-md-10">
                                                <select className="cstm-select cstm-om-slc" onChange={subCategoryFilter} name='Category'>
                                                    <option value="">Select category</option>
                                                    {categoryData.map((item) => (
                                                        <option key={item._id} value={item._id}>{item.categoryName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-md-2 text-right'>
                                                <button className="cstm-btn" data-bs-toggle="modal" data-bs-target="#AddsubCat" onClick={onClickgetId}>Add SubCategory</button>
                                            </div>

                                            <div className="modal fade" id="AddsubCat" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header border-0 p-4">
                                                            <h4 className="modal-title" id="exampleModalLabel1">Add SubCategory</h4>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body p-4 pt-0">
                                                            <div className="mb-3">
                                                                <label className="cstm-label">Select Category<span className="cstm-star">*</span></label>
                                                                <select className="cstm-select cstm-om-slc" name='categoryId' onChange={handleAddOnchange} value={subCategoryData.categoryId}>
                                                                    <option value="">Select category</option>
                                                                    {categoryData.map((item) => (
                                                                        <option key={item._id} value={item._id}>{item.categoryName}</option>
                                                                    ))}
                                                                </select>
                                                                <span className='error-message'>{error.categoryId}</span>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="cstm-label">Subcategory Name<span className="cstm-star">*</span></label>
                                                                <input type="text" className="cstm-input" placeholder="Enter subcategory name" onChange={handleAddOnchange} name="subCategoryName" value={subCategoryData.subCategoryName} required />
                                                                <span className='error-message'>{error.subCategoryName}</span>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="cstm-label">Subcategory Icon<span className="cstm-star">*</span></label>
                                                                <input type="text" className="cstm-input" placeholder="Enter subcategory icon" onChange={handleAddOnchange} name="icon" value={subCategoryData.icon} required />
                                                                <span className='error-message'>{error.icon}</span>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="cstm-label">Meta Title</label>
                                                                <input type="text" className="cstm-input" placeholder="Enter meta title" onChange={handleAddOnchange} name="metaTitle" value={subCategoryData.metaTitle} required />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="cstm-label">Meta Description</label>
                                                                <input type="text" className="cstm-input" placeholder="Enter meta description" onChange={handleAddOnchange} name="metaDesc" value={subCategoryData.metaDesc} required />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="cstm-label">Subcategory Short Description<span className="cstm-star">*</span></label>
                                                                <input type="text" className="cstm-input" placeholder="Enter subcategory short description" onChange={handleAddOnchange} name="subCategoryShortDesc" value={subCategoryData.subCategoryShortDesc} required />
                                                                <span className='error-message'>{error.subCategoryShortDesc}</span>
                                                            </div>

                                                            <div className="mb-3 cstm-subeditor">
                                                                <label className="cstm-label">Description (Optional)</label>
                                                                <Editor
                                                                    placeholder='Enter long description'
                                                                    toolbarClassName="toolbarClassName"
                                                                    wrapperClassName="wrapperClassName"
                                                                    editorClassName="editorClassName"
                                                                    editorState={editorState}
                                                                    defaultContentState={contentState}
                                                                    onContentStateChange={setContentState}
                                                                    onEditorStateChange={setEditorState}
                                                                    toolbar={toolbar}
                                                                    localization={localization}

                                                                />
                                                            </div>

                                                            <div className="text-center cstn-loader-sec cstn-loader-left ">
                                                                <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleAddSubcategory}>Submit</button>
                                                                {popBtnLoader &&
                                                                    <div className='loader-container-cstm'>
                                                                        <img className='btn-pop-loader' src={loaderimg} />
                                                                    </div>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-lg-12">
                                            <div className="table-responsive bg-white rounded">
                                                <table className="table mb-0 table-center">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-top w-4">No.</th>
                                                            <th className="border-top w-12">SubCategory</th>
                                                            <th className="border-top w-15">Category</th>
                                                            <th className="border-top w-10">Date</th>
                                                            <th className="border-top w-11">Action</th>
                                                        </tr>
                                                    </thead>
                                                    {loader ?
                                                        <div className='loader-container'>
                                                            <Loader />
                                                        </div> :
                                                        (subCategoryListing.length === 0 ? <span className="cstm-norecord">No Record Found</span> :
                                                            (
                                                                currentRecords.map((item, i) => (
                                                                    <tbody key={i}>
                                                                        <tr>
                                                                            <td className="fw-bold">{i + 1}</td>
                                                                            <td>{item.subCategoryName}</td>
                                                                            <td>{item.categoryId !== null ? item.categoryId.categoryName : "-"}</td>
                                                                            <td>{moment(item.createdAt).format('D MMM, YYYY')}</td>
                                                                            <td>
                                                                                <Link onClick={(e) => handleViewsubCategory(e, item._id)} className="cstm-btn6 action-btn" data-bs-toggle="modal" data-bs-target="#EditSubCate">
                                                                                    <i><img src={editIcon} /></i>
                                                                                </Link>

                                                                                <Link className="cstm-btn6" onClick={(e) => onClickgetId(e, item._id)} data-bs-toggle="modal" data-bs-target="#DeletesubCategory">
                                                                                    <i><img src={Deleteicon} /></i>
                                                                                </Link>
                                                                                <div className="modal fade cstm-add-bonus" id="DeletesubCategory" tabIndex="-1" aria-labelledby="exampleModalLabel1"
                                                                                    aria-hidden="true">
                                                                                    <div className="modal-dialog modal-dialog-centered">
                                                                                        <div className="modal-content">
                                                                                            <div className="modal-header border-0 p-4">
                                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                            </div>
                                                                                            <div className="modal-body p-4 pt-0">
                                                                                                <div className="mb-4">

                                                                                                    <div className="text-center mb-4">
                                                                                                        <img src={DeleteModel} />
                                                                                                    </div>
                                                                                                    <h4 className="text-center mb-4">Are you sure you want to delete subcategory?</h4>
                                                                                                    <div className="text-center cstn-loader-sec ">
                                                                                                        <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleDeleteSubcategory}>Delete Subcategory</button>
                                                                                                        {popBtnLoader &&
                                                                                                            <div className='loader-container-cstm'>
                                                                                                                <img className='btn-pop-loader' src={loaderimg} />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>




                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                ))
                                                            )
                                                        )
                                                    }
                                                </table>

                                                <div className="modal fade" id="EditSubCate" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content">
                                                            {dataLoader ?
                                                                <div className='model-loader'>
                                                                    <Loader />
                                                                </div> :
                                                                <>
                                                                    <div className="modal-header border-0 p-4">
                                                                        <h4 className="modal-title" id="exampleModalLabel1">Edit Subcategory</h4>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body p-4 pt-0">
                                                                        <div className="mb-3">
                                                                            <label className="cstm-label">Select Category<span className="cstm-star">*</span></label>
                                                                            <select className="cstm-select cstm-om-slc" name='categoryId' onChange={handleEditOnchange} value={subCategoryEdit.categoryId}>
                                                                                <option value="">Select category</option>
                                                                                {categoryData.map((item) => (
                                                                                    <option key={item._id} value={item._id}>{item.categoryName}</option>
                                                                                ))}
                                                                            </select>
                                                                            <span className='error-message'>{error.categoryId}</span>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="cstm-label">Subcategory Name<span className="cstm-star">*</span></label>
                                                                            <input type="text" className="cstm-input" placeholder="Enter subcategory name" onChange={handleEditOnchange} name="subCategoryName" value={subCategoryEdit.subCategoryName} required />
                                                                            <span className='error-message'>{error.subCategoryName}</span>
                                                                        </div>

                                                                        <div className="mb-3">
                                                                            <label className="cstm-label">Subcategory Icon<span className="cstm-star">*</span></label>
                                                                            <input type="text" className="cstm-input" placeholder="Enter subcategory icon" onChange={handleEditOnchange} name="icon" value={subCategoryEdit.icon} required />
                                                                            <span className='error-message'>{error.icon}</span>
                                                                        </div>

                                                                        <div className="mb-3">
                                                                            <label className="cstm-label">Subcategory Short Description<span className="cstm-star">*</span></label>
                                                                            <input type="text" className="cstm-input" placeholder="Enter subcategory short description" onChange={handleEditOnchange} name="subCategoryShortDesc" value={subCategoryEdit.subCategoryShortDesc} required />
                                                                            <span className='error-message'>{error.subCategoryShortDesc}</span>
                                                                        </div>

                                                                        <div className="mb-3">
                                                                            <label className="cstm-label">Meta Title</label>
                                                                            <input type="text" className="cstm-input" placeholder="Enter meta title" onChange={handleEditOnchange} name="metaTitle" value={subCategoryEdit.metaTitle} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="cstm-label">Meta Description</label>
                                                                            <input type="text" className="cstm-input" placeholder="Enter meta description" onChange={handleEditOnchange} name="metaDesc" value={subCategoryEdit.metaDesc} required />
                                                                        </div>

                                                                        <div className="mb-4 cstm-subeditor">
                                                                            <label className="cstm-label">Description (Optional)</label>
                                                                            <Editor
                                                                                editorState={content}
                                                                                onEditorStateChange={handleEditorStateonChange}
                                                                                placeholder='Enter long description'
                                                                                toolbarClassName="toolbarClassName"
                                                                                wrapperClassName="wrapperClassName"
                                                                                editorClassName="editorClassName"
                                                                                toolbar={toolbar}
                                                                                localization={localization}
                                                                            />
                                                                        </div>



                                                                        <div className="text-center cstn-loader-sec cstn-loader-left ">
                                                                            <button disabled={popBtnLoader ? true : false} className="cstm-btn cstm-btn-text" onClick={handleEditSubcategory}>Edit</button>
                                                                            {popBtnLoader &&
                                                                                <div className='loader-container-cstm'>
                                                                                    <img className='btn-pop-loader' src={loaderimg} />
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Success Toast */}
                            <Snackbar
                                open={openSnackbar}
                                onClose={handleClose}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert variant='filled' onClose={handleClose} severity='success'>{message}</Alert>
                            </Snackbar>
                            {/* Error Toast */}
                            <Snackbar
                                open={errorSnackbar}
                                onClose={handleClose}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert variant='filled' onClose={handleClose} severity='error'>{message}</Alert>
                            </Snackbar>
                            {/* Pagination */}
                            <Pagination
                                recordPerPage={recordPerPage}
                                totalRecord={subCategoryListing.length}
                                paginate={paginate}
                                currentPage={currentPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                            />
                        </div>
                    </div>

                </main>
            </div>
        </>
    )
}

export default SubCategory;