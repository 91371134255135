import React, { useEffect, useState } from "react";
// components
import Toollist from "../../components/frontend/toollist/Toollist";
import Pagebreadcrumb from "../../components/frontend/pageBreadCrumb/PageBreadCrumb";
import frontendService from "../../services/frontend.service";
const Toollisting = () => {
  const [toolList, setToolList] = useState([]);
  const [initialData, setInitialData] = useState([]);
  var DataArray = [];
  //filteration input
  const [searchValue, setSearchValue] = useState("");
  const [searchMultiple, setsearchMultiple] = useState([]);
  const [statusFilterInput, setStatusFilterInput] = useState("");
  const [loader, setLoader] = useState(false);
 
  const excludeColumns = ["_id"];
  useEffect(() => {
    userToolListApi();
    setLoader(true)
  }, []);
  //creat data to display in table and filter
  function createData(
    _id,
    toolName,
    category,
    features,
    pricing,
    price,
    status
  ) {
    return {
      _id,
      toolName,
      category,
      features,
      pricing,
      price,
      status,
    };
  }
  async function userToolListApi() {
  
    try {
      const response = await frontendService.userToolListService();
      if (response.status === 200) {
        
        var arr = response.data.data;
        if (response.data.data.length > 0) {
          var newarr = [];
          for (var i = 0; i < arr.length; i++) {
            var tool = arr[i].toolName;
            var categoryData = arr[i].subCategoryData;
            var priceData = arr[i].pricingsData;
            var featuresData = arr[i].featuresData;

            var featureName =
              featuresData !== undefined &&
              featuresData !== null &&
              featuresData.length > 0
                ? featuresData.map((item, i) => {
                    if (item.name !== "" && item.name !== null) {
                      return item.name;
                    } else {
                      return "-";
                    }
                  })
                : "-";
            var priceNames =
              priceData !== undefined &&
              priceData !== null &&
              priceData.length > 0
                ? priceData.map((item, index) => {
                    if (item.name !== "" && item.name !== null) {
                      return item.name;
                    } else {
                      return "-";
                    }
                  })
                : "-";

            var categoryNames =
              categoryData !== undefined &&
              categoryData !== null &&
              categoryData.length > 0
                ? categoryData.map((item, i) => {
                    if (
                      item.subCategoryName !== null &&
                      item.subCategoryName !== ""
                    ) {
                      return item.subCategoryName;
                    } else {
                      return "-";
                    }
                  })
                : "-";
            var price =
              arr[i].startingPrice !== "" && arr[i].startingPrice !== undefined
                ? arr[i].startingPrice
                : "-";
            newarr[i] = createData(
              arr[i]._id,
              tool,
              categoryNames.toString(),
              featureName.toString(),
              priceNames.toString(),
              price,
              arr[i].status
            );
          }
          newarr.map((data1) => {
            DataArray = [...DataArray, data1];
          });
          DataArray = DataArray.sort().reverse();
          setInitialData(DataArray);
          setToolList(DataArray);
          setLoader(false)
        }
        if (
          searchValue !== "" &&
          searchValue !== undefined &&
          searchValue !== null
        ) {
          if (statusFilterInput === "") {
            var filterData = DataArray.filter((item) => {
              return Object.keys(item).some((key) =>
                excludeColumns.includes(key)
                  ? false
                  : item[key]
                      .toString()
                      .toLowerCase()
                      .includes(searchValue.toLowerCase().trim())
              );
            });

            setToolList(filterData);
            setLoader(false);
          } else {
            var filterData = DataArray.filter((item) => {
              return Object.keys(item).some((key) =>
                excludeColumns.includes(key)
                  ? false
                  : item[key]
                      .toString()
                      .toLowerCase()
                      .includes(searchValue.toLowerCase().trim())
              );
            });
            var filterStatusData = filterData.filter((item) => {
              return item.status === statusFilterInput;
            });

            setToolList(filterStatusData);
            setLoader(false);
          }
        } else {
          if (
            statusFilterInput !== "" &&
            statusFilterInput !== undefined &&
            statusFilterInput !== null
          ) {
            var filterStatusData = DataArray.filter((item) => {
              return item.status === statusFilterInput;
            });

            setToolList(filterStatusData);
            setLoader(false);
          } else {
            setToolList(DataArray);
            setLoader(false);
          }
        }
      }
    } catch (error) {}
  }
  //onchange filter function
  const onChangeFilter = (searchValue) => {
    setSearchValue(searchValue);

    if (searchValue !== "") {
      if (statusFilterInput === "") {
        const filteredData = initialData.filter((item) => {
          return Object.keys(item).some((key) =>
            excludeColumns.includes(key)
              ? false
              : item[key]
                  .toString()
                  .toLowerCase()
                  .includes(searchValue.toLowerCase().trim())
          );
        });

        setToolList(filteredData);
        setsearchMultiple(filteredData);
      } else {
        var filteredKeywords = initialData.filter((item) => {
          return item.status === statusFilterInput;
        });
        var finalFiltered = filteredKeywords.filter((item) => {
          return Object.keys(item).some((key) =>
            excludeColumns.includes(key)
              ? false
              : item[key]
                  .toString()
                  .toLowerCase()
                  .includes(searchValue.toLowerCase().trim())
          );
        });

        setToolList(finalFiltered);
      }
    } else {
      if (statusFilterInput === "") {
        setToolList(initialData);
      } else {
        var statusFiltered = initialData.filter((item) => {
          {
            return item.status === statusFilterInput;
          }
        });

        setToolList(statusFiltered);
      }
    }
  };
  //status wise filter function
  const onChangeStatusFilter = (e) => {
    setStatusFilterInput(e.target.value);

    if (e.target.value !== "") {
      if (searchValue !== "") {
        var finalFiltered = searchMultiple.filter((item) => {
          return item.status === e.target.value;
        });
        setToolList(finalFiltered);
      } else {
        var filteredKeywords = initialData.filter((item) => {
          return item.status === e.target.value;
        });
        setToolList(filteredKeywords);
        setsearchMultiple(filteredKeywords);
      }
      // setLoader(false)
    } else {
      if (searchValue !== "") {
        const filteredData = initialData.filter((item) => {
          return Object.keys(item).some((key) =>
            excludeColumns.includes(key)
              ? false
              : item[key]
                  .toString()
                  .toLowerCase()
                  .includes(searchValue.toLowerCase().trim())
          );
        });

        setToolList(filteredData);
        setsearchMultiple(filteredData);
      } else {
        setToolList(initialData);
      }
    }
  };
  return (
    <>
      <div className="spacer-132"></div>
      {/* Tool List */}
      <section className="ai-bg-color">
        <div className="ai-container">
          <Pagebreadcrumb path={"Tools"} />
          {/* Listing Screen */}
          <Toollist
            toolList={toolList}
            onChangeFilter={onChangeFilter}
            onChangeStatusFilter={onChangeStatusFilter}
            loader={loader}
          />
        </div>
      </section>
    </>
  );
};

export default Toollisting;
