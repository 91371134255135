import React, { useEffect, useRef, useState } from "react";

// components
import Bloglisting from "../../components/frontend/blogDetails/BlogListing";
import frontendService from "../../services/frontend.service";
import { useLocation, useNavigate } from "react-router-dom";
import Seo from "../../components/seo/Seo";

// Components
const Blog = () => {
  const [blogListing, setBlogListing] = useState([]);
  const [blogCategoryListing, setBlogCategoryListing] = useState([]);
  const [totalBlog, setTotalBlog] = useState("");
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState();
  const [openDropDown, setOpenDropdown] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [norecord, setNoRecordFound] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const closeMenu = useRef(null);
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const location = useLocation().search;
  const searchTerm = new URLSearchParams(location).get("searchTerm");
  

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    blogListApi(1, searchTerm, selectedCategory);
    blogCategoryListApi();
  }, []);
  useEffect(() => {
    //scroll listner
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, blogListing]);

  async function blogListApi(page, searchTerm, name) {
    setNoRecordFound(false);
    let queryString = "?";
    if (searchTerm !== null && name !== undefined) {
      queryString +=
        "&search=" +
        searchTerm.split("-").join(" ") +
        "&sortByCategory=" +
        name;
    } else if (name !== undefined) {
      queryString += "sortByCategory=" + name;
    }
    queryString += `&page=${page}&limit=20`;
    try {
      const response = await frontendService.blogListingService(queryString);

      if (response.status === 200) {
        setLoading(false);
        setTotalBlog(response.data.data.pagination.totalItems);
        if (response.data.data.response.length === 0) {
          setBlogListing([]);
          setNoRecordFound(true);
        }
        if (searchTerm === null && name === "" && page === 1) {
          setBlogListing(response.data.data.response);
          setPageLimit(response.data.data.pagination.totalPages);
          setPage(page + 1);
        } else {
          if (page === 1) {
            setBlogListing(response.data.data.response);
          } else {
            setBlogListing([...blogListing, ...response.data.data.response]);
          }
        }
        setPageLimit(response.data.data.pagination.totalPages);
        setPage(page + 1);
      }
    } catch (error) {}
  }
  async function blogCategoryListApi() {
    try {
      const response = await frontendService.blogCategoryListService();
      if (response.status === 200) {
        setBlogCategoryListing(response.data.data);
      }
    } catch (error) {}
  }
  const handleScroll = () => {
    // Check if user has scrolled to the bottom of the page
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      if (page <= pageLimit) {
        blogListApi(page, searchTerm, selectedCategory);
      }
    }
  };
  const onChangeCategory = (e, name) => {
    setSelectedCategory(name);
    blogListApi(1, searchTerm, name);
  };
  const onTextChanged = (e) => {
    const value = e.target.value;
    setText(value);
  };
  const handleClickgetquery = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      navigate("/blog" + "?searchTerm=" + text.replace(/\s+/g, "-"));
      blogListApi(1, text, selectedCategory);
    }
  };
  const closeOpenMenus = (e) => {
    if (
      closeMenu.current &&
      openDropDown &&
      !closeMenu.current.contains(e.target)
    ) {
      setOpenDropdown(!openDropDown);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);
  return (
    <>
    <Seo title={"AIToolSpot AI Blog | Stay Updated on the Latest AI Tools & News "} description={"Stay updated on the latest AI tools, news, and trends. Read insightful articles and stay informed with AIToolSpot's AI Blog."}/>
      <div className="spacer-132"></div>
      <section className="ai-bg-color">
        <div className="ai-container">
          <Bloglisting
            blogCategoryListing={blogCategoryListing}
            openDropDown={openDropDown}
            setOpenDropdown={setOpenDropdown}
            noRecordFound={norecord}
            isLoading={isLoading}
            blogListing={blogListing}
            onTextChanged={onTextChanged}
            handleClickgetquery={handleClickgetquery}
            onChangeCategory={onChangeCategory}
            closeMenu={closeMenu}
            selectedCategory={selectedCategory}
            totalBlog={totalBlog}
          />
        </div>
      </section>
    </>
  );
};

export default Blog;
