import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import AddIcon from "@mui/icons-material/Add";
import { ArrowBackIos, KeyboardArrowDown } from "@mui/icons-material";
import { Alert, Snackbar } from "@mui/material";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Multiselect from "multiselect-react-dropdown";
import frontendService from "../../../services/frontend.service";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import localization1 from "../../../config/localization1";
import toolbar1 from "../../../config/toolbar1";

// CSS
import "./SubmitDetails.css";

// Images
import AialertIcon from "../../../assets/images/frontend/ai-alert-icon.svg";
import loaderimg from "../../../assets/images/loder2.gif";
import AicompleteCheck from "../../../assets/images/frontend/ai-complete-check.svg";

const Submitdetails = () => {
  //steps toggle
  const [formFtep1, setFormStep1] = useState(true);
  const [formFtep2, setFormStep2] = useState(false);
  //editor states
  const [editorState, setEditorState] = useState(() =>
  EditorState.createWithContent(ContentState.createFromText(""))
);
  const _contentState = ContentState.createFromText("");
  const raw = convertToRaw(_contentState);
  const [contentState, setContentState] = useState(raw);
  //for api response
  const [message, setMessage] = useState("");
  //input data fields
  const [toolData, setToolData] = useState({
    toolName: "",
    websiteUrl: "",
    shortDesc: "",
    subCategory: [],
    pricing: [],
    features: [],
    startingPrice: "",
  });
  //error state
  const [error, setError] = useState({
    toolName: "",
    websiteUrl: "",
    subCategory: "",
    price: "",
    img: "",
    associate: ""
  });
  //subcategory api data
  const [subCategoryData, setSubCategoryData] = useState([]);
  //pricing api data
  const [pricingData, setPricingData] = useState([]);
  //feature api data
  const [featureData, setFeatureData] = useState([]);
  //selected subcategory,pricing and features
  const [selectedSubCategory, setSelectedSubcategory] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [selectedFuture, setSelectedFuture] = useState([]);
  //isassociated toggle
  const [isAssociated, setIsAssociated] = useState("");
  //for images
  const [files, setFiles] = useState([]);
  //toggle toast
  const [toastOpen, setToastOpen] = useState(false);
  const navigate = useNavigate();
  //convert editor raw data to html
  const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  const [isLoggedin, setLoggedin] = useState(true);
  const [loader, setLoader] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  useEffect(() => {
    subCategoryApi();
    pricingApi();
    featureApi();
    if (localStorage.getItem("isUserOk")) {
      setLoggedin(false);
    } else {
      setLoggedin(true);
    }
  }, []);
  //subcategory api call function
  async function subCategoryApi() {
    try {
      const response = await frontendService.footerSubCategoryGetService();
      if (response.status === 200) {
        setSubCategoryData(response.data.data);
      }
    } catch (error) { }
  }
  //pricing api call function
  async function pricingApi() {
    try {
      const response = await frontendService.priceGetService();
      if (response.status === 200) {
        setPricingData(response.data.data);
      }
    } catch (error) { }
  }
  //feature api call function
  async function featureApi() {
    try {
      const response = await frontendService.featureGetService();
      if (response.status === 200) {
        setFeatureData(response.data.data);
      }
    } catch (error) { }
  }
  //addTool api call function
  async function addToolApi() {
    const formData = new FormData();
    formData.append("toolName", toolData.toolName);
    formData.append("websiteUrl", toolData.websiteUrl);
    toolData.subCategory.map((item) => formData.append("subCategoryId", item));
    toolData.features.map((item) => formData.append("featuresId", item));
    toolData.pricing.map((item) => formData.append("pricingId", item));
    formData.append("shortDesc", toolData.shortDesc);
    formData.append("startingPrice", toolData.startingPrice);
    formData.append("longDesc", editorData);
    formData.append("imageUrl", files[0]);
    formData.append("associated_with_product_or_company", isAssociated);
    try {
      const response = await frontendService.addToolService(formData);
      if (response.status === 200) {
        setToastOpen(true);
        setMessage(response.data.message);
        setLoader(false);
        setTimeout(() => {
          setBtnDisabled(false);
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      setLoader(false);
      setBtnDisabled(false);
    }
  }
  //url regex
  const regex = new RegExp(/^https?:\/\/.+/i);
  //validate function for step1
  const validateStep1 = (toolData) => {
    let isValid = false;
    let toolError = {};
    if (toolData.toolName.trim() === "") {
      isValid = true;
      toolError.toolName = "Please enter the tool name.";
    }
    if (toolData.websiteUrl.trim() === "") {
      isValid = true;
      toolError.websiteUrl = "Please enter the Website url.";
    } else if (!regex.test(toolData.websiteUrl)) {
      isValid = true;
      toolError.websiteUrl = "Please enter valid url.";
    } else {
      toolError.websiteUrl = "";
    }

    setError(toolError);
    return isValid;
  };
  //validate function for step2
  const validateStep2 = () => {
    let isValid = false;
    let toolError = {};
    if (selectedSubCategory.length === 0) {
      isValid = true;
      toolError.subCategory = "Please select category.";
    }
    if (selectedPrice.length === 0) {
      isValid = true;
      toolError.price = "Please select pricing.";
    }
    if (files.length === 0) {
      isValid = true;
      toolError.img = "Please select the tool image.";
    }
    if (isAssociated === "") {
      isValid = true;
      toolError.associate = "Please select option.";
    }
    setError(toolError);
    return isValid;
  };
  //handle next
  const handleNext = (e) => {
    e.preventDefault();
    if (localStorage.getItem("isUserOk")) {
      if (!validateStep1(toolData)) {
        setFormStep1(false);
        setFormStep2(true);
      }
    } else {
      setLoggedin(true);
    }
  };
  //handle previous
  const handleprev = (e) => {
    e.preventDefault();
    setFormStep1(true);
    setFormStep2(false);
  };
  //handle addTool
  const handleAddTool = (e) => {
    e.preventDefault();
    if (!validateStep2(toolData)) {
      selectedSubCategory.map((item) => toolData.subCategory.push(item._id));
      selectedPrice.map((item) => toolData.pricing.push(item._id));
      selectedFuture.map((item) => toolData.features.push(item._id));
      addToolApi();
      setLoader(true);
      setBtnDisabled(true);
    }
  };
  //handle onchange
  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setToolData({ ...toolData, [name]: value });
    switch (name) {
      case "toolName":
        value.trim() === ""
          ? setError({ ...error, toolName: "Please enter the tool name." })
          : setError({ ...error, toolName: "" });
        break;
      case "websiteUrl":
        value.trim() === ""
          ? setError({ ...error, websiteUrl: "Please enter the Website url." })
          : !regex.test(value)
            ? setError({ ...error, websiteUrl: "Please enter valid url." })
            : setError({ ...error, websiteUrl: "" });
        break;
      default:
        break;
    }
  };
  //handle associated
  const handleAssociated = (e) => {
    setIsAssociated(e.target.value);
    setError({ ...error, associate: "" })
  };
  //handle selectcategory
  const handleSelectCategory = (selectedList) => {
    setSelectedSubcategory(selectedList);
    selectedList.length === 0
      ? setError({ ...error, subCategory: "Please select category." })
      : setError({ ...error, subCategory: "" });
  };
  //handle selectfeatures
  const handleSelectfeature = (selectedList) => {
    setSelectedFuture(selectedList);
  };
  //handle selectprice
  const handleSelectprice = (selectedList) => {
    setSelectedPrice(selectedList);
    selectedList.length === 0
      ? setError({ ...error, price: "Please select price." })
      : setError({ ...error, price: "" });
  };
  //darg & drop img
  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    validator: (error) => {
      setError({ ...error, img: "" });
    },
  });
  //priview img
  const thumbs = files.map((file) => (
    <div key={file.name} className="col-12">
      <div className="ai-uploaded-container">
        <img
          className="ai-uploaded-items"
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));
  //img rejection
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  //handleclose toast
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };
  //allow only number
  const handleKeyPress = (event) => {
    const charCode = event.which || event.keyCode;
    const inputChar = String.fromCharCode(charCode);

    // Allow decimal point (ASCII code 46) and digits (ASCII codes 48 to 57)
    if (charCode === 46 || (charCode >= 48 && charCode <= 57)) {
      // Ensure only one decimal point is entered
      if (charCode === 46 && toolData.startingPrice.indexOf(".") !== -1) {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };
  const handleEditorStateChange = (newEditorState)=>{
    setEditorState(newEditorState)
  }
  return (
    <>
      <div className="ai-submi-inner">
        {/* Progress bar section */}
        <div className="submit-progress-sec">
          <div
            className={
              formFtep2 ? "ai-sub-progressbar active" : "ai-sub-progressbar"
            }
          >
            <span className="ai-progrecss-completed"></span>
            <img className="ai-step-comp" src={AicompleteCheck} alt="" />
          </div>
          <div className="ai-ends-name">
            <p className="ai-p-15">Startup Details</p>
            <p className="ai-p-15">Additional Details</p>
          </div>
        </div>

        {/* Multisteps form */}

        <div className="ai-submit-multi-form">
          {/* Step-1 */}
          {formFtep1 && (
            <div className="ai-submit-step-1 ">
              <div className="ai-step-heading">
                <h4 className="ai-h4">Submit A Tool</h4>
                <p className="ai-p-base">
                  It's free and only takes a minute.
                </p>
                <p className="ai-p-15-bold">
                  We strive to review each submitted tool within 7 days and add it to the directory.
                </p>
              </div>

              {/* Alert section */}
              {isLoggedin ? (
                <div className="ai-submit-alert">
                  <div className="ai-alert-icon">
                    <h6 className="ai-h6">
                      <img src={AialertIcon} /> Info
                    </h6>
                  </div>
                  <p className="ai-p-15">Please log in before adding a tool</p>
                </div>
              ) : (
                ""
              )}

              {/* Form */}
              <form>
                <div className="row">
                  <div className="ai-form-group col-md-6 ">
                    <label htmlFor="toolName">Tool Name</label>
                    <input
                      type="text"
                      className="ai-form-control"
                      id="toolName"
                      value={toolData.toolName}
                      name="toolName"
                      placeholder="Copy AI"
                      onChange={handleOnchange}
                    />
                    <span className="error-message ai-error-message ">{error.toolName}</span>
                  </div>
                  <div className="ai-form-group col-md-6">
                    <label htmlFor="toolUrl">Website URL</label>
                    <input
                      onChange={handleOnchange}
                      value={toolData.websiteUrl}
                      name="websiteUrl"
                      type="url"
                      className="ai-form-control"
                      id="toolUrl"
                      placeholder="https://copy.ai"
                    />
                    <span className="error-message ai-error-message ">{error.websiteUrl}</span>
                  </div>
                  <div className="ai-form-group col-12">
                    <label htmlFor="toolDescription">
                      Tool’s short description (Optional)
                    </label>
                    <input
                      onChange={handleOnchange}
                      value={toolData.shortDesc}
                      name="shortDesc"
                      type="text"
                      className="ai-form-control"
                      id="toolDescription"
                      placeholder="Please provide a short description"
                    />
                  </div>
                  <div className="ai-form-group col-12">
                    <label htmlFor="toolDescription">
                      Tool Description (Optional)
                    </label>
                    <Editor
                      placeholder="Please provide a brief description of your tool, including its functionality and purpose. If you would like to offer any deals or discount codes exclusively for AIToolSpot users, please include them here as well."
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      editorState={editorState}
                      onEditorStateChange={handleEditorStateChange}
                      toolbar={toolbar1}
                      localization={localization1}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="ai-form-btn"
                  onClick={handleNext}
                >
                  Next
                </button>
              </form>
            </div>
          )}

          {/* Step - 2  */}
          {formFtep2 && (
            <div className="ai-submit-step-2 ">
              <div className="ai-multi-back-btn-sec">
                <button onClick={handleprev} className="ai-multi-back-btn">
                  <ArrowBackIos /> Back
                </button>
              </div>
              <div className="ai-step-heading">
                <h4 className="ai-h4">Additional Details</h4>
              </div>
              <form>
                <div className="row">
                  <div className="ai-form-group col-md-6 ai-border-clr">
                    <div className="ai-form-control">
                      <label htmlFor="selectCategories">
                        Select Categories (max 3)
                      </label>
                      <Multiselect
                        placeholder={"Categories"}
                        hidePlaceholder
                        onSearch={function noRefCheck() { }}
                        selectionLimit={3}
                        onSelect={handleSelectCategory}
                        onRemove={handleSelectCategory}
                        displayValue="subCategoryName"
                        options={subCategoryData.map((item) => ({
                          subCategoryName: item.subCategoryName,
                          _id: item._id,
                        }))}
                      />
                    </div>
                    <span className="error-message ai-error-message ">{error.subCategory}</span>
                    <div className="ai-select-down-icon">
                      <KeyboardArrowDown />
                    </div>
                  </div>
                  <div className="ai-form-group col-md-6 ai-border-clr">
                    <div className="ai-form-control">
                      <label htmlFor="selectFeatures">
                        Select Features (optional)
                      </label>
                      <Multiselect
                        placeholder={"Features"}
                        hidePlaceholder
                        onSearch={function noRefCheck() { }}
                        onSelect={handleSelectfeature}
                        onRemove={handleSelectfeature}
                        displayValue="featureName"
                        options={featureData.map((item) => ({
                          featureName: item.name,
                          _id: item._id,
                        }))}
                      />
                    </div>
                    <div className="ai-select-down-icon">
                      <KeyboardArrowDown />
                    </div>
                  </div>
                  <div className="ai-form-group col-md-6 ai-border-clr">
                    <div className="ai-form-control">
                      <label htmlFor="selectPricing">
                        Pricing-Select freemium if your tool has both paid and
                        free versions Pricing
                      </label>
                      <Multiselect
                        placeholder={"Pricing"}
                        hidePlaceholder
                        onSearch={function noRefCheck() { }}
                        onSelect={handleSelectprice}
                        onRemove={handleSelectprice}
                        displayValue="pricingName"
                        options={pricingData.map((item) => ({
                          pricingName: item.name,
                          _id: item._id,
                        }))}
                      />
                    </div>
                    <div className="ai-select-down-icon">
                      <KeyboardArrowDown />
                    </div>
                  </div>

                  <div className="ai-form-group col-md-6">
                    <label htmlFor="startPrice">
                      Starting Price (Optional)
                    </label>
                    <input
                      type="text"
                      className="ai-form-control"
                      id="startPrice"
                      placeholder="Please enter starting price"
                      value={toolData.startingPrice}
                      name="startingPrice"
                      onChange={handleOnchange}
                      onKeyPress={handleKeyPress}
                      autoComplete="off"
                    />
                  </div>
                  <div className="ai-form-group col-lg-12">
                    <div className="mb-0">
                      <label className="ai-tool-image-label">
                        Tool image<span className="cstm-astix">*</span>
                      </label>
                      <div
                        {...getRootProps({
                          className: "ai-form-control dropzone blog-dropzone",
                        })}
                      >
                        <input {...getInputProps()} />
                        <p>
                          <span>
                            <AddIcon />
                          </span>
                          Drop your image here, or <span> browse</span>
                        </p>
                      </div>
                      <aside>
                        <div className="row">{thumbs}</div>
                      </aside>
                      <span className="error-message ai-error-message ">
                        {error.img || fileRejectionItems}
                      </span>
                    </div>
                  </div>
                  <div className="ai-form-group col-12">
                    <p className="ai-p-15-bold">
                      Are you associated with the product or company?
                    </p>
                    <div className="ai-custom-radio">
                      <div>
                        <input
                          type="radio"
                          id="Yes"
                          name="radio-group"
                          value="yes"
                          onChange={handleAssociated}
                          required
                        />
                        <label htmlFor="Yes">Yes</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="No"
                          name="radio-group"
                          value="no"
                          onChange={handleAssociated}
                          required
                        />
                        <label htmlFor="No">No</label>
                      </div>
                    </div>
                    <span className="error-message ai-error-message">{error.associate}</span>
                  </div>
                </div>
                <div className="ai-submit-btn-loader">
                  <button
                    type="submit"
                    className="ai-form-btn"
                    onClick={handleAddTool}
                    disabled={btnDisabled ? true : false}
                  >
                    Submit Tool
                  </button>
                  {loader && (
                    <div className="ai-submit-loader">
                      <img src={loaderimg} />
                    </div>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
        <Snackbar
          open={toastOpen}
          onClose={handleClose}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} variant="filled" severity="success">
            {message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default Submitdetails;
