import React from 'react'

const Blogicon = () => {
  return (
    <>
     <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    className="ai-side-icons"
  >
    <path
      fill="#011367"
      d="M21.994 6.99c-.071.09-.132.235-.225.263-.188.062-.408.118-.59.079-.231-.05-.347-.264-.347-.516-.005-.393 0-.78 0-1.194H3.201v.314c0 3.616.022 9.267-.011 12.884-.011 1.222.881 1.995 1.96 1.99 4.572-.028 9.144-.011 13.71-.011 1.25 0 1.967-.724 1.967-1.996V13.57c0-.454.12-.667.418-.756.303-.09.518.022.755.398v6.184c-.05.18-.094.359-.149.533-.418 1.289-1.509 2.057-2.924 2.063H7.855c-1.008 0-2.021.022-3.03-.011-1.14-.045-1.982-.623-2.489-1.66-.176-.359-.226-.768-.336-1.15V4.804c.017-.073.039-.14.05-.213.209-1.233.903-2.046 2.065-2.439.21-.073.424-.1.64-.151H19.24c.061.017.122.04.182.045.865.118 1.57.521 2.033 1.267.248.398.363.88.54 1.323V6.99ZM3.333 4.4H20.7c-.044-.107-.072-.191-.116-.27-.374-.633-.925-.913-1.652-.913-4.616.005-9.232 0-13.847.005-.11 0-.22 0-.33.011A1.684 1.684 0 0 0 3.332 4.4Z"
    />
    <path
      fill="#011367"
      d="M12.01 16.331c0-.358-.028-.716.01-1.074.023-.193.094-.424.226-.562a928.07 928.07 0 0 1 6.147-6.158c.727-.722 1.78-.71 2.518 0 .226.215.446.435.66.661.7.733.722 1.785.012 2.5-2.05 2.066-4.11 4.12-6.175 6.17a.861.861 0 0 1-.534.22c-.733.028-1.47.017-2.204.006-.435-.006-.655-.237-.66-.672-.006-.358 0-.727 0-1.091Zm5.85-5.596a3576.408 3576.408 0 0 0-4.567 4.572c-.05.05-.1.12-.105.181-.01.463-.005.931-.005 1.432.44 0 .848-.005 1.256.006a.572.572 0 0 0 .457-.182c1.426-1.438 2.864-2.87 4.296-4.302.072-.066.182-.093.242-.126a454.531 454.531 0 0 0-1.575-1.581Zm.87-.915c.528.535 1.024 1.03 1.547 1.56.154-.16.32-.331.48-.513.23-.264.236-.562 0-.82-.22-.243-.458-.48-.7-.7-.259-.232-.556-.226-.815.005-.188.165-.358.33-.513.468ZM9.662 10.067H5.206c-.1 0-.204 0-.303-.006a.582.582 0 0 1-.05-1.151c.083-.016.165-.01.248-.01h9.105c.066 0 .127-.006.193.005.314.033.545.28.545.584a.571.571 0 0 1-.55.572c-.304.017-.607.006-.91.006H9.662ZM7.31 13.112c-.782 0-1.559.01-2.341-.006-.468-.005-.744-.457-.534-.848a.551.551 0 0 1 .512-.32c1.57-.005 3.14-.005 4.704 0 .336 0 .584.254.59.573.005.325-.243.584-.596.59-.776.016-1.553.01-2.335.01Z"
    />
  </svg>
    </>
  )
}

export default Blogicon