import React, { useEffect } from 'react'
import SponsorOptions from '../../components/frontend/sponsorShip/SponsorOptions'
import SponsorShipForm from '../../components/frontend/sponsorShip/SponsorShipForm'
import Pagebreadcrumb from '../../components/frontend/pageBreadCrumb/PageBreadCrumb'
import Seo from '../../components/seo/Seo'

const Sponsor = () => {
  useEffect(() => {
    window.scrollTo({
      top: -50,
      left: -50,
      behavior: "smooth",
    });
  }, [])
  return (
    <>
      <Seo title={"AI Tool Sponsorship | Elevate Your Brand with AIToolSpot"} description={"Partner with AIToolSpot and promote your AI Tool to a targeted audience of AI enthusiasts and developers. Learn about sponsorship opportunities here."} />
      <div className="spacer-132"></div>
      <section className="ai-bg-color">
        <div className="ai-container">
          <Pagebreadcrumb path={'Sponsorships'} />
          <div className="ai-sponsor-main">
            <SponsorOptions />
            <SponsorShipForm />
          </div>
        </div>
      </section>
    </>
  )
}

export default Sponsor