import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Cookies from "js-cookie";
// Components
import { bucketUrl } from "../../../services/admin.service";
import Toolcomments from "./ToolComments";
import Aicardcomponent from "../aiCard/AiCardComponent";
import Ratingmodal from "../modalBox/ratingModal/RatingModal";
import frontendService from "../../../services/frontend.service";
import Editratingmodal from "../modalBox/ratingModal/EditRatingModal";
import Deletemodal from "../modalBox/ratingModal/DeleteModal";
import Promotesvg from "./PromoteSvg";
// CSS
import "./ToolDetailsContent.css";

//mui accodian
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Images
import aiLinks from "../../../assets/images/frontend/ai-links.svg";
import aiBadge from "../../../assets/images/frontend/ai-badge.svg";
import aiToolShare from "../../../assets/images/frontend/ai-tool-share.svg";
import aiProductImg from "../../../assets/images/frontend/ai-product-img.png";
import Verify from "../../../assets/images/frontend/verified.svg";
import aiCalender from "../../../assets/images/frontend/ai-calender.svg";
import aiExtension from "../../../assets/images/frontend/ai-extension.svg";
import aiCodecopy from "../../../assets/images/frontend/ai-code-copy.svg";
import aiBadgeoutline from "../../../assets/images/frontend/ai-badge-outline.svg";
import arrowDown from "../../../assets/images/frontend/arrow-down.svg"
// Icons
import { Alert, Rating, Snackbar } from "@mui/material";
import Temptoolcomment from "./TempToolComment";
import ShareCard from "../shareCard/ShareCard";
import Seo from "../../seo/Seo";

const Tooldetailscontent = ({
  detailData,
  similarData,
  reviewData,
  toolReviewGetApi,
  avgReviewCount,
}) => {
  //modelbox toggle state
  const [isActive, setIsActive] = useState(false);
  const [styleShow, setStyleShow] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isEditStyle, setEditStyle] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isDeleteStyle, setDeleteStyle] = useState(false);
  const [showRating, setShowRating] = useState(true);
  const [showPricing, setShowPricing] = useState(true)
  const [toggleShareBtn, setToggleShareBtn] = useState(null);
  //toast toggle state
  const [toastOpen, setToastOpen] = useState(false);
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [errorToast, setErrorToast] = useState(false)
  //api response message
  const [message, setMessage] = useState("");
  //after add review edit delete review show
  const [review, setReview] = useState(false);
  //accordion faq
  const initialAccordionState = Array.from(detailData.FAQ !== undefined && { length: detailData.FAQ.length }, () => false);
  const [accordionState, setAccordionState] = useState(initialAccordionState)
  //after add review review data
  const [userReviewData, setUserReviewData] = useState({});
  //rating
  const [rating, setRating] = useState(0);
  const [toolId, setToolId] = useState("");
  const [reviewId, setReviewId] = useState("");
  //favourite toggle
  const [favourite, setFavourite] = useState(false)
  //favourite count
  const [favoritesCount, setFavouriteCount] = useState(detailData !== undefined ? detailData.countFavouritesToolData : 0)
  //check if user loggedin or not
  var isUserOk = localStorage.getItem("isUserOk")
  const [editorData, setEditorData] = useState(EditorState.createEmpty());
  const [contenthtml, setContenthtml] = useState();
  const open = Boolean(toggleShareBtn);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    //check user tool favourite or not
    if (detailData.userfavouritesToolData.length > 0) {
      setFavourite(true)
    } else {
      setFavourite(false)
    }
  }, [detailData])
  useEffect(() => {

    reviewData.filter((item) =>
      item.userData !== undefined &&
        item.userData[0]._id === Cookies.get("userId")
        ? setShowRating(false) : setShowRating(true)
    );
    detailData.pricingsData.filter((item) => item.name === 'Free' ? setShowPricing(false) : setShowPricing(true))
  });
  //filterBykeyWord api
  async function filterBykeyWordApi(
    page,
    searchTerm,
    priceArray,
    selectedFeature,
    selectedStatus
  ) {
    let queryString = "?";
    //updating querystring and payload
    let sortByData =
      selectedStatus !== undefined &&
        selectedStatus !== null &&
        selectedStatus !== ""
        ? selectedStatus
        : "";
    

    queryString += `&limit=10&page=${1}`;
    const bodyData = {
      pricing: priceArray !== undefined ? priceArray : [],
      features: selectedFeature !== undefined ? selectedFeature : [],
    };
    const response = await frontendService.filterBykeyWord(
      queryString,
      bodyData
    );
    
  }
  //addfavourite tool api
  async function addToolFavoriteApi(toolId) {
    const bodydata = {
      toolId: toolId
    }
    try {
      const response = await frontendService.addToolFavoriteService(bodydata)
      if (response.status === 200) {
      }
    } catch (error) {
    }
  }
  //remove tool from favourite
  async function removeToolFavoriteapi(toolId) {
    const bodydata = {
      toolId: toolId
    }
    try {
      const response = await frontendService.removeToolFavoriteService(bodydata)
      if (response.status === 200) {
      }
    } catch (error) {
    }
  }
  async function reviewViewApi(id) {
    try {
      const response = await frontendService.reviewViewService(id);
      if (response.status === 200) {
        setRating(response.data.data.rating);
        setContenthtml(response.data.data.review);
        setToolId(response.data.data.toolId._id);
        const contentBlock = htmlToDraft(response.data.data.review);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorData(editorState);
      }
    } catch (error) { }
  }
  async function reviewEditApi() {
    const bodyData = {
      toolId: toolId,
      review: contenthtml,
      rating: rating,
    };
    try {
      const response = await frontendService.reviewEditService(
        reviewId,
        bodyData
      );
      if (response.status === 200) {
        setUserReviewData(response.data.data)
        setSuccessToastOpen(true);
        setMessage(response.data.message);
        toolReviewGetApi();
        handleEditClose();
      }
    } catch (error) { }
  }
  async function reviewDeleteApi(id) {
    try {
      const response = await frontendService.reviewDeleteService(id);
      if (response.status === 200) {
        setSuccessToastOpen(true);
        setMessage(response.data.message);
        toolReviewGetApi();
        handleDeleteClose();
        setReview(false)
        setShowRating(true)
      }
    } catch (error) { }
  }
  const handleEditOnclick = (e, id) => {
    if (id !== undefined) {
      reviewViewApi(id);
      setReviewId(id);
    }
    if (localStorage.getItem("isUserOk")) {
      setEdit(!isEdit);
      setEditStyle(!isEditStyle);
      document.body.classList.add("ai-backdrop");
    } else {
      setToastOpen(true);
      setMessage("Please login to leave a review");
    }
  };

  const handleDeleteOpen = (e, id) => {
    if (id !== undefined) {
      setReviewId(id);
    }
    if (localStorage.getItem("isUserOk")) {
      setDelete(!isDelete);
      setDeleteStyle(!isDeleteStyle);
      document.body.classList.add("ai-backdrop");
    } else {
      setToastOpen(true);
      setMessage("Please login to leave a review");
    }
  };
  const handleEditReview = () => {
    reviewEditApi();
  };
  const handleEditClose = () => {
    setRating(0);
    setTimeout(() => {
      setEdit(!isEdit);
    }, 200);
    setTimeout(() => {
      setEditStyle(!isEditStyle);
    }, 500);
    document.body.classList.remove("ai-backdrop");
  };
  const handleDeleteClose = () => {
    setRating(0);
    setTimeout(() => {
      setDelete(!isDelete);
    }, 200);
    setTimeout(() => {
      setDeleteStyle(!isDeleteStyle);
    }, 500);
    document.body.classList.remove("ai-backdrop");
  };
  const handleDeleteReview = () => {
    reviewDeleteApi(reviewId);
  };
  const handleClick = () => {
    // toggle isActive state on click
    if (localStorage.getItem("isUserOk")) {
      setTimeout(() => {
        setIsActive(!isActive);
        setStyleShow(!styleShow);
      }, 0);
      document.body.classList.add("ai-backdrop");
    } else {
      setToastOpen(true);
      setMessage("Please login to leave a review");
    }
  };
  //modal handle close
  const handleClose = () => {
    setTimeout(() => {
      setIsActive(!isActive);
    }, 200);
    setTimeout(() => {
      setStyleShow(!styleShow);
    }, 500);
    setTimeout(() => {
      document.body.classList.remove("ai-backdrop");
    }, 500);
  };
  //get data from add review api message
  const handleToast = (data, userReviewData) => {
    setSuccessToastOpen(true);
    setMessage(data);
    setReview(true);
    setUserReviewData(userReviewData);
  };
  //handleclose toast
  const handleClosetoast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessToastOpen(false);
    setToastOpen(false);
    setErrorToast(false)
  };
  //handle rating
  const handleRating = (e, newValue) => {
    if (localStorage.getItem("isUserOk")) {
      setRating(newValue);
    }
  };
  //editor state onchange
  const handleEditorStateonChange = (editorState) => {
    setContenthtml(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setEditorData(editorState);
  };

  const finalReviewData = reviewData.filter(
    (item) => item.status !== "Pending" && item.status !== "UnApprove"
  );
  //copyEmbedcode
  const copyHtml = () => {
    let html = document.getElementById('clipboard').innerHTML
    navigator.clipboard.writeText(html)
    setSuccessToastOpen(true)
    setMessage('Copied to clipboard!')
  }
  //toggle share popover
  const handleShareBtnClick = (event) => {
    setToggleShareBtn(event.currentTarget);
    document.body.classList.add("ai-backdrop");
  };
  const handleShareBtnClose = () => {
    setToggleShareBtn(null);
    setTimeout(() => {
      document.body.classList.remove("ai-backdrop");
    }, 500);
  };
  //handle favourite 
  const handleFavourite = (toolId, status) => {
    if (status === true && localStorage.getItem("isUserOk")) {
      removeToolFavoriteapi(toolId)
      setFavourite(false)
      setFavouriteCount(favoritesCount - 1)
    } else if (status === false && localStorage.getItem("isUserOk")) {
      addToolFavoriteApi(toolId)
      setFavouriteCount(favoritesCount + 1)
      setFavourite(true)
    } else {
      setErrorToast(true)
      setMessage("Please login to save to favourites")
    }
  }
  //accordion function for index
  const toggleAccordionItem = (index) => {
    const updatedAccordionState = [...accordionState];
    updatedAccordionState[index] = !updatedAccordionState[index];
    setAccordionState(updatedAccordionState);
  };
  return (
    <>
    <Seo title={detailData.metaTitle} description={detailData.metaDesc}/>
      <div className="ai-tool-detail-content">
        <>
          <div className="ai-content-heading" key={detailData._id}>
            <div className="ai-content-header">
              <h2 className="ai-h2-28">
                {detailData.toolName}
              </h2>
              <button className="btn-share" type="button">
                <img src={aiToolShare} alt="" />
              </button>

              <Link
                to={
                  detailData.isAffiliated
                    ? detailData.websiteUrl
                    : detailData.websiteUrl +
                    "?utm_source=aitoolspot&utm_medium=website&utm_campaign=aitoolspot"
                }
                rel="noreferrer"
                target={"_blank"}
              >
                <img src={aiLinks} alt="" />
                <span>Visit</span>
              </Link>
              <div className="ai-small-rating">
                <span className="ai-star-tool">
                  <Rating
                    value={detailData.countRatingAvg}
                    precision={0.5}
                    readOnly
                    className="ai-rated"
                  />
                </span>
                <span>{detailData.countReviewsData} Reviews</span>
              </div>
            </div>
            <div className="ai-sharing-sec">
              <Link to={
                detailData.isAffiliated
                  ? detailData.websiteUrl
                  : detailData.websiteUrl +
                  "?utm_source=aitoolspot&utm_medium=website&utm_campaign=aitoolspot"} target="_blank">
                <img src={aiLinks} alt="" />
                <span>Visit</span>
              </Link>
              {favourite && isUserOk ?
                <button className="btn-save" type="button" onClick={() => handleFavourite(detailData._id, true)}>
                  <img src={aiBadge} alt="aiBadge" />
                  <span>{favoritesCount}</span>
                </button> : <button className="btn-save" type="button" onClick={() => handleFavourite(detailData._id, false)}>
                  <img src={aiBadgeoutline} alt="" />
                  <span>{favoritesCount}</span>
                </button>}
              <button
                className="btn-share"
                type="button"
                onClick={handleShareBtnClick}
              >
                <img src={aiToolShare} alt="" />
              </button>
              <ShareCard
                url={window.location.href}
                toggleShareBtn={toggleShareBtn}
                open={open}
                id={id}
                quote={'The #1 AI Tools Platform, Empowering Your AI Success.'}
                handleShareBtnClose={handleShareBtnClose}
              />
            </div>
          </div>
          {/* Product information */}
          <div className="ai-product-heading">
            <h6 className="ai-h6-18">Product Information</h6>
          </div>
          <div className="ai-product-container">
            <div className="row">
              <div className="col-lg-5 col-xl-4">
                <Link
                  to={detailData.isAffiliated
                    ? detailData.websiteUrl
                    : detailData.websiteUrl +
                    "?utm_source=aitoolspot&utm_medium=website&utm_campaign=aitoolspot"}
                  rel="noreferrer"
                  target={"_blank"}
                  className="ai-product-img-sec"
                >
                  <img
                    src={
                      detailData.imageUrl !== undefined
                        ? bucketUrl + detailData.imageUrl
                        : aiProductImg
                    }
                    alt=""
                  />
                  <div className="ai-product-img-hover">
                    <p className="ai-p-17">Visit website</p>
                  </div>
                </Link>
              </div>
              <div className="col-lg-7 col-xl-6">
                <div className="ai-product-details">
                  <h6 className="ai-h6-18">{detailData.shortDesc}</h6>
                  {detailData.isVerified ? (
                    <div className="ai-extra-details verify">
                      <img src={Verify} alt="" />
                      <p className="ai-p-base">
                        This tool is verified because it is either an
                        established company or has good social media presence
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="ai-extra-details added-date">
                    <img src={aiCalender} alt="" />
                    <p className="ai-p-base">
                      Added on {moment(detailData.createdAt).format("MMMM D")}
                    </p>
                  </div>

                  <div className="ai-details-plans">
                    <div className="ai-details-plans-badge">
                      {detailData.pricingsData.map((pricing) => (
                        <div className="ai-details-budge" key={pricing._id}>
                          <span>{pricing.name}</span>
                        </div>
                      ))}
                    </div>
                    {showPricing &&
                      <p className="ai-p-15">
                        Paid plans start from ${detailData.startingPrice}
                      </p>
                    }
                  </div>

                  <div className="ai-extension-container">
                    {detailData.featuresData.map((features) => (
                      <button className="ai-extension-btn" key={features._id}>
                        <img src={aiExtension} alt="" />
                        <span>{features.name}</span>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Copy code */}
          <div className="ai-embed-code-sec">
            <div className="ai-embed-img-sec">
              <div className="ai-promote-svg">
                <Promotesvg favCount={detailData.countFavouritesToolData} />
              </div>
              <div className="ai-promote-svg" id="clipboard" style={{ display: 'none' }} >
                <Link to={window.location.href}>
                  <Promotesvg favCount={detailData.countFavouritesToolData} />
                </Link>
              </div>
              <Link className="ai-embed-code-link" onClick={copyHtml}>
                <img src={aiCodecopy} alt="" /> COPY EMBED CODE
              </Link>
            </div>
            <Link to="/sponsorship" target="_blank" className="ai-embed-link">
              Promote your tool
            </Link>
          </div>
          {/* Featured sec */}
          <div className="ai-tool-featured-sec">
            <div className="row">
              <div className="col-lg-8">
                <div className="ai-featured-content">
                  <h4 className="ai-h4">{detailData.toolName} Overview</h4>
                  <div
                    className="ai-html-content"
                    dangerouslySetInnerHTML={{ __html: detailData.longDesc }}
                  ></div>
                </div>

                {/* FAQ */}
                {
                  detailData.isFAQ !== undefined && detailData.FAQ !== undefined && detailData.isFAQ && detailData.FAQ.length > 0 ?
                    <>
                      <div className="ai-html-content">
                        <h2 className="ai-h4 mb-4 text-left">Frequently Asked Questions</h2>
                      </div>
                      {detailData.FAQ.map((item, index) => (
                        <>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <div className="ai-html-content">
                                <h4>{item.que}</h4>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="ai-html-content">
                                <p className="ai-faq-content-main">
                                  {item.ans}
                                </p>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      ))}
                    </>
                    :
                    ""
                }

              </div>
            </div>
          </div>
          {/* Reviews Sections */}
          <div className="ai-tool-reviews-sec">
            <div className="ai-tool-reviews-headings">
              <h4 className="ai-h4">{detailData.toolName} Reviews</h4>
              <div className="ai-tool-reviews-card">
                <div className="ai-tool-reviews-card-content">
                  <h5 className="ai-p-15-bold">
                    What do you think about {detailData.toolName}?
                  </h5>
                  <p className="ai-p-13">Leave a review for the community</p>
                </div>
                <div
                  className="ai-tool-reviews-star-sec"
                  onClick={handleClick}
                >
                  {showRating && (
                    <Rating
                      value={rating}
                      onChange={(e, newValue) => handleRating(e, newValue)}
                    />
                  )}
                </div>
              </div>
              {review && (
                <div className="row">
                  <div className="col-md-6">

                    <Temptoolcomment
                      userReviewData={userReviewData}
                      handleDeleteOpen={handleDeleteOpen}
                      handleClick={handleEditOnclick}
                    />
                  </div>
                </div>
              )}
              {finalReviewData.length > 0 ? (
                <div className="row">
                  <div className="col-md-6">
                    {finalReviewData.map((item) => (
                      <Toolcomments
                        handleDeleteOpen={handleDeleteOpen}
                        handleClick={handleEditOnclick}
                        key={item._id}
                        data={item}
                      />
                    ))}
                  </div>
                  <div className="col-md-6">
                    <div className="ai-review-main">
                      <div className="ai-review-counts-sec">
                        <p className="ai-p-15">
                          <span className="ai-review-counts">
                            {detailData.countRatingAvg.toFixed(2)}
                          </span>
                          <span>
                            Based on {detailData.countReviewsData} reviews
                          </span>
                        </p>
                      </div>
                      <div className="ai-review-container">
                        <div className="ai-reviewbar">
                          <span>5</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: `${avgReviewCount.fiveStarAvg !== null
                                  ? avgReviewCount.fiveStarAvg.toFixed(2)
                                  : 0
                                  }%`,
                                backgroundColor: "#ECB016",
                              }}
                            ></div>
                          </div>
                          <span>
                            {avgReviewCount.fiveStarAvg !== null
                              ? avgReviewCount.fiveStarAvg.toFixed(2)
                              : 0}
                            %
                          </span>
                        </div>
                        <div className="ai-reviewbar">
                          <span>4</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: `${avgReviewCount.fourStarAvg !== null
                                  ? avgReviewCount.fourStarAvg.toFixed(2)
                                  : 0
                                  }%`,
                                backgroundColor: "#BF67F5",
                              }}
                            ></div>
                          </div>
                          <span>
                            {avgReviewCount.fourStarAvg !== null
                              ? avgReviewCount.fourStarAvg.toFixed(2)
                              : 0}
                            %
                          </span>
                        </div>
                        <div className="ai-reviewbar">
                          <span>3</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: `${avgReviewCount.threeStarAvg !== null
                                  ? avgReviewCount.threeStarAvg.toFixed(2)
                                  : 0
                                  }%`,
                                backgroundColor: "#2EA1F4",
                              }}
                            ></div>
                          </div>
                          <span>
                            {avgReviewCount.threeStarAvg !== null
                              ? avgReviewCount.threeStarAvg.toFixed(2)
                              : 0}
                            %
                          </span>
                        </div>
                        <div className="ai-reviewbar">
                          <span>2</span>{" "}
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: `${avgReviewCount.twoStarAvg !== null
                                  ? avgReviewCount.twoStarAvg.toFixed(2)
                                  : 0
                                  }%`,
                                backgroundColor: "#F86A3D",
                              }}
                            ></div>
                          </div>
                          <span>
                            {avgReviewCount.twoStarAvg !== null
                              ? avgReviewCount.twoStarAvg.toFixed(2)
                              : 0}
                            %
                          </span>
                        </div>
                        <div className="ai-reviewbar">
                          <span>1</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: `${avgReviewCount.oneStarAvg !== null
                                  ? avgReviewCount.oneStarAvg.toFixed(2)
                                  : 0
                                  }%`,
                                backgroundColor: "#FB467C",
                              }}
                            ></div>
                          </div>
                          <span>
                            {avgReviewCount.oneStarAvg !== null
                              ? avgReviewCount.oneStarAvg.toFixed(2)
                              : 0}
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p className="MuiTypography-root MuiTypography-body1 css-1s9zzhr">
                  This tool has not been reviewed yet. Be the first to share your thoughts.
                </p>
              )}
            </div>
          </div>
          {/* Alternative tools Sections */}
          <div className="ai-alternative-tool">
            <h4 className="ai-h4">
              Alternative AI Tools for {detailData.toolName}
            </h4>
            <div className="row">
              {similarData.length > 0 ? (
                similarData.map((item) => (
                  <div className="col-sm-6 col-lg-4">
                    <Aicardcomponent key={item._id} data={item} filterBykeyWordApi={filterBykeyWordApi} />
                  </div>
                ))
              ) : (
                <div className="ai-norecord">
                  <span>No Record Found</span>
                </div>
              )}
            </div>
          </div>
          {/* Model box */}
          <div
            className={`ai-model ${isActive ? "ai-model-open" : ""}`}
            style={{ display: styleShow ? "flex" : "none" }}
          >
            <Ratingmodal
              handleClose={handleClose}
              handleRating={handleRating}
              rating={rating}
              toolName={detailData.toolName}
              toolId={detailData._id}
              handleToast={handleToast}
            />
          </div>
          <div
            className={`ai-model ${isEdit ? "ai-model-open" : ""}`}
            style={{ display: isEditStyle ? "flex" : "none" }}
          >
            <Editratingmodal
              rating={rating}
              handleRating={handleRating}
              editorData={editorData}
              handleEditorStateonChange={handleEditorStateonChange}
              handleClose={handleEditClose}
              handleClickReview={handleEditReview}
            />
          </div>
          <div
            className={`ai-model ${isDelete ? "ai-model-open" : ""}`}
            style={{ display: isDeleteStyle ? "flex" : "none" }}
          >
            <Deletemodal
              handleClose={handleDeleteClose}
              handleDeleteReview={handleDeleteReview}
            />
          </div>
        </>
      </div>
      <Snackbar
        open={toastOpen}
        onClose={handleClosetoast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClosetoast} variant="filled" severity="info">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successToastOpen}
        onClose={handleClosetoast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClosetoast} variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorToast}
        onClose={handleClosetoast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClosetoast} variant='filled' severity='error' >{message}</Alert>
      </Snackbar>
    </>
  );
};

export default Tooldetailscontent;
